import { all, takeLatest } from 'redux-saga/effects';
import { PolymersTypes } from '../types/polymers.types';
import { PaintsTypes } from '../types/paints.types';
import { SolventsTypes } from '../types/solvents.types';
import { ConversionTypes } from '../types/conversion.types';
import filterPolymers from './polymers';
import filterPaints from './paints';
import filterSolvents from './solvents';
import { conversionUnitMeasure, costEstimate } from './conversion';
import { SimulationTypes } from '../types/simulation.types';
import {
    simulate,
    deleteSimulation,
    selectSimulation,
    newSimulation,
    compareSimulations
} from './simulation';
import { UserTypes } from '../types/user.types';
import { recoveryEmail, recoverUser } from './user';
import { ContentTypes } from '../types/content.types';
import getTermConditions from './content';
import { SearchTypes } from '../types/search.types';
import { search, searchGroups, searchUsers } from './search';
import { GroupsTypes } from '../types/groups.types';
import {
    deleteGroupRequest,
    takeGroupRequest,
    saveGroupDataRequest,
    saveGroupUsersRequest,
    saveGroupPolymersRequest,
    saveGroupSolventsRequest
} from './groups';
import { UsersTypes } from '../types/users.types';
import getTermsRequest, { saveTermsRequest } from './terms';
import { TermsTypes } from '../types/terms.types';
import { ProjectsTypes } from '../types/projects.types';
import {
    getProjectsRequest,
    saveProjectRequest,
    recoverProjectRequest,
    deleteProjectRequest
} from './projects';
import { deleteUserRequest, takeUserRequest, saveUserRequest } from './users';
import {
    uploadPropertiesRequest,
    downloadPropertiesRequest
} from './properties';
import { PropertiesTypes } from '../types/properties.types';

export default function* rootSaga() {
    yield all([
        takeLatest(PolymersTypes.FILTER_REQUEST, filterPolymers),
        takeLatest(PaintsTypes.GET_REQUEST, filterPaints),
        takeLatest(SolventsTypes.FILTER_REQUEST, filterSolvents),
        takeLatest(ConversionTypes.UNIT_MEASURE_REQUEST, conversionUnitMeasure),
        takeLatest(ConversionTypes.COST_ESTIMATE_REQUEST, costEstimate),
        takeLatest(SimulationTypes.SIMULATE_REQUEST, simulate),
        takeLatest(SimulationTypes.DELETE_SIMULATION, deleteSimulation),
        takeLatest(SimulationTypes.SELECT_SIMULATION, selectSimulation),
        takeLatest(SimulationTypes.NEW_SIMULATION, newSimulation),
        takeLatest(UserTypes.RECOVERY_REQUEST, recoveryEmail),
        takeLatest(
            ContentTypes.GET_TERMS_CONDITIONS_REQUEST,
            getTermConditions
        ),
        takeLatest(ProjectsTypes.SAVE_PROJECT_REQUEST, saveProjectRequest),
        takeLatest(
            ProjectsTypes.RECOVER_PROJECT_REQUEST,
            recoverProjectRequest
        ),
        takeLatest(UserTypes.USER_REQUEST, recoverUser),
        takeLatest(SimulationTypes.COMPARE_SIMULATIONS, compareSimulations),
        takeLatest(SearchTypes.SEARCH_REQUEST, search),
        takeLatest(GroupsTypes.SEARCH_GROUPS_REQUEST, searchGroups),
        takeLatest(GroupsTypes.DELETE_GROUP_REQUEST, deleteGroupRequest),
        takeLatest(UsersTypes.SEARCH_USER_REQUEST, searchUsers),
        takeLatest(GroupsTypes.TAKE_GROUP_REQUEST, takeGroupRequest),
        takeLatest(GroupsTypes.SAVE_GROUP_DATA, saveGroupDataRequest),
        takeLatest(GroupsTypes.SAVE_GROUP_USERS, saveGroupUsersRequest),
        takeLatest(GroupsTypes.SAVE_GROUP_POLYMERS, saveGroupPolymersRequest),
        takeLatest(GroupsTypes.SAVE_GROUP_SOLVENTS, saveGroupSolventsRequest),
        takeLatest(TermsTypes.SAVE_TERMS_REQUEST, saveTermsRequest),
        takeLatest(TermsTypes.GET_TERMS_REQUEST, getTermsRequest),
        takeLatest(ProjectsTypes.GET_REQUEST, getProjectsRequest),
        takeLatest(ProjectsTypes.DELETE_PROJECT_REQUEST, deleteProjectRequest),
        takeLatest(UsersTypes.DELETE_USER_REQUEST, deleteUserRequest),
        takeLatest(UsersTypes.TAKE_USER_REQUEST, takeUserRequest),
        takeLatest(UsersTypes.SAVE_USER_REQUEST, saveUserRequest),
        takeLatest(PropertiesTypes.UPLOAD_REQUEST, uploadPropertiesRequest),
        takeLatest(PropertiesTypes.DOWNLOAD_REQUEST, downloadPropertiesRequest)
    ]);
}
