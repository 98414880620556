import { Reducer } from 'redux';
import { PaintsState, PaintsTypes } from '../../types/paints.types';

const INITIAL_STATE: PaintsState = {
    items: [],
    loading: false,
    error: false,
    message: ''
};

const reducer: Reducer<PaintsState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PaintsTypes.GET_REQUEST:
            return { ...state, loading: true };
        case PaintsTypes.GET_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                items: action.payload.paints,
                message: action.payload.message
            };
        case PaintsTypes.GET_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                items: [],
                message: ''
            };
        default:
            return state;
    }
};

export default reducer;
