import React from 'react';
import { bindActionCreators } from 'redux';
import { User as UserModel } from '../../../../../../../models/user.model';
import { ApplicationState } from '../../../../../../../redux/store';
import { connect } from 'react-redux';
import { Loading } from '../../../../../../../components/UI';
import { LoadingBox } from './index.styled';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UserContainer from './container';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    takeUser,
    deleteUser,
    saveUser
} from '../../../../../../../redux/actions/users.actions';

class User extends React.Component<Props, {}> {
    render() {
        const { loading, user } = this.props;
        return loading || !user ? (
            <LoadingBox>
                <Loading />
            </LoadingBox>
        ) : (
            <UserContainer {...this.props} user={user!} />
        );
    }

    componentWillMount() {
        const { match, history, takeUser } = this.props;
        if (match && match.params && match.params['id']) {
            const userId = match.params['id'];
            takeUser(userId);
        } else {
            history.push('/master/users');
        }
    }
}

interface Props extends RouteComponentProps, WithTranslation {
    user: UserModel | null;
    loading: boolean;
    takeUser(id: number): void;
    deleteUser(user: UserModel, callback?: Function): void;
    saveUser(user: UserModel, callback?: Function): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    user: state.users.current,
    loading: state.users.loading
});

const mapDispatchToProps = dispatcher =>
    bindActionCreators(
        {
            takeUser,
            deleteUser,
            saveUser
        },
        dispatcher
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('app')(withRouter(User)));
