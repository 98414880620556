import styled from 'styled-components';
import { Span, H2, Icon } from '../../../../../components/UI';
import { StyledProps } from '../../../../../models/styledProps.model';

export const Container = styled.div`
    overflow-x: auto;
`;

export const Header = styled.div`
    width: 100%;
    padding: 24px 0px;
`;

export const Title = styled(H2)`
    display: inline-block;
`;

export const Hint = styled(Icon)`
    display: inline-block;
    margin-left: 15px;
`;

export const Legend = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 24px;
`;

export const SolventSpan = styled(Span)`
    font-family: 'Source Sans Pro';
    font-size: 14px;
    line-height: 18px;
    color: #444444;
`;

export const SolventColor = styled.div<StyledProps>`
    box-sizing: border-box;
    margin-right: 4px;
    height: 0px;
    ${({ styledProps }) =>
        `border-top: 2px ${styledProps.current ? 'solid' : 'dashed'} ${
            styledProps.color
        }`};
    width: 15px;
`;

export const SolventContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 24px;
`;

export const SimularionName = styled(Span)`
    font-size: 12px;
    line-height: 18px;
    color: #a6a6a6;
    margin-bottom: 8px;
    display: block;
`;

export const GraphHeaderContanier = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`;

export const Label = styled(Span)`
    font-family: 'Source Sans Pro';
    font-size: 14px;
    line-height: 18px;
    margin: 0 5px;
`;

export const LabelContainer = styled.div`
    margin-right: 40px;
`;

export const GraphHeader = styled(Label)`
    color: #763689;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
`;

export const LabelRED = styled(Label)`
    color: #a70e1a;
`;

export const LabelGreen = styled(Label)`
    color: #0b8e57;
`;

export const PolymersEvaporation = styled(Span)`
    position: relative;
    height: 40px;
    line-height: 40px;
    color: #763689;
    font-size: 16px;
    font-weight: 600;
    float: right;
    right: 40px;
`;
