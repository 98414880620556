import React from 'react';
import { Translator, Empty } from '../../..';
import i18next from 'i18next';
import {
    StyledStep,
    StepInfo,
    StepNumber,
    StepTitle,
    StepEditBtn
} from './index.styled';
import { IWizardContext } from '../..';

export const StepContainer = ({
    number,
    i18nTitle,
    children,
    currentStep,
    stepProgress,
    goToStep,
    wizardSize
}: IStepContainerProps) => {
    const renderEditButton = () => {
        if (
            currentStep === number ||
            stepProgress! < number ||
            wizardSize === number
        ) {
            return <Empty />;
        }
        return (
            <StepEditBtn
                id={`Step${number}_editBtn`}
                iconName="edit-alt"
                iconSize={25}
                onClick={() => {
                    goToStep!(number);
                }}
            />
        );
    };
    return (
        <StyledStep id={`Step${number}`}>
            <StepInfo id={`Step${number}_info`}>
                <StepNumber id={`Step${number}_info_number`}>
                    {number}
                </StepNumber>
                <Translator
                    id={`Step${number}_info_title_translator`}
                    translate={i18nTitle}
                    render={(translate: string, t: i18next.TFunction) => (
                        <StepTitle id={`Step${number}_info_title`}>
                            {t(translate)}
                        </StepTitle>
                    )}
                />
            </StepInfo>
            {renderEditButton()}
            <section id={`Step${number}_content`}>{children}</section>
        </StyledStep>
    );
};

export interface IStepContainerProps extends Partial<IWizardContext> {
    number: number;
    wizardSize: number;
    i18nTitle: string;
    children?;
}
