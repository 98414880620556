import React from 'react';
import MasterContainer from './container';
import { ApplicationState } from '../../redux/store';
import { connect } from 'react-redux';
import { Empty } from '../../components/UI';
import withAuthentication from '../../components/hoc/withAuthentication';

const Master = props =>
    props.user ? <MasterContainer {...props} /> : <Empty />;

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.currentUser
});

export default connect(mapStateToProps)(withAuthentication(Master));
