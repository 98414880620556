import React from 'react';
import { CardBox } from './index.styled';

export const Card = ({ className, children }: ICardProps) => (
    <CardBox className={className}>{children}</CardBox>
);

interface ICardProps {
    className?: string;
    children?;
}
