import React from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { Select } from '../Select';

export class Search extends React.Component<Props, {}> {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: ''
        };
    }

    private removeFocusOfSelect = target => {
        const { limit, values } = this.props;
        if (limit && values.length === limit - 1) {
            setTimeout(() => target.blur(), 1);
        }
    };

    private handleSelectChange = ({ target }, { value }) => {
        const { options, onChange } = this.props;
        const item = options.find(item => item.value === value);
        this.removeFocusOfSelect(target);
        if (onChange) {
            onChange(item);
        }
    };

    private handleSearchChange = searchQuery => {
        const { onSearchChange, startSearch } = this.props;
        if (searchQuery.length >= startSearch) {
            this.setState({ searchQuery: searchQuery }, () =>
                onSearchChange(searchQuery)
            );
        }
    };

    render() {
        const {
            id,
            placeholder,
            loading,
            options,
            startSearch,
            noResultsMessage
        } = this.props;
        return (
            <Select
                id={id}
                placeholder={placeholder}
                minCharacters={startSearch}
                noResultsMessage={noResultsMessage}
                loading={loading}
                options={options}
                search
                fluid
                selectOnNavigation={false}
                selectOnBlur={false}
                icon="search"
                value={null}
                iconLeft
                onChange={this.handleSelectChange}
                onSearchChange={(_, { searchQuery }) =>
                    this.handleSearchChange(searchQuery)
                }
            />
        );
    }

    static defaultProps = {
        startSearch: 2
    };
}

interface Props {
    id?: string;
    placeholder?: string;
    noResultsMessage: string;
    options: DropdownItemProps[];
    loading: boolean;
    startSearch: number;
    limit?: number;
    values: any[];
    onChange(item): void;
    onSearchChange(searchQuery: string): void;
}
