import React from 'react';
import Ruler from 'rc-ruler-slider/dist';
import { Container, RulerDescription } from './index.styled';
import { Grid, Column, Row } from '../../../../../components/UI';
import Graph from './Graph';
import { Header, Title, Hint } from '../index.styled';
import { SolventsEvaporation } from '../../../../../models/simulationResult.model';
import pallet from '../index.pallet.json';

class Composition extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            value: 0
        };
    }

    private handleDragEnd = value => {
        this.setState({ value });
    };

    private solventsData = composition => {
        const { value } = this.state;
        const data = composition.solventsEvaporation[value === 0 ? 1 : value];
        if (!data) {
            const nextOrPreviousData = composition.solventsEvaporation[
                value + 1
            ]
                ? composition.solventsEvaporation[value + 1]
                : composition.solventsEvaporation[value - 1];
            return nextOrPreviousData ? nextOrPreviousData : [];
        }
        return data;
    };

    render() {
        const { compositions } = this.props;
        const reference = compositions.find(
            composition => composition.selected === 1
        )!;
        return (
            <Container>
                <Header>
                    <Title i18nKey="compositionGraph.title" />
                    <Hint
                        name="info-circle"
                        size={19}
                        i18nKey="compositionGraph.hint"
                    />
                </Header>
                <Grid margin={0} padding={0}>
                    <Row>
                        {compositions.map((composition, index) => {
                            return (
                                <Graph
                                    key={index}
                                    isComparation={compositions.length >= 2}
                                    data={this.solventsData(composition)}
                                    name={composition.name}
                                    pallet={
                                        index === 0
                                            ? pallet.solvent.current
                                            : pallet.solvent.proposal
                                    }
                                />
                            );
                        })}
                    </Row>
                    <Row>
                        <Column xs={12} sm={12} md={12} lg={12}>
                            <Ruler
                                onDragEnd={this.handleDragEnd}
                                start={0}
                                end={90}
                                step={1}
                                renderValue={value => `${value}%`}
                            />
                            <RulerDescription
                                i18nKey={
                                    reference.is_volume
                                        ? 'compositionGraph.rulerVolumeDescription'
                                        : 'compositionGraph.rulerMassDescription'
                                }
                            />
                        </Column>
                    </Row>
                </Grid>
            </Container>
        );
    }
}

interface Props {
    compositions: CompositionGraph[];
}

interface CompositionGraph {
    solventsEvaporation: SolventsEvaporation[];
    name: string;
    selected: number;
    is_volume: boolean;
}

export default Composition;
