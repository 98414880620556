import React from 'react';
import { useTranslation } from 'react-i18next';
import { htmlT } from '../../../helpers/util';

export const withTranslationText = WrappedComponent => props => {
    const { i18nKey, renderText, ...rest } = props;
    const { t } = useTranslation('app');

    const getText = () => {
        if (!i18nKey) {
            return renderText;
        }

        let translated = i18nKey;

        if (typeof i18nKey === 'string' && !i18nKey.includes(' ')) {
            translated = htmlT(t, i18nKey);
        }

        if (Array.isArray(i18nKey)) {
            translated = htmlT(
                t,
                i18nKey[0],
                typeof i18nKey[1] === 'function' ? i18nKey[1](t) : i18nKey[1]
            );
        }

        if (translated === i18nKey) {
            return renderText ? renderText : translated;
        }

        return translated;
    };

    const text = getText();

    return <WrappedComponent {...rest} renderText={text} />;
};

