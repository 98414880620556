import React from 'react';
import { Grid, Row } from '../../../../../../../../../../components/UI';
import { StyledColumn, Title, PropSpan, ResSpan } from './index.styled';
import { User } from '../../../../../../../../../../models/user.model';

const Data = ({ user }: Props) => {
    return (
        <Grid>
            <Row>
                <StyledColumn xs={12} sm={12} md={12} lg={12}>
                    <Title renderText={user.display_name} />
                </StyledColumn>
            </Row>
            <Row>
                <StyledColumn xs={12} sm={8} md={8} lg={8}>
                    <PropSpan
                        cl="secondary"
                        i18nKey="forms.userProfile.email"
                    />
                    <ResSpan renderText={user.email} />
                </StyledColumn>
                <StyledColumn xs={12} sm={4} md={4} lg={4}>
                    <PropSpan
                        cl="secondary"
                        i18nKey="forms.userProfile.phoneNumber"
                    />
                    <ResSpan renderText={user.phone_number} />
                </StyledColumn>
            </Row>
        </Grid>
    );
};

interface Props {
    user: User;
}

export default Data;
