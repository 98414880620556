import { call, put } from 'redux-saga/effects';
import { getPaintsError, getPaintsSuccess } from '../../actions/paints.actions';
import api from '../../../services/api';

export default function* filterPaints() {
    try {
        const { data } = yield call(api.get, `/paints`);
        if (data.object) {
            yield put(getPaintsSuccess({ paints: data.object, message: '' }));
        } else {
            yield put(
                getPaintsSuccess({
                    paints: [],
                    message: data.messages.warnings[0]
                })
            );
        }
    } catch (err) {
        yield put(getPaintsError());
    }
}
