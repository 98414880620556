import React from 'react';
import { Grid, Span, Line } from '../../../../../../../components/UI';
import {
    Title,
    StyledRow,
    StyledColumn,
    NextButton,
    Email
} from './index.styled';
import EmailRecoveryForm from '../../../../../../../components/forms/EmailRecovery';

class EmailRecoveryContainer extends React.Component<Props, any> {
    private recoveryEmail = data => {
        const { recoveryEmail } = this.props;
        recoveryEmail(data.cellphone);
    };

    renderEmail() {
        const {
            returnToLogin,
            recoveredEmail,
            recoveryEmailClear
        } = this.props;
        return (
            <React.Fragment>
                <StyledRow>
                    <Title i18nKey="recoveryEmail.after.title" cl="primary" />
                </StyledRow>
                <StyledRow>
                    <Email renderText={recoveredEmail} />
                </StyledRow>
                <StyledRow>
                    <Line />
                </StyledRow>
                <StyledRow>
                    <StyledColumn
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        flex={{
                            align: 'center',
                            justify: 'end'
                        }}
                    >
                        <NextButton
                            i18nKey="recoveryEmail.after.returnToLogin"
                            cl="primary"
                            type="button"
                            onClick={() => {
                                returnToLogin();
                                recoveryEmailClear();
                            }}
                        />
                    </StyledColumn>
                </StyledRow>
            </React.Fragment>
        );
    }

    private renderForm() {
        const { loading, returnToLogin } = this.props;
        return (
            <React.Fragment>
                <StyledRow>
                    <Title i18nKey="recoveryEmail.before.title" cl="primary" />
                </StyledRow>
                <StyledRow>
                    <Span i18nKey="recoveryEmail.before.message" />
                </StyledRow>
                <StyledRow>
                    <EmailRecoveryForm
                        onSubmit={this.recoveryEmail}
                        returnToLogin={returnToLogin}
                        loading={loading}
                    />
                </StyledRow>
            </React.Fragment>
        );
    }

    render() {
        const { recoveredEmail } = this.props;
        return (
            <Grid margin={0} padding={0}>
                {recoveredEmail ? this.renderEmail() : this.renderForm()}
            </Grid>
        );
    }

    componentWillMount() {
        const { recoveryEmailClear } = this.props;
        recoveryEmailClear();
    }
}

interface Props {
    recoveredEmail: string;
    loading: boolean;
    recoveryEmail(cellphone: string): void;
    recoveryEmailClear(): void;
    returnToLogin(): void;
}

export default EmailRecoveryContainer;
