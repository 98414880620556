import React from 'react';
import SimulationTopbarContainer from './container';
import { modal } from '../../../../components/UI/Modal';
import SaveProject from '../../../../components/common/SaveProject';
import { Project } from '../../../../models/project.model';
import { height } from '../../../../components/layout/Header/Topbar/container/index.styled';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearProject } from '../../../../redux/actions/projects.actions';

class SimulationTopbar extends React.PureComponent<
    ISimulationTopbarProps,
    ISimulationTopbarState
> {
    constructor(props) {
        super(props);
        this.state = {
            fixed: false
        };
    }

    private handleScroll = e => {
        const { fixed } = this.state;
        const paths = e.path || (e.composedPath && e.composedPath());
        if (!paths) {
            return;
        }
        if (paths[paths.length - 1].scrollY > height) {
            this.setState({
                fixed: true
            });
        } else if (fixed) {
            this.setState({
                fixed: false
            });
        }
    };

    private exit = () => {
        const { history, clearProject } = this.props;
        clearProject();
        history.push('/projects');
    };

    private saveProject = ({ exit }) => {
        const { project } = this.props;
        modal({})(({ close }) => {
            return (
                <SaveProject
                    project={project}
                    close={close}
                    callback={exit && this.exit}
                />
            );
        });
    };

    render() {
        const { fixed } = this.state;
        const { project } = this.props;
        return (
            <SimulationTopbarContainer
                {...this.props}
                projectName={project.name}
                onExit={this.exit}
                onSaveProject={this.saveProject}
                fixed={fixed}
            />
        );
    }

    componentDidMount = () => {
        document.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount = () => {
        document.removeEventListener('scroll', this.handleScroll);
    };
}

interface ISimulationTopbarProps extends RouteComponentProps {
    project: Project;
    isChanged: boolean;
    clearProject(): void;
}

interface ISimulationTopbarState {
    fixed: boolean;
}

const mapStateToProps = dispatch =>
    bindActionCreators(
        {
            clearProject
        },
        dispatch
    );

export default connect(
    null,
    mapStateToProps
)(withRouter(SimulationTopbar));
