import styled from 'styled-components';

export const StyledLabel = styled.label`
    color: ${props => (!props['disabled'] ? '#444444' : '#cecece')};
    font-size: 16px;
    line-height: 20px;
    height: 25px;
    display: block;
`;
StyledLabel.displayName = 'label';
