import { action } from "typesafe-actions";
import { ConversionTypes } from "../types/conversion.types";

export const costEstimateRequest = data =>
    action(ConversionTypes.COST_ESTIMATE_REQUEST, data);
export const unitMeasureRequest = data =>
    action(ConversionTypes.UNIT_MEASURE_REQUEST, data);

export const costEstimateSuccess = data =>
    action(ConversionTypes.COST_ESTIMATE_SUCCESS, data);
export const unitMeasureSuccess = data =>
    action(ConversionTypes.UNIT_MEASURE_SUCCESS, data);

export const costEstimateFailure = message =>
    action(ConversionTypes.COST_ESTIMATE_FAILURE, message);
export const unitMeasureFailure = message =>
    action(ConversionTypes.UNIT_MEASURE_FAILURE, message);
