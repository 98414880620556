import React from 'react';
import { bindActionCreators } from 'redux';
import SaveProjectContainer from './container';
import { connect } from 'react-redux';
import connectWithStore from '../../hoc/connectWithStore';
import { Project } from '../../../models/project.model';
import { saveProject } from '../../../redux/actions/projects.actions';

const SaveProject = (props: Props) => <SaveProjectContainer {...props} />;

interface Props {
    project: Project;
    isEdit?: boolean;
    saveProject(project: Project, callback: Function): void;
    close(): void;
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveProject
        },
        dispatch
    );

export default connectWithStore(
    connect(
        null,
        mapDispatchToProps
    )(SaveProject)
);
