import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import {
    uploadPropertiesSuccess,
    uploadPropertiesError,
    downloadSuccess,
    downloadError
} from '../../actions/properties.actions';
import FileDownload from 'js-file-download';

export function* uploadPropertiesRequest(action) {
    const { file, callback } = action.payload;
    try {
        const formData = new FormData();
        formData.append('file', file);
        const { status, data } = yield call(
            api.post,
            'maintenance/chemical_data',
            formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }
        );
        if (status === 200) {
            yield put(uploadPropertiesSuccess());
            if (callback) {
                callback(data);
            }
        } else {
            yield put(uploadPropertiesError());
        }
    } catch (ex) {
        yield put(uploadPropertiesError());
    }
}

export function* downloadPropertiesRequest(action) {
    const { callback } = action.payload;
    try {
        const { status, data } = yield call(
            api.get,
            'maintenance/chemical_data',
            { responseType: 'arraybuffer' }
        );
        if (status === 200) {
            const blob = new Blob([data], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml;charset=UTF-8'
            });
            FileDownload(blob, 'database.xlsx');
            yield put(downloadSuccess());
            if (callback) {
                callback();
            }
        } else {
            yield put(downloadError());
        }
    } catch (ex) {
        yield put(downloadError());
    }
}
