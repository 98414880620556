import Group from '../../models/group.model';

/**
 * Action types
 */
export enum GroupsTypes {
    SEARCH_GROUPS_REQUEST = 'search:SEARCH_GROUPS_REQUEST',
    SEARCH_GROUPS_SUCCESS = 'search:SEARCH_GROUPS_SUCCESS',
    SEARCH_GROUPS_FAILURE = 'search:SEARCH_GROUPS_FAILURE',
    DELETE_GROUP_REQUEST = 'groups:DELETE_GROUP_REQUEST',
    DELETE_GROUP_SUCCESS = 'groups:DELETE_GROUP_SUCCESS',
    DELETE_GROUP_FAILURE = 'groups:DELETE_GROUP_FAILURE',
    TAKE_GROUP_REQUEST = 'groups:TAKE_GROUP_REQUEST',
    TAKE_GROUP_SUCCESS = 'groups:TAKE_GROUP_SUCCESS',
    TAKE_GROUP_FAILURE = 'groups:TAKE_GROUP_FAILURE',
    SAVE_GROUP_DATA = 'groups:SAVE_GROUP_DATA',
    SAVE_GROUP_USERS = 'groups:SAVE_GROUP_USERS',
    SAVE_GROUP_POLYMERS = 'groups:SAVE_GROUP_POLYMERS',
    SAVE_GROUP_SOLVENTS = 'groups:SAVE_GROUP_SOLVENTS',
    SAVE_GROUP_SUCCESS = 'groups:SAVE_GROUP_SUCCESS',
    SAVE_GROUP_FAILURE = 'groups:SAVE_GROUP_FAILURE'
}

/**
 * State types
 */
export interface GroupsState {
    readonly items: Group[];
    readonly current: Group | null;
    readonly loading: boolean;
    readonly error: boolean;
    readonly message: string;
    readonly paging: {
        total_items: number;
        total_pages: number;
    };
}
