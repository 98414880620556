import React from 'react';
import { StyledCard } from './index.styled';
import { Grid, Column, Line, Empty, Hint } from '../../../../../components/UI';
import SimulationResultWithConfigs from '../../../../../models/simulationResultWithConfigs.model';
import moment from 'moment';
import { Paint } from '../../../../../models/paint.model';
import { confirmDelete } from '../../../../../components/UI/Dialog';
import { toFixedTrunc } from '../../../../../helpers/util';

export class SimulationResultCardContainer extends React.Component<
    SimulationResultCardContainerProps,
    any
> {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            hover: false,
            locked: false,
            name: ''
        };
    }

    public expand = () => {
        this.setState({
            expanded: true
        });
    };

    public compress = () => {
        this.setState({
            expanded: false
        });
    };

    private onHover = () => {
        this.setState({ hover: true });
    };

    private leaveHover = () => {
        const { performed } = this.props;
        if (!performed!.checked) {
            this.setState({ hover: false });
        }
    };

    private handleDelete = () => {
        const { simulation, deleteCurrentSimulation } = this.props;
        confirmDelete(simulation.name, () =>
            deleteCurrentSimulation(simulation)
        );
    };

    private handleNameBlur = () => {
        const { changeSimulationName, simulation } = this.props;
        const { name } = this.state;
        if (simulation.name !== name) {
            changeSimulationName(simulation.date, name);
        }
    };

    private changeName = e => {
        const { name } = this.state;
        const value = e.target.value;
        if (name !== value) {
            this.setState({ name: e.target.value });
        }
    };

    private renderHeader() {
        const { simulation } = this.props;
        const { name } = this.state;
        return (
            <StyledCard.Header padding={5} cols={12} margin={0}>
                <StyledCard.Header.Row>
                    <StyledCard.Header.Column
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        flex={{ align: 'center', justify: 'start' }}
                    >
                        <StyledCard.HeaderFlask name="flask" />
                        <StyledCard.HeaderInputName
                            value={name}
                            onBlur={this.handleNameBlur}
                            onChange={this.changeName}
                            maxLength="15"
                        />
                    </StyledCard.Header.Column>
                    <StyledCard.Header.Column xs={4} sm={4} md={4} lg={4}>
                        <StyledCard.HeaderDateTime>
                            <StyledCard.HeaderDate
                                renderText={moment(simulation.date).format(
                                    'DD/MM/YYYY'
                                )}
                            />
                            <StyledCard.HeaderTime
                                renderText={`${moment(simulation.date).format(
                                    'HH'
                                )}h${moment(simulation.date).format('mm')}m`}
                            />
                        </StyledCard.HeaderDateTime>
                    </StyledCard.Header.Column>
                </StyledCard.Header.Row>
            </StyledCard.Header>
        );
    }

    private renderBody() {
        const { expanded } = this.state;
        const { simulation, paints, performed } = this.props;

        const initialSolubility = (() => {
            const initial = simulation.result.graphs.mixing.paths[0];
            return {
                d: toFixedTrunc(initial.delta_d, 1),
                p: toFixedTrunc(initial.delta_p, 1),
                h: toFixedTrunc(initial.delta_h, 1)
            };
        })();

        const renderExpandedInformations = getRenderExpandedInfo(paints, simulation);

        const renderPolymersRed = getRenderPolymerRed(simulation, expanded);

        return (
            <StyledCard.Body onMouseEnter={performed ? this.onHover : () => {}}>
                <Grid cols={22} padding={0} margin={0}>
                    <StyledCard.BodyRow>
                        <StyledCard.BodySpan
                            cl="primary"
                            i18nKey="simulationResultCard.body.initialSolubilityParameters"
                        />
                        <StyledCard.BodySpan.DPH>
                            <StyledCard.BodySpan
                                cl="secondary"
                                renderText="dD"
                            />
                            <StyledCard.BodySpan
                                renderText={initialSolubility.d}
                            />
                        </StyledCard.BodySpan.DPH>
                        <StyledCard.BodySpan.DPH>
                            <StyledCard.BodySpan
                                cl="secondary"
                                renderText="dP"
                            />
                            <StyledCard.BodySpan
                                renderText={initialSolubility.p}
                            />
                        </StyledCard.BodySpan.DPH>
                        <StyledCard.BodySpan.DPH>
                            <StyledCard.BodySpan
                                cl="secondary"
                                renderText="dH"
                            />
                            <StyledCard.BodySpan
                                renderText={initialSolubility.h}
                            />
                        </StyledCard.BodySpan.DPH>
                    </StyledCard.BodyRow>
                    <StyledCard.BodyRow>
                        <StyledCard.BodySpan
                            cl="primary"
                            i18nKey="simulationResultCard.body.unitConcentration"
                        />
                        {simulation.is_volume ? (
                            <StyledCard.UnitVolume i18nKey="simulationResultCard.body.unitVolume" />
                        ) : (
                            <StyledCard.UnitMass i18nKey="simulationResultCard.body.unitMass" />
                        )}
                    </StyledCard.BodyRow>
                    <StyledCard.BodyRow>
                        <StyledCard.BodySpan
                            cl="primary"
                            i18nKey="simulationResultCard.body.initialRED"
                        />
                        <Hint i18nKey="simulationResultCard.hints.red" />
                    </StyledCard.BodyRow>
                    {renderPolymersRed()}
                    {expanded ? renderExpandedInformations() : <Empty />}
                </Grid>
            </StyledCard.Body>
        );
    }

    private renderFooter() {
        const { expanded } = this.state;
        const { isComparation } = this.props;
        return (
            <StyledCard.Footer>
                {!isComparation && (
                    <StyledCard.FooterBtn
                        i18nKey="simulationResultCard.footer.buttons.delete"
                        onClick={this.handleDelete}
                    />
                )}
                {expanded === false ? (
                    <StyledCard.FooterExpandBtn
                        i18nKey="simulationResultCard.footer.buttons.expand"
                        onClick={this.expand}
                    />
                ) : (
                    <StyledCard.FooterExpandBtn
                        i18nKey="simulationResultCard.footer.buttons.recall"
                        onClick={this.compress}
                    />
                )}
            </StyledCard.Footer>
        );
    }

    private renderLockedOverlay() {
        const { performed } = this.props;
        let locked = false;
        if (performed) {
            if (performed.checked) {
                locked = performed.checked.locked;
            }
        }
        if (!performed || !locked) {
            return <Empty />;
        }
        return (
            <StyledCard.LockedOverlay>
                <StyledCard.LockedOverlay.Padlock name="padlock" />
            </StyledCard.LockedOverlay>
        );
    }

    private renderOverlay() {
        const { selectSimulation, simulation, performed, t } = this.props;
        const { expanded, hover } = this.state;
        const returnval = checkPerformance(performed,expanded,hover);
        if(returnval!=null){
            return returnval;
        }        
        return (
            <StyledCard.Overlay
                data-checked={performed!.checked}
                onMouseLeave={this.leaveHover}
            >
                {performed!.simulationsLength > 1 &&
                    performed!.lockedCardsNumber < 2 && (
                        <StyledCard.Overlay.ComparativeCheck
                            tooltip={
                                performed!.checkedCardsTotal > 0
                                    ? null
                                    : t(
                                          'simulationResultCard.overlay.checkTooltip'
                                      )
                            }
                            value={performed!.checked ? true : false}
                            onChange={() => {
                                !performed!.checked
                                    ? performed!.cardCheck(simulation)
                                    : performed!.cardUncheck(simulation.date);
                            }}
                        />
                    )}
                {!performed!.checked && (
                    <StyledCard.Overlay.ResultButton
                        i18nKey="simulationResultCard.overlay.buttons.result"
                        onClick={() => {
                            selectSimulation(simulation);
                            if (performed) {
                                performed.compress();
                            }
                        }}
                    />
                )}
            </StyledCard.Overlay>
        );
    }

    render() {
        return (
            <StyledCard>
                {this.renderLockedOverlay()}
                {this.renderOverlay()}
                {this.renderHeader()}
                {this.renderBody()}
                {this.renderFooter()}
            </StyledCard>
        );
    }

    private setName() {
        const { name } = this.state;
        const { simulation } = this.props;
        if (name !== simulation.name) {
            this.setState({ name: simulation.name });
        }
    }

    componentWillMount() {
        this.setName();
    }

    componentWillReceiveProps() {
        this.setName();
    }
}

export interface SimulationResultCardContainerProps {
    t: Function;
    simulation: SimulationResultWithConfigs;
    paints: Paint[];
    isComparation?: boolean;
    performed?: {
        compress();
        simulationsLength: number;
        checked?: { date: Date | string; locked: boolean; reference: boolean };
        lockedCardsNumber: number;
        checkedCardsTotal: number;
        cardCheck(simulation: SimulationResultWithConfigs): void;
        cardUncheck(date: Date | string): void;
    };
    changeSimulationName(date: Date | string, name: string): void;
    deleteCurrentSimulation(simulation: SimulationResultWithConfigs): void;
    selectSimulation(simulation: SimulationResultWithConfigs): void;
}
function getRenderExpandedInfo(paints: any, simulation: any) {
    return () => {
        const renderConditions = () => {
            const paintName = paints.find(
                paint => paint.id === simulation.parameters.conditions.paint.id
            )!.name;
            return (
                <React.Fragment>
                    <StyledCard.BodyRow>
                        <StyledCard.BodySpan
                            cl="primary"
                            i18nKey="simulationResultCard.body.conditions.title" />
                    </StyledCard.BodyRow>
                    <StyledCard.BodyRow>
                        <StyledCard.BodySpan
                            cl="secondary"
                            i18nKey="simulationResultCard.body.conditions.type" />
                        <StyledCard.BodySpan
                            i18nKey={simulation.parameters.conditions.paint
                                .custom
                                ? [
                                    'simulationResultCard.body.conditions.paint',
                                    {
                                        name: paintName
                                    }
                                ]
                                : null}
                            renderText={paintName} />
                    </StyledCard.BodyRow>
                    <StyledCard.BodyRow>
                        <Column xs={9} sm={9} md={9} lg={9}>
                            <StyledCard.BodySpan
                                cl="secondary"
                                i18nKey="simulationResultCard.body.conditions.moisture" />
                            <StyledCard.BodySpan
                                renderText={`${simulation.parameters.conditions.environment.relative_humidity}%`} />
                        </Column>
                        <Column xs={12} sm={12} md={12} lg={12}>
                            <StyledCard.BodySpan
                                cl="secondary"
                                i18nKey="simulationResultCard.body.conditions.temperature" />
                            <StyledCard.BodySpan
                                renderText={`${simulation.parameters.conditions.environment.temperature}ºC`} />
                        </Column>
                    </StyledCard.BodyRow>
                </React.Fragment>
            );
        };
        const renderSolvents = () => (
            <React.Fragment>
                <StyledCard.BodyRow>
                    <StyledCard.BodySpan
                        cl="primary"
                        i18nKey="simulationResultCard.body.solvents.title" />
                </StyledCard.BodyRow>
                <StyledCard.BodyRow>
                    <StyledCard.BodySpan>
                        {simulation.parameters.solvents.map(
                            (solvent, index) => {
                                let str = `${solvent.solvent.name} (${solvent.concentration}%)`;
                                if (simulation.parameters.solvents[index + 1]) {
                                    str += ' | ';
                                }
                                return str;
                            }
                        )}
                    </StyledCard.BodySpan>
                </StyledCard.BodyRow>
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <Line />
                {renderConditions()}
                {renderSolvents()}
            </React.Fragment>
        );
    };
}

function getRenderPolymerRed(simulation: any, expanded: any) {
    return () => {
        let polymersRed = simulation.result.graphs.polymer_normalized_distance;

        if (!expanded) {
            let bigger = simulation.result.graphs.polymer_normalized_distance[0];
            simulation.result.graphs.polymer_normalized_distance.forEach(
                item => {
                    if (Number(item.normalized_distance_initial) >
                        bigger.normalized_distance_initial) { bigger = item; }
                }
            );
            polymersRed = [bigger];
        }

        return polymersRed.map((polymer, index) => {
            const red = toFixedTrunc(
                polymer.normalized_distance_initial,
                2
            );
            return (
                <StyledCard.PolymersRow key={index}>
                    <Column xs={21} sm={21} md={21} lg={21} right>
                        <StyledCard.PolymersRow.Row>
                            <StyledCard.BodySpan
                                renderText={polymer.name} />
                        </StyledCard.PolymersRow.Row>
                        <StyledCard.PolymersRow.Row>
                            <StyledCard.BodySpan
                                cl="secondary"
                                renderText="RED" />
                            {polymer.normalized_distance_initial > 1 ? (
                                <StyledCard.SpanRed
                                    renderText={red.replace('.', ',')} />
                            ) : (
                                <StyledCard.SpanGreen
                                    renderText={red.replace('.', ',')} />
                            )}
                            {!expanded &&
                                simulation.result.graphs
                                    .polymer_normalized_distance.length >
                                1 &&
                                '...'}
                        </StyledCard.PolymersRow.Row>
                    </Column>
                </StyledCard.PolymersRow>
            );
        });
    };
}

function checkPerformance(performed,expanded,hover) {
    let locked = false;
    if (performed) {
        if (performed.checked) {
            locked = performed.checked.locked;
        } else {
            if (!(!expanded && hover)) {
                return <Empty />;
            }
        }
    } else {
        if (!(!expanded && hover)) {
            return <Empty />;
        }
    }
    if (locked) {
        return <Empty />;
    }
    return null;
}

