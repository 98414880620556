import React from 'react';
import ReactDOM from 'react-dom';
import App from './app';
import * as serviceWorker from './configs/serviceWorker';

import TagManager from 'react-gtm-module';

const tagManagerArgs = {
    gtmId: 'GTM-NGMSDCW'
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById('root'));
serviceWorker.unregister();
