import React from 'react';
import EmptyStep from './EmptyStep';
import {
    Container,
    Title,
    Content,
    Buttons,
    Data,
    DataList
} from './index.styled';
import { Line } from '../../UI';

class MasterStep extends React.PureComponent<Props> {
    private renderCase(componentProps) {
        const { active, stepNumber, haveData } = this.props;
        if (active === stepNumber) {
            return this.renderActive(componentProps);
        }
        if (haveData) {
            return this.renderData(componentProps);
        }
        return this.renderEmpty();
    }

    private renderEmpty() {
        const { name, onEmptyClick, stepNumber, disabled } = this.props;
        return (
            <div
                data-test="MasterStep.Empty"
                onClick={() =>
                    !disabled && onEmptyClick && onEmptyClick(stepNumber)
                }
            >
                <Title i18nKey={name} />
                <Line />
                <Content>
                    <EmptyStep disabled={disabled} />
                </Content>
            </div>
        );
    }

    private renderActive(componentProps) {
        const {
            onDeleteClick,
            name,
            onCancel,
            component: Component
        } = this.props;
        return (
            <Data data-test="MasterStep.Active">
                {onDeleteClick && (
                    <Data.DeleteBtn
                        onClick={onDeleteClick}
                        iconName="trash-alt"
                        iconSize={19}
                    />
                )}
                <Title i18nKey={name} />
                <Content>
                    <Component.Active {...componentProps}>
                        {({ disableSave, onSave }) => (
                            <Buttons>
                                <Buttons.Cancel
                                    onClick={onCancel}
                                    type="button"
                                    i18nKey="stepButtons.cancel"
                                    cl="invertedPrimary"
                                />
                                <Buttons.Save
                                    onClick={onSave}
                                    type="button"
                                    i18nKey="stepButtons.save"
                                    cl="primary"
                                    disabled={disableSave}
                                />
                            </Buttons>
                        )}
                    </Component.Active>
                </Content>
            </Data>
        );
    }

    private renderData(componentProps) {
        const {
            name,
            component: Component,
            onEdit,
            stepNumber,
            onDeleteClick,
            isDataList
        } = this.props;
        if (!isDataList) {
            return (
                <Data data-test="MasterStep.Data">
                    {onEdit && (
                        <Data.EditBtn
                            onClick={() => onEdit(stepNumber)}
                            iconName="edit-alt"
                            iconSize={19}
                        />
                    )}
                    {onDeleteClick && (
                        <Data.DeleteBtn
                            onClick={onDeleteClick}
                            iconName="trash-alt"
                            iconSize={19}
                        />
                    )}
                    <Title i18nKey={name} />
                    <Content>
                        <Component.Data {...componentProps} />
                    </Content>
                </Data>
            );
        }
        return (
            <DataList>
                {onEdit && (
                    <DataList.EditBtn
                        onClick={() => onEdit(stepNumber)}
                        iconName="edit-alt"
                        iconSize={19}
                    />
                )}
                <Title i18nKey={name} />
                <Line />
                <DataList.Content>
                    <Component.Data {...componentProps} />
                </DataList.Content>
            </DataList>
        );
    }

    render() {
        const { component: Component, componentProps, onSave } = this.props;
        return (
            <Container>
                <Component onSave={onSave} {...componentProps}>
                    {componentProps => this.renderCase(componentProps)}
                </Component>
            </Container>
        );
    }
}

export interface Props {
    name: string;
    active: number;
    disabled?: boolean;
    haveData: boolean;
    isDataList?: boolean;
    stepNumber: number;
    componentProps;
    component;
    onDeleteClick?(): void;
    onEmptyClick?(stepNumber: number): void;
    onSave?(data): void;
    onCancel?(): void;
    onEdit?(stepNumber: number): void;
}

export default MasterStep;
