import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import {
    recoveryEmailSuccess,
    recoveryEmailError,
    recoverUserSuccess,
    recoverUserError
} from '../../actions/user.actions';
import { dialog } from '../../../components/UI';

export function* recoveryEmail(action) {
    try {
        const { data, status } = yield call(
            api.post,
            '/user/verification_email_hint',
            action.payload
        );
        if (status === 200) {
            yield put(recoveryEmailSuccess(data.object.email));
        } else {
            yield put(recoveryEmailError());
        }
    } catch (err) {
        yield put(recoveryEmailError());
    }
}

export function* recoverUser(action) {
    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });
    const { callback } = action.payload;
    loading.show();
    try {
        const { data } = yield call(api.get, '/user/me');
        if (data.object) {
            if (!data.object.display_name) {
                const url = new URL(window.location.href);
                const displayName = url.searchParams.get('name');
                const phoneNumber = url.searchParams.get('cellphone');

                if (!displayName || !phoneNumber) {
                    yield put(recoverUserSuccess(data.object));
                    if (callback) {
                        callback();
                    }
                }

                const { status } = yield call(api.patch, 'user/me', {
                    display_name: displayName,
                    phone_number: phoneNumber!.replace(' ', '+')
                });

                yield* checkStatus(status, action);
            } else {
                yield put(recoverUserSuccess(data.object));
                fetchCallBack(callback);
            }
        } else {
            yield put(recoverUserError());
        }
    } catch (err) {
        yield put(recoverUserError());
    }
    loading.close();
}

function fetchCallBack(callback: any) {
    if (callback) {
        callback();
    }
}

function* checkStatus(status: any, action: any) {
    if (status === 200) {
        yield recoverUser(action);
    } else {
        yield put(recoverUserError());
    }
}
