import React from 'react';
import SolubilityGraph3D from './Graph3D';
import {
    Doughnut,
    Circle,
    Legend,
    MixtureIcon,
    SimularionName
} from './index.styled';
import pallet from '../index.pallet.json';
import mixtureIcon from '../../../../../assets/images/mixture.png';
import { Container, Title, Hint, Header } from '../index.styled';
import SimulationResultWithConfigs from '../../../../../models/simulationResultWithConfigs.model';

const SolubilityGraph = (props: Props) => {
    const renderPolymers = (polymers, isCurrent) =>
        polymers.map((polymer, index) => (
            <Legend.Span key={index}>
                <Doughnut
                    data-color={
                        pallet.polymer[isCurrent ? 'current' : 'proposal'][
                            index
                        ]
                    }
                />
                {polymer.name}
            </Legend.Span>
        ));

    const renderSolvents = (solvents, isCurrent) =>
        solvents.map((solvent, index) => (
            <Legend.Span key={index}>
                <Circle
                    data-color={
                        pallet.solvent[isCurrent ? 'current' : 'proposal'][
                            index
                        ]
                    }
                />
                {solvent.name}
            </Legend.Span>
        ));

    const renderLegends = () => {
        return props.simulations.map((simulation, index) => {
            const isCurrent = index === 0;
            return (
                <React.Fragment key={index}>
                    <SimularionName>{simulation.name}</SimularionName>
                    <Legend>
                        {renderPolymers(
                            simulation.parameters.polymers,
                            isCurrent
                        )}
                        <br />
                        {renderSolvents(
                            simulation.parameters.solvents.map(
                                formulationSolvent => formulationSolvent.solvent
                            ),
                            isCurrent
                        )}
                        <Legend.Span>
                            <MixtureIcon src={mixtureIcon} />
                            <Legend.Span i18nKey="solubilityGraph.definitions.evaporationOfFormulation" />
                        </Legend.Span>
                    </Legend>
                </React.Fragment>
            );
        });
    };

    return (
        <Container className="charts">
            <Header>
                <Title i18nKey="solubilityGraph.title" />
                <Hint
                    name="info-circle"
                    size={19}
                    i18nKey="solubilityGraph.hint"
                    data-html2canvas-ignore
                />
            </Header>
            {renderLegends()}
            <SolubilityGraph3D simulations={props.simulations} />
        </Container>
    );
};

interface Props {
    simulations: SimulationResultWithConfigs[];
}

export default SolubilityGraph;
