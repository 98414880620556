import React from 'react';
import {
    Title,
    Container,
    StyledRow,
    NotReceived,
    RequestResentment,
    CountdownSpan
} from './index.styled';
import Countdown from 'react-countdown-now';
import { Span, Line } from '../../../../components/UI';

class SignSent extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            resent: false,
            countdown: false,
            resentTime: 180000
        };
    }

    private resent = () => {
        const { handleSubmit, data } = this.props;
        const { resentTime } = this.state;
        this.setState({ resent: true, countdown: true }, () => {
            handleSubmit(data);
            setTimeout(() => {
                this.setState({ countdown: false });
            }, resentTime);
        });
    };

    private renderSentMessage() {
        const {
            data: { name, email }
        } = this.props;
        const firstName = name ? name.split(' ')[0] : null;
        return (
            <React.Fragment>
                <StyledRow>
                    <Title
                        i18nKey={
                            firstName
                                ? ['signSent.sent.title', { name: firstName }]
                                : 'signSent.sent.titleWithOutName'
                        }
                        cl="primary"
                    />
                </StyledRow>
                <StyledRow>
                    <Span i18nKey={['signSent.sent.message', { email }]} />
                </StyledRow>
                <Line />
                <StyledRow>
                    <NotReceived
                        onClick={this.resent}
                        i18nKey="signSent.sent.notReceived"
                    />
                </StyledRow>
            </React.Fragment>
        );
    }

    private renderResentMessage() {
        const { countdown, resentTime } = this.state;
        const {
            data: { name, email }
        } = this.props;
        const firstName = name ? name.split(' ')[0] : null;
        const renderCountdown = () => {
            if (countdown) {
                return (
                    <React.Fragment>
                        <RequestResentment i18nKey="signSent.resent.requestResentment" />
                        <Countdown
                            date={Date.now() + resentTime}
                            renderer={({ hours, minutes, seconds }) => {
                                return (
                                    <CountdownSpan>
                                        {hours.toString().padStart(2, '0')}:
                                        {minutes.toString().padStart(2, '0')}:
                                        {seconds.toString().padStart(2, '0')}
                                    </CountdownSpan>
                                );
                            }}
                        />
                    </React.Fragment>
                );
            }
            return (
                <NotReceived
                    onClick={this.resent}
                    i18nKey="signSent.resent.notReceived"
                />
            );
        };

        return (
            <React.Fragment>
                <StyledRow>
                    <Title
                        i18nKey={
                            firstName
                                ? ['signSent.resent.title', { name: firstName }]
                                : 'signSent.resent.titleWithOutName'
                        }
                        cl="primary"
                    />
                </StyledRow>
                <StyledRow>
                    <Span i18nKey={['signSent.sent.message', { email }]} />
                </StyledRow>
                <Line />
                <StyledRow>{renderCountdown()}</StyledRow>
            </React.Fragment>
        );
    }

    render() {
        const { resent } = this.state;
        return (
            <Container margin={0}>
                {!resent
                    ? this.renderSentMessage()
                    : this.renderResentMessage()}
            </Container>
        );
    }
}

interface Props {
    data;
    handleSubmit(data): void;
}

export default SignSent;
