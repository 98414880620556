import React from "react";

import { CustomProgress } from "./index.styled";

export const ProgressBar = ({ percent }: Props) =>
    percent > 100 ? (
        <CustomProgress percent={percent} error size="tiny" />
    ) : (
        <CustomProgress percent={percent} success size="tiny" />
    );

ProgressBar.defaultProps = {
    percent: 0
};

interface Props {
    percent: number;
}
