import styled from "styled-components";
import { Table, Span } from "../../../../../../components/UI";

export const Container = styled.div`
    margin-bottom: 24px;
`;

export const Header = styled(Span)`
    color: #444444;
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
`;

export const TableTitle = styled(Span)`
    color: #444444;
    font-family: "Source Sans Pro";
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
`;

export const StyledTable = styled(Table)`
    margin-bottom: 34px !important;

    @media (max-width: 767px) {
        th {
            text-align: center !important;
        }
    }
`;

export const ResultCellLabel = styled(Table.Cell)`
    padding: 16px !important;
    height: 20px !important;
`;

export const ResultCellValue = styled(Table.Cell)`
    color: #444444 !important;
    padding: 16px !important;
    line-height: 20px !important;
    height: 20px !important;
`;

export const ResultFooterLabel = styled(Table.Cell)`
    padding: 16px !important;
    height: 20px !important;
    background-color: rgba(204, 204, 204, 0.3);
`;

export const ResultFooterValue = styled(Table.Cell)`
    color: #444444 !important;
    font-size: 16px;
    font-weight: 600;
    padding: 16px !important;
    line-height: 20px !important;
    height: 20px !important;
    background-color: rgba(118, 54, 137, 0.1);
`;

export const ResultSpanLabel = styled(Span)`
    color: #875098;
    line-height: 20px;
`;

export const ResultFooterSpan = styled(Span)`
    line-height: 20px;
    color: #444444 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
`;

export const ResultSpanValue = styled(Span)`
    color: #444444;
    line-height: 20px;
`;
