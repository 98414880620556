import React from 'react';
import { Container, Current, LoadingBox } from './index.styled';
import { RouteComponentProps } from 'react-router-dom';
import Menu from './Menu';
import Groups from './Accesses/Groups';
import Group from './Accesses/Groups/container/Group';
import { Empty, Loading } from '../../../components/UI';
import { User as UserModel } from '../../../models/user.model';
import Terms from './Juridical/Terms';
import Term from './Juridical/Terms/container/Term';
import { Privileges } from '../../../models/privileges.enum';
import Users from './Accesses/Users';
import User from './Accesses/Users/container/User';
import Upload from './Properties/Upload';

class MasterContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            items: [
                {
                    title: 'master.menu.accesses.title',
                    items: [
                        {
                            name: 'master.menu.accesses.users',
                            icon: 'user',
                            path: '/master/users',
                            privilege: Privileges.USER_MAINTENANCE_PRIVILEGE,
                            component: Users
                        },
                        {
                            path: [
                                '/master/users/user',
                                '/master/users/user/:id'
                            ],
                            privilege: Privileges.USER_MAINTENANCE_PRIVILEGE,
                            component: User
                        },
                        {
                            name: 'master.menu.accesses.groups',
                            icon: 'users-alt',
                            path: '/master/groups',
                            privilege:
                                Privileges.DATA_RESTRICTION_MAINTENANCE_PRIVILEGE,
                            component: Groups
                        },
                        {
                            path: [
                                '/master/groups/group',
                                '/master/groups/group/:id'
                            ],
                            privilege:
                                Privileges.DATA_RESTRICTION_MAINTENANCE_PRIVILEGE,
                            component: Group
                        }
                    ]
                },
                {
                    title: 'master.menu.properties.title',
                    items: [
                        {
                            name: 'master.menu.properties.update',
                            icon: 'file-upload',
                            path: '/master/properties/upload',
                            privilege: Privileges.POLYMER_MAINTENANCE_PRIVILEGE,
                            component: Upload
                        }
                    ]
                },
                {
                    title: 'master.menu.juridical.title',
                    items: [
                        {
                            name: 'master.menu.juridical.termsOfUse',
                            icon: 'file-edit-alt',
                            path: '/master/terms',
                            privilege:
                                Privileges.TERM_CONDITIONS_MAINTENANCE_PRIVILEGE,
                            component: Terms
                        },
                        {
                            path: '/master/terms/term/:language',
                            privilege:
                                Privileges.TERM_CONDITIONS_MAINTENANCE_PRIVILEGE,
                            component: Term
                        }
                    ]
                }
            ]
        };
    }

    render() {
        const { items } = this.state;
        const { user, match } = this.props;

        if (user.privileges) {
            let Component = Empty;

            items.forEach(category => {
                const item = category.items.find(item => {
                    if (Array.isArray(item.path)) {
                        return Boolean(
                            item.path.find(path => path === match.path)
                        );
                    } else {
                        return item.path === match.path;
                    }
                });
                if (
                    item &&
                    user.privileges.find(
                        privilege => privilege === item.privilege
                    )
                ) {
                    Component = item.component;
                }
            });

            return (
                <Container>
                    <Menu currentPath={match.path} user={user} items={items} />
                    <Current>
                        {Component ? <Component match={match} /> : <Empty />}
                    </Current>
                </Container>
            );
        }

        return (
            <LoadingBox>
                <Loading />
            </LoadingBox>
        );
    }

    componentWillMount() {
        const { user, history } = this.props;
        setTimeout(() => {
            if (!user.privileges || user.privileges.length === 0) {
                history.push('/');
            }
        }, 1500);
    }
}

interface Props extends RouteComponentProps {
    user: UserModel;
}

interface State {
    items: {
        title: string;
        items: {
            name?: string;
            icon?: string;
            path: string | string[];
            privilege: Privileges;
            component;
        }[];
    }[];
}

export default MasterContainer;
