import React from 'react';
import { Grid, Row } from '../../../../../../../../../../components/UI';
import { StyledColumn, PropSpan, GroupTitle } from './index.styled';
import { ResSpan } from '../index.styled';

const Data = ({ group }) => {
    return (
        <Grid>
            <Row>
                <StyledColumn xs={12} sm={12} md={12} lg={12}>
                    <GroupTitle renderText={group!.name} />
                </StyledColumn>
            </Row>
            <Row>
                <StyledColumn xs={12} sm={6} md={6} lg={6}>
                    <PropSpan
                        cl="secondary"
                        i18nKey="forms.group.customerEmail"
                    />
                    <ResSpan renderText={group!.customer_email} />
                </StyledColumn>
                <StyledColumn xs={12} sm={6} md={6} lg={6}>
                    <PropSpan
                        cl="secondary"
                        i18nKey="forms.group.customerName"
                    />
                    <ResSpan renderText={group!.customer_name} />
                </StyledColumn>
            </Row>
            <Row>
                <StyledColumn xs={12} sm={6} md={6} lg={6}>
                    <PropSpan
                        cl="secondary"
                        i18nKey="forms.group.representativeRegion"
                    />
                    <ResSpan renderText={group!.representative_region} />
                </StyledColumn>
                <StyledColumn xs={12} sm={6} md={6} lg={6}>
                    <PropSpan
                        cl="secondary"
                        i18nKey="forms.group.representativeEmail"
                    />
                    <ResSpan renderText={group!.representative_email} />
                </StyledColumn>
            </Row>
        </Grid>
    );
};

export default Data;
