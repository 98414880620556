import React from 'react';
import { Title, Container } from './index.styled';
import SignInForm from '../../../../../components/forms/SignIn';
import { signIn } from '../../../../../redux/actions/user.actions';
import EmailRecovery from './EmailRecovery';
import SignSent from '../../SignSent';

class SignInContainer extends React.Component<{}, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            recoveryEmail: false,
            sent: false,
            data: {
                email: ''
            }
        };
    }

    private handleSubmit = data => {
        this.setState({ loading: true }, () => {
            signIn(data.email)
                .then(_ => {
                    this.setState({ sent: true, data, loading: false });
                })
                .catch(_ => {
                    this.setState({ loading: false });
                });
        });
    };

    private renderForm() {
        const { loading } = this.state;
        return (
            <Container>
                <Title i18nKey="signIn.title" cl="primary" />
                <SignInForm
                    showRecoveryEmail={() =>
                        this.setState({ recoveryEmail: true })
                    }
                    onSubmit={this.handleSubmit}
                    loading={loading}
                />
            </Container>
        );
    }

    render() {
        const { sent, recoveryEmail, data } = this.state;
        if (recoveryEmail) {
            return (
                <Container>
                    <EmailRecovery
                        returnToLogin={() =>
                            this.setState({ recoveryEmail: false })
                        }
                    />
                </Container>
            );
        }
        return (
            <Container>
                {!sent ? (
                    this.renderForm()
                ) : (
                    <SignSent data={data} handleSubmit={this.handleSubmit} />
                )}
            </Container>
        );
    }
}

export default SignInContainer;
