import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import Group from '../../../models/group.model';
import { Grid, Field, Input, Row } from '../../UI';
import { StyledColumn } from './index.styled';
import { withTranslation, WithTranslation } from 'react-i18next';

class GroupForm extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        const { group } = this.props;
        this.state = {
            initialValues: {
                name: group ? group.name : '',
                customer_email: group ? group.customer_email : '',
                customer_name: group ? group.customer_name : '',
                representative_region: group ? group.representative_region : '',
                representative_email: group ? group.representative_email : ''
            }
        };
    }

    private get validationSchema() {
        return yup.object().shape({
            name: yup
                .string()
                .max(25)
                .required(),
            customer_email: yup
                .string()
                .email()
                .max(50)
                .required(),
            customer_name: yup
                .string()
                .max(50)
                .required(),
            representative_region: yup
                .string()
                .max(25)
                .required(),
            representative_email: yup
                .string()
                .email()
                .max(50)
                .required()
        });
    }

    render() {
        const { initialValues } = this.state;
        const { children, t, onSubmit } = this.props;
        return (
            <Formik
                validationSchema={this.validationSchema}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                {({ isValid, handleSubmit }) => {
                    return (
                        <Form>
                            <Grid>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Field
                                            id="group_name"
                                            name="name"
                                            i18nKey="forms.group.name"
                                            placeholder={t(
                                                'forms.group.namePlaceholder'
                                            )}
                                            component={Input}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <StyledColumn xs={12} sm={6} md={6} lg={6}>
                                        <Field
                                            id="group_customer_email"
                                            name="customer_email"
                                            i18nKey="forms.group.customerEmail"
                                            placeholder={t(
                                                'forms.group.customerEmailPlaceholder'
                                            )}
                                            component={Input}
                                        />
                                    </StyledColumn>
                                    <StyledColumn xs={12} sm={6} md={6} lg={6}>
                                        <Field
                                            id="group_customer_name"
                                            name="customer_name"
                                            i18nKey="forms.group.customerName"
                                            placeholder={t(
                                                'forms.group.customerNamePlaceholder'
                                            )}
                                            component={Input}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <StyledColumn xs={12} sm={6} md={6} lg={6}>
                                        <Field
                                            id="group_representative_region"
                                            name="representative_region"
                                            i18nKey="forms.group.representativeRegion"
                                            placeholder={t(
                                                'forms.group.representativeRegionPlaceholder'
                                            )}
                                            component={Input}
                                        />
                                    </StyledColumn>
                                    <StyledColumn xs={12} sm={6} md={6} lg={6}>
                                        <Field
                                            id="group_representative_email"
                                            name="representative_email"
                                            i18nKey="forms.group.representativeEmail"
                                            placeholder={t(
                                                'forms.group.representativeEmailPlaceholder'
                                            )}
                                            component={Input}
                                        />
                                    </StyledColumn>
                                </Row>
                                {children({
                                    onSave: handleSubmit,
                                    disableSave: !isValid
                                })}
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

interface Props extends WithTranslation {
    group: Group | null;
    onSubmit(data): void;
    children?;
}

export default withTranslation('app')(GroupForm);
