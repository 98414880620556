import React from 'react';
import { Grid as StyledGrid } from './index.styled';

export const Grid = React.forwardRef(
    (
        { children, cols = 12, padding = 15, margin = 15, ...rest }: IGridProps,
        ref: React.Ref<any>
    ) => {
        return (
            <StyledGrid
                data-cols={cols}
                data-padding={padding}
                data-margin={margin}
                {...rest}
                ref={ref}
            >
                {children}
            </StyledGrid>
        );
    }
);

interface IGridProps {
    id?: string;
    className?: string;
    cols?: number;
    padding?: number;
    margin?: number;
    children?;
}

export const Row = ({ children, flex, className = '' }: IRowProps) => {
    const createClassFlex = () => {
        if (!flex) {
            return '';
        }
        if (typeof flex !== 'object') {
            return `flex-row flex-align-center flex-justify-${flex}`;
        } else {
            return `flex-row flex-align-${flex.align} flex-justify-${
                flex.justify
            }`;
        }
    };
    return (
        <div className={`row ${createClassFlex()} ${className}`}>
            {children}
        </div>
    );
};

interface IRowProps {
    id?: string;
    children?;
    margin?: number;
    className?: string;
    randonGridRef?: string;
    flex?:
        | {
              align: 'center' | 'start' | 'end';
              justify: 'center' | 'start' | 'end';
          }
        | string;
}

export const Column = ({
    id,
    flex,
    xs,
    sm,
    md,
    lg,
    className = '',
    children,
    centered,
    right,
    column
}: IColumnProps) => {
    const createClassFlex = (flex?) => {
        if (!flex) {
            return '';
        }
        if (typeof flex !== 'object') {
            return `flex flex-align-center flex-justify-${flex}`;
        } else {
            return `flex flex-align-${flex.align} flex-justify-${flex.justify}`;
        }
    };
    const createClassXs = (value?: number): string =>
        value || value === 0 ? `col-xs-${value}` : '';
    const createClassSm = (value?: number): string =>
        value || value === 0 ? `col-sm-${value}` : '';
    const createClassMd = (value?: number): string =>
        value || value === 0 ? `col-md-${value}` : '';
    const createClassLg = (value?: number): string =>
        value || value === 0 ? `col-lg-${value}` : '';
    const createClassCentered = (value?: boolean): string =>
        value ? 'col-centered' : '';
    const createClassRight = (value?: boolean): string =>
        value ? 'col-right' : '';
    const createFlexColumn = (value?: boolean): string =>
        value ? 'flex-column' : '';
    return (
        <div
            id={id}
            className={`${createClassFlex(flex)} ${createClassXs(
                xs
            )} ${createClassSm(sm)} ${createClassMd(md)} ${createClassLg(
                lg
            )} ${createClassCentered(centered)} ${createClassRight(
                right
            )} ${createFlexColumn(column)} ${className}`}
        >
            {children}
        </div>
    );
};

interface IColumnProps {
    id?: string;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    column?: boolean;
    className?: string;
    children?: any;
    centered?: boolean;
    right?: boolean;
    flex?:
        | {
              align: 'center' | 'start' | 'end';
              justify: 'center' | 'start' | 'end';
          }
        | 'center'
        | 'start'
        | 'end';
}
