import styled from 'styled-components';
import { Form } from 'formik';
import { Row, TextArea } from '../../UI';

export const StyledForm = styled(Form)`
    padding: 15px 0;
    padding-bottom: 0;
`;

export const StyledRow = styled(Row)`
    padding: 10px 0;
`;

export const StyledTextArea = styled(TextArea)`
    height: 120px;
    overflow-y: auto;
    resize: none;
`;
