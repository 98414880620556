import { Solvent } from '../../models/solvent.model';
/**
 * Action types
 */
export enum SolventsTypes {
    FILTER_REQUEST = 'solvent:FILTER_REQUEST',
    FILTER_SUCCESS = 'solvent:FILTER_SUCCESS',
    FILTER_FAILURE = 'solvent:FILTER_FAILURE'
}

/**
 * State types
 */
export interface SolventsState {
    readonly items: Solvent[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly message: string;
}
