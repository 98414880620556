import store from '../redux/store';
import { signOut } from '../redux/actions/user.actions';
import { dialog } from '../components/UI';
import { sleep } from '../helpers/util';
import moment from "moment";

const idleToken = async () => {
    const lastRequest = localStorage.getItem(process.env.REACT_APP_CURRENT_URL + '_SolsysLastRequestDateTime');
    const setLastRequest = () => localStorage.setItem(process.env.REACT_APP_CURRENT_URL + '_SolsysLastRequestDateTime', moment().toString());
    const removeLastRequest = () => localStorage.removeItem(process.env.REACT_APP_CURRENT_URL + '_SolsysLastRequestDateTime');


    if(!lastRequest){
        setLastRequest()
    }else {
        if(moment().diff(moment(lastRequest), "minutes") > 180) {
            removeLastRequest();
            const exit = () => {
                store.dispatch(signOut());
                window.location.href = `${process.env.REACT_APP_CURRENT_URL}/sign`;
            };
            dialog({
                type: 'error',
                i18nKey: 'tokenExpired',
                onClose: exit
            });
            await sleep(999999);
        } else {
            setLastRequest()
        }
    }
}

export default idleToken
