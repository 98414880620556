import React from 'react';
import ReactDOM from 'react-dom';
import { Background, Modal, CloseBtn } from './index.styled';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import ReactTooltip from 'react-tooltip';

export const modal = ({ instantShow = true, onClose }: Props) => Component => {
    const root = document.getElementById('root')!;
    const modal = document.createElement('div');

    const removeModal = () => {
        try {
            root.removeChild(modal);
        } catch (ex) {}
    };

    const createModal = () => {
        try {
            root.appendChild(modal);
            ReactTooltip.rebuild();
        } catch (ex) {}
    };

    const closeModal = () => {
        removeModal();
        if (onClose) {
            onClose();
        }
    };

    const handleClose = e => {
        if (e.target.id === 'modal-background') {
            closeModal();
        }
    };

    ReactDOM.render(
        <Background id="modal-background" onMouseDown={handleClose}>
            <I18nextProvider i18n={i18next}>
                <Modal
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    <CloseBtn name="times" onClick={closeModal} />
                    {Component({
                        show: createModal,
                        close: closeModal
                    })}
                </Modal>
            </I18nextProvider>
        </Background>,
        modal
    );

    if (instantShow && root) {
        createModal();
    }

    return {
        show: createModal,
        close: closeModal
    };
};

interface Props {
    instantShow?: boolean;
    onClose?(): void;
}
