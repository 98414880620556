import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import {
    deleteGroupError,
    deleteGroupSuccess,
    takeGroupSuccess,
    takeGroupError,
    saveGroupSuccess,
    saveGroupError
} from '../../actions/groups.actions';
import { dialog } from '../../../components/UI';

function* needsTime(action?) {
    return yield new Promise(resolve => {
        setTimeout(() => {
            resolve(action ? action() : () => {});
        }, 1500);
    });
}

export function* deleteGroupRequest(action) {
    const { group, callback } = action.payload;

    const loading = dialog({
        type: 'loading',
        i18nKey: {
            name: 'dialogs.delete.loading',
            vars: { name: group.name }
        },
        instantShow: false
    });

    loading.show();

    try {
        const { status } = yield call(api.delete, `/data/groups/${group.id}`);
        if (status === 200) {
            yield put(deleteGroupSuccess(group));
            if (callback) {
                callback(group);
            }
        } else {
            yield put(deleteGroupError(group));
        }
    } catch (ex) {
        yield put(deleteGroupError(group));
    }

    loading.close();
}

export function* takeGroupRequest(action) {
    const id = action.payload;

    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });

    if (!id) {
        yield needsTime(() => {});
        yield put(takeGroupSuccess({ group: null, message: '' }));
        return;
    }

    loading.show();

    try {
        const { status, data } = yield call(api.get, `/data/groups/${id}`);
        if (status === 200 && data.object) {
            yield put(
                takeGroupSuccess({ group: data.object, message: data.messages })
            );
        } else {
            yield put(takeGroupError());
        }
    } catch (ex) {
        yield put(takeGroupError());
    }

    loading.close();
}

export function* saveGroupDataRequest(action) {
    const { group, callback } = action.payload;
    const { id, ...restGroup } = group;

    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });

    loading.show();
    try {
        const url = `/data/groups${id ? `/${id}` : ''}`;
        const { status, data } = yield call(id ? api.put : api.post, url, {
            ...restGroup
        });

        if (status === 201 || status === 200) {
            yield put(saveGroupSuccess(!id));
            if (callback) {
                callback(data.object);
            }
        } else {
            yield put(saveGroupError());
        }
    } catch (ex) {
        yield put(saveGroupError());
    }

    loading.close();
}

enum SaveGroupSteps {
    users = 'users',
    polymers = 'polymers',
    solvents = 'solvents'
}

function* saveGroupSteps(
    type: SaveGroupSteps,
    groupId: number,
    ids: number[],
    callback: Function
) {
    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });

    loading.show();

    try {
        const { status } = yield call(
            api.put,
            `/data/groups/${groupId}/${type}`,
            { ids }
        );
        if (status === 200) {
            yield put(saveGroupSuccess());
            if (callback) {
                callback();
            }
        } else {
            yield put(saveGroupError());
        }
    } catch (ex) {
        yield put(saveGroupError());
    }

    loading.close();
}

export function* saveGroupUsersRequest(action) {
    const { id, ids, callback } = action.payload;
    yield saveGroupSteps(SaveGroupSteps.users, id, ids, callback);
}

export function* saveGroupPolymersRequest(action) {
    const { id, ids, callback } = action.payload;
    yield saveGroupSteps(SaveGroupSteps.polymers, id, ids, callback);
}

export function* saveGroupSolventsRequest(action) {
    const { id, ids, callback } = action.payload;
    yield saveGroupSteps(SaveGroupSteps.solvents, id, ids, callback);
}
