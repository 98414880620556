import { Polymer } from './polymer.model';
import { FormulationSolvent } from './solvent.model';

export default class SimulationConfig {
    polymers: Polymer[];
    solvents: FormulationSolvent[];
    conditions: ConditionsConfig;
    is_volume: boolean | null;
    is_value_per_liter: boolean;
}

export class ConditionsConfig {
    paint: PaintConfig;
    environment: EnvironmentConfig;
}

interface PaintConfig {
    id?: number;
    custom?: boolean;
    surface_area: number;
    film_thickness: number;
    volume_solids: number;
    non_volatile: number;
    paint_density: number;
}

interface EnvironmentConfig {
    relative_humidity: number;
    temperature: number;
}
