import SimulationResultWithConfigs from './simulationResultWithConfigs.model';

export class Project {
    public id?: number;
    public name: string;
    public description: string;
    public created_at: Date;
    public updated_at: Date;
    public simulations: SimulationResultWithConfigs[] = [];
    public count_simulations: number;
    public total_simulations: number;
    public isNew?: boolean;

    constructor(name, description, simulations) {
        this.name = name;
        this.description = description;
        this.simulations = simulations;
        this.count_simulations = 0;
        this.total_simulations = 0;
        this.isNew = true;
    }
}
