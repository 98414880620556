import styled from 'styled-components';
import { H1, H2, Button } from '../../components/UI';
import BannerCallForRegistation from '../../assets/images/home_banner_call_for_registration.svg';
import LeftHalfCircle from '../../assets/images/home_left_half_circle.svg';
import RightHalfCircle from '../../assets/images/home_right_half_circle.svg';

export const Container = styled.div`
    flex: 1;
    width: 100%;
    background-image: url(${LeftHalfCircle}), url(${RightHalfCircle});
    background-position: left top 35%, right bottom 15%;
    background-repeat: no-repeat, no-repeat;
    overflow: hidden;
`;

export const Welcome: any = styled.section`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    background-color: white;
`;

Welcome.Banner = styled.img`
    width: 100%;
`;

Welcome.Text = styled.div`
    width: 400px;
`;

Welcome.Title = styled(H1)`
    font-size: 32px;
    font-weight: 600;
    text-align: right;
    margin-bottom: 10px;
`;

Welcome.Subtitle = styled(H2)`
    width: 100%;
    font-weight: normal;
    text-align: right;
`;

export const Presentation: any = styled.section`
    padding: 60px 200px 65px 200px;

    @media (max-width: 992px) {
        padding: 60px 10px 65px 10px;
    }
`;

Presentation.Title = styled(H1)`
    font-size: 28px;
    text-align: center;
    margin-bottom: 40px;
`;

Presentation.Video = styled.div`
    border-radius: 4px;
    background-color: #d8d8d8;
    border: 1px solid #d8d8d8;
    max-width: 960px;
    margin: auto;
    overflow: hidden;

    & video {
        display: block;
        width: 100%;
        height: auto;
    }
`;

export const Cards: any = styled.section`
    padding: 0 200px;

    @media (max-width: 992px) {
        padding: 0 10px;
    }
`;

export const CallForRegistration: any = styled.section`
    padding: 41px 200px;

    @media (max-width: 992px) {
        padding: 41px 10px;
    }
`;

CallForRegistration.Title = styled(H1)`
    font-size: 28px;
    text-align: center;
    margin-bottom: 41px;
`;

CallForRegistration.Banner = styled.div`
    position: relative;
    height: 280px;
    width: 100%;
    border-radius: 4px;
    background-color: #d8d8d8;
    background-image: url(${BannerCallForRegistation});
    background-size: cover;
`;

CallForRegistration.Banner.Button = styled(Button)`
    &.ui.button {
        position: absolute;
        right: 40px;
        top: 0;
        bottom: 0;
        height: 40px;
        margin: auto;
        font-size: 16px;
        font-weight: bold;
    }
`;
