import React from 'react';
import { ApplicationState } from '../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import SimulationContainer from '../../pages/Simulation/container';
import SimulationConfig from '../../models/simulationConfig.model';
import withAuthentication from '../../components/hoc/withAuthentication';
import { Project } from '../../models/project.model';
import { Empty } from '../../components/UI';
import { getPaintsRequest } from "../../redux/actions/paints.actions";

class Simulation extends React.Component<Props, {}> {
    render() {
        const { project } = this.props;
        if (!project) {
            return <Empty />;
        }
        return <SimulationContainer {...this.props} />;
    }

    componentDidMount() {
        const { project, history } = this.props;
        if (!project) {
            history.push('/projects');
        }
    }

    componentWillMount() {
        const { getPaints } = this.props;
        getPaints();
    }

    componentWillUnmount() {
        window.onbeforeunload = null;
    }
}

interface Props extends RouteComponentProps {
    project: Project;
    cacheProject: Project;
    config: SimulationConfig;
    loading: boolean;
    getPaints: () => void;
}

const mapStateToProps = (state: ApplicationState) => ({
    simulation: state.simulation,
    project: state.simulation.project,
    cacheProject: state.simulation.cacheProject,
    config: state.simulation.config,
    loading: state.simulation.loading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { getPaints: getPaintsRequest },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withAuthentication(Simulation));
