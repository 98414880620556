import React from 'react';
import { Formik, Form, FormikProps } from 'formik';
import { Label, Select, Grid, Column, Field } from '../../UI';
import { withTranslation, WithTranslation } from 'react-i18next';
import * as yup from 'yup';
import { Paint } from '../../../models/paint.model';
import { Row, CharacteristicsStep } from './index.styled';
import { H3 } from '../../UI/H3';
import { InputNumber } from '../../UI/InputNumber';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { getPaintsRequest } from "../../../redux/actions/paints.actions";
import { connect } from 'react-redux';
import { ApplicationState } from '../../../redux/store';

class ConditionsOfApplicationForm extends React.Component<
    IConditionsOfApplicationFormProps,
    IConditionsOfApplicationFormState
> {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                paint_type: '',
                surface_area: '',
                thickness: '',
                nv: '',
                sv: '',
                density: '',
                moisture: 75,
                temperature: 25,
                ...props.data
            }
        };
    }

    private getCharacteristicsOfPaint(id: number) {
        const { paints } = this.props;
        return paints.find((paint: Paint) => paint.id === id)!.characteristics;
    }

    private isCustom = values => {
        if (values.paint_type === '') {
            return false;
        }
        const characteristics = this.getCharacteristicsOfPaint(
            values.paint_type
        );
        const exclude = ['moisture', 'temperature', 'paint_type', 'custom'];
        return !_.isEqual(characteristics, _.omit(values, exclude));
    };

    private convertPaintListToDropdownList(values) {
        const { paints, t } = this.props;
        const custom = values.paint_type !== '' ? this.isCustom(values) : null;
        return paints.map((paint: Paint) => ({
            key: paint.id,
            text: `${paint.name}${
                custom && paint.id === values.paint_type
                    ? ` - ${t(
                          'forms.conditionsOfApplication.paintSelection.custom'
                      )}`
                    : ''
            }`,
            value: paint.id
        }));
    }

    private setPaintCharacteristics(
        paint_type: number,
        setValues: Function,
        values: IConditionsOfApplicationFormValues
    ) {
        setValues({
            ...values,
            ...this.getCharacteristicsOfPaint(paint_type),
            paint_type
        });
    }

    private revertCharacteristicsToDefault(values, setValues) {
        setValues({
            ...values,
            ...this.getCharacteristicsOfPaint(values.paint_type)
        });
    }

    private handleSubmit = values => {
        const { onSubmit } = this.props;
        onSubmit({
            ...values,
            custom: this.isCustom(values)
        });
    };

    get validationSchema() {
        return yup.object().shape({
            surface_area: yup
                .number()
                .required()
                .min(0)
                .max(9999999.9999),
            thickness: yup
                .number()
                .required()
                .min(0)
                .max(999999.99),
            nv: yup
                .number()
                .required()
                .min(0)
                .max(9999999.99),
            sv: yup
                .number()
                .required()
                .min(0)
                .max(9999999.99),
            density: yup
                .number()
                .required()
                .min(0)
                .max(9999999.9999),
            moisture: yup
                .number()
                .required()
                .min(0)
                .max(999.99),
            temperature: yup
                .number()
                .required()
                .min(-50)
                .max(99)
        });
    }

    render() {
        const { t, children, getPaints, paintLoading } = this.props;
        const { data } = this.state;
        return (
            <Formik
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
                initialValues={data}
                enableReinitialize
            >
                {({
                    values,
                    isValid,
                    handleSubmit,
                    setValues
                }: FormikProps<IConditionsOfApplicationFormValues>) => {
                    return (
                        <Form>
                            <Grid cols={15}>
                                <CharacteristicsStep>
                                    <Row>
                                        <Column xs={15} sm={15} md={15} lg={15}>
                                            <H3
                                                id="ConditionsOfApplicationForm_characteristicsPaintTitle_translator"
                                                i18nKey="forms.conditionsOfApplication.paintCharacteristicsTitle"
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column xs={15} sm={15} md={15} lg={15}>
                                            <Label
                                                id="ConditionsOfApplicationForm_selectPaintLabel"
                                                i18nKey="forms.conditionsOfApplication.paintSelection.typesOfPaint"
                                            />
                                            <Select
                                                id="ConditionsOfApplicationForm_selectPaint"
                                                name="paint_type"
                                                selectOnNavigation={false}
                                                selectOnBlur={false}
                                                value={values.paint_type}
                                                loading={paintLoading}
                                                onClick={getPaints}
                                                revert={
                                                    this.isCustom(values)
                                                        ? () =>
                                                              this.revertCharacteristicsToDefault(
                                                                  values,
                                                                  setValues
                                                              )
                                                        : null
                                                }
                                                fluid
                                                placeholder={t(
                                                    'forms.conditionsOfApplication.paintSelection.placeHolder'
                                                )}
                                                options={this.convertPaintListToDropdownList(
                                                    values
                                                )}
                                                onChange={(_, { value }) => {
                                                    this.setPaintCharacteristics(
                                                        value,
                                                        setValues,
                                                        values
                                                    );
                                                }}
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column xs={15} sm={15} md={3} lg={3}>
                                            <Field
                                                id="ConditionsOfApplicationForm_fields_surface_area"
                                                i18nKey="forms.conditionsOfApplication.fields.surface_area"
                                                name="surface_area"
                                                tag="m²"
                                                disabled={!values.paint_type}
                                                component={InputNumber}
                                                decimalScale={4}
                                            />
                                        </Column>
                                        <Column xs={15} sm={15} md={3} lg={3}>
                                            <Field
                                                id="ConditionsOfApplicationForm_fields_thickness"
                                                i18nKey="forms.conditionsOfApplication.fields.thickness"
                                                name="thickness"
                                                tag="µm"
                                                disabled={!values.paint_type}
                                                component={InputNumber}
                                                decimalScale={2}
                                            />
                                        </Column>
                                        <Column xs={15} sm={15} md={3} lg={3}>
                                            <Field
                                                id="ConditionsOfApplicationForm_fields_nv"
                                                i18nKey="forms.conditionsOfApplication.fields.nv"
                                                name="nv"
                                                tag="%m/m"
                                                tagPadding="60px"
                                                disabled={!values.paint_type}
                                                component={InputNumber}
                                                decimalScale={2}
                                            />
                                        </Column>
                                        <Column xs={15} sm={15} md={3} lg={3}>
                                            <Field
                                                id="ConditionsOfApplicationForm_fields_sv"
                                                i18nKey="forms.conditionsOfApplication.fields.sv"
                                                name="sv"
                                                tag="%v/v"
                                                tagPadding="50px"
                                                disabled={!values.paint_type}
                                                component={InputNumber}
                                                decimalScale={2}
                                            />
                                        </Column>
                                        <Column xs={15} sm={15} md={3} lg={3}>
                                            <Field
                                                id="ConditionsOfApplicationForm_fields_density"
                                                i18nKey="forms.conditionsOfApplication.fields.density"
                                                name="density"
                                                tag="g/cm³"
                                                tagPadding="60px"
                                                disabled={!values.paint_type}
                                                component={InputNumber}
                                                decimalScale={4}
                                            />
                                        </Column>
                                    </Row>
                                </CharacteristicsStep>
                                <CharacteristicsStep>
                                    <Row>
                                        <Column xs={15} sm={15} md={15} lg={15}>
                                            <H3
                                                id="ConditionsOfApplicationForm_characteristicsAmbientTitle_translator"
                                                i18nKey="forms.conditionsOfApplication.ambientCharacteristicsTitle"
                                            />
                                        </Column>
                                    </Row>
                                    <Row>
                                        <Column xs={15} sm={15} md={3} lg={3}>
                                            <Field
                                                id="ConditionsOfApplicationForm_fields_moisture"
                                                i18nKey="forms.conditionsOfApplication.fields.moisture"
                                                name="moisture"
                                                tag="%"
                                                component={InputNumber}
                                                decimalScale={2}
                                            />
                                        </Column>
                                        <Column xs={15} sm={15} md={3} lg={3}>
                                            <Field
                                                id="ConditionsOfApplicationForm_fields_temperature"
                                                i18nKey="forms.conditionsOfApplication.fields.temperature"
                                                name="temperature"
                                                tag="ºC"
                                                component={InputNumber}
                                                decimalScale={0}
                                            />
                                        </Column>
                                    </Row>
                                </CharacteristicsStep>
                            </Grid>
                            {children(isValid, handleSubmit)}
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

export interface IConditionsOfApplicationFormValues {
    paint_type: number | '';
    surface_area: number | '';
    thickness: number | '';
    nv: number | '';
    sv: number | '';
    density: number | '';
    moisture: number;
    temperature: number;
}

export interface IConditionsOfApplicationFormProps extends WithTranslation {
    paints: Paint[];
    loading: boolean;
    data?: IConditionsOfApplicationFormValues | {};
    children?;
    paintLoading: boolean;
    getPaints(): void;
    onSubmit(data): void;
    t(str);
}

export interface IConditionsOfApplicationFormSubmit
    extends IConditionsOfApplicationFormValues {
    custom: boolean;
}

interface IConditionsOfApplicationFormState {
    data: IConditionsOfApplicationFormValues;
}

const mapStateToProps = (state: ApplicationState) => ({
    paintLoading: state.paints.loading
})

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { getPaints: getPaintsRequest },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation('app')(ConditionsOfApplicationForm))
