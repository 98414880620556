import React from 'react';
import { Title, Container } from './index.styled';
import SignUpForm from '../../../../../components/forms/SignUp';
import { signUp } from '../../../../../redux/actions/user.actions';
import SignSent from '../../SignSent';

class SignUpContainer extends React.Component<{}, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            sent: false,
            data: {
                name: '',
                email: '',
                cellphone: ''
            }
        };
    }

    private handleSubmit = data => {
        this.setState({ loading: true }, () => {
            signUp(data.email, data.name, data.cellphone)
                .then(_ => {
                    this.setState({ sent: true, data, loading: false });
                })
                .catch(_ => {
                    this.setState({ loading: false });
                });
        });
    };

    private renderForm() {
        const { loading } = this.state;
        return (
            <React.Fragment>
                <Title i18nKey="signUp.title" cl="primary" />
                <SignUpForm onSubmit={this.handleSubmit} loading={loading} />
            </React.Fragment>
        );
    }

    render() {
        const { sent, data } = this.state;
        return (
            <Container>
                {!sent ? (
                    this.renderForm()
                ) : (
                    <SignSent data={data} handleSubmit={this.handleSubmit} />
                )}
            </Container>
        );
    }
}

export default SignUpContainer;
