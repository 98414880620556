import React from 'react';
import ProjectsContainer from './container';
import { ApplicationState } from '../../redux/store';
import { bindActionCreators } from 'redux';
import {
    getProjects,
    setProject,
    deleteProject,
    recoverProject
} from '../../redux/actions/projects.actions';
import { connect } from 'react-redux';
import { Project } from '../../models/project.model';
import withAuthentication from '../../components/hoc/withAuthentication';
import { RouteComponentProps } from 'react-router-dom';
import { GetFilter, GetFilterSort } from '../../models/getFilter.model';
import { Paging } from '../../models/paging.model';
import { User } from '../../models/user.model';
import { withTranslation, WithTranslation } from 'react-i18next';

class Projects extends React.Component<Props, {}> {
    render() {
        return <ProjectsContainer {...this.props} limit={10} />;
    }

    componentWillMount() {
        const { getProjects } = this.props;
        const filter: GetFilter = {
            page: 1,
            limit: 10,
            sort: GetFilterSort.CREATED_AT
        };
        getProjects(filter);
    }
}

interface Props extends RouteComponentProps, WithTranslation {
    projects: Project[];
    user: User;
    paging: Paging;
    loading: boolean;
    setProject(project: Project): void;
    deleteProject(project: Project, callback: Function): void;
    getProjects(filter: GetFilter, callback?: Function): void;
    recoverProject(id: number, callback?: Function): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    projects: state.projects.items,
    paging: state.projects.paging,
    loading: state.projects.loading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setProject,
            getProjects,
            deleteProject,
            recoverProject
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withAuthentication(withTranslation('app')(Projects)));
