import { Term } from '../../models/term.model';

/**
 * Action types
 */
export enum TermsTypes {
    GET_TERMS_REQUEST = 'terms:GET_TERMS_REQUEST',
    GET_TERMS_SUCCESS = 'terms:GET_TERMS_SUCCESS',
    GET_TERMS_FAILURE = 'terms:GET_TERMS_FAILURE',
    SAVE_TERMS_REQUEST = 'terms:SAVE_TERMS_REQUEST',
    SAVE_TERMS_SUCCESS = 'terms:SAVE_TERMS_SUCCESS',
    SAVE_TERMS_FAILURE = 'terms:SAVE_TERMS_FAILURE'
}

/**
 * State types
 */
export interface TermsState {
    readonly items: Term[];
    readonly loading: boolean;
}
