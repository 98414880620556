import React from 'react';
import Data from './Data';
import Active from './Active';
import Group from '../../../../../../../../../models/group.model';

class PolymersStep extends React.Component<Props, {}> {
    static Active = Active;
    static Data = Data;

    render() {
        const { children, group, ...rest } = this.props;
        return (
            <div>
                {children({
                    ...rest,
                    values: group ? (group.polymers ? group.polymers : []) : []
                })}
            </div>
        );
    }
}

interface Props {
    group: Group | null;
    children;
    onSave(data): void;
}

export default PolymersStep;
