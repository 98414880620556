import styled from 'styled-components';
import { Column, Button, Span } from '../../UI';

export const StyledColumn = styled(Column)`
    padding: 10px 0;
`;

export const NextButton = styled(Button)`
    &.ui.button {
        padding: 4px 40px;
        font-size: 16px;
        font-weight: bold;
        margin-left: 15px;
        margin-right: 0;
    }
`;

export const ReturnToLogin = styled(Span)`
    font-size: 14px;
    line-height: 18px;
    color: #a6a6a6;

    &:hover {
        color: #763689;
        cursor: pointer;
    }
`;
