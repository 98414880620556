import React from 'react';
import {
    StyledRow,
    Name,
    Description,
    AxisDescription,
    RedSpan,
    GreenSpan,
    MixtureIcon
} from './index.styled';
import { Column, Empty } from '../../../../../../components/UI';
import { normalizeCommaNumber } from '../../../../../../helpers/util';
import mixtureIcon from '../../../../../../assets/images/mixture.png';
import PositionDPH from '../../../../../../models/positionDPH.model';
import { Doughnut, Circle } from '../index.styled';
import { Mixing } from '../../../../../../models/simulationResult.model';
import pallet from '../../index.pallet.json';

const MixtureDialog = ({ mixture }: Props) => {
    const getInitialPosition = (): PositionDPH => ({
        dD: normalizeCommaNumber(mixture.paths[0].delta_d),
        dP: normalizeCommaNumber(mixture.paths[0].delta_p),
        dH: normalizeCommaNumber(mixture.paths[0].delta_h)
    });

    const getFinalPosition = (): PositionDPH => ({
        dD: normalizeCommaNumber(
            mixture.paths[mixture.paths.length - 1].delta_d
        ),
        dP: normalizeCommaNumber(
            mixture.paths[mixture.paths.length - 1].delta_p
        ),
        dH: normalizeCommaNumber(
            mixture.paths[mixture.paths.length - 1].delta_h
        )
    });
    const renderRED = (red: number) => {
        if (red <= 1) {
            return <GreenSpan renderText={normalizeCommaNumber(red)} />;
        } else {
            return <RedSpan renderText={normalizeCommaNumber(red)} />;
        }
    };
    const renderName = () => (
        <StyledRow>
            <Column xs={12} sm={12} md={12} lg={12}>
                <MixtureIcon src={mixtureIcon} />
                <Name i18nKey="solubilityGraph.definitions.evaporationOfFormulation" />
            </Column>
        </StyledRow>
    );
    const renderInitial = () => {
        const initial = getInitialPosition();
        return (
            <React.Fragment>
                <StyledRow>
                    <Column xs={12} sm={12} md={12} lg={12}>
                        <Description i18nKey="solubilityGraph.definitions.initial" />
                    </Column>
                </StyledRow>
                <StyledRow>
                    <Column xs={4} sm={4} md={4} lg={4}>
                        <AxisDescription renderText="dD" />
                        {initial.dD}
                    </Column>
                    <Column xs={4} sm={4} md={4} lg={4}>
                        <AxisDescription renderText="dP" />
                        {initial.dP}
                    </Column>
                    <Column xs={4} sm={4} md={4} lg={4}>
                        <AxisDescription renderText="dH" />
                        {initial.dH}
                    </Column>
                </StyledRow>
            </React.Fragment>
        );
    };
    const renderFinal = () => {
        const final = getFinalPosition();
        return (
            <React.Fragment>
                <StyledRow>
                    <Column xs={12} sm={12} md={12} lg={12}>
                        <Description renderText="90%" />
                    </Column>
                </StyledRow>
                <StyledRow>
                    <Column xs={4} sm={4} md={4} lg={4}>
                        <AxisDescription renderText="dD" />
                        {final.dD}
                    </Column>
                    <Column xs={4} sm={4} md={4} lg={4}>
                        <AxisDescription renderText="dP" />
                        {final.dP}
                    </Column>
                    <Column xs={4} sm={4} md={4} lg={4}>
                        <AxisDescription renderText="dH" />
                        {final.dH}
                    </Column>
                </StyledRow>
            </React.Fragment>
        );
    };
    const renderPolymers = () =>
        mixture.polymers.map((polymer, index) => {
            const initialRED = polymer.normalized_distance_initial;
            const finalRed = polymer.normalized_distance_final;

            if (!initialRED || !finalRed) {
                return <Empty key={polymer.id} />;
            }
            return (
                <React.Fragment key={polymer.id}>
                    <StyledRow>
                        <Column xs={12} sm={12} md={12} lg={12}>
                            <Doughnut
                                data-color={pallet.polymer.current[index]}
                            />
                            <Name renderText={polymer.name} />
                        </Column>
                    </StyledRow>
                    <StyledRow>
                        <Column right xs={11} sm={11} md={11} lg={11}>
                            <Doughnut data-color="#ab62c1" data-size={8} />
                            <Description i18nKey="solubilityGraph.definitions.initialRed" />
                            {renderRED(initialRED)}
                        </Column>
                        <Column right xs={11} sm={11} md={11} lg={11}>
                            <Circle data-color="#ab62c1" data-size={8} />
                            <Description renderText="RED - 90%" />
                            {renderRED(finalRed)}
                        </Column>
                    </StyledRow>
                </React.Fragment>
            );
        });
    return (
        <React.Fragment>
            {renderName()}
            {renderInitial()}
            {renderFinal()}
            {renderPolymers()}
        </React.Fragment>
    );
};

interface Props {
    mixture: Mixing;
}

export default MixtureDialog;
