import React from 'react';
import ComparationBarContainer from './container';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { compareSimulations } from '../../../../../../redux/actions/simulation.actions';

const ComparationBar = (props: Props) => <ComparationBarContainer {...props} />;

export interface Props {
    compress(): void;
    cardLock(date: Date | string): void;
    cardUnlock(date: Date | string): void;
    compareSimulations(
        checkeds: { date: Date | string; reference: boolean }[],
        callback: Function
    ): void;
    checkedCards: {
        name: string;
        date: Date | string;
        locked: boolean;
        reference: boolean;
    }[];
}

const mapDispatchToProps = dispatch =>
    bindActionCreators({ compareSimulations }, dispatch);

export default connect(
    null,
    mapDispatchToProps
)(ComparationBar);
