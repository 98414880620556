import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Grid, Row, Field, Input, Line, InputMask, Checkbox } from '../../UI';
import { StyledColumn, StyledForm, StyledButton } from './index.styled';
import i18next from 'i18next';
import { modal } from '../../UI/Modal';
import AcceptOrDecline from './AcceptOrDecline';

class SignUpForm extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                name: '',
                email: '',
                cellphone: this.initialDDI,
                termsConditionsAndPrivacyPolicy: false
            },
            termsConditionsAndPrivacyPolicyCheckedDateTime: null
        };
    }

    private get initialDDI() {
        switch (i18next.language) {
            case 'pt_br':
                return '+55';
            case 'en_us':
                return '+1';
            case 'es_es':
                return '+34';
            default:
                return '+55';
        }
    }

    private get validationSchema() {
        return yup.object().shape({
            name: yup
                .string()
                .max(50)
                .required(),
            email: yup
                .string()
                .email()
                .max(50)
                .required(),
            cellphone: yup
                .string()
                .min(6)
                .required(),
            termsConditionsAndPrivacyPolicy: yup
                .boolean()
                .oneOf([true], 'accept')
        });
    }

    private openTermsConditionsAndPrivacyPolicy = setFieldValue => {
        modal({})(({ close }) => {
            const accept = () => {
                this.setState(
                    {
                        termsConditionsAndPrivacyPolicyCheckedDateTime: new Date()
                    },
                    () => {
                        setFieldValue('termsConditionsAndPrivacyPolicy', true);
                        close();
                    }
                );
            };
            const decline = () => {
                setFieldValue('termsConditionsAndPrivacyPolicy', false);
                close();
            };
            return <AcceptOrDecline accept={accept} decline={decline} />;
        });
    };

    private handleSubmit = data => {
        const { onSubmit } = this.props;
        const { termsConditionsAndPrivacyPolicyCheckedDateTime } = this.state;
        onSubmit({ ...data, termsConditionsAndPrivacyPolicyCheckedDateTime });
    };

    render() {
        const { initialValues } = this.state;
        const { loading } = this.props;
        return (
            <Formik
                validationSchema={this.validationSchema}
                onSubmit={this.handleSubmit}
                initialValues={initialValues}
            >
                {({ isValid, setFieldValue }) => {
                    return (
                        <StyledForm>
                            <Grid padding={12} margin={12}>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Field
                                            id="SignUp_name"
                                            i18nKey="forms.signUp.nameLabel"
                                            name="name"
                                            component={Input}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <StyledColumn xs={12} sm={7} md={7} lg={7}>
                                        <Field
                                            id="SignUp_email"
                                            i18nKey="forms.signUp.emailLabel"
                                            name="email"
                                            component={Input}
                                        />
                                    </StyledColumn>
                                    <StyledColumn xs={12} sm={5} md={5} lg={5}>
                                        <Field
                                            id="SignUp_cellphone"
                                            i18nKey="forms.signUp.cellphoneLabel"
                                            name="cellphone"
                                            mask="+9999999999999"
                                            component={InputMask}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Field
                                            id="SignUp_termsAndConditions"
                                            i18nKey="forms.signUp.termsConditionsAndPrivacyPolicy"
                                            name="termsConditionsAndPrivacyPolicy"
                                            component={Checkbox}
                                            componentLabel
                                            readOnly={true}
                                            onClick={() =>
                                                this.openTermsConditionsAndPrivacyPolicy(
                                                    setFieldValue
                                                )
                                            }
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Line />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        flex={{
                                            align: 'center',
                                            justify: 'end'
                                        }}
                                    >
                                        <StyledButton
                                            i18nKey="forms.signIn.next"
                                            cl="primary"
                                            disabled={!isValid}
                                            type="submit"
                                            loading={loading}
                                        />
                                    </StyledColumn>
                                </Row>
                            </Grid>
                        </StyledForm>
                    );
                }}
            </Formik>
        );
    }
}

interface Props {
    loading: boolean;
    onSubmit(data): void;
}

export default SignUpForm;
