import React from 'react';
import { Grid, Row, Column } from '../../../../../components/UI';
import ReportButtom from '../../../../../components/reports/pdf';
import { ReportInfo } from './index.styled';

const ResultReport = ({ projectName }: Props) => (
    <Grid>
        <Row flex={{ align: 'center', justify: 'start' }}>
            <Column
                xs={12}
                sm={9}
                md={9}
                lg={9}
                flex={{ align: 'center', justify: 'start' }}
            >
                <ReportInfo>
                    <ReportInfo.Icon name="exclamation-circle" />
                    <ReportInfo.Span i18nKey="simulationResultReport.info" />
                </ReportInfo>
            </Column>
            <Column xs={12} sm={3} md={3} lg={3}>
                <ReportButtom projectName={projectName} />
            </Column>
        </Row>
    </Grid>
);

export interface Props {
    projectName: string;
}

export default ResultReport;
