import { User } from '../../models/user.model';
import { Moment } from "moment";

/**
 * Action types
 */
export enum UserTypes {
    RECOVERY_REQUEST = 'user:RECOVERY_REQUEST',
    RECOVERY_SUCCESS = 'user:RECOVERY_SUCCESS',
    RECOVERY_FAILURE = 'user:RECOVERY_FAILURE',
    RECOVERY_CLEAR = 'user:RECOVERY_CLEAR',
    USER_UPDATE = 'user:USER_UPDATE',
    USER_REQUEST = 'user:USER_UPDATE',
    USER_SUCCESS = 'user:USER_SUCCESS',
    USER_CLEAR = 'user:USER_CLEAR',
    USER_LOGOUT = 'user:USER_LOGOUT',
}

/**
 * State types
 */
export interface UserState {
    readonly recoveredEmail: string;
    readonly loading: boolean;
    readonly currentUser: User | null;
}
