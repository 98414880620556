import styled from 'styled-components';
import colors from '../../../theme/colors';

export const StyledH2 = styled.h2`
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;

    ${(props: any) => {
        const { cl = 'default' } = props;
        return `
            color: ${colors.label[cl].color};
        `;
    }}
`;
