import React from 'react';
import {
    Container,
    Welcome,
    Presentation,
    Cards,
    CallForRegistration
} from './index.styled';
import Card from './Card';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
//@ts-ignore
import HomeVideo from "../../assets/videos/home.mp4";
// imgs
import BannerWelcomePTBR from '../../assets/images/home_banner_welcome_pt.svg';
import BannerWelcomeESES from '../../assets/images/home_banner_welcome_es.svg';
import BannerWelcomeENUS from '../../assets/images/home_banner_welcome_en.svg';
// gifs
import GIFGraph3D from '../../assets/images/home_graph_3d.gif';
import GIFGraphComposition from '../../assets/images/home_composition_graph.gif';
import GIFGraphEvaporation from '../../assets/images/home_evaporation_graph.gif';
import { Link } from 'react-router-dom';

const Home = () => {
    const { t } = useTranslation('app');
    const getWelcomeBanner = () => {
        switch (i18next.language) {
            case 'pt_br':
                return BannerWelcomePTBR;
            case 'es_es':
                return BannerWelcomeESES;
            case 'en_us':
                return BannerWelcomeENUS;
            default:
                return BannerWelcomeENUS;
        }
    };

    return (
        <Container>
            <Welcome>
                <Welcome.Banner src={getWelcomeBanner()} />
                {/* <Welcome.Text>
                    <Welcome.Title renderText="Conheça o Solsys®" />
                    <Welcome.Subtitle renderText="Algum subtítulo muito legal, e curtinho (até 70 caracteres), aqui." />
                </Welcome.Text> */}
            </Welcome>
            <Presentation>
                <Presentation.Title i18nKey="home.presentation" />
                <Presentation.Video>
                    <video controls autoPlay muted>
                        <source type="video/mp4" src={HomeVideo} />
                    </video>
                </Presentation.Video>
            </Presentation>
            <Cards>
                <Card
                    title={t('home.cards.card1.title')}
                    description={t('home.cards.card1.text')}
                    img={GIFGraph3D}
                />
                <Card
                    title={t('home.cards.card2.title')}
                    description={t('home.cards.card2.text')}
                    img={GIFGraphComposition}
                    inverted
                />
                <Card
                    title={t('home.cards.card3.title')}
                    description={t('home.cards.card3.text')}
                    img={GIFGraphEvaporation}
                />
            </Cards>
            <CallForRegistration>
                <CallForRegistration.Title i18nKey="home.callForRegistration" />
                <CallForRegistration.Banner>
                    <Link to="sign?signUp">
                        <CallForRegistration.Banner.Button
                            cl="primary"
                            i18nKey="home.createAccountNow"
                        />
                    </Link>
                </CallForRegistration.Banner>
            </CallForRegistration>
        </Container>
    );
};

export default Home;
