import styled from 'styled-components';

export const Grid = styled.div`
    height: 100%;
    width: 100%;
    display: block;

    .col-centered {
        float: none !important;
        margin: 0 auto;
    }

    .col-right {
        float: none !important;
        margin: 0 0 0 auto;
    }

    .flex {
        display: flex;
        height: 100%;
    }

    .flex-row {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
    }

    .flex-align-center {
        align-items: center;
    }

    .flex-align-start {
        align-items: flex-start;
    }

    .flex-align-end {
        align-items: flex-end;
    }

    .flex-justify-center {
        justify-content: center;
    }

    .flex-justify-start {
        justify-content: flex-start;
    }

    .flex-justify-end {
        justify-content: flex-end;
    }

    .flex-column {
        flex-direction: column;
    }

    .row {
        margin-right: -${props => `${props['data-margin']}px`};
        margin-left: -${props => `${props['data-margin']}px`};

        &:after {
            content: '';
            clear: both;
            display: table;
        }
    }

    ${props => {
        const cols = props['data-cols'];
        const padding = props['data-padding'];

        let gridCss = '';

        const createAllCols = cols => {
            const sizes = ['xs', 'sm', 'md', 'lg'];
            for (let c = 1; c <= cols; c++) {
                for (let i = 0; i <= sizes.length; i++) {
                    gridCss += `.col-${sizes[i]}-${c}{
                            position: relative;
                            min-height: 1px;
                        }`;
                }
            }
        };

        const defineFloatInXsWithZeroPosition = (cols, size) => {
            for (let c = 0; c <= cols; c++) {
                gridCss += `.col-${size}-${c}{
                        float: left;
                    }`;
            }
        };

        const definePaddingInXsWithoutZeroPosition = (cols, size) => {
            for (let c = 1; c <= cols; c++) {
                gridCss += `.col-${size}-${c}{
                        padding-right: ${padding}px;
                        padding-left: ${padding}px;
                    }`;
            }
        };

        const createColWithZeroPosition = size => {
            gridCss += `.col-${size}-0{
                    width: 0;
                    overflow: hidden;
                    padding-left: 0;
                    padding-right: 0;
                }`;
        };

        const createCols = (cols, size) => {
            for (let c = 1; c <= cols; c++) {
                gridCss += `.col-${size}-${c}{
                        width: ${(100 / cols) * c}%;
                    }`;
            }
        };

        createAllCols(cols);
        defineFloatInXsWithZeroPosition(cols, 'xs');
        definePaddingInXsWithoutZeroPosition(cols, 'xs');
        createColWithZeroPosition('xs');
        createCols(cols, 'xs');
        gridCss += '@media (min-width: 768px) {';
        defineFloatInXsWithZeroPosition(cols, 'sm');
        definePaddingInXsWithoutZeroPosition(cols, 'sm');
        createColWithZeroPosition('sm');
        createCols(cols, 'sm');
        gridCss += '} @media (min-width: 992px) {';
        defineFloatInXsWithZeroPosition(cols, 'md');
        definePaddingInXsWithoutZeroPosition(cols, 'md');
        createColWithZeroPosition('md');
        createCols(cols, 'md');
        gridCss += '} @media (min-width: 1200px) {';
        defineFloatInXsWithZeroPosition(cols, 'lg');
        definePaddingInXsWithoutZeroPosition(cols, 'lg');
        createColWithZeroPosition('lg');
        createCols(cols, 'lg');
        gridCss += '}';

        return gridCss;
    }}
`;
