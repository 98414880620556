import Footer from './components/layout/Footer/index.translation.json';
import Topbar from './components/layout/Header/Topbar/container/index.translation.json';
import combineTranslations from './configs/combineTranslations';
import StepPolymer from './pages/Simulation/container/Configuration/StepPolymer/container/index.translation.json';
import StepCondition from './pages/Simulation/container/Configuration/StepCondition/container/index.translation.json';
import StepFormulation from './pages/Simulation/container/Configuration/StepFormulation/container/index.translation.json';
import PolymersList from './pages/Simulation/container/Configuration/StepPolymer/container/PolymersList/index.translation.json';
import Simulation from './pages/Simulation/container/index.translation.json';
import SimulationTopbar from './pages/Simulation/container/Topbar/container/index.translation.json';
import FormError from './components/UI/Error/index.translation.json';
import ConditionsOfApplicationForm from './components/forms/ConditionsOfApplication/index.translation.json';
import PolymerSelectItem from './pages/Simulation/container/Configuration/StepPolymer/container/Expanded/PolymerSelectItem/index.translation.json';
import Select from './components/UI/Select/index.translate.json';
import StepButtons from './components/UI/Wizard/StepButtons/index.translation.json';
import SolventGrid from './pages/Simulation/container/Configuration/StepFormulation/container/SolventGrid/index.translation.json';
import SolubilityGraph from './pages/Simulation/container/Result/SolubilityGraph/index.translation.json';
import Actions from './redux/actions/actions.translation.json';
import CompositionGraph from './pages/Simulation/container/Result/Composition/index.translation.json';
import EvaporationGraph from './pages/Simulation/container/Result/EvaporationGraph/index.translation.json';
import SimulationResultCard from './pages/Simulation/container/SimulationResultCard/container/index.translation.json';
import Reducers from './redux/reducers/reducers.translation.json';
import ResultTable from './pages/Simulation/container/Result/ResultTable/index.translation.json';
import PerformedSimulations from './pages/Simulation/container/Performed/container/index.translation.json';
import ResultReport from './pages/Simulation/container/Result/Report/index.translation.json';
import Sign from './pages/Sign/container/index.translation.json';
import SignIn from './pages/Sign/container/SignIn/container/index.translation.json';
import SignInFrom from './components/forms/SignIn/index.translation.json';
import SignUp from './pages/Sign/container/SignUp/container/index.translation.json';
import SignUpForm from './components/forms/SignUp/index.translation.json';
import EmailRecoveryForm from './components/forms/EmailRecovery/index.translation.json';
import EmailRecovery from './pages/Sign/container/SignIn/container/EmailRecovery/container/index.translation.json';
import SignSent from './pages/Sign/container/SignSent/index.translation.json';
import SaveProjectForm from './components/forms/SaveProject/index.translation.json';
import SaveProject from './components/common/SaveProject/container/index.translation.json';
import ComparationBar from './pages/Simulation/container/Performed/container/ComparationBar/container/index.translation.json';
import SimulationSaga from './redux/sagas/simulation/index.translation.json';
import SimulationCards from './pages/Simulation/container/Result/SimulationCards/index.translation.json';
import Search from './components/UI/DocumentSearch/container/index.translation.json';
import Projects from './pages/Projects/container/index.translation.json';
import ProjectItem from './pages/Projects/container/ProjectItem/index.translation.json';
import ProjectsSaga from './redux/sagas/projects/index.translation.json';
import Home from './pages/Home/index.translation.json';
import Master from './pages/Master/container/index.translation.json';
import MasterGroups from './pages/Master/container/Accesses/Groups/container/index.translation.json';
import MasterGroup from './pages/Master/container/Accesses/Groups/container/Group/container/index.translation.json';
import MasterGroupForm from './components/forms/Group/index.translation.json';
import MasterGroupUsers from './pages/Master/container/Accesses/Groups/container/Group/container/UsersStep/index.translation.json';
import MasterGroupPolymers from './pages/Master/container/Accesses/Groups/container/Group/container/PolymersStep/index.translation.json';
import MasterGroupSolvents from './pages/Master/container/Accesses/Groups/container/Group/container/SolventsStep/index.translation.json';
import MasterTerms from './pages/Master/container/Juridical/Terms/container/index.translation.json';
import MasterTerm from './pages/Master/container/Juridical/Terms/container/Term/container/index.translation.json';
import MasterTermsForm from './components/forms/Terms/index.translation.json';
import MasterUsers from './pages/Master/container/Accesses/Users/container/index.translation.json';
import MasterUser from './pages/Master/container/Accesses/Users/container/User/container/index.translation.json';
import MasterUserProfileForm from './components/forms/UserProfile/index.translation.json';
import MasterUserJuridical from './pages/Master/container/Accesses/Users/container/User/container/JuridicalStep/index.translation.json';
import MasterPropertiesTemplate from './pages/Master/container/Properties/Upload/container/index.translation.json';
import AcceptOrDecline from './components/forms/SignUp/AcceptOrDecline/index.translation.json';

export default combineTranslations([
    Topbar,
    Footer,
    Simulation,
    StepPolymer,
    PolymersList,
    SimulationTopbar,
    StepCondition,
    FormError,
    ConditionsOfApplicationForm,
    PolymerSelectItem,
    Select,
    StepFormulation,
    StepButtons,
    SolventGrid,
    SolubilityGraph,
    Actions,
    CompositionGraph,
    EvaporationGraph,
    SimulationResultCard,
    Reducers,
    ResultTable,
    PerformedSimulations,
    ResultReport,
    Sign,
    SignIn,
    SignInFrom,
    SignUp,
    SignUpForm,
    EmailRecoveryForm,
    EmailRecovery,
    SignSent,
    SaveProjectForm,
    SaveProject,
    ComparationBar,
    SimulationSaga,
    SimulationCards,
    Search,
    Home,
    Master,
    MasterGroups,
    MasterGroup,
    MasterGroupForm,
    MasterGroupUsers,
    MasterGroupPolymers,
    MasterGroupSolvents,
    MasterTerms,
    MasterTerm,
    MasterTermsForm,
    Projects,
    ProjectItem,
    ProjectsSaga,
    MasterUsers,
    MasterUser,
    MasterUserProfileForm,
    MasterUserJuridical,
    MasterPropertiesTemplate,
    AcceptOrDecline
]);
