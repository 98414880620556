import styled from 'styled-components';
import { Icon } from '../../../UI';

export const Container = styled.div`
    height: 56px;
    width: 100%;
    border: 1px dashed #a6a6a6;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;

    ${props => {
        const disabled = props['data-disabled'];
        if (!disabled) {
            return `
            cursor: pointer;
            &:hover {
                border-color: 656565;

                i {
                    color: #656565;
                }
            }
            `;
        } else {
            return 'cursor: not-allowed;';
        }
    }}
`;

export const Plus = styled(Icon)`
    font-size: 24px;
    color: #cccccc;
`;
