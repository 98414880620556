import React from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import {
    Search,
    IconButton
} from '../../../../../../../../../../components/UI';
import { User } from '../../../../../../../../../../models/user.model';
import { UserItem, SearchContainer } from './index.styled';
import { DataList } from '../../index.styled';
import { withTranslation, WithTranslation } from 'react-i18next';
import { removeObjectReference } from '../../../../../../../../../../helpers/util';

export class Active extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            selecteds: []
        };
    }

    private removeItem = (id: number) => {
        const { selecteds } = this.state;
        const indexToRemove = selecteds.findIndex(value => value.id === id);
        selecteds.splice(indexToRemove, 1);
        this.setState({ selecteds });
    };

    private handleChange = item => {
        const { users } = this.props;
        const { selecteds } = this.state;
        const user = users.find(user => user.id === item.value);
        if (user) {
            this.setState({ selecteds: [...selecteds, user] });
        }
    };

    private isChanged = () => {
        const { values } = this.props;
        const { selecteds } = this.state;
        if (values.length !== selecteds.length) {
            return true;
        }
        let isChanged = false;
        values.forEach(value => {
            const finded = selecteds.find(selected => selected.id === value.id);
            if (!finded) {
                isChanged = true;
            }
        });
        return isChanged;
    };

    private content(value: Partial<User>) {
        return (
            <UserItem key={value.id}>
                <UserItem.Email renderText={value.email} />
                <UserItem.Name renderText={value.display_name} />
            </UserItem>
        );
    }

    private convertValuesToDropdownList = (): DropdownItemProps[] => {
        const { users } = this.props;
        const { selecteds } = this.state;
        const arr: DropdownItemProps[] = [];
        if (!users) {
            return arr;
        }
        users.forEach(value => {
            if (selecteds.find(item => item.id === value.id) != null) {
                return;
            }
            arr.push({
                key: value.id,
                text: `${value.email} ${value.display_name}`,
                value: value.id,
                content: this.content(value)
            });
        });
        return arr;
    };

    private createRow(user: Partial<User>) {
        return (
            <DataList.Row key={user.id}>
                <DataList.Cell>{user.email}</DataList.Cell>
                <DataList.Cell>{user.display_name}</DataList.Cell>
                <DataList.Cell>
                    <IconButton
                        onClick={() => this.removeItem(user.id!)}
                        iconSize={20}
                        iconName="times-circle"
                    />
                </DataList.Cell>
            </DataList.Row>
        );
    }

    private renderUsersRow() {
        const { selecteds } = this.state;
        return selecteds.map(value => this.createRow(value));
    }

    render() {
        const { children, loading, onSearchChange, onSave, t } = this.props;
        const { selecteds } = this.state;
        return (
            <div>
                <SearchContainer>
                    <Search
                        id="group_users_search"
                        placeholder={t(
                            'master.groups.group.steps.users.search.placeholder'
                        )}
                        noResultsMessage={t(
                            'master.groups.group.steps.users.search.noResultsMessage'
                        )}
                        options={this.convertValuesToDropdownList()}
                        onSearchChange={onSearchChange}
                        loading={loading}
                        startSearch={3}
                        values={selecteds}
                        onChange={this.handleChange}
                    />
                </SearchContainer>
                <DataList>
                    <DataList.Header>
                        <DataList.HeaderRow>
                            <DataList.HeaderCell width={5}>
                                {t(
                                    'master.groups.group.steps.users.usersList.header.email'
                                )}
                            </DataList.HeaderCell>
                            <DataList.HeaderCell width={6}>
                                {t(
                                    'master.groups.group.steps.users.usersList.header.name'
                                )}
                            </DataList.HeaderCell>
                            <DataList.HeaderCell width={1} textAlign="center" />
                        </DataList.HeaderRow>
                    </DataList.Header>
                    <DataList.Body>{this.renderUsersRow()}</DataList.Body>
                </DataList>
                {children({
                    disableSave: !this.isChanged(),
                    onSave: () => onSave(selecteds)
                })}
            </div>
        );
    }

    componentWillMount() {
        const { values } = this.props;
        this.setState({ selecteds: removeObjectReference(values) });
    }
}

interface Props extends WithTranslation {
    values: { id: number; display_name: string; email: string }[];
    users: Partial<User>[];
    loading: boolean;
    children;
    onSave(data: Partial<User>[]): void;
    onSearchChange(searchQuery: string): void;
}

interface State {
    selecteds: Partial<User>[];
}

export default withTranslation('app')(Active);
