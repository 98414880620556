import styled from 'styled-components';

export const CardBox = styled.div`
    min-height: 56px;
    width: 100%;
    border: 0.5px solid #dddddd;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.2),
        0 0 4px 2px rgba(204, 204, 204, 0.15);
`;
