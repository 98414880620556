import React from 'react';
import { Empty } from '../Empty';
import { SpanError } from './index.styled';

export const Error = ({ message, touched }: IErrorProps) => {
    if (!message || !touched) {
        return <Empty />;
    }
    const splittedMessage = {
        translate: message.split('/')[0],
        value: message.split('/')[1]
    };
    return (
        <SpanError
            i18nKey={[
                `error.${splittedMessage.translate}`,
                { value: splittedMessage.value }
            ]}
        />
    );
};

interface IErrorProps {
    message: string | undefined;
    touched: boolean;
}
