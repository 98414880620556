import styled from 'styled-components';
import ReactQuill from 'react-quill';

export const StyledReactQuill = styled(ReactQuill)`
    .ql-toolbar.ql-snow {
        border: 1px solid #a6a6a6;
        ${props => {
            const error = props['data-error'];
            if (error) {
                return `
                    border-top: 1px solid #a70e1a;
                    border-left: 1px solid #a70e1a;
                    border-right: 1px solid #a70e1a;
                `;
            }
        }}
    }
    .ql-container.ql-snow {
        border: 1px solid #a6a6a6;
        ${props => {
            const error = props['data-error'];
            if (error) {
                return `
                    border-bottom: 1px solid #a70e1a;
                    border-left: 1px solid #a70e1a;
                    border-right: 1px solid #a70e1a;
                `;
            }
        }}
    }
`;
