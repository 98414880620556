import styled from 'styled-components';
import { Span } from '../..';
import theme from '../../../../theme/colors';

export const Container = styled.div`
    padding-bottom: 28px;
`;

export const Content: any = styled.div``;

Content.Name = styled(Span)`
    display: block;
    width: 100%;
    font-size: 15px;
    font-weight: 600;
    color: ${theme.label.primary.color};
`;

Content.Item = styled.div`
    display: block;
    width: 100%;
    margin-top: 4px;
`;

Content.Item.Name = styled(Span)`
    font-size: 13px;
    display: inline-block;
    color: ${theme.label.primary.color};
    margin-right: 5px;
`;

Content.Item.Result = styled(Span)`
    font-size: 13px;
    display: inline-block;
`;
