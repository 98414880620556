import styled from 'styled-components';
import { Input } from 'semantic-ui-react';
import { Icon } from '../Icon';

export const Container = styled.div`
    width: 100%;
    position: relative;
`;
const DATA_TAG_PADDING = 'data-tagpadding'; // Define a constant for the duplicated literal

export const InputIcon = styled(Icon)`
    position: absolute;
    color: #cccccc;
    font-weight: normal;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 18px;
    font-size: 15px;
    left: 12.5px;
    z-index: 1;
`;

export const StyledInput = styled(Input)`
    width: 100%;

    ${props => {
        if (props.tag) {
            return `
            &::after {
            content: '${props.tag}';
            position: absolute;
            color: #cccccc;
            font-weight: normal;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 18px;
            font-size: 15px;
            ${
                props['data-tagposition'] === 'left'
                    ? 'left: 12.5px;'
                    : 'right: 12.5px;'
            }
            }
        `;
        }
    }}

    &.ui.input > input {
        height: 40px;
        border-color: #a6a6a6;
        border-radius: 4px;
        background-color: #ffffff;
        width: 100%;
        ${props => {
            if (props['data-icon']) {
                return `
                    padding-left: 35px;
                `;
            } else if (props.tag) {
                if (props['data-tagposition'] === 'right') {
                    return `
                    padding-right: ${
                        props[DATA_TAG_PADDING]
                            ? props[DATA_TAG_PADDING]
                            : '35px'
                    };
                    text-align: left;
                `;
                } else {
                    return `
                    padding-left: ${
                        props[DATA_TAG_PADDING]
                            ? props[DATA_TAG_PADDING]
                            : '35px'
                    };
                    text-align: right;
                `;
                }
            }
        }}

        &::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-size: 16px;
            opacity: 1; /* Firefox */
        }

        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-size: 16px;
        }

        &::-ms-input-placeholder {
            /* Microsoft Edge */
            font-size: 16px;
        }
    }

    &.ui.input > input:hover,
    &.ui.input > input:active,
    &.ui.input > input:focus,
    &.ui.input > input:focus-within {
        ${props => (!props.error ? 'border-color: #444444 !important;' : '')}
        border-radius: 4px !important;
    }

    &.ui.input.error > input {
        background-color: inherit;
        border-color: #a70e1a;
        color: inherit;
    }
`;
