import styled from 'styled-components';

export const StyledIcon = styled.i`
    color: #656565;
    font-size: ${({ size }: any) => (size ? `${size}px` : '16px')};

    &:before {
        ${({ mirror }: any) => (mirror ? 'transform: scaleX(-1);' : '')};
    }
`;
