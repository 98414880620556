import styled, { css } from 'styled-components';
import {
    H2,
    Span,
    Icon,
    H3,
    Button,
    H1
} from '../../../../../../components/UI';

export const Container = styled.section`
    padding: 48px;
`;

export const Title = styled(H2)`
    padding-bottom: 7px;
`;

export const Content = styled.div`
    padding: 25px 0;
`;

const dragAccept = css`
    border-color: #0bab47;
    & span,
    i {
        color: #0bab47;
    }
`;
const dragReject = css`
    border-color: #e57878;
    & span,
    i {
        color: #e57878;
    }
`;

export const Drop: any = styled.div`
    padding: 30px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border: 1px dashed #a6a6a6;
    cursor: ${(props: any) => (props.uploading ? 'progress' : 'pointer')};

    ${(props: any) => props.isDragAccept && dragAccept};
    ${(props: any) => props.isDragReject && dragReject};
`;

Drop.Icon = styled(Icon)`
    color: #a6a6a6;
    font-size: 70px;
    margin-bottom: 20px;
`;

Drop.Span = styled(Span)`
    color: #a6a6a6;
`;

export const Data: any = styled.div`
    border: 0.5px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0px 0px 0 0 rgba(68, 68, 68, 0.2),
        1px 1px 4px 2px rgba(204, 204, 204, 0.15);
    padding: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

Data.File = styled.div`
    width: auto;
    padding-right: 20px;
    overflow: hidden;
`;

Data.Name = styled(H3)`
    height: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

Data.Size = styled(Span)`
    color: #a6a6a6;
    font-size: 14px;
    display: block;
`;

Data.Buttons = styled.div`
    margin-left: auto;
    white-space: nowrap;
`;

Data.Buttons.Cancel = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: bold;
        margin: 4px 0;
    }
`;

Data.Buttons.Upload = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: bold;
        margin: 4px 0;
        margin-left: 20px;
    }
`;

export const Download: any = styled.div`
    width: 100%;
    padding: 34px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
`;

Download.Info = styled.div`
    margin-right: auto;
    display: flex;
    flex-direction: column;
`;

Download.Info.Title = styled(H3)`
    margin-top: 10px;
`;

Download.Info.Icon = styled(Icon)`
    font-size: 32px;
    margin-right: 5px;
`;

Download.Info.Span = styled(Span)`
    width: 100%;
    padding: 10px
`;

Download.ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`

Download.Button = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: bold;
        margin: 4px 0;
        margin-left: 20px;
    }
`;

export const OutputUpload: any = styled.div`
    padding: 20px 40px;
`;

OutputUpload.Title = styled(H1)`
    margin-bottom: 20px;
`;

OutputUpload.Type = styled.div`
    margin: 15px 0;
`;

OutputUpload.Type.Header = styled.div`
    padding-bottom: 10px;
    display: flex;
    align-items: center;
`;

OutputUpload.Type.Header.Title = styled(H3)`
    padding: 0;
    margin: 0;
    display: inline-block;
    height: auto;
    color: #666;
`;

OutputUpload.Type.Header.Icon = styled(Icon)`
    color: #bbb;
    font-size: 30px;
    display: inline-block;
    margin-left: -5px;
`;

OutputUpload.Type.Header.Errors = styled(OutputUpload.Type.Header.Icon)`
    color: red;
`;

OutputUpload.Type.Header.Warnings = styled(OutputUpload.Type.Header.Icon)`
    color: orange;
`;

OutputUpload.Type.List = styled.ul`
    padding: 10px 5px;
    height: 135px;
    overflow: auto;
    border: 1px solid #eee;
    border-radius: 2.5px;
`;

OutputUpload.Type.ListItem = styled.li`
    padding: 5px;
    font-size: 16px;
`;

OutputUpload.OK = styled(Button)`
    &.ui.button {
        display: block;
        margin-left: auto;
        margin-top: 34px;
        font-weight: bold;
        font-size: 16px;
    }
`;

OutputUpload.NoChanges = styled(Span)`
    color: #aaa;
    font-size: 23px;
    padding: 25px 0;
    display: block;
    width: 100%;
    text-align: center;
`;
