import Route from './models/route.model';
import Simulation from './pages/Simulation';
import Sign from './pages/Sign';
import Home from './pages/Home';
import Master from './pages/Master';
import Projects from './pages/Projects';
import SwitchComponent from './components/common/SwitchComponent';

export default [
    new Route(
        '/',
        true,
        SwitchComponent({ reduxPath: 'user.currentUser' })(Projects)(Home)
    ),
    new Route('/sign', true, Sign),
    new Route('/projects', true, Projects),
    new Route('/simulation', true, Simulation),
    new Route('/master', true, Master),
    new Route('/master/users', true, Master),
    new Route('/master/users/user', true, Master),
    new Route('/master/users/user/:id', true, Master),
    new Route('/master/groups', true, Master),
    new Route('/master/groups/group', true, Master),
    new Route('/master/groups/group/:id', true, Master),
    new Route('/master/terms', true, Master),
    new Route('/master/terms/term/:language', true, Master),
    new Route('/master/properties', true, Master),
    new Route('/master/properties/upload', true, Master)
];
