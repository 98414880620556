import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Grid, Field, Input } from '../../UI';
import { StyledForm, StyledRow, StyledTextArea } from './index.styled';
import { Project } from '../../../models/project.model';

class SaveProjectForm extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        const { project } = this.props;
        this.state = {
            initialValues: {
                name: project.name,
                description: project.description
            }
        };
    }

    private get validationSchema() {
        return yup.object().shape({
            name: yup
                .string()
                .max(25)
                .required(),
            description: yup.string().max(255)
        });
    }

    render() {
        const { initialValues } = this.state;
        const { onSubmit, children } = this.props;
        return (
            <Formik
                validationSchema={this.validationSchema}
                onSubmit={onSubmit}
                initialValues={initialValues}
                isInitialValid
            >
                {({ isValid }) => {
                    return (
                        <StyledForm>
                            <Grid margin={0} padding={0}>
                                <StyledRow>
                                    <Field
                                        id="SaveProject_name"
                                        i18nKey="forms.saveProject.projectName"
                                        name="name"
                                        placeholder="Solsys Lite"
                                        component={Input}
                                    />
                                </StyledRow>
                                <StyledRow>
                                    <Field
                                        id="SaveProject_description"
                                        i18nKey="forms.saveProject.observations"
                                        name="description"
                                        component={StyledTextArea}
                                        maxLength={"255"}
                                    />
                                </StyledRow>
                                <StyledRow>{children(isValid)}</StyledRow>
                            </Grid>
                        </StyledForm>
                    );
                }}
            </Formik>
        );
    }
}

interface Props {
    project: Project;
    children?;
    onSubmit(data): void;
}

export default SaveProjectForm;
