import i18next from 'i18next';
import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import TopbarContainer from './container';
import { ApplicationState } from '../../../../redux/store';
import { connect } from 'react-redux';
import { signOut } from '../../../../redux/actions/user.actions';
import { bindActionCreators } from 'redux';
import { Project } from '../../../../models/project.model';
import { saveProject } from '../../../../redux/actions/projects.actions';
import { removeObjectReference } from '../../../../helpers/util';

const Topbar = ({
    currentUser,
    signOut,
    project,
    cache,
    saveProject,
    ...rest
}: Props) => {
    const isChanged =
        project && cache
            ? !_.isEqual(
                  removeObjectReference(project.simulations).map(simulation => {
                      delete simulation.selected;
                      return simulation;
                  }),
                  removeObjectReference(cache.simulations).map(simulation => {
                      delete simulation.selected;
                      return simulation;
                  })
              )
            : false;
    return (
        <TopbarContainer
            user={currentUser}
            signOut={signOut}
            saveProject={saveProject}
            project={project}
            isChanged={isChanged}
            onChangeLanguage={value => i18next.changeLanguage(value)}
            {...rest}
        />
    );
};

interface Props extends RouteComponentProps {
    currentUser?;
    project: Project | null;
    cache: Project | null;
    signOut(): void;
    saveProject(data, callback): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    currentUser: state.user.currentUser,
    project: state.simulation.project,
    cache: state.simulation.cacheProject
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            signOut,
            saveProject
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Topbar));
