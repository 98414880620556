import styled from 'styled-components';
import { Span } from '../../../../../components/UI';

export const Container = styled.section`
    padding: 30px 0px;

    .react-ruler-wrapper {
        & * {
            user-select: none;
        }

        &.ruler-list .rule-mark .line-text {
            font-size: 14px;
            margin-left: -2px;
        }
    }
`;

export const RulerDescription = styled(Span)`
    height: 40px;
    line-height: 40px;
    color: #763689;
    font-size: 16px;
    font-weight: 600;
    float: right;
`;
