import React from "react";
import { Empty } from "../..";
import {
    StepLine,
    CancelButton,
    NextButton,
    Buttons,
    SaveButton
} from "./index.styled";
import { IWizardContext } from "..";

export const StepButtons = ({
    onNext,
    onSave,
    disableNext,
    onCancel,
    fromStep,
    currentStep,
    stepProgress,
    wizardSize,
    loading
}: IStepButtonsProps) => {
    if (fromStep !== currentStep) {
        return <Empty />;
    }

    if (
        fromStep !== stepProgress &&
        currentStep === fromStep &&
        fromStep !== wizardSize
    ) {
        return (
            <React.Fragment>
                <StepLine id="StepButtons_line" className="line" />
                <Buttons>
                    <CancelButton
                        id="StepButtons_cancelBtn"
                        i18nKey="stepButtons.cancel"
                        cl="invertedPrimary"
                        type="button"
                        onClick={onCancel}
                    />
                    <SaveButton
                        id="StepButtons_saveBtn"
                        i18nKey="stepButtons.save"
                        cl="primary"
                        type="button"
                        disabled={disableNext}
                        onClick={onSave}
                        loading={loading}
                    />
                </Buttons>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <StepLine id="StepButtons_line" />
            <Buttons>
                <NextButton
                    id="StepButtons_nextBtn"
                    i18nKey={
                        fromStep === wizardSize
                            ? "stepButtons.simulate"
                            : "stepButtons.next"
                    }
                    cl="primary"
                    type="button"
                    disabled={disableNext}
                    onClick={onNext}
                    loading={loading}
                />
            </Buttons>
        </React.Fragment>
    );
};

StepButtons.defaultProps = {
    onNext: () => {},
    onSave: () => {}
};

interface IStepButtonsProps extends Partial<IWizardContext> {
    disableNext: boolean;
    fromStep: number;
    loading?: boolean;
    onCancel(): void;
    onNext(): void;
    onSave(): void;
}
