import React from 'react';
import moment from 'moment';
import { Project } from '../../../../models/project.model';
import { Grid, Column, confirmDelete } from '../../../../components/UI';
import {
    Container,
    StyledRow,
    Flask,
    Title,
    Inf,
    Arrow,
    Expanded,
    Compressed,
    Buttons
} from './index.styled';
import { modal } from '../../../../components/UI/Modal';
import SaveProject from '../../../../components/common/SaveProject';

class ProjectItem extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false
        };
    }

    private expand = () => {
        this.setState({ expanded: true });
    };

    private compress = () => {
        this.setState({ expanded: false });
    };

    private recoverProject = () => {
        const { project, openProject } = this.props;
        openProject(project.id!);
    };

    private editProject = () => {
        const { project, getProjects } = this.props;
        modal({})(({ close }) => {
            return (
                <SaveProject
                    project={project}
                    callback={getProjects}
                    close={close}
                    isEdit
                />
            );
        });
    };

    private renderExpanded() {
        const { project, deleteProject } = this.props;
        return (
            <React.Fragment>
                {project.description && (
                    <Expanded>
                        <Grid>
                            <StyledRow>
                                <Column xs={12} sm={12} md={12} lg={12}>
                                    <Inf
                                        cl="secondary"
                                        i18nKey="projects.item.observations"
                                    />
                                </Column>
                            </StyledRow>
                            <StyledRow>
                                <Column xs={12} sm={12} md={12} lg={12}>
                                    <Inf renderText={project.description} />
                                </Column>
                            </StyledRow>
                        </Grid>
                    </Expanded>
                )}
                <Buttons>
                    <Buttons.Delete
                        cl="span"
                        i18nKey="projects.item.buttons.delete"
                        onClick={() => deleteProject(project)}
                    />
                    <Buttons.Edit
                        cl="span"
                        i18nKey="projects.item.buttons.edit"
                        onClick={this.editProject}
                    />
                    <Buttons.Open
                        cl="span"
                        i18nKey="projects.item.buttons.open"
                        onClick={this.recoverProject}
                    />
                </Buttons>
            </React.Fragment>
        );
    }

    render() {
        const { project } = this.props;
        const { expanded } = this.state;
        const createdWithZone = moment(`${project.created_at}Z`);
        const updatedWithZone = moment(`${project.updated_at}Z`);
        const created = {
            date: createdWithZone.format('DD/MM/YYYY'),
            time: `${createdWithZone.format('HH')}h${createdWithZone.format(
                'mm'
            )}m`
        };
        const updated = {
            date: updatedWithZone.format('DD/MM/YYYY'),
            time: `${updatedWithZone.format('HH')}h${updatedWithZone.format(
                'mm'
            )}m`
        };
        return (
            <Container>
                {!expanded ? (
                    <Arrow
                        onClick={this.expand}
                        iconName="angle-down"
                        iconSize={30}
                    />
                ) : (
                    <Arrow
                        onClick={this.compress}
                        iconName="angle-up"
                        iconSize={30}
                    />
                )}
                <Compressed onClick={this.recoverProject}>
                    <Grid>
                        <StyledRow>
                            <Column xs={12} sm={12} md={12} lg={12}>
                                <Title cl="primary" renderText={project.name} />
                            </Column>
                        </StyledRow>
                        <StyledRow>
                            <Column xs={12} sm={4} md={4} lg={4}>
                                <Inf
                                    cl="secondary"
                                    i18nKey={[
                                        'projects.item.created',
                                        {
                                            date: created.date,
                                            time: created.time
                                        }
                                    ]}
                                />
                            </Column>
                            <Column xs={12} sm={4} md={4} lg={4}>
                                <Inf
                                    cl="secondary"
                                    i18nKey={[
                                        'projects.item.updated',
                                        {
                                            date: updated.date,
                                            time: updated.time
                                        }
                                    ]}
                                />
                            </Column>
                            <Column xs={12} sm={4} md={4} lg={4}>
                                <Flask name="flask" />
                                <Inf
                                    cl="secondary"
                                    i18nKey={[
                                        project.count_simulations <= 1
                                            ? 'projects.item.simulation'
                                            : 'projects.item.simulations',
                                        { total: project.count_simulations }
                                    ]}
                                />
                            </Column>
                        </StyledRow>
                    </Grid>
                </Compressed>
                {expanded && this.renderExpanded()}
            </Container>
        );
    }
}

interface Props {
    project: Project;
    deleteProject(project: Project): void;
    openProject(id: number): void;
    getProjects(): void;
}

interface State {
    expanded: boolean;
}

export default ProjectItem;
