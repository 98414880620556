import React from 'react';
import { StyledRow, Name, AxisDescription } from './index.styled';
import { Column } from '../../../../../../components/UI';
import RadiusDPH from '../../../../../../models/radiusDPH.model';
import PositionDPH from '../../../../../../models/positionDPH.model';
import {
    normalizeDPH,
    normalizeRadiusDPH
} from '../../../../../../helpers/util';
import { Doughnut } from '../index.styled';

const PolymerDialog = ({ name, color, position, size }: Props) => {
    const dph = normalizeDPH(position);
    const rdph = normalizeRadiusDPH(size);
    const renderName = () => (
        <StyledRow>
            <Column xs={12} sm={12} md={12} lg={12}>
                <Doughnut data-color={color} />
                <Name renderText={name} />
            </Column>
        </StyledRow>
    );
    const renderPosition = () => {
        return (
            <StyledRow>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="dD" />
                    {dph.dD}
                </Column>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="dP" />
                    {dph.dP}
                </Column>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="dH" />
                    {dph.dH}
                </Column>
            </StyledRow>
        );
    };
    const renderRadius = () => {
        return (
            <StyledRow>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="rD" />
                    {rdph.rD}
                </Column>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="rP" />
                    {rdph.rP}
                </Column>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="rH" />
                    {rdph.rH}
                </Column>
            </StyledRow>
        );
    };
    return (
        <React.Fragment>
            {renderName()}
            {renderPosition()}
            {renderRadius()}
        </React.Fragment>
    );
};

interface Props {
    name: string;
    color: string;
    position: PositionDPH;
    size: RadiusDPH;
}

export default PolymerDialog;
