import { Reducer } from 'redux';
import { ProjectsState, ProjectsTypes } from '../../types/projects.types';

const INITIAL_STATE: ProjectsState = {
    items: [],
    loading: false,
    paging: {
        total_items: 0,
        total_pages: 1
    }
};

const reducer: Reducer<ProjectsState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ProjectsTypes.GET_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }
        case ProjectsTypes.GET_SUCCESS: {
            return {
                ...state,
                loading: false,
                items: action.payload.projects,
                paging: action.payload.paging
            };
        }
        case ProjectsTypes.GET_FAILURE: {
            return {
                ...state,
                loading: false,
                items: []
            };
        }
        case ProjectsTypes.DELETE_PROJECT_REQUEST: {
            return {
                ...state,
                loading: true
            };
        }
        case ProjectsTypes.DELETE_PROJECT_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case ProjectsTypes.DELETE_PROJECT_FAILURE: {
            return {
                ...state,
                loading: false
            };
        }
        default:
            return state;
    }
};

export default reducer;
