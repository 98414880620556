import React from 'react';
import { bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import UsersContainer from './container';
import { ApplicationState } from '../../../../../redux/store';
import { User } from '../../../../../models/user.model';
import {
    searchUser,
    deleteUser
} from '../../../../../redux/actions/users.actions';

class Users extends React.Component<Props, {}> {
    render() {
        return <UsersContainer limit={10} {...this.props} />;
    }

    componentWillMount() {
        const { searchUser } = this.props;
        searchUser({ q: '', page: 1, limit: 10 });
    }
}

interface Props extends RouteComponentProps {
    users: Partial<User>[];
    loading: boolean;
    paging: {
        total_items: number;
        total_pages: number;
    };
    searchUser(filter, callback?): void;
    deleteUser(user: Partial<User>, callback?: Function): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    users: state.users.items,
    loading: state.users.loading,
    paging: state.users.paging
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            searchUser,
            deleteUser
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Users));
