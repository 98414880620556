/**
 * Action types
 */
export enum PropertiesTypes {
    UPLOAD_REQUEST = 'properties:UPLOAD_REQUEST',
    UPLOAD_SUCCESS = 'properties:UPLOAD_SUCCESS',
    UPLOAD_FAILURE = 'properties:UPLOAD_FAILURE',
    DOWNLOAD_REQUEST = 'properties:DOWNLOAD_REQUEST',
    DOWNLOAD_SUCCESS = 'properties:DOWNLOAD_SUCCESS',
    DOWNLOAD_FAILURE = 'properties:DOWNLOAD_FAILURE'
}

/**
 * State types
 */
export interface PropertiesState {
    readonly uploading: boolean;
    readonly downloading: boolean;
    readonly error: boolean;
    readonly message: string;
}
