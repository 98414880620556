import i18next from 'i18next';
import React from 'react';
import { Empty, Translator, Trash } from '../../../../../../../components/UI';
import {
    Label,
    PolymerListItem,
    PolymerName,
    Polymers,
    PolymersOrdenedList,
    Limit,
    LimitExceeded,
    PolymerConcentration,
    PolymerConcentrationPercentage,
    PolymerInf,
    PolymerCard
} from './index.styled';
import { Polymer } from '../../../../../../../models/polymer.model';

const PolymersList = ({
    limit,
    polymers,
    removePolymer
}: IPolymersListProps) => {
    const renderPolymersList = () => {
        if (!polymers) {
            return [];
        }
        return polymers.map(polymer => (
            <PolymerListItem key={polymer.id}>
                <PolymerCard>
                    <PolymerInf>
                        <PolymerName id="PolymersList_item_name">
                            {polymer.name}
                        </PolymerName>
                        <Translator
                            id="PolymersList_item_concentration_translator"
                            translate="polymersList.concentration"
                            render={(
                                translate: string,
                                t: i18next.TFunction
                            ) => (
                                <PolymerConcentration id="PolymersList_item_concentration">
                                    {polymer.chemical &&
                                        polymer.chemical.concentration && (
                                            <React.Fragment>
                                                {t(translate)}
                                                <PolymerConcentrationPercentage id="PolymersList_item_concentration_percentage">
                                                    {
                                                        polymer.chemical
                                                            .concentration
                                                    }
                                                    %
                                                </PolymerConcentrationPercentage>
                                            </React.Fragment>
                                        )}
                                </PolymerConcentration>
                            )}
                        />
                    </PolymerInf>
                    {removePolymer ? (
                        <Trash handleOnClick={() => removePolymer(polymer)} />
                    ) : (
                        <Empty />
                    )}
                </PolymerCard>
            </PolymerListItem>
        ));
    };

    const renderLimit = () => {
        if (polymers.length === 0 || !limit) {
            return <Empty />;
        }
        if (polymers.length === limit) {
            return (
                <Translator
                    id="PolymersList_limit_translator"
                    translate="simulation.list.exceeded"
                    render={(translate: string, t: i18next.TFunction) => (
                        <LimitExceeded id="PolymersList_limit_exceeded">
                            {t(translate, {
                                name: t('simulation.stepPolymer.resins')
                            })}
                        </LimitExceeded>
                    )}
                />
            );
        }
        return (
            <Translator
                id="PolymersList_limit_translator"
                translate="simulation.list.limit"
                render={(translate: string, t: i18next.TFunction) => (
                    <Limit id="PolymersList_limit">
                        {t(translate, {
                            limit: `<b>${limit - polymers.length}</b>`,
                            name: t(
                                limit - polymers.length > 1
                                    ? 'simulation.stepPolymer.resins'
                                    : 'simulation.stepPolymer.resin'
                            )
                        })}
                    </Limit>
                )}
            />
        );
    };

    if (polymers.length === 0) {
        return <Empty />;
    }

    return (
        <Polymers id="PolymersList">
            <Label id="PolymersList_label" i18nKey="polymersList.label" />
            {renderLimit()}
            <PolymersOrdenedList id="PolymersList_list">
                {renderPolymersList()}
            </PolymersOrdenedList>
        </Polymers>
    );
};

export interface IPolymersListProps {
    polymers: Polymer[];
    limit?: number;
    removePolymer?(polymer: Polymer): void;
}

export default PolymersList;
