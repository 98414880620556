import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Grid, Row, Field, Line, InputMask } from '../../UI';
import { StyledColumn, NextButton, ReturnToLogin } from './index.styled';
import i18next from 'i18next';

class EmailRecoveryForm extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                cellphone: this.initialDDI
            }
        };
    }

    private get initialDDI() {
        switch (i18next.language) {
            case 'pt_br':
                return '+55';
            case 'en_us':
                return '+1';
            case 'es_es':
                return '+34';
            default:
                return '+55';
        }
    }

    private get validationSchema() {
        return yup.object().shape({
            cellphone: yup.string().required()
        });
    }

    render() {
        const { initialValues } = this.state;
        const { loading, onSubmit, returnToLogin } = this.props;
        return (
            <Formik
                validationSchema={this.validationSchema}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                {({ isValid }) => {
                    return (
                        <Form>
                            <Grid margin={0} padding={0}>
                                <Row>
                                    <StyledColumn xs={12} sm={12} md={5} lg={5}>
                                        <Field
                                            id="emailRecovery_cellphone"
                                            i18nKey="forms.emailRecovery.registeredCellphone"
                                            name="cellphone"
                                            mask="+9999999999999999"
                                            component={InputMask}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <Line />
                                </Row>
                                <Row>
                                    <StyledColumn xs={12} sm={8} md={8} lg={8}>
                                        <ReturnToLogin
                                            onClick={returnToLogin}
                                            i18nKey="recoveryEmail.after.returnToLogin"
                                        />
                                    </StyledColumn>
                                    <StyledColumn
                                        xs={12}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        flex={{
                                            align: 'center',
                                            justify: 'end'
                                        }}
                                    >
                                        <NextButton
                                            i18nKey="forms.emailRecovery.showEmail"
                                            cl="primary"
                                            disabled={!isValid}
                                            type="submit"
                                            loading={loading}
                                        />
                                    </StyledColumn>
                                </Row>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

interface Props {
    loading: boolean;
    returnToLogin(): void;
    onSubmit(data): void;
}

export default EmailRecoveryForm;
