import React from 'react';
import { Modal } from './index.styled';
import { ApplicationState } from '../../../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTermConditions } from '../../../../redux/actions/content.actions';
import connectWithStore from '../../../hoc/connectWithStore';
import ReactHtmlParser from 'react-html-parser';
import { Loading } from '../../../UI';
import { Term } from '../../../../models/term.model';
import { WithTranslation, withTranslation } from 'react-i18next';

class AcceptOrDecline extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            scrolled: false
        };
    }

    private handleScroll = e => {
        if (
            e.target.offsetHeight + e.target.scrollTop + 1000 >= e.target.scrollHeight
        ) {
            this.setState({ scrolled: true });
        }
    };

    render() {
        const { accept, decline, termsConditions, loading, t } = this.props;
        const { scrolled } = this.state;
        return (
            <Modal>
                <Modal.Title
                    i18nKey={
                        !termsConditions
                            ? 'forms.signUp.modals.termsConditionsAndPrivacyPolicy.title'
                            : termsConditions.title
                    }
                />
                <Modal.Text onScroll={this.handleScroll}>
                    {loading || !termsConditions ? (
                        <Loading />
                    ) : (
                        ReactHtmlParser(termsConditions.body)
                    )}
                </Modal.Text>
                <Modal.Buttons>
                    <Modal.Buttons.Button
                        cl="invertedPrimary"
                        i18nKey="acceptOrDecline.decline"
                        onClick={decline}
                    />
                    <Modal.Buttons.Button
                        cl="primary"
                        i18nKey="acceptOrDecline.accept"
                        tooltip={
                            !scrolled ? t('acceptOrDecline.agreeTooltip') : null
                        }
                        disabledWithTooltip={!scrolled}
                        onClick={accept}
                    />
                </Modal.Buttons>
            </Modal>
        );
    }

    componentWillMount() {
        const { getTermConditions } = this.props;
        getTermConditions();
    }
}

interface Props extends WithTranslation {
    termsConditions: Partial<Term>;
    loading: boolean;
    accept(): void;
    decline(): void;
    getTermConditions(): void;
}

interface State {
    scrolled: boolean;
}

const mapStateToProps = (state: ApplicationState) => ({
    termsConditions: state.content.termConditions,
    loading: state.content.loading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTermConditions
        },
        dispatch
    );

export default connectWithStore(
    withTranslation('app')(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(AcceptOrDecline)
    )
);
