import React from 'react';
import TermContainer from './container';
import { withTranslation, WithTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../../../../../../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Term as TermModel } from '../../../../../../../models/term.model';
import { saveTerms } from '../../../../../../../redux/actions/terms.actions';

const Term = ({ terms, match, ...rest }: Props) => {
    const language = match.params['language'];
    const current = terms.find(term => term.language === language)!;
    return <TermContainer {...rest} term={current} />;
};

interface Props extends RouteComponentProps, WithTranslation {
    terms: TermModel[];
    saveTerms(term: TermModel, callback?: Function): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    terms: state.terms.items
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            saveTerms
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('app')(withRouter(Term)));
