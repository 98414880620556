import { Reducer } from 'redux';
import moment from 'moment';
import { SimulationState, SimulationTypes } from '../../types/simulation.types';
import { ConditionsConfig } from '../../../models/simulationConfig.model';
import { outTranslator, removeObjectReference } from '../../../helpers/util';
import { ProjectsTypes } from '../../types/projects.types';

const INITIAL_STATE: SimulationState = {
    config: {
        polymers: [],
        solvents: [],
        conditions: new ConditionsConfig(),
        is_volume: null,
        is_value_per_liter: false
    },
    project: null,
    cacheProject: null,
    loading: false,
    error: false,
    message: ''
};

const reducer: Reducer<SimulationState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SimulationTypes.SELECT_SIMULATION:
            return {
                ...state,
                loading: true
            };
        case SimulationTypes.SELECT_SIMULATION_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        case SimulationTypes.SELECT_SIMULATION_ERROR:
            return {
                ...state,
                loading: false
            };
        case SimulationTypes.DELETE_SIMULATION:
            return {
                ...state,
                loading: true
            };
        case SimulationTypes.DELETE_SIMULATION_ERROR:
            return {
                ...state,
                loading: false
            };
        case SimulationTypes.DELETE_SIMULATION_SUCCESS:
            return {
                ...state,
                loading: false,
                ...action.payload
            };
        case SimulationTypes.SIMULATE_REQUEST:
            return { ...state, loading: true };
        case SimulationTypes.SIMULATE_SUCCESS:
            const simulation = {
                result: action.payload.result,
                parameters: {
                    solvents: state.config.solvents,
                    polymers: state.config.polymers,
                    conditions: state.config.conditions,
                    is_volume: state.config.is_volume,
                    is_value_per_liter: state.config.is_value_per_liter
                },
                selected: 1,
                is_volume: state.config.is_volume,
                is_value_per_liter: state.config.is_value_per_liter,
                date: moment().format('YYYY-MM-DDTHH:mm:ss'),
                name: `${outTranslator(
                    'redux.reducers.simulation.simulation'
                )} ${state.project!.total_simulations + 1}`
            };
            const simulations = state.project!.simulations.map(simulation => {
                simulation.selected = 0;
                return simulation;
            });
            return {
                ...state,
                project: {
                    ...state.project,
                    total_simulations: state.project!.total_simulations + 1,
                    simulations: [...simulations, simulation]
                },
                loading: false,
                error: false,
                message: action.payload.message
            };
        case SimulationTypes.SIMULATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                message: ''
            };
        case SimulationTypes.SET_POLYMERS:
            return {
                ...state,
                config: {
                    ...state.config,
                    polymers: action.payload
                }
            };
        case SimulationTypes.SET_FORMULATION:
            return {
                ...state,
                config: {
                    ...state.config,
                    solvents: action.payload.solvents,
                    is_volume: action.payload.is_volume,
                    is_value_per_liter: action.payload.is_value_per_liter
                }
            };
        case SimulationTypes.SET_CONDITIONS:
            return {
                ...state,
                config: {
                    ...state.config,
                    conditions: action.payload
                }
            };
        case SimulationTypes.REFRESH_RESULTS:
            return {
                ...state,
                project: {
                    ...state.project,
                    simulations: action.payload
                }
            };
        case SimulationTypes.NEW_SIMULATION:
            return {
                ...state,
                loading: true
            };
        case SimulationTypes.NEW_SIMULATION_SUCCESS:
            return {
                ...state,
                loading: false,
                config: {
                    polymers: [],
                    solvents: [],
                    conditions: new ConditionsConfig(),
                    is_volume: null,
                    is_value_per_liter: false
                },
                ...action.payload
            };

        case SimulationTypes.ADD_SOLVENT_TO_FORMULATION:
            return {
                ...state,
                config: {
                    ...state.config,
                    solvents: [...state.config.solvents, action.payload]
                }
            };
        case SimulationTypes.REMOVE_SOLVENT_TO_FORMULATION:
            return {
                ...state,
                config: {
                    ...state.config,
                    solvents: action.payload
                }
            };

        case SimulationTypes.SET_SOLVENT_CONCENTRATION:
            return {
                ...state,
                config: {
                    ...state.config,
                    solvents: action.payload
                }
            };

        case SimulationTypes.SET_SOLVENT_COST:
            return {
                ...state,
                config: {
                    ...state.config,
                    solvents: action.payload
                }
            };

        case SimulationTypes.SWITCH_COST_UNIT:
            return {
                ...state,
                config: {
                    ...state.config,
                    is_value_per_liter: action.payload
                }
            };
        case SimulationTypes.SWITCH_UNIT_TYPE:
            return {
                ...state,
                config: {
                    ...state.config,
                    is_volume: action.payload
                }
            };
        case SimulationTypes.COMPARE_SIMULATIONS:
            return {
                ...state,
                loading: true
            };
        case SimulationTypes.COMPARE_SIMULATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                config: action.payload.config,
                project: {
                    ...state.project,
                    simulations: action.payload.simulations
                }
            };
        case ProjectsTypes.CLEAR_PROJECT:
            return {
                ...state,
                project: null,
                cacheProject: null
            };
        case ProjectsTypes.SET_PROJECT:
            const projectSimulations = action.payload.simulations
                ? action.payload.simulations
                : [];
            return {
                ...state,
                config: {
                    polymers: [],
                    solvents: [],
                    conditions: new ConditionsConfig(),
                    is_volume: null,
                    is_value_per_liter: false
                },
                loading: false,
                project: {
                    ...action.payload,
                    simulations: projectSimulations
                },
                cacheProject: removeObjectReference({
                    ...action.payload,
                    simulations: projectSimulations
                })
            };
        case ProjectsTypes.RECOVER_PROJECT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ProjectsTypes.RECOVER_PROJECT_FAILURE:
            return {
                ...state,
                loading: false,
                project: null
            };
        case ProjectsTypes.SAVE_PROJECT_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ProjectsTypes.SAVE_PROJECT_SUCCESS:
            return {
                ...state,
                loading: false,
                cacheProject: removeObjectReference(state.project)
            };
        case ProjectsTypes.SAVE_PROJECT_FAILURE:
            return {
                ...state,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;
