import React from 'react';
import SolubilityGraph from './SolubilityGraph';
import Composition from './Composition';
import EvaporationGraph from './EvaporationGraph';
import SimulationCards from './SimulationCards';
import SimulationResultWithConfigs from '../../../../models/simulationResultWithConfigs.model';
import { Empty } from '../../../../components/UI';
import ResultTable from './ResultTable';
import ResultReport from './Report';

const Result = ({ simulations, projectName }: Props) => {
    const currentSimulation = simulations.find(
        simulation => simulation.selected === 1
    )!;

    if (!currentSimulation) {
        return <Empty />;
    }

    const normalizeSolventsEvaporationArray = (
        simulation: SimulationResultWithConfigs
    ) => {
        const compositionArr: any[] = [];
        simulation.result.graphs.solvents_evaporation.forEach(solvent => {
            solvent.percents.forEach(percent => {
                if (!compositionArr[percent.percent_evaporation]) {
                    compositionArr[percent.percent_evaporation] = [];
                }
                compositionArr[percent.percent_evaporation] = [
                    ...compositionArr[percent.percent_evaporation],
                    {
                        id: solvent.id,
                        name: solvent.name,
                        value: percent.percent_mass
                    }
                ];
            });
        });
        return compositionArr;
    };

    const compositions = simulations.map(simulation => {
        return {
            solventsEvaporation: normalizeSolventsEvaporationArray(simulation),
            name: simulation.name,
            selected: simulation.selected,
            is_volume: simulation.is_volume
        };
    });

    return (
        <section id="Simulation_result">
            <SimulationCards simulations={simulations} />
            <EvaporationGraph simulations={simulations} />
            <Composition compositions={compositions} />
            <SolubilityGraph simulations={simulations} />
            <ResultTable simulations={simulations} />
            <ResultReport projectName={projectName} />
        </section>
    );
};

export interface Props {
    id: string;
    simulations: SimulationResultWithConfigs[];
    projectName: string;
}

export default Result;
