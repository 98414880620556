import React from 'react';
import { StyledLogin, Opposite } from './index.styled';
import DoubleSliderSign from 'double_slider_sign';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { Line, Column } from '../../../components/UI';
import grafismo from '../../../assets/images/grafismo-login.svg';

const SignContainer = () => {
    const url = new URL(window.location.href);
    const signUp = url.searchParams.get('signUp');
    return (
        <StyledLogin>
            <DoubleSliderSign initialRightActive={signUp != null}>
                {(isSignIn, isSignUp, handleSignIn, handleSignUp) => (
                    <React.Fragment>
                        <DoubleSliderSign.SignIn>
                            {isSignIn && <SignIn />}
                        </DoubleSliderSign.SignIn>
                        <DoubleSliderSign.SignUp>
                            {isSignUp && <SignUp />}
                        </DoubleSliderSign.SignUp>
                        <DoubleSliderSign.Overlay>
                            <DoubleSliderSign.Overlay.Left>
                                <Opposite>
                                    <Opposite.Row>
                                        <Opposite.Title i18nKey="sign.opposite.signIn.title" />
                                    </Opposite.Row>
                                    <Opposite.Row>
                                        <Opposite.Span i18nKey="sign.opposite.signIn.span" />
                                    </Opposite.Row>
                                    <Line />
                                    <Opposite.Row>
                                        <Column
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            flex={{
                                                align: 'center',
                                                justify: 'end'
                                            }}
                                        >
                                            <Opposite.Button
                                                type="button"
                                                tabIndex="-1"
                                                i18nKey="sign.opposite.signIn.button"
                                                cl="invertedPrimary"
                                                onClick={handleSignIn}
                                            />
                                        </Column>
                                    </Opposite.Row>
                                    <Opposite.LeftImage src={grafismo} />
                                </Opposite>
                            </DoubleSliderSign.Overlay.Left>
                            <DoubleSliderSign.Overlay.Right>
                                <Opposite margin={0} padding={0}>
                                    <Opposite.Row>
                                        <Opposite.Title i18nKey="sign.opposite.signUp.title" />
                                    </Opposite.Row>
                                    <Opposite.Row>
                                        <Opposite.Span i18nKey="sign.opposite.signUp.span" />
                                    </Opposite.Row>
                                    <Line />
                                    <Opposite.Row>
                                        <Column
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                            flex={{
                                                align: 'center',
                                                justify: 'end'
                                            }}
                                        >
                                            <Opposite.Button
                                                tabIndex="-1"
                                                type="button"
                                                i18nKey="sign.opposite.signUp.button"
                                                cl="invertedPrimary"
                                                onClick={handleSignUp}
                                            />
                                        </Column>
                                    </Opposite.Row>
                                    <Opposite.RightImage src={grafismo} />
                                </Opposite>
                            </DoubleSliderSign.Overlay.Right>
                        </DoubleSliderSign.Overlay>
                    </React.Fragment>
                )}
            </DoubleSliderSign>
        </StyledLogin>
    );
};

export default SignContainer;
