import React, { Component } from 'react';
import { Paint } from '../../../../../../../models/paint.model';
import ConditionsOfApplicationForm, {
    IConditionsOfApplicationFormSubmit
} from '../../../../../../../components/forms/ConditionsOfApplication';
import {
    StepButtons,
    IWizardContext
} from '../../../../../../../components/UI';

class Expanded extends Component<Props, {}> {
    render() {
        const {
            loading,
            paints,
            stepNumber,
            currentStep,
            wizardSize,
            stepProgress,
            onSubmit,
            previousStep,
            onCancel,
            data
        } = this.props;
        return (
            <ConditionsOfApplicationForm
                paints={paints}
                onSubmit={onSubmit}
                loading={loading}
                data={data}
            >
                {(isValid, submit) => (
                    <StepButtons
                        fromStep={stepNumber}
                        disableNext={!isValid}
                        onNext={submit}
                        onSave={submit}
                        onCancel={() => previousStep!(onCancel)}
                        currentStep={currentStep}
                        stepProgress={stepProgress}
                        wizardSize={wizardSize}
                    />
                )}
            </ConditionsOfApplicationForm>
        );
    }
}

export interface Props extends Partial<IWizardContext> {
    loading: boolean;
    paints: Paint[];
    stepNumber: number;
    data: Partial<IConditionsOfApplicationFormSubmit>;
    onCancel();
    onSubmit(data);
}

export default Expanded;
