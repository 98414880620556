import styled, { css } from 'styled-components';
import { H2, Select, Label, Button, Icon, Span } from '../../../components/UI';
import colors from '../../../theme/colors';

export const Container = styled.section`
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    overflow: hidden;
`;

export const LoadingBox = styled.div`
    width: 100%;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    flex: 1;
    padding: 47px 120px;
    width: 100%;

    @media (max-width: 992px) {
        padding: 47px 20px;
    }
`;

export const NoProjectsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 50vh;
`;

export const NoProjects: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 670px;

    @media (max-width: 767px) {
        flex-direction: column;
    }
`;

NoProjects.Flask = styled(Icon)`
    color: #cccccc;
`;

NoProjects.Info = styled.div`
    display: block;
    text-align: left;

    @media (max-width: 767px) {
        padding: 15px 0;
        text-align: center;
    }
`;

NoProjects.Span = styled(Span)`
    font-size: 18px;
    width: 100%;
    display: block;
    margin-bottom: 24px;
    font-weight: 300;

    b {
        font-weight: 600;
    }
`;

NoProjects.Button = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: bold;
    }
`;

const headerFixed = css`
    z-index: 9;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 25px 120px;
    border-bottom: 1px solid #ddd;
    box-shadow: 0 4px 8px 0 rgba(166, 166, 166, 0.5);
    background-color: #fff;

    @media (max-width: 992px) {
        padding: 25px 20px;
    }
`;

export const Header: any = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 10px;
    background-color: transparent;
    top: -50px;
    transition: top 1s;
    ${props => props['data-fixed'] && headerFixed}
`;

Header.VerticalLine = styled.div`
    display: none;
    height: 70px;
    width: 2px;
    background-color: #ccc;
    margin: 0 15px;

    @media (max-width: 600px) {
        display: inline-block;
    }
`;

Header.Title = styled(H2)`
    font-weight: 600;
    display: inline-block;
    margin-right: auto;
`;

Header.NewProjectBtn = styled(Button)`
    display: inline-block;

    &.ui.button {
        color: ${colors.buttons.invertedPrimary.color};
        font-size: 18px;
        padding: 0;
    }
`;

export const Filter: any = styled.div`
    width: 100%;
    padding: 20px 0 10px 0;
    display: flex;
    align-items: center;
`;

Filter.Label = styled(Label)`
    font-weight: 600;
    display: inline-block;
    margin-right: 8px;
`;

Filter.Select = styled(Select)`
    width: 200px;
    display: inline-block;
`;

export const Projects = styled.div`
    padding: 0 100px;

    @media (max-width: 992px) {
        padding: 0;
    }
`;

export const PaginationContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 20px 100px;

    @media (max-width: 992px) {
        padding: 20px 0;
    }
`;
