import React from 'react';
import { Container, CardLabel } from './index.styled';
import SimulationResultWithConfigs from '../../../../../models/simulationResultWithConfigs.model';
import SimulationResultCard from '../../SimulationResultCard';
import { Column, Row, Empty } from '../../../../../components/UI';

const SimulationCards = ({ simulations }: Props) => {
    const renderCards = () =>
        simulations.map((simulation, index) => {
            const isComparation = simulations.length > 1;
            return (
                <Column
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    key={simulation.date.toString()}
                >
                    {isComparation ? (
                        index === 0 ? (
                            <CardLabel i18nKey="simulation.result.simulationCards.reference" />
                        ) : (
                            <CardLabel i18nKey="simulation.result.simulationCards.proposal" />
                        )
                    ) : (
                        <Empty />
                    )}
                    <SimulationResultCard
                        simulation={simulation}
                        isComparation={isComparation}
                    />
                </Column>
            );
        });
    return (
        <Container>
            <Row>{renderCards()}</Row>
        </Container>
    );
};

interface Props {
    simulations: SimulationResultWithConfigs[];
}

export default SimulationCards;
