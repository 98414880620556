import styled from 'styled-components';
import { Column, Button } from '../../UI';
import { Form } from 'formik';

export const StyledForm = styled(Form)`
    padding: 15px 0;
`;

export const StyledColumn = styled(Column)`
    padding: 10px 0;
`;

export const StyledButton = styled(Button)`
    &.ui.button {
        padding: 4px 40px;
        font-size: 16px;
        font-weight: bold;
        margin-right: 0;
    }
`;
