import React from 'react';
import { Step, IStep } from '../../UI';
import { scrollToTargetAdjusted } from '../../../helpers/util';

export const Wizard = ({
    context: Context,
    value,
    steps,
    componentProps
}: IWizardProps) => (
    <Context.Provider value={value}>
        <Context.Consumer>
            {context =>
                steps.map(
                    (
                        { i18nTitle, component: Component }: IStep,
                        index: number
                    ) => (
                        <Step
                            key={index}
                            number={index + 1}
                            i18nTitle={i18nTitle}
                            wizardSize={steps.length}
                            {...context}
                        >
                            <Component wizardProps={componentProps} />
                        </Step>
                    )
                )
            }
        </Context.Consumer>
    </Context.Provider>
);

export interface IWizardContext {
    currentStep: number;
    stepProgress: number;
    wizardSize: number;
    goToStep(number: number): void;
    nextStep(callback?: Function): void;
    previousStep(callback?: Function): void;
    resetSteps(): void;
}

export const createWizardContext = () =>
    React.createContext({
        currentStep: 0,
        stepProgress: 0,
        wizardSize: 0,
        nextStep: () => {},
        goToStep: () => {},
        previousStep: () => {},
        resetSteps: () => {}
    });

const scrollToStep = (step: number) => {
    scrollToTargetAdjusted(`Step${step}`);
};

export const initWizardContext = (initial: number, steps: IStep[]) => {
    const [currentStep, setCurrentStep] = React.useState(initial);
    const [stepProgress, setStepProgress] = React.useState(initial);
    const goToStep = (number: number, callback: Function) => {
        setCurrentStep(number);
        if (callback) {
            callback();
        }
    };
    const nextStep = (callback: Function) => {
        setStepProgress(stepProgress + 1);
        goToStep(stepProgress + 1, callback);
        scrollToStep(stepProgress + 1);
    };
    const previousStep = (callback: Function) => {
        goToStep(stepProgress, callback);
        scrollToStep(stepProgress);
    };
    const resetSteps = () => {
        setCurrentStep(initial);
        setStepProgress(initial);
    };
    return {
        currentStep,
        stepProgress,
        nextStep,
        goToStep,
        previousStep,
        resetSteps,
        wizardSize: steps.length
    };
};

interface IWizardProps {
    context: React.Context<any>;
    steps: IStep[];
    value;
    componentProps?;
}
