import { Button as SemanticButton } from 'semantic-ui-react';
import styled from 'styled-components';
import colors from '../../../theme/colors';

export const StyledButton = styled(SemanticButton)`
    &.ui.button {
        position: relative;
        padding: 4px 24px;
        border-radius: 4px;
        box-shadow: none;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        text-align: center;
        line-height: 32px;
        white-space: nowrap;
    }

    ${props => {
        const { cl = 'default' } = props;
        const disabledWithTooltip = props['data-disabled-with-tooltip']
            ? props['data-disabled-with-tooltip']
            : false;
        return `
        &.ui.button {
            background: ${
                !disabledWithTooltip ? colors.buttons[cl].bg : 'transparent'
            };
            color: ${
                !disabledWithTooltip
                    ? colors.buttons[cl].color
                    : colors.buttons.disabled.color
            };
            box-shadow:inset 0px 0px 0px 1px ${
                !disabledWithTooltip
                    ? colors.buttons[cl].border
                    : colors.buttons.disabled.border
            };

            &.ui.button:disabled {
                background-color: ${colors.buttons.disabled.bg};
                color: ${colors.buttons.disabled.color};
                box-shadow:inset 0px 0px 0px 1px ${
                    colors.buttons.disabled.border
                };
            }

            &:active,
            &:focus,
            &:hover {
                background-color: ${
                    !disabledWithTooltip
                        ? colors.buttons[cl].hover.bg
                        : 'transparent'
                };
                box-shadow:inset 0px 0px 0px 2px ${
                    !disabledWithTooltip
                        ? colors.buttons[cl].hover.border
                        : colors.buttons.disabled.border
                };
                color: ${
                    !disabledWithTooltip
                        ? colors.buttons[cl].hover.color
                        : colors.buttons.disabled.color
                };
            }
        }`;
    }}

    ${props => {
        const { cl = 'default' } = props;
        if (cl === 'span') {
            return `
                &.ui.button{
                    font-size: 16px;
                    font-weight: 600;
                    padding: 16px;
                    line-height: 16px;
                }
            `;
        }
    }}
`;
