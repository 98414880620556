import React from 'react';
import { Container, Image, Title, Description, ImageBox } from './index.styled';
import { Grid, Row, Column } from '../../../components/UI';

const Card = ({ title, description, img, inverted }: Props) => {
    const renderTexts = () => (
        <Column xs={12} sm={12} md={6} lg={6}>
            <Title renderText={title} />
            <Description renderText={description} />
        </Column>
    );
    const renderImg = () => (
        <Column xs={12} sm={12} md={6} lg={6}>
            <ImageBox>
                <Image src={img} />
            </ImageBox>
        </Column>
    );
    const renderNotInverted = () => (
        <React.Fragment>
            {renderTexts()}
            {renderImg()}
        </React.Fragment>
    );
    const renderInverted = () => (
        <React.Fragment>
            {renderImg()}
            {renderTexts()}
        </React.Fragment>
    );
    return (
        <Container>
            <Grid>
                <Row flex="center">
                    {inverted ? renderInverted() : renderNotInverted()}
                </Row>
            </Grid>
        </Container>
    );
};

interface Props {
    title: string;
    description: string;
    inverted?: boolean;
    img;
}

export default Card;
