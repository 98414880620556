import styled from 'styled-components';
import {
    H2,
    H3,
    Table,
    IconButton,
    Span,
    Loading,
    Checkbox,
    Icon
} from '../../../../../../components/UI';
import colors from '../../../../../../theme/colors';

export const Container = styled.section`
    padding: 47px;
`;

export const Title = styled(H2)`
    line-height: 31px;
    padding-bottom: 7px;
`;

export const NewGroup = styled(Span)`
    font-size: 18px;
    font-weight: 600;
    line-height: 31px;
    cursor: pointer;
    color: ${colors.buttons.invertedPrimary.color};

    &:hover {
        color: ${colors.buttons.invertedPrimary.hover.color};
    }
`;

export const Subtitle = styled(H3)``;

export const SearchContainer = styled.div`
    padding: 22px 0 32px 0;
`;

export const Data: any = styled.section`
    position: relative;
`;

Data.LoadingOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.35);
`;

Data.Loading = styled(Loading)`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
`;

export const DataList: any = styled(Table)`
    min-height: 100px;
`;

DataList.Header = styled(Table.Header)`
    background-color: rgba(166, 166, 166, 0.08);
`;

DataList.HeaderRow = styled(Table.Row)``;

DataList.HeaderCell = styled(Table.HeaderCell)`
    color: #444444 !important;
    font-weight: 600 !important;
    padding: 14px !important;
`;

DataList.Body = styled(Table.Body)``;

DataList.Row = styled(Table.Row)`
    cursor: pointer;

    &:hover {
        border-radius: 4px !important;
        box-shadow: 0 2px 4px 0 rgba(68, 68, 68, 0.25),
            0 0 12px 0 rgba(166, 166, 166, 0.5);
    }
`;

DataList.Cell = styled(Table.Cell)`
    padding: 14px !important;
    font-size: 14px !important;
`;

DataList.Checkbox = styled(Checkbox)`
    .ui.checkbox {
        min-height: auto;
    }
`;

DataList.EditBtn = styled(IconButton)`
    float: right;

    &.ui.button {
        height: auto;
        width: auto;
    }
`;

DataList.DeleteBtn = styled(IconButton)`
    &.ui.button {
        height: auto;
        width: auto;
    }
`;

DataList.UsersIcon = styled(Icon)`
    font-size: 22px;
    font-weight: bold;
`;

export const DataListResources: any = styled.div`
    width: 100%;
    padding: 10px 0;
    display: flex;
    align-items: center;
`;

DataListResources.Checkbox = styled(Checkbox)`
    margin-right: 10px;

    &.ui.checkbox {
        margin-top: 0;
    }
`;
