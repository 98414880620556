import { call, put, select } from 'redux-saga/effects';
import api from '../../../services/api';
import {
    simulationError,
    simulationSuccess,
    deleteCurrentSimulationSuccess,
    deleteCurrentSimulationError,
    selectSimulationError,
    selectSimulationSuccess,
    newSimulationSuccess,
    compareSimulationsSuccess
} from '../../actions/simulation.actions';
import { dialog } from '../../../components/UI';
import { ConditionsConfig } from '../../../models/simulationConfig.model';
import { switchUnitType } from '../../actions/simulation.actions';
import { scrollToTargetAdjusted } from '../../../helpers/util';
import SimulationResultWithConfigs from '../../../models/simulationResultWithConfigs.model';

const translationPath = 'redux.sagas.simulation.';

const scrollToResult = () => {
    setTimeout(() => {
        scrollToTargetAdjusted('StepButtons_nextBtn');
    }, 500);
};

const setConfigs = (simulation: SimulationResultWithConfigs) => {
    if (!simulation) {
        return {
            polymers: [],
            solvents: [],
            conditions: new ConditionsConfig(),
            is_volume: false,
            is_value_per_liter: false
        };
    }
    return {
        polymers: simulation.parameters.polymers,
        solvents: simulation.parameters.solvents,
        conditions: simulation.parameters.conditions,
        is_volume: simulation.parameters.is_volume,
        is_value_per_liter: simulation.parameters.is_value_per_liter
    };
};

function* needsTime(action?) {
    return yield new Promise(resolve => {
        setTimeout(() => {
            resolve(action ? action() : () => {});
        }, 1500);
    });
}

export function* simulate(action) {
    const loading = dialog({
        type: 'loading',
        i18nKey: translationPath + 'simulate.loading',
        instantShow: false,
        flaskLoading: true
    });
    loading.show();
    try {
        const { data } = yield call(
            api.post,
            '/simulation/solubility/evaporation',
            action.payload.request
        );
        if (data.object) {
            yield put(
                simulationSuccess({
                    result: { ...data.object, ...action.payload.rest },
                    message: ''
                })
            );
        } else {
            yield put(simulationError());
        }
    } catch (err) {
        yield put(simulationError());
    }
    loading.close();
    scrollToResult();
}

export function* deleteSimulation(action) {
    const simulation = action.payload;
    const loading = dialog({
        type: 'loading',
        i18nKey: {
            name: 'dialogs.delete.loading',
            vars: { name: simulation.name }
        },
        instantShow: false
    });
    loading.show();
    try {
        const project = yield select(state => state.simulation.project);
        const simulations = project.simulations;
        let reducer = {};
        let last;

        const simulationIndex = project.simulations.findIndex(
            result => result.date === simulation.date
        );

        if (simulationIndex !== -1) {
            yield needsTime(() => {
                const deleted = simulations.splice(simulationIndex, 1)[0];
                last = simulations[simulations.length - 1];
                reducer = {
                    project: {
                        ...project,
                        simulations: simulations ? simulations : []
                    }
                };
                setSelected(last, deleted);
                reducer = {
                    ...reducer,
                    config: setConfigs(last)
                };
            });
            if (last) {
                yield put(switchUnitType(last.is_volume ? 'VOLUME' : 'MASS'));
            }
            yield put(
                deleteCurrentSimulationSuccess({
                    name: simulation.name,
                    reducer
                })
            );
        } else {
            yield put(deleteCurrentSimulationError(simulation.name));
        }
    } catch (err) {
        yield put(deleteCurrentSimulationError(simulation.name));
    }
    loading.close();
}

function setSelected(last: any, deleted: any) {
    if (last && deleted.selected !== 0) {
            last.selected = 1;
    }
}

export function* selectSimulation(action) {
    const simulation = action.payload;
    const loading = dialog({
        type: 'loading',
        i18nKey: {
            name: translationPath + 'selectSimulation.loading',
            vars: { name: simulation.name }
        },
        instantShow: false
    });
    loading.show();
    try {
        const project = yield select(state => state.simulation.project);
        let reducer = {};
        yield needsTime(() => {
            reducer = {
                ...reducer,
                project: {
                    ...project,
                    simulations: project.simulations.map(result => {
                        if (result.date === simulation.date) {
                            result.selected = 1;
                        } else {
                            result.selected = 0;
                        }
                        return result;
                    })
                },
                config: setConfigs(simulation)
            };
        });
        yield put(switchUnitType(simulation.is_volume ? 'VOLUME' : 'MASS'));
        yield put(selectSimulationSuccess({ reducer }));
    } catch (err) {
        yield put(selectSimulationError(simulation.name));
    }
    loading.close();
    scrollToResult();
}

export function* newSimulation() {
    const loading = dialog({
        type: 'loading',
        i18nKey: translationPath + 'newSimulation.loading',
        instantShow: false
    });
    loading.show();
    yield needsTime();
    yield put(switchUnitType(null));
    yield put(newSimulationSuccess());
    loading.close();
}

export function* compareSimulations(action) {
    const loading = dialog({
        type: 'loading',
        i18nKey: translationPath + 'compareSimulations.loading',
        instantShow: false
    });
    loading.show();
    yield needsTime();
    const project = yield select(state => state.simulation.project);
    const simulations = project.simulations.map(simulation => {
        const finded = action.payload.find(
            checked => checked.date === simulation.date
        );
        return {
            ...simulation,
            selected: finded ? (finded.reference ? 1 : 2) : 0
        };
    });
    yield put(
        compareSimulationsSuccess({
            simulations,
            config: setConfigs(
                simulations.find(simulation => simulation.selected === 1)!
            )
        })
    );
    loading.close();
    scrollToResult();
}
