import React from 'react';
import { StyledTextArea } from './index.styled';

export const TextArea = ({
    setFieldValue,
    setFieldTouched,
    ...props
}: TextAreaProps) => <StyledTextArea {...props} />;

export interface TextAreaProps {
    name: string;
    readOnly?: boolean | undefined;
    disabled?: boolean | undefined;
    id?: string;
    value?: any;
    onChange?;
    onBlur?;
    onClick?;
    setFieldValue?(name: string, value): void;
    setFieldTouched?(name: string, value: boolean): void;
}
