import i18next from 'i18next';
import React from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { Body, Footer, Header } from '../components/layout';
import history from '../configs/history';
import store from '../redux/store';
import routes from '../routes';
import translations from '../translations';
import { GoTop } from '../components/UI';
import withFirebase from '../components/hoc/withFirebase';
import '../configs/toastr';
import '../configs/yup';
import '../helpers/prototypes';
import 'rc-ruler-slider/dist/index.css';
import 'react-quill/dist/quill.snow.css';
import 'semantic-ui-css/semantic.min.css';
import './index.scss';
import '@iconscout/unicons/css/unicons.css';

class App extends React.PureComponent {
    private getLanguage = () => {
        const url = new URL(window.location.href);
        const lng = url.searchParams.get('lng');
        if (lng) {
            return lng;
        }
        switch (navigator.language.split('-')[0]) {
            case 'pt':
                return 'pt_br';
            case 'en':
                return 'en_us';
            case 'es':
                return 'es_es';
            default:
                return 'en_us';
        }
    };

    private createRoutes = () =>
        routes.map(({ path, exact, component }, index) => (
            <Route
                key={index}
                exact={exact}
                path={path}
                component={component}
            />
        ));

    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <I18nextProvider i18n={i18next}>
                        <Header />
                        <Body>
                            <Switch>{this.createRoutes()}</Switch>
                        </Body>
                        <Footer />
                        <GoTop />
                    </I18nextProvider>
                </Router>
            </Provider>
        );
    }

    componentWillMount() {
        i18next.init({
            lng: this.getLanguage(),
            resources: translations
        });
    }
}

export default withFirebase(App);
