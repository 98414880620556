import styled from 'styled-components';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import { Label } from '../Label';

export const Container = styled.div`
    display: flex;
    align-items: flex-start;
    position: relative;
`;

export const StyledLabel = styled(Label)`
    line-height: 23px;
    font-size: 16px;
    padding-left: 8px;
    display: block;
    height: auto;
    margin-top: -3px;
    cursor: pointer !important;
`;

export const InputCheckbox = styled(SemanticCheckbox)`
    &.ui.checkbox {
        padding: 0;
        margin: 0;
        height: 14px;

        &::selection {
            background-color: #763689;
        }

        input:checked ~ label:before {
            background-color: #763689 !important;
        }

        input:checked ~ label:after {
            content: '\\e8ca' !important;
        }

        input[disabled] ~ label {
            opacity: 1;
        }

        input[disabled] ~ label::after {
            color: #717171 !important;
        }

        input[disabled] ~ label::before {
            background-color: #e5e5e5 !important;
            border-color: #e5e5e5 !important;
        }

        input[disabled] ~ &::selection {
            background-color: #e5e5e5;
        }

        input[disabled] ~ label::selection {
            background-color: #e5e5e5;
        }

        input:focus ~ label:before {
            border-color: #763689 !important;
        }
    }

    label {
        &::selection {
            background-color: #763689 !important;
        }

        &::before {
            background-color: ${props =>
                props['indeterminate'] ? '#763689' : '#fff'} !important;
        }

        &::after {
            font-family: 'unicons' !important;
            font-size: 17px !important;
            color: #fff !important;
            content: ${props => {
                if (props['indeterminate']) {
                    return "'\\eaba' !important";
                }
            }};
        }
    }
`;
