import React from 'react';
import { StepContainer } from './container';

export const Step = (props: IStepProps) => <StepContainer {...props} />;

export interface IStepProps {
    number: number;
    wizardSize: number;
    i18nTitle: string;
    children?;
}

export interface IStep {
    i18nTitle: string;
    component;
}
