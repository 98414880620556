import { Reducer } from 'redux';
import { TermsState, TermsTypes } from '../../types/terms.types';

const INITIAL_STATE: TermsState = {
    items: [],
    loading: false
};

const reducer: Reducer<TermsState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TermsTypes.GET_TERMS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case TermsTypes.GET_TERMS_SUCCESS:
            return {
                ...state,
                items: action.payload,
                loading: false
            };
        case TermsTypes.GET_TERMS_FAILURE:
            return {
                ...state,
                items: [],
                error: true,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;
