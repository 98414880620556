import React from 'react';
import { scrollToTargetAdjusted } from '../../../helpers/util';
import GoTopContainer from './container';

export class GoTop extends React.Component<{}, State> {
    constructor(props) {
        super(props);
        this.state = {
            scrolledY: 0
        };
    }

    private handleScroll = e => {
        const path = e.path || (e.composedPath && e.composedPath());
        if (!path) {
            return;
        }
        this.setState({
            scrolledY: path[1].scrollY
        });
    };

    private scrollToTop = () => {
        scrollToTargetAdjusted('root');
    };

    render() {
        const { scrolledY } = this.state;
        return (
            <GoTopContainer
                scrolledY={scrolledY}
                scrollToTop={this.scrollToTop}
            />
        );
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }
}

interface State {
    scrolledY: number;
}
