import styled from 'styled-components';
import { Button, H1 } from '../../../../../components/UI';
import { height as TopbarHeight } from '../../../../../components/layout/Header/Topbar/container/index.styled';

export const Topbar = styled.div`
    position: absolute;
    left: 0;
    top: ${TopbarHeight}px;
    height: 101px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(166, 166, 166, 0.5);
    display: flex;
    align-items: center;
    padding: 0 120px;
    z-index: 10;
    overflow: hidden;

    ${props => {
        if (props['data-fixed']) {
            return `
                position: fixed;
                top: 0;
            `;
        }
    }}

    @media (max-width: 992px) {
        padding: 0 10px;
    }
`;

export const Title = styled(H1)`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @media (max-width: 600px) {
        font-size: 24px;
    }
`;

export const Buttons = styled.div`
    margin-left: auto;
    white-space: nowrap;
`;

export const ExitButton = styled(Button)`
    &.ui.button {
        font-size: 20px;
        padding: 10px 40px;
        letter-spacing: 0.3px;
        margin-right: 32px;
    }

    @media (max-width: 600px) {
        &.ui.button {
            font-size: 14px;
            padding: 5px 10px;
            margin-bottom: 5px;
            margin-right: 5px;
        }
    }
`;

export const SaveButton = styled(Button)`
    &.ui.button {
        font-size: 20px;
        font-weight: bold;
        padding: 10px 40px;
        letter-spacing: 0.3px;
        margin: 0;
    }

    @media (max-width: 600px) {
        &.ui.button {
            font-size: 16px;
            padding: 5px 10px;
        }
    }
`;
