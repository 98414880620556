import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Grid, Field, Input, Row } from '../../UI';
import { StyledColumn, Buttons, Editor } from './index.styled';
import { Term } from '../../../models/term.model';

class TermsForm extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        const { term } = this.props;
        this.state = {
            initialValues: {
                title: term.title,
                body: term.body
            }
        };
    }

    private get validationSchema() {
        return yup.object().shape({
            title: yup
                .string()
                .max(50)
                .min(3)
                .required(),
            body: yup
                .string()
                .test('editor', 'required', value => {
                    if (value === '<p><br></p>' || value === '<p></p>') {
                        return false;
                    }
                    return true;
                })
                .required()
        });
    }

    render() {
        const { initialValues } = this.state;
        const { onSubmit, onCancel } = this.props;
        return (
            <Formik
                validationSchema={this.validationSchema}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                {({ isValid, handleSubmit }) => {
                    return (
                        <Form>
                            <Grid>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Field
                                            id="terms_title"
                                            name="title"
                                            i18nKey="forms.terms.title"
                                            component={Input}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Field
                                            id="terms_body"
                                            name="body"
                                            component={Editor}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Buttons>
                                    <Buttons.Cancel
                                        id="terms_cancel"
                                        i18nKey="stepButtons.cancel"
                                        cl="invertedPrimary"
                                        type="button"
                                        onClick={() => onCancel(isValid)}
                                    />
                                    <Buttons.Save
                                        id="terms_save"
                                        i18nKey="stepButtons.save"
                                        cl="primary"
                                        type="button"
                                        disabled={!isValid}
                                        onClick={handleSubmit}
                                        loading={false}
                                    />
                                </Buttons>
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

interface Props {
    term: Term;
    onCancel(values): void;
    onSubmit(data): void;
}

export default TermsForm;
