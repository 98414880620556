import React from 'react';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Grid, Row, Field, Input, Line } from '../../UI';
import {
    StyledColumn,
    StyledForm,
    ForgotEmail,
    StyledButton
} from './index.styled';

class SignInForm extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            initialValues: {
                email: ''
            }
        };
    }

    private get validationSchema() {
        return yup.object().shape({
            email: yup
                .string()
                .email()
                .required()
        });
    }

    render() {
        const { initialValues } = this.state;
        const { onSubmit, loading, showRecoveryEmail } = this.props;
        return (
            <Formik
                validationSchema={this.validationSchema}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                {({ isValid }) => {
                    return (
                        <StyledForm>
                            <Grid margin={0} padding={0}>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Field
                                            id="SignIn_email"
                                            i18nKey="forms.signIn.emailLabel"
                                            name="email"
                                            component={Input}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <Line />
                                </Row>
                                <Row>
                                    <StyledColumn xs={12} sm={8} md={8} lg={8}>
                                        <ForgotEmail
                                            onClick={showRecoveryEmail}
                                            i18nKey="forms.signIn.forgotEmail"
                                        />
                                    </StyledColumn>
                                    <StyledColumn
                                        xs={12}
                                        sm={4}
                                        md={4}
                                        lg={4}
                                        flex={{
                                            align: 'center',
                                            justify: 'end'
                                        }}
                                    >
                                        <StyledButton
                                            i18nKey="forms.signIn.next"
                                            cl="primary"
                                            disabled={!isValid}
                                            type="submit"
                                            loading={loading}
                                        />
                                    </StyledColumn>
                                </Row>
                            </Grid>
                        </StyledForm>
                    );
                }}
            </Formik>
        );
    }
}

interface Props {
    loading: boolean;
    showRecoveryEmail(): void;
    onSubmit(data): void;
}

export default SignInForm;
