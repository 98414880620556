import React from 'react';
import { InputCheckbox, Container, StyledLabel } from './index.styled';
import { IInputProps } from '..';
import { Tooltip } from '../Tooltip';

export const Checkbox = ({
    name,
    disabled,
    readOnly,
    i18nKey,
    value,
    onClick,
    setFieldValue,
    setFieldTouched,
    className,
    indeterminate,
    defaultChecked,
    onChange,
    tooltip
}: Props) => {
    const checkboxId = `checkbox-${Math.random()
        .toString(36)
        .substring(2, 15)}`;
    const tooltipId = `tooltip-${Math.random()
        .toString(36)
        .substring(2, 15)}`;
    const isMobile = window.innerWidth <= 767;
    const handleChange = (event, data) => {
        if (setFieldValue && setFieldTouched) {
            setFieldValue(name!, data.checked);
            setFieldTouched(name!, true);
        }
        if (onChange) {
            onChange(event, data);
        }
    };
    const handleClick = e => {
        if (onClick) {
            onClick(e);
        }
        if (setFieldTouched) {
            setFieldTouched(name!, true);
        }
    };
    return (
        <Container className={className}>
            <InputCheckbox
                id={checkboxId}
                data-for={tooltipId}
                data-tip={tooltip}
                data-event={isMobile ? 'focus' : ''}
                data-event-off={isMobile ? 'blur' : ''}
                checked={value}
                indeterminate={indeterminate}
                defaultChecked={defaultChecked}
                disabled={disabled}
                readOnly={readOnly}
                onClick={handleClick}
                onChange={handleChange}
            />
            {i18nKey && (
                <StyledLabel
                    htmlFor={checkboxId}
                    disabled={disabled}
                    i18nKey={i18nKey}
                />
            )}
            {tooltip && <Tooltip id={tooltipId} effect="solid" type="light" />}
        </Container>
    );
};

interface Props extends IInputProps {
    name?: string;
    indeterminate?: boolean;
    validate?: Function;
    changeValue?: Function;
    defaultChecked?: boolean;
    tooltip?: string;
}
