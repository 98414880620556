import React from 'react';
import styled from 'styled-components';
import { Select as SemanticSelect } from 'semantic-ui-react';
import { IconButton } from '..';

export const RevertBtn = styled(IconButton)`
    position: absolute;
    z-index: 1;
    top: 5px;
    right: 35px;
`;

export const SelectContainer = styled.div`
    position: relative;
    z-index: 1;
`;

const CustomSelect = props => {
    const prop = { ...props };
    delete prop.iconLeft;
    return <SemanticSelect {...prop} />;
};

export const StyledSelect = styled(CustomSelect)`
    padding-left: ${props => (props.iconLeft ? '35px !important' : '')};
    height: 44px;
    border: 1px solid #cccccc;
    border-radius: 4px;
    background-color: #ffffff;

    &.ui.dropdown > .default.text {
        color: #cccccc;
        top: 0px !important;
    }

    &.ui.dropdown > input {
        height: 44px;
        top: 0px;
        margin-left: ${props => (props.iconLeft ? '20px !important' : '')};
    }

    &.ui.dropdown > .text {
        font-size: 16px;
        line-height: 20px;
        color: #444444;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: calc(100% - 30px);
    }

    &.ui.dropdown > .dropdown.icon,
    &.ui.fluid.dropdown > .dropdown.icon {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100% !important;
        right: 8px;
        width: 38px;
    }

    &.ui.dropdown > i.icon.search:before {
        font-family: 'unicons';
        content: ${props => (!props.loading ? '"\\eb44"' : '')};
    }

    &.ui.dropdown > .dropdown.icon:before {
        font-family: 'unicons';
        content: ${props => (!props.loading ? '"\\e829"' : '')};
        font-size: 24px;
    }

    &.ui.loading.dropdown>i.icon:after {
        margin: -.84285714em 0 0 -.84285714em;
        width: 20px;
        height: 20px;
        border-width: 3px;
    }

    &.ui.selection.dropdown {
        border-color: #a6a6a6;
    }

    &.ui.selection.dropdown:hover,
    &.ui.selection.dropdown:focus-within {
        border-color: #444444 !important;
    }

    &.ui.selection.active.dropdown,
    &.ui.selection.active.dropdown .menu {
        border-color: #444444 !important;
        border-radius: 4px !important;
        border-width: 1px !important;
    }

    &.ui.dropdown .menu .selected.item,
    .ui.dropdown.selected {
        background: rgba(118, 54, 137, 0.05);
    }

    &.ui.loading.dropdown > i.icon:before {
        top: initial;
        left: initial;
        margin: 0;
        width: auto;
        height: auto;
        border-radius: 0;
        border: 0;
    }

    .menu {
        border-radius: 4px !important;
        margin-top: 5px !important;
    }

    .search.icon {
        color: #cccccc !important;
        left: ${props => (props.iconLeft ? '10px !important' : '')};
        right: ${props => (props.iconLeft ? 'unset !important' : '')};
    }
`;
