import React from 'react';
import moment from 'moment';
import { Checkbox, Empty } from '../../../../../../../../../../components/UI';
import { User } from '../../../../../../../../../../models/user.model';

const Data = ({ user }: Props) => {
    const createdWithZone = moment(`${user.created_at}Z`);
    const checked = {
        date: createdWithZone.format('DD/MM/YYYY'),
        time: `${createdWithZone.format('HH')}h${createdWithZone.format('mm')}m`
    };
    return user.created_at ? (
        <Checkbox
            value={true}
            disabled
            i18nKey={[
                'master.users.user.juridicalStep.checkbox',
                { date: checked.date, time: checked.time }
            ]}
        />
    ) : (
        <Empty />
    );
};

interface Props {
    user: User;
}

export default Data;
