import React from 'react';
import {
    StyledRow,
    Name,
    AxisDescription,
    Description,
    GreenSpan
} from './index.styled';
import { Column, Empty } from '../../../../../../components/UI';
import PositionDPH from '../../../../../../models/positionDPH.model';
import {
    normalizeDPH,
    normalizeCommaNumber
} from '../../../../../../helpers/util';
import { Doughnut, Circle } from '../index.styled';
import pallet from '../../index.pallet.json';
import { Mixing } from '../../../../../../models/simulationResult.model';

const SolventDialog = ({ name, color, position, ter, mixture }: Props) => {
    const dph = normalizeDPH(position);
    const renderRED = (red: number) => {
        if (red <= 1) {
            return <GreenSpan renderText={normalizeCommaNumber(red)} />;
        } else {
            return <GreenSpan renderText={normalizeCommaNumber(red)} />;
        }
    };
    const renderName = () => (
        <StyledRow>
            <Column xs={12} sm={12} md={12} lg={12}>
                <Circle data-color={color} />
                <Name renderText={name} />
            </Column>
        </StyledRow>
    );
    const renderTER = () => (
        <StyledRow>
            <Column xs={12} sm={12} md={12} lg={12}>
                <Description renderText="TER" />
                {normalizeCommaNumber(ter)}
            </Column>
        </StyledRow>
    );
    const renderPosition = () => {
        return (
            <StyledRow>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="dD" />
                    {dph.dD}
                </Column>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="dP" />
                    {dph.dP}
                </Column>
                <Column xs={4} sm={4} md={4} lg={4}>
                    <AxisDescription renderText="dH" />
                    {dph.dH}
                </Column>
            </StyledRow>
        );
    };
    const renderPoylmersRED = () =>
        mixture.polymers.map((polymer, index) => {
            if (!mixture) {
                return <Empty key={polymer.id} />;
            }
            return (
                <StyledRow key={polymer.id}>
                    <Column xs={12} sm={12} md={12} lg={12}>
                        <Doughnut
                            data-color={pallet.polymer.current[index]}
                            data-size={8}
                        />
                        {polymer.name}
                    </Column>
                    <Column right xs={11} sm={11} md={11} lg={11}>
                        <Description renderText="RED" />
                        {renderRED(polymer.normalized_distance_initial)}
                    </Column>
                </StyledRow>
            );
        });
    return (
        <React.Fragment>
            {renderName()}
            {renderTER()}
            {renderPosition()}
            {/* {renderPoylmersRED()} */}
        </React.Fragment>
    );
};

interface Props {
    name: string;
    color: string;
    position: PositionDPH;
    ter: number;
    mixture: Mixing;
}

export default SolventDialog;
