import styled from 'styled-components';

export const Container = styled.section`
    width: 100%;
    background-color: #fff;
    display: flex;
    flex: 1;
    padding: 0 120px;
    min-width: 700px;

    @media (max-width: 992px) {
        padding: 0 15px;
    }
`;

export const Current = styled.section`
    width: 100%;
    height: 100%;
    overflow: auto;
    background: rgb(251, 251, 251);
    background: linear-gradient(to right, rgb(251, 251, 251), #fff);
`;

export const LoadingBox = styled.section`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
`;
