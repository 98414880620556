import React from 'react';
import Firebase from '../../../configs/firebase';
import history from '../../../configs/history';
import { Loading } from '../../UI';
import { Section } from './index.styled';
import { connect } from 'react-redux';
import store, { ApplicationState } from '../../../redux/store';
import { User } from '../../../models/user.model';

const mapStateToProps = (state: ApplicationState) => ({
    user: state.user.currentUser
});

const withAuthentication = Component =>
    connect(mapStateToProps)((props: Props) => {
        const redirect = () => {
            const user = store.getState().user.currentUser;
            if (!Firebase.auth.currentUser || !user) {
                history.push('/');
            }
        };
        if (!Firebase.auth.currentUser || !props.user) {
            setTimeout(() => {
                redirect();
            }, 3000);
            return (
                <Section>
                    <Loading />
                </Section>
            );
        }
        return <Component {...props} />;
    });

interface Props {
    user: User | null;
}

export default withAuthentication;
