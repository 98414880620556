import React from 'react';
import {
    Title,
    SimulationGrid,
    SimulationSection,
    StyledSimulation
} from './index.styled';
import _ from 'lodash';
import { Column, Empty } from '../../../components/UI';
import Topbar from './Topbar';
import Configuration from './Configuration';
import Performed from './Performed';
import SimulationConfig from '../../../models/simulationConfig.model';
import Result from './Result';
import { Project } from '../../../models/project.model';
import { removeObjectReference, outTranslator } from '../../../helpers/util';

class SimulationContainer extends React.Component<Props, any> {
    get selectedSimulations() {
        const { project } = this.props;
        const selectedSimulations = project.simulations
            ? project.simulations.filter(
                  simulation => simulation.selected && simulation.selected !== 0
              )
            : [];
        return _.orderBy(selectedSimulations, (o: any) => o.selected, 'asc');
    }

    private renderResult = selectedSimulations => {
        const { loading, project } = this.props;
        if (loading || selectedSimulations.length === 0) {
            return <Empty />;
        }
        return (
            <SimulationSection>
                <Title
                    i18nKey={
                        selectedSimulations.length === 1
                            ? 'simulation.resultTitle'
                            : 'simulation.comparationTitle'
                    }
                />
                <Column xs={12} sm={12} md={10} lg={10} centered>
                    <Result
                        id="simulation_result"
                        simulations={selectedSimulations}
                        projectName={project.name}
                    />
                </Column>
            </SimulationSection>
        );
    };

    private renderConfigs = selectedSimulations => {
        const { loading, config } = this.props;
        if (loading) {
            return <Empty />;
        }
        return (
            <SimulationSection>
                <Title i18nKey="simulation.configurationTitle" />
                <Column xs={12} sm={12} md={11} lg={10} centered>
                    <Configuration
                        simulation={selectedSimulations[0]}
                        isComparation={selectedSimulations.length >= 2}
                        config={config}
                        id="simulation_configuration"
                    />
                </Column>
            </SimulationSection>
        );
    };

    private renderPerformedSimulations = () => {
        const { project } = this.props;
        if (project.simulations && project.simulations.length !== 0) {
            return (
                <Performed simulations={project.simulations} pageLimit={4} />
            );
        }
        return <Empty />;
    };

    render() {
        const { project, cacheProject } = this.props;
        const selectedSimulations = this.selectedSimulations;
        const isChanged = !_.isEqual(
            removeObjectReference(project.simulations).map(simulation => {
                delete simulation.selected;
                return simulation;
            }),
            removeObjectReference(cacheProject.simulations).map(simulation => {
                delete simulation.selected;
                return simulation;
            })
        );
        if (isChanged) {
            window.onbeforeunload = e => {
                e.returnValue = outTranslator('confirmLeave');
            };
        } else {
            window.onbeforeunload = null;
        }

        return (
            <StyledSimulation id="Simulation">
                <Topbar project={project} isChanged={isChanged} />
                <SimulationGrid>
                    {this.renderConfigs(selectedSimulations)}
                    {this.renderResult(selectedSimulations)}
                </SimulationGrid>
                {this.renderPerformedSimulations()}
            </StyledSimulation>
        );
    }
}

export interface Props {
    project: Project;
    cacheProject: Project;
    config: SimulationConfig;
    loading: boolean;
}

export default SimulationContainer;
