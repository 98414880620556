import React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../redux/store';
import { SimulationResultCardContainer } from './container';
import { bindActionCreators } from 'redux';
import {
    deleteCurrentSimulation,
    selectSimulation,
    changeSimulationName
} from '../../../../redux/actions/simulation.actions';
import SimulationResultWithConfigs from '../../../../models/simulationResultWithConfigs.model';
import { Paint } from '../../../../models/paint.model';
import { useTranslation } from 'react-i18next';

const SimulationResultCard = React.forwardRef(
    (props: SimulationResultCardProps, ref: React.Ref<any>) => (
        <SimulationResultCardContainer
            ref={ref}
            {...props}
            t={useTranslation('app').t}
        />
    )
);

interface SimulationResultCardProps {
    simulation: SimulationResultWithConfigs;
    paints: Paint[];
    isComparation?: boolean;
    performed?: {
        compress();
        simulationsLength: number;
        checked?: { date: Date | string; locked: boolean; reference: boolean };
        lockedCardsNumber: number;
        checkedCardsTotal: number;
        cardCheck(simulation: SimulationResultWithConfigs): void;
        cardUncheck(date: Date | string): void;
    };
    changeSimulationName(date: Date | string, name: string): void;
    deleteCurrentSimulation(simulation: SimulationResultWithConfigs): void;
    selectSimulation(simulation: SimulationResultWithConfigs): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    paints: state.paints.items,
    results: state.simulation.project
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            deleteCurrentSimulation,
            selectSimulation,
            changeSimulationName
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(SimulationResultCard);
