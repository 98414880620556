import styled from 'styled-components';
import { H2 } from '../../../../../components/UI';

export const Container = styled.section``;

export const TableContainer = styled.div``;

export const Title = styled(H2)`
    margin-bottom: 32px;
`;

export const TableTitle = styled(H2)`
    color: #444444;
    margin-bottom: 8px !important;
    font-family: 'Source Sans Pro';
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
`;
