import { ProjectsTypes } from '../types/projects.types';
import { action } from 'typesafe-actions';
import { Project } from '../../models/project.model';
import { dialog } from '../../components/UI';
import { GetFilter } from '../../models/getFilter.model';
import { Paging } from '../../models/paging.model';

const DEFAULT_PAGING = {
    total_items: 0,
    total_pages: 1
};

export const recoverProject = (id: number, callback?: Function) =>
    action(ProjectsTypes.RECOVER_PROJECT_REQUEST, { id, callback });

export const recoverProjectError = () => {
    dialog({
        type: 'error',
        i18nKey: 'redux.actions.projects.recover.error'
    });
    return action(ProjectsTypes.RECOVER_PROJECT_FAILURE);
};

export const clearProject = () => action(ProjectsTypes.CLEAR_PROJECT);

export const setProject = project => action(ProjectsTypes.SET_PROJECT, project);

export const getProjects = (filter: GetFilter, callback?: Function) =>
    action(ProjectsTypes.GET_REQUEST, { filter, callback });

export const getProjectsSuccess = (
    paging: Paging = DEFAULT_PAGING,
    projects: Project[] = []
) => action(ProjectsTypes.GET_SUCCESS, { paging, projects });

export const getProjectsError = () => {
    dialog({
        type: 'error',
        i18nKey: 'redux.actions.projects.get.error'
    });
    return action(ProjectsTypes.GET_FAILURE);
};

export const saveProject = (project: Project, callback: Function) => {
    return action(ProjectsTypes.SAVE_PROJECT_REQUEST, { project, callback });
};

export const saveProjectSuccess = project => {
    dialog({
        type: 'success',
        i18nKey: {
            name: 'saveProject.saveSuccess',
            vars: { name: project.name }
        }
    });
    return setProject(project);
};

export const saveProjectError = () => {
    dialog({
        type: 'error',
        i18nKey: 'saveProject.saveError'
    });
    return action(ProjectsTypes.SAVE_PROJECT_FAILURE);
};

export const deleteProject = (project: Project, callback?: Function) =>
    action(ProjectsTypes.DELETE_PROJECT_REQUEST, { project, callback });

export const deleteProjectSuccess = (name: string) => {
    dialog({
        type: 'success',
        i18nKey: {
            name: 'redux.actions.projects.delete.success',
            vars: { name }
        }
    });
    return action(ProjectsTypes.DELETE_PROJECT_SUCCESS);
};

export const deleteProjectError = (name: string) => {
    dialog({
        type: 'error',
        i18nKey: {
            name: 'redux.actions.projects.delete.error',
            vars: { name }
        }
    });
    return action(ProjectsTypes.DELETE_PROJECT_FAILURE);
};
