import styled from 'styled-components';
import { Button } from '../..';

export const StepLine = styled.div`
    height: 1px;
    width: 100%;
    background: #cccccc;
`;

export const NextButton = styled(Button)`
    width: 140px;
`;

export const SaveButton = styled(Button)`
    width: 140px;
`;

export const CancelButton = styled(Button)`
    width: 140px;

    &.ui.button {
        margin-right: 32px;
    }
`;

export const Buttons = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 24px 0;
`;
