import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Container, Title, TableTitle, TableContainer } from './index.styled';
import Table from './Table';
import SimulationResultWithConfigs from '../../../../../models/simulationResultWithConfigs.model';
import { ApplicationState } from '../../../../../redux/store';
import { Paint } from '../../../../../models/paint.model';
import {
    toFixedTrunc,
    normalizeCommaNumber
} from '../../../../../helpers/util';
import { FormulationSolvent } from '../../../../../models/solvent.model';
import { withTranslation, WithTranslation } from 'react-i18next';

class ResultTable extends Component<Props, {}> {
    aplicationConditionsToValues = () => {
        const { simulations, paints, t } = this.props;

        const key = 'resultTable.aplicationConditions.';
        const rows: string[] = [
            `${key}typeOfInk`,
            `${key}humidity`,
            `${key}temp`
        ];
        const values = simulations.map(simulation => {
            const paint = paints.find(
                paint => paint.id === simulation.parameters.conditions.paint.id
            );
            let paintName = paint && paint.name ? paint.name : '-';
            if (simulation.parameters.conditions.paint.custom) {
                paintName = paintName + ' - ' + t('resultTable.customized');
            }
            return {
                simulationName: simulation.name,
                [`${key}typeOfInk`]: paintName,
                [`${key}humidity`]: `${simulation.parameters.conditions.environment.relative_humidity}%`,
                [`${key}temp`]: `${simulation.parameters.conditions.environment.temperature}°C`
            };
        });
        return { rows, values };
    };

    solventsToValues = () => {
        const { simulations } = this.props;
        const rows: string[] = [];

        simulations.forEach(simulation =>
            simulation.parameters.solvents.forEach(formulationSolvent => {
                const name = formulationSolvent.solvent.name;
                if (!rows.find(row => row === name)) {
                    rows.push(name);
                }
            })
        );

        const values = simulations.map(simulation => ({
            simulationName: simulation.name,
            ...simulation.parameters.solvents.reduce(
                (acc, formulationSolvent) => {
                    acc[formulationSolvent.solvent.name] = toFixedTrunc(
                        formulationSolvent.concentration,
                        1
                    );
                    return acc;
                },
                {}
            )
        }));

        return { rows, values };
    };

    solubilityParamsToValues = () => {
        const { simulations } = this.props;
        const rows = ['dD', 'dP', 'dH'];
        const values = simulations.map(simulation => ({
            simulationName: simulation.name,
            dD: normalizeCommaNumber(
                simulation.result.graphs.mixing.paths[0].delta_d,
                1
            ),
            dP: normalizeCommaNumber(
                simulation.result.graphs.mixing.paths[0].delta_p,
                1
            ),
            dH: normalizeCommaNumber(
                simulation.result.graphs.mixing.paths[0].delta_h,
                1
            )
        }));
        return { rows, values };
    };

    evaporationToValues = () => {
        const { simulations } = this.props;
        const translationkey = 'resultTable.evaporation.';
        const rows = [
            `${translationkey}initial`,
            `${translationkey}vol_30`,
            `${translationkey}vol_50`,
            `${translationkey}vol_70`,
            `${translationkey}vol_90`
        ];
        const positions = [0, 30, 50, 70, 90];
        const polymers: string[] = [];
        simulations.forEach(simulation =>
            simulation.result.graphs.polymer_normalized_distance.forEach(
                polymer => {
                    const finded = polymers.find(
                        currPolymer => currPolymer === polymer.name
                    );
                    if (!finded) {
                        polymers.push(polymer.name);
                    }
                }
            )
        );

        return polymers.map(polymer => ({
            header: polymer,
            rows,
            values: simulations.map(simulation => ({
                simulationName: simulation.name,
                ...positions.reduce((acc, position) => {
                    const finded = simulation.result.graphs.polymer_normalized_distance.find(
                        pol_normalized => pol_normalized.name === polymer
                    );
                    let result;
                    if (finded) {
                        let axe = finded.axes.find(
                            axe =>
                                axe.x >= position && axe.x <= position + 1
                        );
                        axe = setAxe(axe, finded, position);

                        result = { ...axe, x: position };
                    }
                    if (result) {
                        acc[
                            position === 0
                                ? `${translationkey}initial`
                                : `${translationkey}vol_${position}`
                        ] = toFixedTrunc(result.y, 2);
                    }
                    return acc;
                }, {})
            }))
        }));
    };

    performaceToValues = () => {
        const { simulations, t } = this.props;

        const key = 'resultTable.performace.';

        const rows = [
            `${key}time`,
            `${key}absorption`,
            `${key}tlv.title`,
            `${key}mir.title`,
            `${key}cost`
        ];

        const values = simulations.map(simulation => {
            const { properties } = simulation.result;
            const { solvents } = simulation.parameters;

            let total = 0;

            const unitType = simulation.is_volume;
            const unitMeasure = simulation.is_value_per_liter;

            solvents.every((solvent: FormulationSolvent) => {
                if (solvent.cost! === 0) {
                    total = 0;
                    return false;
                }
                total += (solvent.cost! * solvent.concentration!) / 100;
                return true;
            });

            const unit = t(
                `resultTable.${unitType ? 'volume' : 'mass'}`
            );

            return {
                simulationName: simulation.name,
                [`${key}time`]: moment(
                    properties.general.drying_time!.in_milliseconds!
                ).format(
                    `mm[min] ss[${t('resultTable.performace.sec')}]`
                ),
                [`${key}absorption`]: `${toFixedTrunc(properties.general.percent_water_absorption, 2)} (%${unit})`,
                [`${key}tlv.title`]: `${properties.general.tlv}`,
                [`${key}mir.title`]: `${properties.general.mir}`,
                [`${key}cost`]: (() => {
                    if (total > 0) {
                        const costUnit = t(
                            unitMeasure
                                ? 'simulation.stepFormulation.solventGrid.literCoin'
                                : 'simulation.stepFormulation.solventGrid.WeightCoin'
                        );
                        return `${costUnit} ${toFixedTrunc(total, 2)}`;
                    }
                })()
            };
        });
        return { rows, values };
    };

    getHints = () => {
        const { t } = this.props;

        const key = 'resultTable.performace.';
        return {
            [`${key}time`]: '',
            [`${key}absorption`]: '',
            [`${key}tlv.title`]: t(`${key}tlv.hint`),
            [`${key}mir.title`]: t(`${key}mir.hint`),
            [`${key}cost`]: ''
        };
    };

    render() {
        const aplicationConditions = this.aplicationConditionsToValues();
        const solvents = this.solventsToValues();
        const solubilityParms = this.solubilityParamsToValues();
        const evaporation = this.evaporationToValues();
        const performace = this.performaceToValues();
        const hints = this.getHints();

        return (
            <Container id={'tableResult'}>
                <Title i18nKey="resultTable.title" className="pdf" />
                <TableContainer className="pdf">
                    <TableTitle
                        i18nKey={'resultTable.aplicationConditions.title'}
                    />
                    <Table
                        header={'resultTable.items'}
                        rows={aplicationConditions.rows}
                        values={aplicationConditions.values}
                    />
                </TableContainer>

                <TableContainer className="pdf">
                    <TableTitle i18nKey={'resultTable.formulation'} />
                    <Table
                        header={'resultTable.solvents'}
                        rows={solvents.rows}
                        values={solvents.values}
                        emptyPlace="resultTable.solventsEmpty"
                        hasFooter
                    />
                    <Table
                        header={'resultTable.solubilityParms'}
                        rows={solubilityParms.rows}
                        values={solubilityParms.values}
                    />
                </TableContainer>

                <TableContainer>
                    <TableTitle i18nKey={'resultTable.red'} className="pdf" />
                    {evaporation.map((item, index) => (
                        <Table
                            className="pdf"
                            key={index}
                            header={item.header}
                            rows={item.rows}
                            values={item.values}
                            emptyPlace="resultTable.evaporationEmpty"
                        />
                    ))}
                </TableContainer>

                <TableContainer className="pdf">
                    <TableTitle i18nKey={'resultTable.performace.title'} />
                    <Table
                        header={'resultTable.items'}
                        rows={performace.rows}
                        values={performace.values}
                        hints={hints}
                    />
                </TableContainer>
            </Container>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    paints: state.paints.items,
    unitType: state.conversion.unitType,
    unitMeasure: state.conversion.unitMeasure.measurement
});

interface Props extends WithTranslation {
    simulations: SimulationResultWithConfigs[];
    paints: Paint[];
}

export default connect(mapStateToProps)(withTranslation('app')(ResultTable));

function setAxe(axe: any, finded: any, position: number) {
    if (!axe) {
        const axes = JSON.parse(JSON.stringify(finded.axes));
        const next = axes.find(axe => axe.x >= position)!;
        const previous = axes.reverse().find(axe => axe.x <= position)!;

        axe = setAxeBasedOnPosition(next, previous, axe, position);
    }
    return axe;
}
function setAxeBasedOnPosition(next: any, previous: any, axe: any, position: number) {
    if (!next && previous) {
        axe = previous;
    } else if (next && !previous) {
        axe = next;
    } else if (next && previous) {
        axe = (next ? next.x : 0) - position >= position - (previous ? previous.x : 0) ? previous : next;
    }
    return axe;
}

