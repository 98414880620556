import React from 'react';

import {
    Container,
    StyledTable,
    ResultCellLabel,
    ResultCellValue,
    ResultSpanLabel,
    ResultSpanValue,
    Header,
    ResultFooterLabel,
    ResultFooterValue,
    ResultFooterSpan
} from './index.styled';
import {
    Table as CustomTable,
    Hint,
    Empty
} from '../../../../../../components/UI';
import { toFixedTrunc } from '../../../../../../helpers/util';

const Table = ({
    header,
    rows,
    values,
    hints,
    hasFooter,
    className,
    emptyPlace
}: Props) => {
    const renderHint = (key: string) => {
        if (hints !== undefined && hints[key]) {
            return <Hint i18nKey={hints[key]} data-html2canvas-ignore />;
        }
        return <Empty />;
    };
    return (
        <Container className={className}>
            <StyledTable celled structured>
                <CustomTable.Header>
                    <CustomTable.Row>
                        <CustomTable.HeaderCellInverted>
                            <Header i18nKey={header} />
                        </CustomTable.HeaderCellInverted>
                        {values.map(({ simulationName }, index) => (
                            <CustomTable.HeaderCell
                                key={index}
                                width={values.length === 1 ? 6 : 4}
                            >
                                {simulationName}
                            </CustomTable.HeaderCell>
                        ))}
                    </CustomTable.Row>
                </CustomTable.Header>
                <CustomTable.Body>
                    {rows.map((row, index) => {
                        return (
                            <CustomTable.Row key={`${row}-${index}`}>
                                <ResultCellLabel>
                                    <ResultSpanLabel i18nKey={row} />
                                    {renderHint(row)}
                                </ResultCellLabel>
                                {values.map((value, index) => (
                                    <ResultCellValue
                                        key={index}
                                        width={values.length === 1 ? 6 : 4}
                                    >
                                        <ResultSpanValue
                                            i18nKey={
                                                value[row]
                                                    ? value[row]
                                                    : emptyPlace
                                                    ? emptyPlace
                                                    : '-'
                                            }
                                        />
                                    </ResultCellValue>
                                ))}
                            </CustomTable.Row>
                        );
                    })}
                    {hasFooter && (
                        <CustomTable.Row>
                            <ResultFooterLabel>
                                <ResultFooterSpan
                                    i18nKey={'resultTable.total'}
                                />
                            </ResultFooterLabel>
                            {values.map((value, index) => (
                                <ResultFooterValue
                                    key={index}
                                    width={values.length === 1 ? 6 : 4}
                                >
                                    {toFixedTrunc(
                                        Object.keys(value).reduce(
                                            (acc, cur) => {
                                                if (cur === 'simulationName') {
                                                    return acc;
                                                }
                                                return (
                                                    acc +
                                                    Number(
                                                        value[cur]
                                                            .toString()
                                                            .replace(',', '.')
                                                    )
                                                );
                                            },
                                            0
                                        ),
                                        1
                                    )}
                                </ResultFooterValue>
                            ))}
                        </CustomTable.Row>
                    )}
                </CustomTable.Body>
            </StyledTable>
        </Container>
    );
};

Table.defaultProps = {
    hints: {},
    hasFooter: false
};

interface Props {
    header: string;
    rows: string[];
    values: any[];
    hints?: {};
    emptyPlace?: string;
    hasFooter?: boolean;
    className?: string;
}

export default Table;
