import SimulationConfig from '../../models/simulationConfig.model';
import { Project } from '../../models/project.model';

/**
 * Action types
 */
export enum SimulationTypes {
    SIMULATE_REQUEST = 'simulation:SIMULATE_REQUEST',
    SIMULATE_SUCCESS = 'simulation:SIMULATE_SUCCESS',
    SIMULATE_FAILURE = 'simulation:SIMULATE_FAILURE',
    SET_POLYMERS = 'simulation:SET_POLYMERS',
    SET_FORMULATION = 'simulation:SET_FORMULATION',
    SET_CONDITIONS = 'simulation:SET_CONDITIONS',
    DELETE_SIMULATION = 'simulation:DELETE_SIMULATION',
    DELETE_SIMULATION_SUCCESS = 'simulation:DELETE_SIMULATION_SUCCESS',
    DELETE_SIMULATION_ERROR = 'simulation:DELETE_SIMULATION_ERROR',
    SELECT_SIMULATION = 'simulation:SELECT_SIMULATION',
    SELECT_SIMULATION_SUCCESS = 'simulation:SELECT_SIMULATION_SUCCESS',
    SELECT_SIMULATION_ERROR = 'simulation:SELECT_SIMULATION_ERROR',
    REFRESH_RESULTS = 'simulation:REFRESH_RESULTS',
    NEW_SIMULATION = 'simulation:NEW_SIMULATION',
    NEW_SIMULATION_SUCCESS = 'simulation:NEW_SIMULATION_SUCCESS',
    ADD_SOLVENT_TO_FORMULATION = 'simulation:ADD_SOLVENT_TO_FORMULATION',
    REMOVE_SOLVENT_TO_FORMULATION = 'simulation:REMOVE_SOLVENT_TO_FORMULATION',
    SET_SOLVENT_CONCENTRATION = 'simulation:SET_SOLVENT_CONCENTRATION',
    SET_SOLVENT_COST = 'simulation:SET_SOLVENT_COST',
    SWITCH_COST_UNIT = 'simulation:SWITCH_COST_UNIT',
    SWITCH_UNIT_TYPE = 'simulation:SWITCH_UNIT_TYPE',
    COMPARE_SIMULATIONS = 'simulation:COMPARE_SIMULATIONS',
    COMPARE_SIMULATIONS_SUCCESS = 'simulation:COMPARE_SIMULATIONS_SUCCESS'
}

/**
 * State types
 */
export interface SimulationState {
    readonly config: SimulationConfig;
    readonly project: Project | null;
    readonly cacheProject: Project | null;
    readonly loading: boolean;
    readonly error: boolean;
    readonly message: string;
}
