import { Project } from '../../models/project.model';
import { Paging } from '../../models/paging.model';

/**
 * Action types
 */
export enum ProjectsTypes {
    CLEAR_PROJECT = 'projects:CLEAR_PROJECT',
    SET_PROJECT = 'projects:SET_PROJECT',
    GET_REQUEST = 'projects:GET_REQUEST',
    GET_SUCCESS = 'projects:GET_SUCCESS',
    GET_FAILURE = 'projects:GET_FAILURE',
    RECOVER_PROJECT_REQUEST = 'projects:RECOVER_PROJECT_REQUEST',
    RECOVER_PROJECT_FAILURE = 'projects:RECOVER_PROJECT_FAILURE',
    SAVE_PROJECT_REQUEST = 'projects:SAVE_PROJECT_REQUEST',
    SAVE_PROJECT_SUCCESS = 'projects:SAVE_PROJECT_SUCCESS',
    SAVE_PROJECT_FAILURE = 'projects:SAVE_PROJECT_FAILURE',
    DELETE_PROJECT_REQUEST = 'projects:DELETE_PROJECT_REQUEST',
    DELETE_PROJECT_SUCCESS = 'projects:DELETE_PROJECT_SUCCESS',
    DELETE_PROJECT_FAILURE = 'projects:DELETE_PROJECT_FAILURE'
}

/**
 * State types
 */
export interface ProjectsState {
    readonly items: Partial<Project>[];
    readonly loading: boolean;
    readonly paging: Paging;
}
