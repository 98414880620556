import styled from 'styled-components';
import {
    H2,
    Row,
    Column,
    Button,
    Span
} from '../../../../../../../components/UI';

export const Title = styled(H2)``;

export const StyledRow = styled(Row)`
    margin: 10px 0;
`;

export const StyledColumn = styled(Column)``;

export const Email = styled(Span)`
    padding: 8px 9px;
    display: block;
    width: fit-content;
    border: 1px solid rgba(118, 54, 137, 0.1);
    border-radius: 4px;
    background-color: rgba(118, 54, 137, 0.1);
`;

export const NextButton = styled(Button)`
    &.ui.button {
        padding: 4px 40px;
        font-size: 16px;
        font-weight: bold;
        margin-right: 0;
    }
`;
