import React from 'react';
import { Container, List, ItemColor, ItemName, Hint } from './index.styled';
import { toFixedTrunc } from '../../../../../../../helpers/util';

export const Legends = ({ solvents, selected, onSelected, pallet }: Props) => {
    const isSelected = id => {
        if (!selected) {
            return 1;
        }
        if (selected === id) {
            return 1;
        } else {
            return 0.3;
        }
    };
    const renderSolvents = () => {
        return solvents.map((solvent, index) => {
            return (
                <List.Item
                    key={solvent.id}
                    data-selected={isSelected(solvent.id)}
                    onMouseEnter={() => onSelected(solvent.id)}
                    onMouseLeave={() => onSelected(null)}
                >
                    <ItemColor data-color={pallet[index]} />
                    <ItemName
                        renderText={`${solvent.name} (${toFixedTrunc(
                            solvent.value,
                            2
                        )}%)`}
                    />
                    {(solvent.name.toLowerCase() === 'água' ||
                        solvent.name.toLowerCase() === 'agua' ||
                        solvent.name.toLowerCase() === 'water') && (
                        <Hint
                            name="info-circle"
                            size={19}
                            i18nKey="compositionGraph.waterHint"
                        />
                    )}
                </List.Item>
            );
        });
    };
    return (
        <Container>
            <List>{renderSolvents()}</List>
        </Container>
    );
};

export default Legends;

export interface Props {
    solvents;
    pallet;
    selected: number;
    onSelected(id: number | null): void;
}
