import styled from 'styled-components';
import { Span, Table, Button } from '../../../../../../../components/UI';

export const Limit = styled(Span)`
    color: #444444;
    font-size: 14px;
    line-height: 23px;
    float: right;
    padding-bottom: 16px;
`;

export const LimitExceeded = styled(Span)`
    color: #a70e1a;
    font-size: 14px;
    line-height: 23px;
    float: right;
    padding-bottom: 16px;
`;

export const StyledTable = styled(Table)`
    margin-bottom: 34px !important;

    @media (max-width: 767px) {
        th {
            text-align: center !important;
        }
    }
`;

export const ParamsSpan = styled(Span)`
    font-size: 14px;
`;

export const SolventNameSpan = styled(Span)`
    color: #875098;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    line-height: 20px;
`;

export const VolumeCell = styled(Table.Cell)`
    background-color: #d70e66;
    color: #fff;
    padding: 0 !important;
`;

export const MassCell = styled(Table.Cell)`
    background-color: #f6a906;
    color: #fff;
    padding: 0 !important;
`;

export const RadioContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
`;

export const ProgressSpan = styled(Span)`
    color: ${props => (props['data-error'] ? '#444' : '#0b8e57')};
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
`;

export const CostContainer = styled.div`
    display: inline-block;
`;

export const SpanCostMessage = styled(Span)`
    display: inline-block;
    color: #444444;
    font-size: 14px;
    line-height: 18px;
`;
export const SpanCost: any = styled(Span)`
    display: inline-block;
    color: #444444;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
`;

SpanCost.Coin = styled(Span)`
    padding-right: 5px;
`;

export const StepLine = styled.div`
    height: 1px;
    width: 100%;
    background: #cccccc;
`;

export const ConvertButton = styled(Button)`
    height: 40px;
    width: 270px;
    border-radius: 4px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 24px 0;
`;

export const HeaderSpan = styled(Span)`
    display: inline-block;
    color: #fff;
    font-size: 14px;
    white-space: nowrap;
`;

export const TER = styled(HeaderSpan)`
    width: 60px;
`;

export const CostMessage = styled.span`
    white-space: nowrap;
    display: block;
`;
