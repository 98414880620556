import React from 'react';
import GroupsContainer from './container';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Group from '../../../../../models/group.model';
import {
    deleteGroup,
    searchGroups
} from '../../../../../redux/actions/groups.actions';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ApplicationState } from '../../../../../redux/store';

class Groups extends React.PureComponent<Props> {
    render() {
        return <GroupsContainer limit={10} {...this.props} />;
    }

    componentWillMount() {
        const { searchGroups } = this.props;
        searchGroups({ q: '', page: 1, limit: 10 });
    }
}

interface Props extends RouteComponentProps {
    groups: Group[];
    loading: boolean;
    paging: {
        total_items: number;
        total_pages: number;
    };
    searchGroups(filter, callback?): void;
    deleteGroup(group: Group, callback?: Function): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.groups.loading,
    paging: state.groups.paging,
    groups: state.groups.items
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            searchGroups,
            deleteGroup
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(Groups));
