import styled from 'styled-components';
import { Icon, Span, Button, Loading } from '..';

export const Background = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${props => props['data-type'] === 'success' ? 101 : 100};
    background-color: rgba(0, 0, 0, 0.4);
`;

export const Dialog = styled.div`
    position: relative;
    min-height: 115px;
    width: 100%;
    max-width: 480px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 20px;
    text-align: center;
`;

const StyledIcon = styled(Icon)`
    padding-bottom: 15px;
    display: block;
    font-size: 48px;
`;

export const SuccessIcon = styled(StyledIcon)`
    color: #0da565;
`;

export const DeleteIcon = styled(StyledIcon)`
    color: #a70e1a;
`;

export const WarningIcon = styled(StyledIcon)`
    color: #a70e1a;
`;

export const ErrorIcon = styled(StyledIcon)`
    color: #a70e1a;
`;

export const LoadingIcon = styled(Loading)``;

export const FlaskLoading = styled.img`
    width: 100px;
`;

export const Header = styled(Span)`
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;
`;

export const Body = styled(Span)`
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 20px;
    padding: 16px 0;
`;

export const CloseBtn = styled(Icon)`
    font-size: 21px;
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
        color: #444444;
        cursor: pointer;
    }
`;

export const Footer: any = styled.div`
    width: 100%;
    text-align: center;
    margin-top: 15px;
`;

Footer.Button = styled(Button)`
    &.ui.button {
        font-size: 18px;
        font-weight: bold;
        letter-spacing: 0.3px;
        min-width: 120px;
        margin: 5px 15px;
    }
`;
