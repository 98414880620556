import styled from 'styled-components';
import { H2, Icon } from '../../../../components/UI';

export const Container = styled.section`
    margin-bottom: 30px;
`;

export const Header = styled.div`
    width: 100%;
    padding: 24px 0px;
`;

export const Title = styled(H2)`
    display: inline-block;
`;

export const Hint = styled(Icon)`
    display: inline-block;
    margin-left: 15px;
`;
