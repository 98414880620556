import styled from 'styled-components';
import { Icon } from '../..';
import colors from '../../../../theme/colors';
import { height } from '../../../layout/Header/Topbar/container/index.styled';

export const StyledGoTop = styled.div`
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 12;
    bottom: 100px;
    right: 0;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    height: 40px;
    width: 40px;
    border: 1px solid #ccc;
    border-right: 0;
    background-color: #fff;
    cursor: pointer;
    display: ${props => (props['data-scrolled'] > height ? 'flex' : 'none')};
`;

export const ArrowUp = styled(Icon)`
    font-size: 30px;
    color: ${colors.main};
`;
