import { shallow, ShallowWrapper } from 'enzyme';
import i18next from 'i18next';
import _ from 'lodash';
import { initReactI18next } from 'react-i18next';
import ReactHtmlParser from 'react-html-parser';
import translations from '../translations';
import PositionDPH from '../models/positionDPH.model';
import RadiusDPH from '../models/radiusDPH.model';
import toastr from 'toastr';

export const init18n = (): any => {
    i18next.use(initReactI18next).init({
        lng: 'pt_br',
        resources: translations
    });
    return i18next.t.bind(i18next);
};

export const init18nWithProps = (): any => {
    i18next.use(initReactI18next).init({
        lng: 'pt_br',
        resources: translations
    });
    return {
        t: i18next.t.bind(i18next),
        i18n: i18next,
        tReady: () => {}
    };
};

export const testElementTranslation = element => {
    Object.keys(translations).forEach(key => {
        i18next.changeLanguage(key);
        let objTranslator = _.get(
            translations[key].app,
            element.getElement().props.translate
        );
        expect(objTranslator).not.toBeUndefined();
        const regex = /\{(.*?)\}/gm;
        const strTranslated = element
            .dive()
            .children()
            .debug();
        if (objTranslator.match(regex)) {
            objTranslator.match(regex)!.forEach(match => {
                objTranslator = objTranslator.replace(`${match}} `, '');
            });
            objTranslator.split(' ').forEach(word => {
                expect(strTranslated).toContain(word);
            });
        }
    });
};

export const testTranslation = component => {
    let wrapper;
    if (component instanceof ShallowWrapper) {
        wrapper = component;
    } else {
        wrapper = shallow(component);
    }
    if (wrapper.find('Translator').length === 0) {
        wrapper = wrapper.dive();
    }
    wrapper.find('Translator').forEach((element: ShallowWrapper) => {
        testElementTranslation(element);
    });
    i18next.changeLanguage('pt_br');
};

export const htmlT = (t, str: string, options?) => {
    const unescapeHTML = html => {
        const escapeEl = document.createElement('textarea');
        escapeEl.innerHTML = html;
        return escapeEl.textContent;
    };
    return ReactHtmlParser(unescapeHTML(t(str, options)));
};

export const shallowDive = (element, level: number) => {
    let wrapper =
        element instanceof ShallowWrapper ? element : shallow(element);
    for (let i = 0; i < level; i++) {
        try {
            wrapper = wrapper.dive();
        } catch (ex) {
            return wrapper;
        }
    }
    return wrapper;
};

export const getMousePos = (bounds, event) => {
    const scaleX = bounds.width / bounds.width;
    const scaleY = bounds.height / bounds.height;

    return {
        x: (event.clientX - bounds.left) * scaleX,
        y: (event.clientY - bounds.top) * scaleY
    };
};

export const convertHexColorToNumber = (hex: string): number => {
    return Number(hex.replace('#', '0x'));
};

export const objectByString = (o, s) => {
    s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
    s = s.replace(/^\./, ''); // strip a leading dot
    const a = s.split('.');
    for (let i = 0, n = a.length; i < n; ++i) {
        const k = a[i];
        if (k in o) {
            o = o[k];
        } else {
            return;
        }
    }
    return o;
};

export const outTranslator = (path: string, vars = {}) => {
    if (!translations[i18next.language]) {
        return path;
    }
    let obj: string = objectByString(translations[i18next.language].app, path);
    Object.keys(vars).forEach(key => {
        obj = obj.replace('{{' + key + '}}', vars[key]);
    });
    return obj;
};

export const showToastr = (
    type: 'info' | 'success' | 'error' | 'warning',
    str: string
) => {
    toastr.clear();
    toastr[type](str);
};

export const scrollToTargetAdjusted = (elementId: string) => {
    const element = document.getElementById(elementId);
    if (element) {
        element.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }
};

export const toFixedTrunc = (v, d: number, dot?: boolean) => {
    var out = (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
    if (dot) {
        out = out.replace('.', ',');
    }
    return out;
};

export const normalizeCommaNumber = (n: number | string, d?: number) =>
    toFixedTrunc(n, d ? d : 2);

export const normalizeDPH = (position: PositionDPH) => ({
    dD: normalizeCommaNumber(position.dD),
    dP: normalizeCommaNumber(position.dP),
    dH: normalizeCommaNumber(position.dH)
});

export const normalizeRadiusDPH = (radius: RadiusDPH) => ({
    rD: normalizeCommaNumber(radius.rD),
    rP: normalizeCommaNumber(radius.rP),
    rH: normalizeCommaNumber(radius.rH)
});

export const removeObjectReference = obj => JSON.parse(JSON.stringify(obj));

export const sleep = time => {
    return new Promise(resolve => setTimeout(resolve, time));
};

export const normalizePolymersValuesForComparation = (positions, simulations) =>{
    const data: any[] = [];
    simulations.forEach(simulation => {
        data.push(
            simulation.result.graphs.polymer_normalized_distance.map(
                polymer => {
                    const axes = positions.map(position => {
                        let axe = polymer.axes.find(
                            axe =>
                                axe.x >= position && axe.x <= position + 1
                        );
                        axe = setAxeValue(axe, polymer, position);
                        return { ...axe, x: position };
                    });
                    return {
                        id: polymer.id,
                        name: polymer.name,
                        axes,
                        simulation: {
                            name: simulation.name,
                            date: simulation.date
                        }
                    };
                }
            )
        );
    });
    return data;
}

function setAxeValue(axe: any, polymer: any, position: any) {
    if (!axe) {
        const axes = JSON.parse(JSON.stringify(polymer.axes));
        const next = axes.find(axe => axe.x >= position)!;
        const previous = axes.reverse().find(axe => axe.x <= position)!;

        axe = fetchAxe(next, previous, axe, position);
    }
    return axe;
}
function fetchAxe(next: any, previous: any, axe: any, position: any) {
    if (!next && previous) {
        axe = previous;
    } else if (next && !previous) {
        axe = next;
    } else if (next && previous) {
        axe = (next ? next.x : 0) - position >= position - (previous ? previous.x : 0) ? previous : next;
    }
    return axe;
}

