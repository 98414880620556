import styled from "styled-components";
import { Button } from "../../UI";

export const CustomButtom = styled(Button)`
    height: 40px;
    width: 215px;
    border-radius: 4px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 24px 0;
`;
