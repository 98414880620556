import styled from "styled-components";
import { Progress as SemanticProgress } from "semantic-ui-react";

export const CustomProgress = styled(SemanticProgress)`
    &.ui.tiny.progress {
        margin: 8px 0 !important;
        .bar {
            background: ${props =>
                props.percent === 0 ? "transparent !important" : ""};
        }
    }
`;
