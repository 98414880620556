import styled from 'styled-components';
import { footerHeight } from '../Footer/index.styled';
import { headerHeight } from '../Header/index.styled';

export const Main = styled.main`
    min-height: calc(100vh - ${footerHeight}px - ${headerHeight}px);
    z-index: 0;
    height: auto;
    display: flex;
    background-color: hsla(0, 0%, 99%, 1);
    overflow-x: auto;
`;
