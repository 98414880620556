import React from 'react';
import { Topbar, ExitButton, SaveButton, Buttons, Title } from './index.styled';
import { dialog } from '../../../../../components/UI';

const SimulationTopbarContainer = ({
    projectName,
    onExit,
    onSaveProject,
    fixed,
    isChanged
}: ISimulationTopbarContainerProps) => {
    const exit = () => {
        if (isChanged) {
            dialog({
                type: 'delete',
                header: {
                    i18nKey: 'simulation.topbar.exit.title'
                },
                body: {
                    i18nKey: 'simulation.topbar.exit.message'
                },
                buttons: {
                    confirm: {
                        i18nKey: 'simulation.topbar.exit.buttons.exit',
                        event: onExit
                    },
                    cancel: {
                        i18nKey: 'simulation.topbar.exit.buttons.saveChanges',
                        event: () => onSaveProject({ exit: true })
                    }
                }
            });
        } else {
            onExit();
        }
    };

    return (
        <Topbar id="Simulation_topbar" data-fixed={fixed}>
            <Title
                id="Simulation_topbar_newProjectName"
                renderText={projectName}
            />
            <Buttons>
                <ExitButton
                    id="Simulation_topbar_exitButton"
                    i18nKey="exit"
                    cl="invertedDefault"
                    onClick={exit}
                />
                <SaveButton
                    id="Simulation_topbar_saveButton"
                    i18nKey="simulation.topbar.saveProject"
                    onClick={() => onSaveProject({ exit: false })}
                    disabled={!isChanged}
                />
            </Buttons>
        </Topbar>
    );
};

export interface ISimulationTopbarContainerProps {
    projectName: string;
    fixed: boolean;
    isChanged: boolean;
    onExit(): void;
    onSaveProject({ exit: boolean }): void;
}

export default SimulationTopbarContainer;
