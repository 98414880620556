import styled from 'styled-components';
import { Column, Button } from '../../UI';
import { Editor as HtmlEditor } from '../../UI';

export const StyledColumn = styled(Column)`
    padding: 10px 0;
`;

export const Buttons: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 24px;
`;

Buttons.Save = styled(Button)`
    &.ui.button {
        margin-left: 32px;
    }
`;

Buttons.Cancel = styled(Button)``;

export const Editor = styled(HtmlEditor)`
    .ql-editor {
        height: 475px;
        overflow: auto;
    }
`;
