import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Empty } from '../../../../../../components/UI';
import { Polymer } from '../../../../../../models/polymer.model';
import Expanded from './Expanded';
import Compacted from './Compacted';
import { IWizardContext } from '../../../../../../components/UI';
import Search from '../../../../../../models/search.model';

class StepPolymerContainer extends React.Component<
    StepPolymerContainerProps,
    StepPolymerContainerState
> {
    constructor(props) {
        super(props);
        this.state = {
            polymersList: []
        };
    }

    private savePolymersList = (polymersList: Polymer[]) => {
        this.setState({ polymersList });
    };

    render() {
        const { polymersList } = this.state;
        const { currentStep, stepNumber, stepProgress } = this.props;

        if (currentStep !== stepNumber && stepProgress > stepNumber) {
            return (
                <section id="StepPolymer">
                    <Compacted polymersList={polymersList} />
                </section>
            );
        }

        if (currentStep !== stepNumber) {
            return (
                <section id="StepPolymer">
                    <Empty />
                </section>
            );
        }

        return (
            <section id="StepPolymer">
                <Expanded
                    polymersList={polymersList}
                    savePolymersList={this.savePolymersList}
                    {...this.props}
                />
            </section>
        );
    }

    componentWillMount() {
        const { initialValues } = this.props;
        this.setState({ polymersList: initialValues });
    }
}

export interface StepPolymerContainerProps
    extends WithTranslation,
        IWizardContext {
    initialValues: Polymer[];
    stepNumber: number;
    polymers: Polymer[];
    loading: boolean;
    limit: number;
    startSearch: number;
    onSearchChange(item: Search, callback: Function): void;
    onChange?(polymers: Polymer[]): void;
    onNext(polymersList: Polymer[]): void;
}

export interface StepPolymerContainerState {
    polymersList: Polymer[];
}

export default withTranslation('app')(StepPolymerContainer);
