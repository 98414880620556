import React from 'react';
import { LineChart } from '../../../../../components/charts';
import {
    Container,
    Header,
    Title,
    Hint,
    Legend,
    SolventContainer,
    SolventColor,
    SolventSpan,
    SimularionName,
    GraphHeaderContanier,
    GraphHeader,
    LabelContainer,
    LabelGreen,
    LabelRED,
    PolymersEvaporation
} from './index.styled';
import pallet from '../index.pallet.json';
import SimulationResultWithConfigs from '../../../../../models/simulationResultWithConfigs.model';

const EvaporationGraph = ({ simulations }: Props) => {
    const renderLegend = () => {
        return simulations.map((simulation, index) => (
            <React.Fragment key={index}>
                <SimularionName>{simulation.name}</SimularionName>
                <Legend>
                    {simulation.result.graphs.polymer_normalized_distance.map(
                        (polymer, index) => (
                            <SolventContainer key={polymer.id}>
                                <SolventColor
                                    styledProps={{
                                        color:
                                            simulation.selected === 1
                                                ? pallet.polymer.current[index]
                                                : pallet.polymer.proposal[
                                                      index
                                                  ],
                                        current: simulation.selected === 1
                                    }}
                                />
                                <SolventSpan>{polymer.name}</SolventSpan>
                            </SolventContainer>
                        )
                    )}
                </Legend>
            </React.Fragment>
        ));
    };

    return (
        <Container className="charts">
            <Header>
                <Title i18nKey="evaporationGraph.title" />
                <Hint
                    name="info-circle"
                    size={19}
                    i18nKey="evaporationGraph.hint"
                    data-html2canvas-ignore
                />
            </Header>
            {renderLegend()}
            <GraphHeaderContanier>
                <GraphHeader i18nKey={'evaporationGraph.standardDistance'} />
                <LabelContainer>
                    <SolventSpan renderText={'RED'} />
                    <LabelRED renderText={'> 1,0'} />
                    <SolventSpan renderText={'='} />
                    <LabelRED i18nKey={'evaporationGraph.insoluble'} />
                    <SolventSpan renderText={'| RED'} />
                    <LabelGreen renderText={'<= 1,0'} />
                    <SolventSpan renderText={'='} />
                    <LabelGreen i18nKey={'evaporationGraph.soluble'} />
                </LabelContainer>
            </GraphHeaderContanier>
            <LineChart simulations={simulations} colors={pallet.polymer} />
            <PolymersEvaporation
                i18nKey={'evaporationGraph.polymersEvaporation'}
            />
        </Container>
    );
};

interface Props {
    simulations: SimulationResultWithConfigs[];
}

export default EvaporationGraph;
