import styled from 'styled-components';
import { Icon, Span } from '../../../../../components/UI';

export const ReportInfo: any = styled.div`
    display: flex;
    align-items: center;
`;

ReportInfo.Icon = styled(Icon)`
    font-size: 32px;
    margin-right: 5px;
`;

ReportInfo.Span = styled(Span)`
    color: #444444;
    font-size: 14px;
    line-height: 24px;
`;
