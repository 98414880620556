import React from 'react';
import PolymersList from '../PolymersList';
import { Polymer } from '../../../../../../../models/polymer.model';

const Compacted = ({ polymersList }: Props) => (
    <PolymersList polymers={polymersList} />
);

export interface Props {
    polymersList: Polymer[];
}

export default Compacted;
