import styled from 'styled-components';
import { Table } from '../../../../../../../../components/UI';

export const Container = styled.section`
    padding: 48px;
`;

export const DataList: any = styled(Table)`
    min-height: 100px;
    max-height: 300px;
    overflow-y: auto;
`;

DataList.Header = styled(Table.Header)`
    background-color: rgba(166, 166, 166, 0.08);
`;

DataList.HeaderRow = styled(Table.Row)``;

DataList.HeaderCell = styled(Table.HeaderCell)`
    color: #444444 !important;
    font-weight: 600 !important;
    padding: 14px !important;
`;

DataList.Body = styled(Table.Body)``;

DataList.Row = styled(Table.Row)``;

DataList.Cell = styled(Table.Cell)`
    padding: 14px !important;
    font-size: 14px !important;
`;
