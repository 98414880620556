import React from 'react';
import {
    Container,
    Title,
    Subtitle,
    SearchContainer,
    DataList,
    NewGroup,
    DataListResources,
    Data
} from './index.styled';
import {
    Line,
    Grid,
    Row,
    Column,
    Input,
    Pagination,
    IconButton,
    Span,
    confirmDelete
} from '../../../../../../components/UI';
import { Link, RouteComponentProps } from 'react-router-dom';
import Group from '../../../../../../models/group.model';
import { removeObjectReference } from '../../../../../../helpers/util';

class GroupsContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            onHover: null,
            currentPage: 1,
            selecteds: []
        };
    }

    private handleMouseEnter = (id: number) => {
        this.setState({ onHover: id });
    };

    private handleMouseLeave = () => {
        this.setState({ onHover: null });
    };

    private addSelected = (id: number) => {
        const { selecteds } = this.state;
        this.setState({ selecteds: [...selecteds, id] });
    };

    private removeSelected = (id: number) => {
        const { selecteds } = this.state;
        const index = selecteds.findIndex(item => item === id);
        selecteds.splice(index, 1);
        this.setState({ selecteds });
    };

    private deleteGroup = group => {
        const { deleteGroup, limit, searchGroups, groups } = this.props;
        const { value, currentPage } = this.state;
        return new Promise(resolve => {
            confirmDelete(
                group.name,
                () => {
                    deleteGroup(group, () => {
                        const { selecteds } = this.state;
                        let page = currentPage;
                        const groupIndex = selecteds.findIndex(
                            id => id === group.id
                        );
                        selecteds.splice(groupIndex, 1);
                        if (groups.length === 1 && currentPage > 1) {
                            page = currentPage - 1;
                        }
                        this.setState({ selecteds, currentPage: page }, () => {
                            const search = {
                                q: value,
                                limit,
                                page
                            };
                            searchGroups(search);
                            resolve();
                        });
                    });
                },
                resolve
            );
        });
    };

    private deleteAllSelecteds = async () => {
        const { selecteds } = this.state;
        const { groups } = this.props;
        for await (const id of removeObjectReference(selecteds)) {
            const group = groups.find(group => group.id === id);
            if (group) {
                await this.deleteGroup(group);
            }
        }
    };

    private handleSearchChange = e => {
        const { searchGroups, limit } = this.props;
        const value = e.target.value;
        const search = {
            q: value,
            limit,
            page: 1
        };
        this.setState({ value }, () => searchGroups(search));
    };

    private handlePageChange = page => {
        const { searchGroups, limit } = this.props;
        const { value } = this.state;
        const search = {
            q: value,
            limit,
            page: page + 1
        };
        this.setState(
            {
                currentPage: page + 1
            },
            () => searchGroups(search)
        );
    };

    private selectAll = () => {
        const { groups } = this.props;
        this.setState({
            selecteds: groups.map(group => group.id)
        });
    };

    private unselectAll = () => {
        this.setState({
            selecteds: []
        });
    };

    private openGroup = id => {
        const { history } = this.props;
        history.push(`/master/groups/group/${id}`);
    };

    private createRow(group: Group) {
        const { onHover, selecteds } = this.state;
        const { id, customer_email, name, count_users } = group;
        return (
            <DataList.Row
                key={id}
                onClick={() => this.openGroup(id)}
                onMouseEnter={() => this.handleMouseEnter(id)}
                onMouseLeave={this.handleMouseLeave}
            >
                <DataList.Cell textAlign="center">
                    <DataList.Checkbox
                        onClick={e => e.stopPropagation()}
                        value={
                            selecteds.find(item => item === id) ? true : false
                        }
                        onChange={(_, { checked }) =>
                            checked
                                ? this.addSelected(id)
                                : this.removeSelected(id)
                        }
                    />
                </DataList.Cell>
                <DataList.Cell>{name}</DataList.Cell>
                <DataList.Cell>{customer_email}</DataList.Cell>
                <DataList.Cell textAlign="center">
                    {onHover !== id ? (
                        count_users
                    ) : (
                        <DataList.DeleteBtn
                            onClick={e => {
                                e.stopPropagation();
                                this.deleteGroup(group);
                            }}
                            iconName="trash-alt"
                            iconSize={18}
                        />
                    )}
                </DataList.Cell>
            </DataList.Row>
        );
    }

    private renderGroups() {
        const { groups } = this.props;
        return groups.map(group => this.createRow(group));
    }

    render() {
        const { loading, groups, paging } = this.props;
        const { selecteds, currentPage } = this.state;
        return (
            <Container>
                <Grid>
                    <Row>
                        <Column xs={12} sm={6} md={6} lg={6}>
                            <Title i18nKey="master.groups.title" />
                        </Column>
                        <Column
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            flex={{ align: 'center', justify: 'end' }}
                        >
                            <Link to="/master/groups/group">
                                <NewGroup i18nKey="master.groups.newGroup" />
                            </Link>
                        </Column>
                    </Row>
                </Grid>
                <Line />
                <SearchContainer>
                    <Input
                        icon="search"
                        placeholder="master.groups.search.placeholder"
                        onChange={this.handleSearchChange}
                    />
                </SearchContainer>
                <Subtitle i18nKey="master.groups.subtitle" />
                <Line />
                <DataListResources>
                    <Grid>
                        <Row>
                            <Column
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                flex={{ align: 'center', justify: 'start' }}
                            >
                                <DataListResources.Checkbox
                                    indeterminate={
                                        selecteds.length > 0 &&
                                        selecteds.length < groups.length
                                    }
                                    value={
                                        selecteds.length === groups.length &&
                                        groups.length > 0
                                    }
                                    onClick={() => {
                                        selecteds.length !== groups.length &&
                                        groups.length > 0
                                            ? this.selectAll()
                                            : this.unselectAll();
                                    }}
                                />
                                {selecteds.length > 0 && (
                                    <IconButton
                                        onClick={this.deleteAllSelecteds}
                                        iconName="trash-alt"
                                        iconSize={18}
                                    />
                                )}
                            </Column>
                            <Column
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                flex={{ align: 'center', justify: 'end' }}
                            >
                                <Pagination
                                    forcePage={currentPage - 1}
                                    pageCount={paging.total_pages}
                                    handlePageClick={({ selected }) =>
                                        this.handlePageChange(selected)
                                    }
                                />
                            </Column>
                        </Row>
                    </Grid>
                </DataListResources>
                <Data>
                    <DataList>
                        <DataList.Header>
                            <DataList.HeaderRow>
                                <DataList.HeaderCell
                                    width={1}
                                    textAlign="center"
                                />
                                <DataList.HeaderCell width={5}>
                                    <Span i18nKey="master.groups.table.header.groupName" />
                                </DataList.HeaderCell>
                                <DataList.HeaderCell width={5}>
                                    <Span i18nKey="master.groups.table.header.renponsibleEmail" />
                                </DataList.HeaderCell>
                                <DataList.HeaderCell
                                    width={1}
                                    textAlign="center"
                                >
                                    <DataList.UsersIcon name="user" />
                                </DataList.HeaderCell>
                            </DataList.HeaderRow>
                        </DataList.Header>
                        <DataList.Body>{this.renderGroups()}</DataList.Body>
                    </DataList>
                    {loading && (
                        <Data.LoadingOverlay>
                            <Data.Loading />
                        </Data.LoadingOverlay>
                    )}
                </Data>
            </Container>
        );
    }
}

interface Props extends RouteComponentProps {
    limit: number;
    groups: Group[];
    loading: boolean;
    paging: {
        total_items: number;
        total_pages: number;
    };
    searchGroups(search, callback?): void;
    deleteGroup(group: Group, callback?: Function): void;
}

interface State {
    value: string;
    onHover: number | null;
    currentPage: number;
    selecteds: number[];
}

export default GroupsContainer;
