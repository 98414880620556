import React from 'react';
import { Modal } from './index.styled';
import { ApplicationState } from '../../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTermConditions } from '../../../redux/actions/content.actions';
import connectWithStore from '../../hoc/connectWithStore';
import ReactHtmlParser from 'react-html-parser';
import { Loading } from '../../UI';
import { Term } from '../../../models/term.model';

class TermsOfUseModal extends React.Component<Props> {

    render() {
        const { termsConditions, loading } = this.props;;
        return (
            <Modal>
                <Modal.Title
                    i18nKey={
                        !termsConditions
                            ? 'forms.signUp.modals.termsConditionsAndPrivacyPolicy.title'
                            : termsConditions.title
                    }
                />
                <Modal.Text>
                    {loading || !termsConditions ? (
                        <Loading />
                    ) : (
                            ReactHtmlParser(termsConditions.body)
                        )}
                </Modal.Text>
            </Modal>
        );
    }

    componentWillMount() {
        const { getTermConditions } = this.props;
        getTermConditions();
    }
}

interface Props {
    termsConditions: Partial<Term> | null;
    loading: boolean;
    getTermConditions(): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    termsConditions: state.content.termConditions,
    loading: state.content.loading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTermConditions
        },
        dispatch
    );

export default connectWithStore(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(TermsOfUseModal)
);
