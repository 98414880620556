import styled from 'styled-components';
import { Span, Icon } from '../../../../../../../components/UI';

export const Container = styled.div`
    min-height: 250px;
    width: 100%;
    color: black;
    display: flex;
    justify-content: center;
    flex-flow: column;
    padding-left: 15px;

    @media (max-width: 480px) {
        min-height: 100px;
    }
`;

export const List: any = styled.ul`
    width: 100%;
    max-height: 250px;
    overflow: auto;
    padding-right: 10px;
    box-sizing: border-box;
`;

List.Item = styled.li`
    display: flex;
    width: 100%;
    padding: 7.5px 0;
    ${props => `opacity: ${props['data-selected']}`}
`;

export const ItemColor = styled.div`
    height: 20px;
    width: 20px;
    min-width: 20px;
    border-radius: 3.5px;
    display: inline-block;
    margin-right: 10px;
    ${props => `background-color: ${props['data-color']}`}
`;

export const ItemName = styled(Span)`
    font-size: 12px;
    display: inline-block;
    line-height: 20px;
`;

export const Hint = styled(Icon)`
    display: inline-block;
    margin-left: 5px;
`;
