import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import StepPolymerContainer from './container';
import { ApplicationState } from '../../../../../redux/store';
import { filterPolymersRequest } from '../../../../../redux/actions/polymers.actions';
import { setPolymers } from '../../../../../redux/actions/simulation.actions';
import { SimulationContext } from '..';
import { Polymer } from '../../../../../models/polymer.model';
import Search from '../../../../../models/search.model';

const StepPolymer = ({
    polymers,
    filterPolymers,
    setPolymers,
    loading,
    initialValues
}: Props) => {
    const handleOnSearchChange = (item: Search, callback: Function) => {
        filterPolymers([item.id], callback);
    };
    return (
        <SimulationContext.Consumer>
            {context => (
                <StepPolymerContainer
                    initialValues={initialValues}
                    stepNumber={1}
                    polymers={polymers}
                    onSearchChange={handleOnSearchChange}
                    onNext={setPolymers}
                    loading={loading}
                    limit={10}
                    startSearch={2}
                    {...context}
                />
            )}
        </SimulationContext.Consumer>
    );
};

const mapStateToProps = (state: ApplicationState) => ({
    initialValues: state.simulation.config.polymers,
    polymers: state.polymers.items,
    loading: state.polymers.loading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            filterPolymers: filterPolymersRequest,
            setPolymers
        },
        dispatch
    );

interface Props {
    polymers: Polymer[];
    loading: boolean;
    initialValues: Polymer[];
    filterPolymers(ids: number[], callback: Function): void;
    setPolymers(polymers: Polymer[]): void;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StepPolymer);
