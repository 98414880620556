import React from "react";
import { Field as FormikField } from "formik";
import { InputRadio, Container } from "./index.styled";

import { Label, IInputProps } from "..";

export const Radio = ({
    name,
    validate,
    disabled,
    i18nKey,
    value,
    changeValue
}: Props) => {
    return (
        <FormikField name={name} validate={validate}>
            {({ field, form }) => {
                const { setFieldValue, setFieldTouched } = form;
                const hasError: string | undefined =
                    form && form.errors[field!.name]
                        ? form.errors[field!.name]
                        : undefined;
                const hasTouched = form
                    ? Boolean(form.touched[field!.name])
                    : false;
                return (
                    <Container>
                        <InputRadio
                            value={value}
                            checked={value === form.values[name]}
                            onChange={(_, data) => {
                                changeValue(data.value);
                                setFieldValue(name, data.value);
                                setFieldTouched(name);
                            }}
                            disabled={disabled}
                            error={hasError && hasTouched}
                        />
                        <Label disabled={disabled} i18nKey={i18nKey} />
                    </Container>
                );
            }}
        </FormikField>
    );
};

export interface Props extends IInputProps {
    name: string;
    validate?: Function;
    changeValue: Function;
}
