import { action } from 'typesafe-actions';
import { UsersTypes } from '../types/users.types';
import { User } from '../../models/user.model';
import { dialog } from '../../components/UI';

export const searchUser = (search, callback) =>
    action(UsersTypes.SEARCH_USER_REQUEST, { search, callback });

export const searchUserSuccess = data => {
    return action(UsersTypes.SEARCH_USER_SUCCESS, data);
};

export const searchUserError = () => {
    return action(UsersTypes.SEARCH_USER_FAILURE);
};

export const deleteUser = (user: User, callback?: Function) =>
    action(UsersTypes.DELETE_USER_REQUEST, { user, callback });

export const deleteUserSuccess = (user: User) => {
    dialog({
        type: 'success',
        i18nKey: {
            name: 'dialogs.delete.success',
            vars: { name: user.display_name ? user.display_name : user.email }
        }
    });
    return action(UsersTypes.DELETE_USER_SUCCESS);
};

export const deleteUserError = (user: User) => {
    dialog({
        type: 'error',
        i18nKey: {
            name: 'dialogs.delete.error',
            vars: { name: user.display_name ? user.display_name : user.email }
        }
    });
    return action(UsersTypes.DELETE_USER_FAILURE);
};

export const takeUser = (id: number) =>
    action(UsersTypes.TAKE_USER_REQUEST, id);

export const takeUserSuccess = payload =>
    action(UsersTypes.TAKE_USER_SUCCESS, payload);

export const takeUserError = () => {
    dialog({
        type: 'error',
        i18nKey: 'i18nkey'
    });
    return action(UsersTypes.TAKE_USER_FAILURE);
};

export const saveUser = (user: User, callback?: Function) => {
    return action(UsersTypes.SAVE_USER_REQUEST, { user, callback });
};

export const saveUserSuccess = () => {
    dialog({
        type: 'success',
        i18nKey: 'redux.actions.users.save.success'
    });
    return action(UsersTypes.SAVE_USER_SUCCESS);
};

export const saveUserError = () => {
    dialog({
        type: 'error',
        i18nKey: 'redux.actions.users.save.error'
    });
    return action(UsersTypes.SAVE_USER_FAILURE);
};
