import * as yup from 'yup';

yup.setLocale({
    mixed: {
        notType: _ref => {
            switch (_ref.type) {
                case 'number':
                    return 'typeNumber';
                case 'string':
                    return 'typeString';
                default:
                    return 'typeCustom';
            }
        },
        required: 'required'
    },
    number: {
        positive: 'positive',
        min: 'min/${min}',
        max: 'max/${max}'
    },
    string: {
        email: 'email',
        min: 'minChar/${min}',
        max: 'maxChar/${max}'
    }
});

yup.addMethod<yup.StringSchema>(yup.string, 'phone', function() {
    const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
    return this.test('phone', 'phone', value => rePhoneNumber.test(value));
});
