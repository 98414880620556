import styled from 'styled-components';
import { Row as DefaultRow } from '../../UI';

export const Row = styled(DefaultRow)`
    margin-bottom: 15px;
`;

export const CharacteristicsStep = styled.div`
    padding-bottom: 30px;
`;
