import React from 'react';
import { Grid, Empty } from '../../../../../../components/UI';
import { Container } from './index.styled';
import MixtureDialog from './MixtureDialog';
import PolymerDialog from './PolymerDialog';
import SolventDialog from './SolventDialog';
import { SolubilityObjectType } from '../../../../../../models/solubilityObjectType.enum';
import { SimulationName } from '../../../../../../components/charts/Tooltip/index.styled';

const Definitions = ({ mouse, object }: Props) => {
    const { userData } = object;
    switch (object.userData.type) {
        case SolubilityObjectType.Mixture:
            return (
                <Container {...mouse}>
                    <Grid padding={5}>
                        <SimulationName renderText={userData.simulationName} />
                        <MixtureDialog mixture={userData.mixture} />
                    </Grid>
                </Container>
            );
        case SolubilityObjectType.Polymer:
            return (
                <Container {...mouse}>
                    <Grid padding={5}>
                        <SimulationName renderText={userData.simulationName} />
                        <PolymerDialog
                            name={userData.name}
                            color={userData.color}
                            position={userData.position}
                            size={userData.size}
                        />
                    </Grid>
                </Container>
            );
        case SolubilityObjectType.Solvent:
            return (
                <Container {...mouse}>
                    <Grid padding={5}>
                        <SimulationName renderText={userData.simulationName} />
                        <SolventDialog
                            name={userData.name}
                            color={userData.color}
                            position={userData.position}
                            ter={userData.ter}
                            mixture={userData.mixture}
                        />
                    </Grid>
                </Container>
            );
        default:
            return <Empty />;
    }
};

interface Props {
    mouse;
    object;
}

export default Definitions;
