import { action } from 'typesafe-actions';
import { SimulationTypes } from '../types/simulation.types';
import { Polymer } from '../../models/polymer.model';
import { outTranslator, showToastr } from '../../helpers/util';
import { ConditionsConfig } from '../../models/simulationConfig.model';
import store from '../store';
import SimulationRequestConfig from '../../models/simulationRequestConfig.model';
import { dialog } from '../../components/UI';
import SimulationResultWithConfigs from '../../models/simulationResultWithConfigs.model';
import { FormulationSolvent } from '../../models/solvent.model';

export const simulationRequest = () => {
    const state = store.getState();
    const config = state.simulation.config;
    const request: SimulationRequestConfig = {
        polymers: config.polymers.map(polymer => ({ id: polymer.id })),
        solvents: config.solvents.map(solvent => ({
            id: solvent.id,
            concentration: solvent.concentration!
        })),
        conditions: config.conditions,
        is_volume: config.is_volume!
    };
    const rest = {
        is_value_per_liter: config.is_value_per_liter
    };
    return action(SimulationTypes.SIMULATE_REQUEST, { request, rest });
};

export const simulationSuccess = data => {
    showToastr(
        'success',
        outTranslator('redux.actions.simulation.success_simulate')
    );
    return action(SimulationTypes.SIMULATE_SUCCESS, data);
};

export const simulationError = () => {
    showToastr(
        'error',
        outTranslator('redux.actions.simulation.error_simulate')
    );
    return action(SimulationTypes.SIMULATE_FAILURE);
};

export const setPolymers = (polymers: Polymer[]) => ({
    type: SimulationTypes.SET_POLYMERS,
    payload: polymers
});

export const setFormulation = ({ data }) => {
    const state = store.getState();
    const is_volume = state.simulation.config.is_volume;
    const is_value_per_liter = state.simulation.config.is_value_per_liter;
    return action(SimulationTypes.SET_FORMULATION, {
        solvents: data,
        is_volume,
        is_value_per_liter
    });
};

export const setConditions = (conditions: ConditionsConfig) =>
    action(SimulationTypes.SET_CONDITIONS, conditions);

export const deleteCurrentSimulation = (
    simulation: SimulationResultWithConfigs
) => {
    return action(SimulationTypes.DELETE_SIMULATION, simulation);
};

export const deleteCurrentSimulationSuccess = ({ name, reducer }) => {
    dialog({
        type: 'success',
        i18nKey: {
            name: 'dialogs.delete.success',
            vars: { name }
        }
    });
    return action(SimulationTypes.DELETE_SIMULATION_SUCCESS, reducer);
};

export const deleteCurrentSimulationError = name => {
    dialog({
        type: 'error',
        i18nKey: {
            name: 'dialogs.delete.error',
            vars: { name }
        }
    });
    return action(SimulationTypes.DELETE_SIMULATION_ERROR, name);
};

export const selectSimulation = (simulation: SimulationResultWithConfigs) => {
    return action(SimulationTypes.SELECT_SIMULATION, simulation);
};

export const selectSimulationSuccess = ({ reducer }) => {
    return action(SimulationTypes.SELECT_SIMULATION_SUCCESS, reducer);
};

export const selectSimulationError = name => {
    dialog({
        type: 'error',
        i18nKey: {
            name: 'simulationResultCard.dialog.select.error',
            vars: { name }
        }
    });
    return action(SimulationTypes.SELECT_SIMULATION_ERROR, name);
};

export const changeSimulationName = (date: Date | string, name: string) => {
    const simulations = store.getState().simulation.project!.simulations;
    const simulation = simulations.find(simulation => simulation.date === date);
    if (simulation) {
        simulation.name = name;
    }
    return action(SimulationTypes.REFRESH_RESULTS, simulations);
};

export const newSimulation = () => {
    return action(SimulationTypes.NEW_SIMULATION, {});
};

export const newSimulationSuccess = () => {
    const state = store.getState();
    const results = state.simulation.project!.simulations.map(simulation => {
        simulation.selected = 0;
        return simulation;
    });
    return action(SimulationTypes.NEW_SIMULATION_SUCCESS, { results });
};

export const addSolventToFormulation = (solvent: FormulationSolvent) =>
    action(SimulationTypes.ADD_SOLVENT_TO_FORMULATION, solvent);

export const removeSolventToFormulation = (id: number) => {
    const state = store.getState();
    const solvents = state.simulation.config.solvents.filter(
        item => item.id !== id
    );

    return action(SimulationTypes.REMOVE_SOLVENT_TO_FORMULATION, solvents);
};
export const setSolventConcentration = ({ id, value }) => {
    const state = store.getState();
    const solvents = state.simulation.config.solvents.map(item => {
        if (item.id === id) {
            return {
                ...item,
                concentration: value
            };
        }
        return item;
    });
    return action(SimulationTypes.SET_SOLVENT_CONCENTRATION, solvents);
};

export const setSolventCost = ({ id, value }) => {
    const state = store.getState();
    const solvents = state.simulation.config.solvents.map(item => {
        if (item.id === id) {
            return {
                ...item,
                cost: value
            };
        }
        return item;
    });
    return action(SimulationTypes.SET_SOLVENT_COST, solvents);
};

export const switchUnitType = type => {
    const isVolume = type === 'VOLUME' ? true : false;
    return action(SimulationTypes.SWITCH_UNIT_TYPE, isVolume);
};

export const switchCostUnit = type => {
    const isValuePerLiter = type === 'VOLUME' ? true : false;
    return action(SimulationTypes.SWITCH_COST_UNIT, isValuePerLiter);
};

export const compareSimulationsSuccess = payload => {
    return action(SimulationTypes.COMPARE_SIMULATIONS_SUCCESS, payload);
};

export const compareSimulations = (
    checkeds: { date: Date | string; reference: boolean }[],
    callback: Function
) => {
    callback();
    return action(SimulationTypes.COMPARE_SIMULATIONS, checkeds);
};
