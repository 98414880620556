import React from 'react';
import { Modal } from './index.styled';
import SaveProjectForm from '../../../forms/SaveProject';
import { Project } from '../../../../models/project.model';

const SaveProjectContainer = ({
    isEdit,
    callback,
    close,
    saveProject,
    project
}: Props) => {
    const handleSubmit = data => {
        close();
        saveProject({ ...project, ...data }, () => {
            if (callback) {
                callback();
            }
        });
    };

    return (
        <Modal>
            <Modal.Title
                i18nKey={isEdit ? 'saveProject.editTitle' : `saveProject.title`}
            />
            <SaveProjectForm project={project} onSubmit={handleSubmit}>
                {isValid => (
                    <Modal.Buttons>
                        <Modal.Buttons.Button
                            cl="invertedPrimary"
                            i18nKey="saveProject.buttons.cancel"
                            onClick={close}
                        />
                        <Modal.Buttons.SaveButton
                            cl="primary"
                            i18nKey={
                                isEdit
                                    ? 'save'
                                    : 'saveProject.buttons.saveProject'
                            }
                            type="submit"
                            loading={false}
                            disabled={!isValid}
                        />
                    </Modal.Buttons>
                )}
            </SaveProjectForm>
        </Modal>
    );
};

interface Props {
    project: Project;
    isEdit?: boolean;
    callback?(): void;
    saveProject(project: Project, callback?: Function): void;
    close(): void;
}

export default SaveProjectContainer;
