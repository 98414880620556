import { call, put } from 'redux-saga/effects';
import {
    filterPolymersError,
    filterPolymersSuccess
} from '../../actions/polymers.actions';
import api from '../../../services/api';
import { dialog } from '../../../components/UI';

export default function* filterPolymers(action) {
    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });
    loading.show();
    try {
        const { callback } = action.payload;
        const ids = action.payload.ids.join(',');
        const { data } = yield call(api.get, `/polymers?limit=100&ids=${ids}`);
        if (data.object) {
            yield put(
                filterPolymersSuccess({ polymers: data.object, message: '' })
            );
            if (callback) {
                callback(data.object[0]);
            }
        } else {
            yield put(
                filterPolymersSuccess({
                    polymers: [],
                    message: data.messages.warnings[0]
                })
            );
            if (callback) {
                callback(data.object[0]);
            }
        }
    } catch (err) {
        yield put(filterPolymersError());
    }
    loading.close();
}
