import React from 'react';
import { Container } from './index.styled';
import { RouteComponentProps, Link } from 'react-router-dom';
import {
    Breadcrumb,
    confirmDelete,
    confirmCancel
} from '../../../../../../../../components/UI';
import { WithTranslation } from 'react-i18next';
import { User } from '../../../../../../../../models/user.model';
import MasterStep from '../../../../../../../../components/common/MasterStep';
import ProfileStep from './ProfileStep';
import JuridicalStep from './JuridicalStep';

class UserContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            user: props.user
        };
    }

    private handleDelete = () => {
        const { deleteUser, history } = this.props;
        const { user } = this.state;
        confirmDelete(user.email, () => {
            deleteUser(user, () => history.push('/master/users'));
        });
    };

    private setActive = (stepNumber: number) => {
        this.setState({ active: stepNumber });
    };

    private handleEdit = (stepNumber: number) => {
        this.setState({ active: stepNumber });
    };

    private profileActiveOnSave = data => {
        const { saveUser } = this.props;
        const { user } = this.state;
        const request = {
            id: user.id,
            ...data
        };
        saveUser(request, () => {
            this.setState({ active: 0, user: { ...user, ...request } });
        });
    };

    private handleCancel = () => {
        confirmCancel(() => {
            this.setActive(0);
        });
    };

    private renderBreadcrumb() {
        const { t } = this.props;
        const { user } = this.state;
        return (
            <Breadcrumb>
                <Breadcrumb.Section>
                    <Link to="/master/users">
                        {t('master.users.user.breadcrumb.users')}
                    </Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section>{user.email}</Breadcrumb.Section>
            </Breadcrumb>
        );
    }

    render() {
        const { active, user } = this.state;
        return (
            <Container>
                {this.renderBreadcrumb()}
                <MasterStep
                    name="master.users.user.profile"
                    stepNumber={1}
                    active={active}
                    haveData
                    componentProps={{ user }}
                    component={ProfileStep}
                    onDeleteClick={this.handleDelete}
                    onEmptyClick={this.setActive}
                    onSave={this.profileActiveOnSave}
                    onCancel={this.handleCancel}
                    onEdit={this.handleEdit}
                />
                <MasterStep
                    name="master.users.user.juridical"
                    stepNumber={2}
                    active={active}
                    haveData
                    componentProps={{ user }}
                    component={JuridicalStep}
                />
            </Container>
        );
    }
}

interface Props extends RouteComponentProps, WithTranslation {
    user: User;
    deleteUser(user: User, callback?: Function): void;
    saveUser(user: User, callback?: Function): void;
}

interface State {
    active: number;
    user: User;
}

export default UserContainer;
