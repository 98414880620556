import { Reducer } from 'redux';
import { SolventsTypes, SolventsState } from '../../types/solvents.types';

const INITIAL_STATE: SolventsState = {
    items: [],
    loading: false,
    error: false,
    message: ''
};

const reducer: Reducer<SolventsState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SolventsTypes.FILTER_REQUEST:
            return { ...state, loading: true };
        case SolventsTypes.FILTER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                items: action.payload.solvents,
                message: action.payload.message
            };
        case SolventsTypes.FILTER_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                items: [],
                message: ''
            };
        default:
            return state;
    }
};

export default reducer;
