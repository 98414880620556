import React from 'react';
import { connect } from 'react-redux';
import PerformedContainer from './container';
import SimulationResultWithConfigs from '../../../../models/simulationResultWithConfigs.model';
import { bindActionCreators } from 'redux';
import { newSimulation } from '../../../../redux/actions/simulation.actions';
import { ApplicationState } from '../../../../redux/store';

const Performed = (props: Props) => <PerformedContainer {...props} />;

interface Props {
    simulations: SimulationResultWithConfigs[];
    pageLimit: number;
    newSimulation(): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    simulation: state.simulation
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            newSimulation
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Performed);
