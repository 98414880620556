import React from 'react';
import { StyledLoading } from './index.styled';

export const Loading = props => (
    <StyledLoading {...props}>
        <div />
        <div />
        <div />
        <div />
    </StyledLoading>
);
