import React from 'react';
import { DropdownItemProps } from 'semantic-ui-react';
import { Container, Content } from './index.styled';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Label, Select } from '../..';
import Search from '../../../../models/search.model';

class DocumentSearchContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            searchQuery: ''
        };
    }

    private removeFocusOfSelect = target => {
        const { limit, stateValues } = this.props;
        if (limit && stateValues.length === limit - 1) {
            setTimeout(() => target.blur(), 1);
        }
    };

    private handleSearchChange = (searchQuery: string) => {
        const { startSearch, onSearchChange } = this.props;
        if (searchQuery.length <= 40) {
            this.setState({ searchQuery }, () => {
                if (searchQuery.length >= startSearch) {
                    onSearchChange(searchQuery);
                }
            });
        }
    };

    private handleSelectChange = ({ target }, { value }) => {
        const { values, onChange } = this.props;
        const item = values.find(item => item.id === value);
        this.removeFocusOfSelect(target);
        this.setState({ searchQuery: '' });
        if (onChange) {
            onChange(item);
        }
    };

    private content = (item: Search) => {
        return (
            <Content>
                <Content.Name renderText={item.title} />
                {item.concentration && (
                    <Content.Item>
                        <Content.Item.Name i18nKey="search.concentration" />
                        <Content.Item.Result
                            renderText={`${item.concentration}%`}
                        />
                    </Content.Item>
                )}
                {item.synonymous && (
                    <Content.Item>
                        <Content.Item.Name i18nKey="search.synonymous" />
                        <Content.Item.Result renderText={item.synonymous} />
                    </Content.Item>
                )}
                {item.code && (
                    <Content.Item>
                        <Content.Item.Name renderText="CAS Number:" />
                        <Content.Item.Result renderText={item.code} />
                    </Content.Item>
                )}
            </Content>
        );
    };

    private convertValuesToDropdownList = (): DropdownItemProps[] => {
        const { values, stateValues } = this.props;
        const { searchQuery } = this.state;

        const arr: DropdownItemProps[] = [];

        if (!values) {
            return arr;
        }
        values.forEach(value => {
            if (stateValues.find(id => id === value.id) != null) {
                return;
            }
            arr.push({
                key: value.id,
                text: searchQuery,
                value: value.id,
                content: this.content(value)
            });
        });
        return arr;
    };

    render() {
        const { searchQuery } = this.state;
        const {
            loading,
            limit,
            startSearch,
            stateValues,
            i18nKey,
            noResultsMessage,
            placeHolder
        } = this.props;

        return (
            <Container>
                {i18nKey && <Label i18nKey={i18nKey} />}
                <Select
                    iconLeft
                    search
                    selectOnNavigation={false}
                    minCharacters={startSearch}
                    icon="search"
                    fluid
                    searchQuery={searchQuery}
                    loading={loading}
                    selectOnBlur={false}
                    disabled={stateValues.length === limit}
                    value={null}
                    placeholder={placeHolder}
                    options={this.convertValuesToDropdownList()}
                    onChange={this.handleSelectChange}
                    noResultsMessage={noResultsMessage(searchQuery)}
                    onSearchChange={(_, { searchQuery }) =>
                        this.handleSearchChange(searchQuery)
                    }
                />
            </Container>
        );
    }
}

export interface Props extends WithTranslation {
    id?: string;
    values: Search[];
    stateValues: number[];
    i18nKey?: string;
    noResultsMessage;
    placeHolder;
    limit?: number;
    loading: boolean;
    startSearch: number;
    onSearchChange(seachQuery: string);
    onChange?(value);
}

export interface State {
    searchQuery: string;
}

export default withTranslation('app')(DocumentSearchContainer);
