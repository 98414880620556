import { Reducer } from 'redux';
import { ContentState, ContentTypes } from '../../types/content.types';
const INITIAL_STATE: ContentState = {
    termConditions: null,
    loading: false
};

const reducer: Reducer<ContentState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case ContentTypes.GET_TERMS_CONDITIONS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case ContentTypes.GET_TERMS_CONDITIONS_FAILURE:
            return {
                ...state,
                loading: false
            };
        case ContentTypes.GET_TERMS_CONDITIONS_SUCCESS:
            return {
                ...state,
                termConditions: action.payload,
                loading: false
            };
        default:
            return state;
    }
};

export default reducer;
