import React from 'react';
import { IconButton } from '../../../../../../../../../../components/UI';
import DocumentSearch from '../../../../../../../../../../components/UI/DocumentSearch';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    htmlT,
    removeObjectReference
} from '../../../../../../../../../../helpers/util';
import { DataList } from '../../index.styled';
import { GroupPolymer } from '../../../../../../../../../../models/group.model';

export class Active extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            selecteds: []
        };
    }

    private removeItem = (id: number) => {
        const { selecteds } = this.state;
        const indexToRemove = selecteds.findIndex(value => value.id === id);
        selecteds.splice(indexToRemove, 1);
        this.setState({ selecteds });
    };

    private handleChange = item => {
        const { selecteds } = this.state;
        const polymer = {
            id: item.id,
            name: item.title,
            concentration: item.concentration
        };
        this.setState({ selecteds: [...selecteds, polymer] });
    };

    private isChanged = () => {
        const { values } = this.props;
        const { selecteds } = this.state;
        if (values.length !== selecteds.length) {
            return true;
        }
        let isChanged = false;
        values.forEach(value => {
            const finded = selecteds.find(selected => selected.id === value.id);
            if (!finded) {
                isChanged = true;
            }
        });
        return isChanged;
    };

    private createRow(polymer: GroupPolymer) {
        return (
            <DataList.Row key={polymer.id}>
                <DataList.Cell>
                    {polymer.name}{' '}
                    {polymer.concentration && `(${polymer.concentration}%)`}
                </DataList.Cell>
                <DataList.Cell>
                    <IconButton
                        onClick={() => this.removeItem(polymer.id)}
                        iconSize={20}
                        iconName="times-circle"
                    />
                </DataList.Cell>
            </DataList.Row>
        );
    }

    private renderDataRow = () => {
        const { selecteds } = this.state;
        return selecteds.map(value => this.createRow(value));
    };

    private renderSearch() {
        const { t } = this.props;
        const { selecteds } = this.state;
        const i18nNameRefer = t(
            'master.groups.group.steps.polymers.search.nameRefer'
        );
        const placeHolder = t('search.simulation.placeHolder', {
            i18nNameRefer
        });
        const noResultMessage = searchQuery =>
            htmlT(t, 'search.simulation.notFound', {
                name: `<b>${searchQuery}</b>`,
                i18nNameRefer
            });
        return (
            <DocumentSearch
                id="group_polymers_serach"
                type="POLYMER"
                startSearch={2}
                restricted
                stateValues={selecteds.map(data => data.id)}
                placeHolder={placeHolder}
                noResultsMessage={noResultMessage}
                onChange={this.handleChange}
            />
        );
    }

    render() {
        const { t, children, onSave } = this.props;
        const { selecteds } = this.state;
        return (
            <div>
                {this.renderSearch()}
                <DataList>
                    <DataList.Header>
                        <DataList.HeaderRow>
                            <DataList.HeaderCell width={11}>
                                {t(
                                    'master.groups.group.steps.polymers.polymersList.polymerName'
                                )}
                            </DataList.HeaderCell>
                            <DataList.HeaderCell width={1} textAlign="center" />
                        </DataList.HeaderRow>
                    </DataList.Header>
                    <DataList.Body>{this.renderDataRow()}</DataList.Body>
                </DataList>
                {children({
                    disableSave: !this.isChanged(),
                    onSave: () => onSave(selecteds)
                })}
            </div>
        );
    }

    componentWillMount() {
        const { values } = this.props;
        this.setState({ selecteds: removeObjectReference(values) });
    }
}

interface Props extends WithTranslation {
    values: GroupPolymer[];
    children;
    onSave(data: GroupPolymer[]): void;
}

interface State {
    selecteds: GroupPolymer[];
}

export default withTranslation('app')(Active);
