import React from 'react';
import DocumentSearchContainer from './container';
import { ApplicationState } from '../../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { searchRequest } from '../../../redux/actions/search.actions';
import SearchModel from '../../../models/search.model';

const DocumentSearch = ({
    searchRequest,
    onSearchChange,
    restricted,
    type,
    ...props
}: Props) => {
    const searchForItems = query => {
        const filter = { q: query, type, restricted };
        searchRequest(filter, onSearchChange);
    };
    return (
        <DocumentSearchContainer onSearchChange={searchForItems} {...props} />
    );
};

interface Props {
    id?: string;
    type: 'POLYMER' | 'SOLVENT';
    restricted?: boolean;
    values: SearchModel[];
    stateValues: number[];
    i18nKey?: string;
    noResultsMessage;
    placeHolder;
    limit?: number;
    loading: boolean;
    startSearch: number;
    searchRequest(search, callback): void;
    onSearchChange?(seachQuery: string);
    onChange?(value);
}

const mapStateToProps = (state: ApplicationState) => ({
    loading: state.search.loading,
    values: state.search.items
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            searchRequest
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentSearch);
