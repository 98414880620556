import { ComponentClass, FunctionComponent } from 'react';
import { RouteComponentProps, StaticContext } from 'react-router';

export default class Route {
    public path: string;

    public exact: boolean;

    public component:
        | ComponentClass<any, any>
        | FunctionComponent<any>
        | ComponentClass<RouteComponentProps<any, StaticContext, any>, any>
        | FunctionComponent<RouteComponentProps<any, StaticContext, any>>
        | undefined;

    constructor(
        path: string,
        exact: boolean,
        component:
            | ComponentClass<any, any>
            | FunctionComponent<any>
            | ComponentClass<RouteComponentProps<any, StaticContext, any>, any>
            | FunctionComponent<RouteComponentProps<any, StaticContext, any>>
            | undefined
    ) {
        this.path = path;
        this.exact = exact;
        this.component = component;
    }
}
