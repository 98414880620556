import Firebase from '../../configs/firebase';
import { dialog } from '../../components/UI';
import { action } from 'typesafe-actions';
import { UserTypes } from '../types/user.types';
import { outTranslator } from '../../helpers/util';
import api from '../../services/api';
import store from '../store';
import i18next from 'i18next';

const verifyUserExistsForSigIn = email => {
    return Firebase.auth.fetchSignInMethodsForEmail(email);
};

const verifyUserExistsForSignUp = async (
    email: string,
    phone_number: string
) => {
    const { data } = await api.post('user/exists', { email, phone_number });
    return data.object;
};

export const signIn = (email: string) => {
    return new Promise((resolve, reject) => {
        verifyUserExistsForSigIn(email).then(response => {
            if (response.length === 0) {
                dialog({
                    type: 'error',
                    header: { i18nKey: 'redux.actions.user.userNotFound' },
                    body: { i18nKey: 'redux.actions.user.registerToContinue' }
                });
                reject();
                return;
            }
            Firebase.auth
                .sendSignInLinkToEmail(email, {
                    url: `${process.env.REACT_APP_CURRENT_URL}/sign?email=${email}&lng=${i18next.language}`,
                    handleCodeInApp: true
                })
                .then(_ => {
                    resolve();
                })
                .catch(error => {
                    dialog({
                        type: 'error',
                        message: error
                    });
                    reject();
                });
        });
    });
};

export const signUp = (email: string, name: string, cellphone: string) => {
    return new Promise((resolve, reject) => {
        verifyUserExistsForSignUp(email, cellphone).then(response => {
            if (response) {
                dialog({
                    type: 'error',
                    i18nKey: 'redux.actions.user.userExists'
                });
                reject({ code: 'userExists' });
                return;
            }
            Firebase.auth
                .sendSignInLinkToEmail(email, {
                    url: `${process.env.REACT_APP_CURRENT_URL}/sign?email=${email}&name=${name}&cellphone=${cellphone}&lng=${i18next.language}`,
                    handleCodeInApp: true
                })
                .then(_ => {
                    resolve();
                })
                .catch(error => {
                    dialog({
                        type: 'error',
                        message: error
                    });
                    reject();
                });
        });
    });
};

export const completeSign = () => {
    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });
    if (Firebase.auth.isSignInWithEmailLink(window.location.href)) {
        const url = new URL(window.location.href);
        const email = url.searchParams.get('email');
        if (!email) {
            return;
        }
        loading.show();
        Firebase.auth.signOut();
        Firebase.auth
            .signInWithEmailLink(email, window.location.href)
            .then(_ => {
                loading.close();
            })
            .catch(() => {
                loading.close();
                debugger
                dialog({
                    type: 'error',
                    message: outTranslator('redux.actions.user.tokenExpired')
                });
            });
    }
};

export const recoveryEmail = (phone_number: string) =>
    action(UserTypes.RECOVERY_REQUEST, { phone_number });

export const recoveryEmailSuccess = email =>
    action(UserTypes.RECOVERY_SUCCESS, email);

export const recoveryEmailError = () => {
    dialog({
        type: 'error',
        message: outTranslator('redux.actions.user.recoverEmailError')
    });
    return action(UserTypes.RECOVERY_FAILURE);
};

export const recoveryEmailClear = () => action(UserTypes.RECOVERY_CLEAR);

export const recoverUser = callback => {
    store.dispatch(action(UserTypes.USER_REQUEST, { callback }));
};

export const recoverUserSuccess = user => action(UserTypes.USER_SUCCESS, user);

export const signOut = () => {
    window.localStorage.removeItem(process.env.REACT_APP_CURRENT_URL + '_SolsysLastRequestDateTime');
    Firebase.auth.signOut();
    return action(UserTypes.USER_LOGOUT);
};

export const recoverUserError = () => {
    dialog({
        type: 'error',
        message: outTranslator('redux.actions.user.recoverUserError')
    });
    return signOut();
};

export const clearUser = () => {
    store.dispatch(action(UserTypes.USER_CLEAR));
};
