import styled from 'styled-components';

export const Container = styled.section`
    padding: 48px;
`;

export const Data = styled.div`
    position: relative;
    margin: 30px 0;
    border: 0.5px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0px 0px 0 0 rgba(68, 68, 68, 0.2),
        1px 1px 4px 2px rgba(204, 204, 204, 0.15);
    padding: 24px;
`;
