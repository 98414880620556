import React from 'react';
import { useTranslation } from 'react-i18next';
import { SelectContainer, StyledSelect, RevertBtn } from './index.styled';
import { Empty } from '..';

export const Select = props => {
    const { loading, revert, ...rest } = props;
    const { t } = useTranslation('app');
    const renderRevertBtn = () => {
        if (revert) {
            return (
                <RevertBtn
                    iconName="redo"
                    iconSize={18}
                    tip={t('select.returnToDefault')}
                    onClick={revert}
                    mirror
                />
            );
        }
        return <Empty />;
    };
    return (
        <SelectContainer>
            {renderRevertBtn()}
            <StyledSelect
                {...rest}
                loading={loading}
                noResultsMessage={
                    loading ? `${t('loading')}...` : props.noResultsMessage
                }
            />
        </SelectContainer>
    );
};
