import React from 'react';
import { Grid, Row, Column, Span } from '../../../../../../../components/UI';
import { Compact, SpanDescription } from './index.styled';

const Compacted = ({ name, custom, moisture, temperature }: Props) => (
    <Compact>
        <Grid>
            <Row>
                <Column
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                    flex={{ align: 'start', justify: 'start' }}
                >
                    <SpanDescription
                        style={{ minWidth: '101px' }}
                        i18nKey="simulation.stepCondition.compact.typeOfPaint"
                    />
                    <Span
                        cl="primary"
                        i18nKey={
                            custom
                                ? [
                                      'simulation.stepCondition.compact.custom',
                                      { name }
                                  ]
                                : null
                        }
                        renderText={name}
                    />
                </Column>
                <Column xs={12} sm={12} md={3} lg={3}>
                    <SpanDescription i18nKey="simulation.stepCondition.compact.moisture" />
                    <Span cl="primary" renderText={`${moisture}%`} />
                </Column>
                <Column xs={12} sm={12} md={3} lg={3}>
                    <SpanDescription i18nKey="simulation.stepCondition.compact.temperature" />
                    <Span cl="primary" renderText={`${temperature}ºC`} />
                </Column>
            </Row>
        </Grid>
    </Compact>
);

interface Props {
    name: string;
    custom: boolean;
    moisture: number;
    temperature: number;
}

export default Compacted;
