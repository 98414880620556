import React from "react";
import StepConditionContainer from "./container";
import { Paint } from "../../../../../models/paint.model";
import { ApplicationState } from "../../../../../redux/store";
import { bindActionCreators } from "redux";
import { setConditions } from "../../../../../redux/actions/simulation.actions";
import { connect } from "react-redux";
import { SimulationContext } from "..";
import { ConditionsConfig } from "../../../../../models/simulationConfig.model";

class StepCondition extends React.Component<Props> {
    private handleOnNext = data => {
        const { setConditions } = this.props;
        const conditions: ConditionsConfig = {
            paint: {
                id: data.paint_type,
                custom: data.custom,
                surface_area: data.surface_area,
                film_thickness: data.thickness,
                volume_solids: data.sv,
                non_volatile: data.nv,
                paint_density: data.density
            },
            environment: {
                relative_humidity: data.moisture,
                temperature: data.temperature
            }
        };
        setConditions(conditions);
    };

    private convertToDataFormat = () => {
        const { initialValues } = this.props;
        if (
            !initialValues ||
            !initialValues.paint ||
            !initialValues.environment
        ) {
            return null;
        }
        return {
            paint_type: initialValues.paint.id,
            custom: initialValues.paint.custom,
            surface_area: initialValues.paint.surface_area,
            thickness: initialValues.paint.film_thickness,
            sv: initialValues.paint.volume_solids,
            nv: initialValues.paint.non_volatile,
            density: initialValues.paint.paint_density,
            moisture: initialValues.environment.relative_humidity,
            temperature: initialValues.environment.temperature
        };
    };

    render() {
        const { paints } = this.props;
        return (
            <SimulationContext.Consumer>
                {context => (
                    <StepConditionContainer
                        initialValues={this.convertToDataFormat()}
                        stepNumber={2}
                        loading={false}
                        paints={paints}
                        onNext={this.handleOnNext}
                        {...context}
                    />
                )}
            </SimulationContext.Consumer>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => ({
    paints: state.paints.items,
    loading: state.paints.loading,
    initialValues: state.simulation.config.conditions
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        { setConditions },
        dispatch
    );

interface Props {
    loading: boolean;
    paints: Paint[];
    initialValues: ConditionsConfig;
    setConditions(data): void;
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StepCondition);
