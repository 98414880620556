import { useTranslation } from 'react-i18next';
import { htmlT } from '../../../helpers/util';

// use (translate: string, t: i18next.TFunction)

export const Translator = ({ translate, render }: TranslatorProps) => {
    const { t } = useTranslation('app');
    return render(translate, (str, options) => htmlT(t, str, options));
};

interface TranslatorProps {
    id?: string;
    translate: string;
    render: any;
}
