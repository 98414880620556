import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { DataList } from '../../index.styled';
import { GroupSolvent } from '../../../../../../../../../../models/group.model';

const Data = ({ values, t }: Props) => {
    const createRow = (polymer: GroupSolvent) => {
        return (
            <DataList.Row key={polymer.id}>
                <DataList.Cell>{polymer.name}</DataList.Cell>
            </DataList.Row>
        );
    };

    const renderDataRows = () => {
        return values.map(value => createRow(value));
    };

    return (
        <DataList>
            <DataList.Header>
                <DataList.HeaderRow>
                    <DataList.HeaderCell width={12}>
                        {t(
                            'master.groups.group.steps.solvents.solventsList.solventName'
                        )}
                    </DataList.HeaderCell>
                </DataList.HeaderRow>
            </DataList.Header>
            <DataList.Body>{renderDataRows()}</DataList.Body>
        </DataList>
    );
};

interface Props extends WithTranslation {
    values: GroupSolvent[];
}

export default withTranslation('app')(Data);
