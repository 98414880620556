import React from 'react';
import { DataList } from '../../index.styled';
import { User } from '../../../../../../../../../../models/user.model';
import { withTranslation, WithTranslation } from 'react-i18next';

const Data = ({ values, t }: Props) => {
    const createRow = (user: Partial<User>) => {
        return (
            <DataList.Row key={user.id}>
                <DataList.Cell>{user.email}</DataList.Cell>
                <DataList.Cell>{user.display_name}</DataList.Cell>
            </DataList.Row>
        );
    };

    const renderUsersRow = () => {
        return values.map(value => createRow(value));
    };

    return (
        <DataList>
            <DataList.Header>
                <DataList.HeaderRow>
                    <DataList.HeaderCell width={5}>
                        {t(
                            'master.groups.group.steps.users.usersList.header.email'
                        )}
                    </DataList.HeaderCell>
                    <DataList.HeaderCell width={7}>
                        {t(
                            'master.groups.group.steps.users.usersList.header.name'
                        )}
                    </DataList.HeaderCell>
                </DataList.HeaderRow>
            </DataList.Header>
            <DataList.Body>{renderUsersRow()}</DataList.Body>
        </DataList>
    );
};

interface Props extends WithTranslation {
    values: Partial<User>[];
}

export default withTranslation('app')(Data);
