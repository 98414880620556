import React from 'react';
import { Container, User, Category } from './index.styled';
import { User as UserModel } from '../../../../models/user.model';
import { Link } from 'react-router-dom';
import { Empty } from '../../../../components/UI';
import { Privileges } from '../../../../models/privileges.enum';

class Menu extends React.Component<Props, {}> {
    private renderItems() {
        const { items, currentPath, user } = this.props;
        return items.map((category, index) => (
            <Category key={index}>
                <Category.Title i18nKey={category.title} />
                <Category.Items>
                    {category.items.map((item, index) =>
                        item.name &&
                        !Array.isArray(item.path) &&
                        user.privileges.find(
                            privilege => privilege === item.privilege
                        ) ? (
                            <Link to={item.path} key={index}>
                                <Category.Item
                                    className={
                                        currentPath.includes(item.path)
                                            ? 'active'
                                            : ''
                                    }
                                >
                                    <Category.Item.Icon name={item.icon} />
                                    <Category.Item.Name i18nKey={item.name} />
                                </Category.Item>
                            </Link>
                        ) : (
                            <Empty key={index} />
                        )
                    )}
                </Category.Items>
            </Category>
        ));
    }

    render() {
        const { user } = this.props;
        return (
            <Container>
                <User>
                    <User.Name>
                        <User.Key name="key-skeleton-alt" />
                        {user.display_name}
                    </User.Name>
                    <User.Role>Master Solsys Lite</User.Role>
                </User>
                {this.renderItems()}
            </Container>
        );
    }
}

interface Props {
    user: UserModel;
    currentPath: string;
    items: {
        title: string;
        items: {
            name?: string;
            icon?: string;
            path: string | string[];
            privilege: Privileges;
        }[];
    }[];
}

export default Menu;
