import { call, put } from "redux-saga/effects";
import {
    costEstimateSuccess,
    unitMeasureSuccess,
    costEstimateFailure,
    unitMeasureFailure
} from "../../actions/conversion.actions";

import {
    setSolventConcentration,
    switchUnitType
} from "../../actions/simulation.actions";
import api from "../../../services/api";

export function* conversionUnitMeasure(action) {
    try {
        const { data } = yield call(
            api.post,
            "functions/conversion_unit_measure",
            action.payload
        );
        if (data.object) {
            yield put(unitMeasureSuccess(data.object));
            yield put(
                switchUnitType(
                    data.object.measurement === "MASS" ? "VOLUME" : "MASS"
                )
            );
            for (let i = 0; i < data.object.conversions.length; i++) {
                yield put(
                    setSolventConcentration({
                        id: data.object.conversions[i].id,
                        value: data.object.conversions[i].concentration
                    })
                );
            }
        } else {
            yield put(unitMeasureSuccess({}));
        }
    } catch (err) {
        yield put(unitMeasureFailure(err));
    }
}

export function* costEstimate(action) {
    try {
        const { data } = yield call(
            api.post,
            "functions/cost_estimate",
            action.payload
        );

        if (data.object) {
            yield put(costEstimateSuccess(data.object.total_cost));
        } else {
            yield put(costEstimateSuccess({}));
        }
    } catch (err) {
        yield put(costEstimateFailure(err));
    }
}
