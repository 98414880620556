import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import logo from '../../../assets/images/logo.png';
import { outTranslator } from '../../../helpers/util';

const imageToBase64 = src => {
    const image = new Image();
    image.src = src;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    //@ts-ignore
    ctx.drawImage(image, 0, 0);
    return canvas.toDataURL('image/png');
};

const renderHeader = (projectName, pdf, pageHeight) => {
    const width = pdf.internal.pageSize.getWidth();
    const logo64 = imageToBase64(logo);
    pdf.addImage(logo64, 'PNG', 20, pageHeight + 3, 36, 18.4);
    pdf.setTextColor('#763689');
    pdf.setFontSize(10);
    pdf.text('solsys.solvay.com', width - 40, pageHeight + 14);
    pageHeight += 30;

    pdf.setLineWidth(0.5);
    pdf.setDrawColor(229, 229, 229);
    pdf.line(20, pageHeight, 200, pageHeight);
    pageHeight += 3;
    pdf.setTextColor('#444444');
    pdf.setFontSize(14);
    pdf.setFontType('bold');
    pdf.text(projectName, 20, pageHeight + 5);

    pdf.setTextColor('#444444');
    pdf.setFontSize(10);
    pdf.setFontType('regular');
    const date = moment(new Date()).format('DD[.]MM[.]YYYY [-] HH[:]mm');
    pdf.text(date, width - 40, pageHeight + 5);

    pageHeight += 10;
    pdf.setLineWidth(0.5);
    pdf.setDrawColor(229, 229, 229);
    pdf.line(20, pageHeight, 200, pageHeight);

    return pageHeight;
};

const renderFooter = pdf => {
    const height = pdf.internal.pageSize.getHeight();
    const width = pdf.internal.pageSize.getWidth();
    pdf.setTextColor('#444444');
    pdf.setFontSize(8);
    pdf.text(
        outTranslator('simulation.export.copyright'),
        width - 90,
        height - 5
    );
};

export const generateReport = (projectName, callback) => {
    const pdf = new jsPDF('png', 'mm', 'a4');
    const height = pdf.internal.pageSize.getHeight();
    const tags = document.getElementsByClassName('pdf');
    const charts = document.getElementsByClassName('charts');
    const promises: Html2CanvasPromise<HTMLCanvasElement>[] = [];

    //@ts-ignore
    for (const tag of tags) {
        promises.push(html2canvas(tag));
    }

    //@ts-ignore
    for (const chart of charts) {
        promises.push(html2canvas(chart));
    }

    let heightLeft = height - renderHeader(projectName, pdf, 5) - 5;

    Promise.all(promises).then(items => {
        items.forEach(canvas => {
            // const image = canvas.toDataURL('image/png');
            const image = canvas;
            const imgWidth = 140;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            const position = height - heightLeft;
            if (heightLeft - (imgHeight + 5) <= 5) {
                renderFooter(pdf);
                pdf.addPage();
                pdf.addImage(image, 'PNG', 40, 5, imgWidth, imgHeight);
                heightLeft = height - imgHeight - 6;
            } else {
                pdf.addImage(image, 'PNG', 40, position, imgWidth, imgHeight);
                heightLeft -= imgHeight + 6;
            }
        });
        renderFooter(pdf);
        pdf.save(
            `Solsys - ${moment(new Date()).format(
                'DD[.]MM[.]YYYY [-] HH[:]mm[:]ss'
            )}.pdf`
        );
        callback();
    });
};
