import React from 'react';
import UploadContainer from './container';
import { ApplicationState } from '../../../../../redux/store';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    uploadProperties,
    downloadProperties
} from '../../../../../redux/actions/properties.actions';

const Upload = (props: Props) => <UploadContainer {...props} />;

interface Props {
    uploading: boolean;
    downloading: boolean;
    uploadProperties(file: File, callback?: Function): void;
    downloadProperties(): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    uploading: state.properties.uploading,
    downloading: state.properties.downloading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            uploadProperties,
            downloadProperties
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Upload);
