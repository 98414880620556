import React from 'react';
import {
    StyledGrid,
    SelectedLabel,
    SelectedBox,
    CompareButton,
    UnselectedBox,
    Section
} from './index.styled';
import { Row, Column, Empty } from '../../../../../../../components/UI';

const ComparationBarContainer = ({
    compress,
    checkedCards,
    cardLock,
    cardUnlock,
    compareSimulations
}: Props) => {
    const renderReference = () => {
        const simulation = checkedCards.find(checked => checked.reference);
        return (
            <Row>
                <Column xs={3} sm={3} md={3} lg={3}>
                    <SelectedLabel i18nKey="performedSimulations.comparationBar.reference" />
                </Column>
                <Column xs={9} sm={9} md={9} lg={9}>
                    {simulation ? (
                        <SelectedBox>
                            <SelectedBox.Name renderText={simulation.name} />
                            {simulation.locked ? (
                                <SelectedBox.Padlock
                                    iconName="padlock"
                                    onClick={() => cardUnlock(simulation.date)}
                                />
                            ) : (
                                <SelectedBox.Padlock
                                    iconName="lock-open-alt"
                                    onClick={() => cardLock(simulation.date)}
                                />
                            )}
                        </SelectedBox>
                    ) : (
                        <UnselectedBox>
                            <UnselectedBox.Span i18nKey="performedSimulations.comparationBar.unselected" />
                        </UnselectedBox>
                    )}
                </Column>
            </Row>
        );
    };

    const renderProposal = () => {
        const simulation = checkedCards.find(checked => !checked.reference);
        return (
            <Row>
                <Column xs={3} sm={3} md={3} lg={3}>
                    <SelectedLabel i18nKey="performedSimulations.comparationBar.proposal" />
                </Column>
                <Column xs={9} sm={9} md={9} lg={9}>
                    {simulation ? (
                        <SelectedBox>
                            <SelectedBox.Name renderText={simulation.name} />
                            {simulation.locked ? (
                                <SelectedBox.Padlock
                                    iconName="padlock"
                                    onClick={() => cardUnlock(simulation.date)}
                                />
                            ) : (
                                <SelectedBox.Padlock
                                    iconName="lock-open-alt"
                                    onClick={() => cardLock(simulation.date)}
                                />
                            )}
                        </SelectedBox>
                    ) : (
                        <UnselectedBox>
                            <UnselectedBox.Span i18nKey="performedSimulations.comparationBar.unselected" />
                        </UnselectedBox>
                    )}
                </Column>
            </Row>
        );
    };

    if (!checkedCards || checkedCards.length === 0) {
        return <Empty />;
    }

    return (
        <Section onClick={e => e.stopPropagation()}>
            <StyledGrid margin={0}>
                <Row>
                    <Column xs={12} sm={12} md={11} lg={11} centered>
                        <Row>
                            <Column xs={5} sm={5} md={5} lg={5}>
                                {renderReference()}
                            </Column>
                            <Column xs={5} sm={5} md={5} lg={5}>
                                {renderProposal()}
                            </Column>
                            <Column xs={2} sm={2} md={2} lg={2} flex="center">
                                <CompareButton
                                    disabled={checkedCards.length !== 2}
                                    cl="primary"
                                    i18nKey="performedSimulations.comparationBar.compare"
                                    onClick={() =>
                                        compareSimulations(
                                            checkedCards,
                                            compress
                                        )
                                    }
                                />
                            </Column>
                        </Row>
                    </Column>
                </Row>
            </StyledGrid>
        </Section>
    );
};

export interface Props {
    compress(): void;
    cardLock(date: Date | string): void;
    cardUnlock(date: Date | string): void;
    compareSimulations(
        checkeds: { date: Date | string; reference: boolean }[],
        callback: Function
    ): void;
    checkedCards: {
        name: string;
        date: Date | string;
        locked: boolean;
        reference: boolean;
    }[];
}

export default ComparationBarContainer;
