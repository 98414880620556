import React from 'react';
import _ from 'lodash';
import SimulationResultWithConfigs from '../../../../../models/simulationResultWithConfigs.model';
import {
    Container,
    Header,
    Body,
    BackgroundOverlay,
    SelectContainer,
    SelectSpan,
    SelectFiler
} from './index.styled';
import SimulationResultCard from '../../SimulationResultCard';
import { Column, Empty, Pagination } from '../../../../../components/UI';
import moment from 'moment';
import { outTranslator } from '../../../../../helpers/util';
import ComparationBar from './ComparationBar';

enum FILTER_CARDS {
    LATTER = 0,
    OLDER = 1
}
interface FilterItem {
    id: number;
    value: number;
    label: string;
}

class PerformedContainer extends React.Component<
    PerformedContainerProps,
    State
> {
    private bodyRef;
    private cardRefs;

    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            currentPage: 0,
            allExpanded: false,
            selectFilter: 0,
            checkedCards: []
        };
        this.cardRefs = [];
        this.bodyRef = React.createRef();
    }

    private toggleExpand = () => {
        const { expanded } = this.state;
        this.setState({ expanded: !expanded },
            () => document.getElementsByClassName("CardsRowScroll")[0].scrollTop = 0
        );
    };

    private compress = () => {
        this.setState({ expanded: false });
    };

    private newSimulation = e => {
        const { newSimulation } = this.props;
        e.stopPropagation();
        newSimulation();
        this.compress();
    };

    private setPage = ({ selected }) => {
        this.setState({ currentPage: selected, allExpanded: false }, () => {
            this.bodyRef.current.scrollTop = 0;
        });
    };

    private toggleExpandAllCards = () => {
        const { allExpanded } = this.state;
        if (!allExpanded) {
            this.setState(
                { allExpanded: true },
                this.cardRefs.forEach(cardRef => {
                    if (cardRef.current) {
                        cardRef.current.expand();
                    }
                })
            );
        } else {
            this.setState({ allExpanded: false }, () =>
                this.cardRefs.forEach(cardRef => {
                    if (cardRef.current) {
                        cardRef.current.compress();
                    }
                })
            );
        }
    };

    private filterSimulations() {
        const { simulations, pageLimit } = this.props;
        const { currentPage, selectFilter } = this.state;

        let newList: SimulationResultWithConfigs[] = [];

        switch (selectFilter) {
            case FILTER_CARDS.LATTER:
                newList = _.orderBy(
                    simulations,
                    (o: any) => moment(o.date),
                    'desc'
                );
                break;
            case FILTER_CARDS.OLDER:
                newList = _.orderBy(
                    simulations,
                    (o: any) => moment(o.date),
                    'asc'
                );
                break;
            default:
                newList = _.orderBy(
                    simulations,
                    (o: any) => moment(o.date),
                    'desc'
                );
                break;
        }

        newList = newList.slice(
            currentPage * pageLimit,
            currentPage * pageLimit + pageLimit
        );

        if (newList.length === 0 && currentPage > 0) {
            this.setState({ currentPage: currentPage - 1 });
        }

        return newList;
    }

    convertValuesListToDropdownList() {
        const values: FilterItem[] = [];
        const latter: FilterItem = {
            id: 1,
            label: outTranslator('performedSimulations.filter.latter'),
            value: 0
        };

        const older: FilterItem = {
            id: 2,
            label: outTranslator('performedSimulations.filter.older'),
            value: 1
        };

        values.push(latter);
        values.push(older);

        return values.map(item => ({
            key: item.id,
            value: item.value,
            text: item.label
        }));
    }

    private cardCheck = (simulation: SimulationResultWithConfigs) => {
        let { checkedCards } = this.state;
        const haveLocked = checkedCards.find(checked => checked.locked);
        if (!haveLocked) {
            checkedCards.push({
                date: simulation.date,
                locked: false,
                reference: false
            });
            if (checkedCards.length > 2) {
                checkedCards = checkedCards.slice(1, 3);
            }
            checkedCards[0].reference = true;
        } else {
            const unlocked = checkedCards.findIndex(checked => !checked.locked);
            if (unlocked !== -1) {
                checkedCards[unlocked] = {
                    date: simulation.date,
                    locked: false,
                    reference: checkedCards[unlocked].reference
                };
            } else {
                const locked = checkedCards.find(checked => checked.locked);
                checkedCards.push({
                    date: simulation.date,
                    locked: false,
                    reference: !locked!.reference
                });
            }
        }
        this.setState({ checkedCards });
    };

    private cardUncheck = (date: Date) => {
        const { checkedCards } = this.state;
        if (checkedCards.find(checked => checked.date === date)!.reference) {
            this.setState({
                checkedCards: checkedCards
                    .filter(checked => checked.date !== date)
                    .map(checked => {
                        if (!checked.locked) {
                            return { ...checked, reference: true };
                        }
                        return checked;
                    })
            });
        } else {
            this.setState({
                checkedCards: checkedCards.filter(
                    checked => checked.date !== date
                )
            });
        }
    };

    private cardLock = (date: Date) => {
        const { checkedCards } = this.state;
        checkedCards.find(checked => checked.date === date)!.locked = true;
        this.setState({ checkedCards });
    };

    private cardUnlock = (date: Date) => {
        const { checkedCards } = this.state;
        checkedCards.find(checked => checked.date === date)!.locked = false;
        this.setState({ checkedCards });
    };

    private renderBackgroundOverlay = () => {
        const { expanded } = this.state;
        if (expanded) {
            return <BackgroundOverlay onClick={this.compress} />;
        }
        return <Empty />;
    };

    private renderCards() {
        const { simulations } = this.props;
        const { checkedCards } = this.state;
        return this.filterSimulations().map((simulation, index) => {
            this.cardRefs[index] = React.createRef();
            const checkedCard = checkedCards.find(
                checked => checked.date === simulation.date
            );
            return (
                <Column
                    key={simulation.date.toString()}
                    xs={12}
                    sm={12}
                    md={6}
                    lg={6}
                >
                    <SimulationResultCard
                        simulation={simulation}
                        performed={{
                            compress: this.compress,
                            simulationsLength: simulations.length,
                            cardCheck: this.cardCheck,
                            cardUncheck: this.cardUncheck,
                            checkedCardsTotal: checkedCards.length,
                            lockedCardsNumber: checkedCards.filter(
                                checked => checked.locked
                            ).length,
                            checked: checkedCard
                        }}
                        ref={this.cardRefs[index]}
                    />
                </Column>
            );
        });
    }

    private renderFilterAndPagination() {
        const { simulations, pageLimit } = this.props;
        const { allExpanded, selectFilter, currentPage } = this.state;

        return (
            <Body.FilterAndPagination>
                <Body.FilterAndPagination.Row>
                    <Body.FilterAndPagination.FilterColumn
                        xs={12}
                        sm={8}
                        md={8}
                        lg={8}
                        flex={{ align: 'center', justify: 'start' }}
                    >
                        <SelectContainer>
                            <SelectSpan
                                i18nKey={'performedSimulations.filter.title'}
                            />
                            <SelectFiler
                                id="FilterCards_Select"
                                name="FilterCards"
                                selectOnNavigation={false}
                                selectOnBlur={false}
                                value={selectFilter}
                                loading={false}
                                width={180}
                                fluid
                                options={this.convertValuesListToDropdownList()}
                                onChange={(_, { value }) => {
                                    this.setState({ selectFilter: value });
                                }}
                            />
                        </SelectContainer>
                        {simulations.length > 1 && (
                            <Body.ExpandAll
                                i18nKey={
                                    allExpanded
                                        ? 'performedSimulations.compressAll'
                                        : 'performedSimulations.expandAll'
                                }
                                onClick={this.toggleExpandAllCards}
                            />
                        )}
                    </Body.FilterAndPagination.FilterColumn>
                    <Body.FilterAndPagination.PaginationColumn
                        xs={12}
                        sm={4}
                        md={4}
                        lg={4}
                        flex={{ align: 'center', justify: 'end' }}
                    >
                        <Pagination
                            pageCount={simulations.length / pageLimit}
                            handlePageClick={this.setPage}
                            forcePage={currentPage}
                        />
                    </Body.FilterAndPagination.PaginationColumn>
                </Body.FilterAndPagination.Row>
            </Body.FilterAndPagination>
        );
    }

    render() {
        const { simulations } = this.props;
        const { expanded, checkedCards } = this.state;
        return (
            <React.Fragment>
                <Container onClick={this.toggleExpand} data-expanded={expanded}>
                    <Header margin={0}>
                        <Header.Row>
                            <Header.Column xs={8} sm={8} md={6} lg={4}>
                                <Header.Flask name="flask" />
                                <Header.Title i18nKey="performedSimulations.title" />
                                <Header.Arrow
                                    name={expanded ? 'angle-down' : 'angle-up'}
                                />
                            </Header.Column>
                            <Header.Column xs={0} sm={0} md={3} lg={5} />
                            <Header.RightColumn xs={4} sm={4} md={3} lg={3}>
                                <Header.NewSimulation
                                    i18nKey="performedSimulations.newSimulation"
                                    onClick={this.newSimulation}
                                />
                            </Header.RightColumn>
                        </Header.Row>
                    </Header>
                    <Body
                        data-checkedcards={checkedCards}
                        padding={5}
                        margin={0}
                        onClick={e => e.stopPropagation()}
                        ref={this.bodyRef}
                    >
                        {this.renderFilterAndPagination()}
                        <Body.CardsRow className="CardsRowScroll">
                            <Body.Cards
                                centered
                                xs={12}
                                sm={12}
                                md={10}
                                lg={10}
                            >
                                {this.renderCards()}
                            </Body.Cards>
                        </Body.CardsRow>
                    </Body>
                    {checkedCards.length > 0 && (
                        <ComparationBar
                            compress={this.compress}
                            cardLock={this.cardLock}
                            cardUnlock={this.cardUnlock}
                            checkedCards={checkedCards
                                .filter(checked =>
                                    simulations.find(
                                        simulation =>
                                            simulation.date === checked.date
                                    )
                                )
                                .map(checked => ({
                                    ...checked,
                                    name: simulations.find(
                                        simulation =>
                                            simulation.date === checked.date
                                    )!.name
                                }))}
                        />
                    )}
                </Container>
                {this.renderBackgroundOverlay()}
            </React.Fragment>
        );
    }

    componentWillUpdate() {
        const { simulations } = this.props;
        const { checkedCards } = this.state;
        if (simulations.length <= 1 && checkedCards.length !== 0) {
            this.setState({ checkedCards: [] });
        }
    }
}

export interface PerformedContainerProps {
    simulations: SimulationResultWithConfigs[];
    pageLimit: number;
    newSimulation(): void;
}

interface State {
    expanded: boolean;
    currentPage: number;
    selectFilter: number;
    allExpanded: boolean;
    checkedCards: {
        date: Date | string;
        locked: boolean;
        reference: boolean;
    }[];
}
export default PerformedContainer;
