import { Term } from '../../models/term.model';

/**
 * Action types
 */
/**
 * Action types
 */
export enum ContentTypes {
    GET_TERMS_CONDITIONS_REQUEST = 'content:GET_TERMS_CONDITIONS_REQUEST',
    GET_TERMS_CONDITIONS_SUCCESS = 'content:GET_TERMS_CONDITIONS_SUCESS',
    GET_TERMS_CONDITIONS_FAILURE = 'content:GET_TERMS_CONDITIONS_FAILURE',
    SAVE_TERMS_REQUEST = 'SAVE_TERMS_REQUEST'
}

/**
 * State types
 */
export interface ContentState {
    readonly termConditions: Partial<Term> | null;
    readonly loading: boolean;
}
