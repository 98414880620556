import { Reducer } from 'redux';
import { UsersState, UsersTypes } from '../../types/users.types';

const INITIAL_STATE: UsersState = {
    current: null,
    items: [],
    paging: {
        total_items: 0,
        total_pages: 1
    },
    loading: false,
    error: false,
    message: ''
};

const users: Reducer<UsersState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UsersTypes.SEARCH_USER_REQUEST:
            return { ...state, loading: true };
        case UsersTypes.SEARCH_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                items: action.payload.items,
                paging: action.payload.paging,
                message: action.payload.message
            };
        case UsersTypes.SEARCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                items: [],
                paging: {
                    total_pages: 1,
                    total_items: 0
                },
                message: ''
            };
        case UsersTypes.TAKE_USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case UsersTypes.TAKE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                current: action.payload.user,
                message: action.payload.message
            };
        case UsersTypes.TAKE_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                current: null,
                message: ''
            };
        default:
            return state;
    }
};

export default users;
