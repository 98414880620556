import React from 'react';
import { Project } from '../../../models/project.model';
import { GetFilter } from '../../../models/getFilter.model';
import {
    Container,
    Projects,
    Content,
    PaginationContent,
    Filter,
    Header,
    LoadingBox,
    NoProjects,
    NoProjectsContainer
} from './index.styled';
import {
    Line,
    Pagination,
    Loading,
    confirmDelete
} from '../../../components/UI';
import ProjectItem from './ProjectItem';
import grafismo from '../../../assets/images/grafismo-projects.svg';
import { RouteComponentProps } from 'react-router-dom';
import { Paging } from '../../../models/paging.model';
import { User } from '../../../models/user.model';
import { WithTranslation } from 'react-i18next';

enum ProjectsFilter {
    CREATED_AT = 'CREATED_AT',
    SIMULATIONS_AVAILABLE = 'SIMULATIONS_AVAILABLE',
    NAME = 'NAME'
}

class ProjectsContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1,
            selectFilter: ProjectsFilter.CREATED_AT,
            headerFixed: false
        };
    }

    private handleScroll = e => {
        const { headerFixed } = this.state;
        const paths = e.path || (e.composedPath && e.composedPath());
        if (!paths) {
            return;
        }
        if (paths[paths.length - 1].scrollY > 200 && !headerFixed) {
            this.setState({
                headerFixed: true
            });
        } else if (paths[paths.length - 1].scrollY <= 200 && headerFixed) {
            this.setState({ headerFixed: false });
        }
    };

    private handleGetProjects = () => {
        const { currentPage, selectFilter } = this.state;
        const { getProjects, limit } = this.props;
        const filter: GetFilter<ProjectsFilter> = {
            page: currentPage,
            sort: selectFilter,
            limit
        };
        getProjects(filter);
    };

    private handleDeleteProject = project => {
        const { currentPage, selectFilter } = this.state;
        const { deleteProject, getProjects, limit, projects } = this.props;
        confirmDelete(project.name, () =>
            deleteProject(project, () => {
                const filter: GetFilter<ProjectsFilter> = {
                    page:
                        projects.length > 1
                            ? currentPage
                            : currentPage > 1
                            ? currentPage - 1
                            : 1,
                    sort: selectFilter,
                    limit
                };
                getProjects(filter, () => {
                    if (projects.length === 1 && currentPage > 1) {
                        this.setState({ currentPage: currentPage - 1 });
                    }
                });
            })
        );
    };

    private handlePageChange = page => {
        this.setState({ currentPage: page + 1 }, this.handleGetProjects);
    };

    private handleChangeFilter = value => {
        this.setState({ selectFilter: value }, this.handleGetProjects);
    };

    private openProject = (id: number) => {
        const { setProject, recoverProject, history } = this.props;
        recoverProject(id, projeto => {
            setProject(projeto);
            history.push('/simulation');
        });
    };

    private newProject = () => {
        const { setProject, history, t } = this.props;
        setProject(
            new Project(t('redux.reducers.simulation.newProject'), '', [])
        );
        history.push('/simulation');
    };

    private renderFilter() {
        const { t } = this.props;
        const { selectFilter } = this.state;
        const filterOptions = [
            {
                key: 0,
                value: ProjectsFilter.CREATED_AT,
                text: t('projects.filter.creationDate')
            },
            {
                key: 1,
                value: ProjectsFilter.SIMULATIONS_AVAILABLE,
                text: t('projects.filter.moreSimulations')
            },
            {
                key: 2,
                value: ProjectsFilter.NAME,
                text: t('projects.filter.name')
            }
        ];

        return (
            <Filter>
                <Filter.Label i18nKey="projects.filter.label" />
                <Filter.Select
                    selectOnNavigation={false}
                    selectOnBlur={false}
                    value={selectFilter}
                    width={180}
                    options={filterOptions}
                    onChange={(_, { value }) => {
                        this.handleChangeFilter(value);
                    }}
                />
            </Filter>
        );
    }

    private renderProjects() {
        const { projects } = this.props;
        return projects.map(project => {
            return (
                <ProjectItem
                    key={project.id}
                    openProject={this.openProject}
                    deleteProject={this.handleDeleteProject}
                    getProjects={this.handleGetProjects}
                    project={project}
                />
            );
        });
    }

    private renderNoProjects() {
        const { user } = this.props;
        return (
            <NoProjectsContainer>
                <NoProjects>
                    <NoProjects.Flask name="flask" size={170} />
                    <NoProjects.Info>
                        <NoProjects.Span
                            i18nKey={[
                                'projects.noProject.span',
                                {
                                    name: user.display_name
                                        ? user.display_name.split(' ')[0]
                                        : 'User'
                                }
                            ]}
                        />
                        <NoProjects.Button
                            i18nKey="projects.noProject.createProject"
                            cl="primary"
                            onClick={this.newProject}
                        />
                    </NoProjects.Info>
                </NoProjects>
            </NoProjectsContainer>
        );
    }

    private renderWithProjects() {
        const { loading, paging } = this.props;
        const { currentPage, headerFixed } = this.state;
        return (
            <React.Fragment>
                <Header data-fixed={headerFixed}>
                    <Header.Title
                        i18nKey={[
                            'projects.title',
                            { total: paging.total_items }
                        ]}
                    />
                    <Header.VerticalLine />
                    <Header.NewProjectBtn
                        cl="span"
                        i18nKey="projects.newProject"
                        onClick={this.newProject}
                    />
                </Header>
                <Line />
                {loading ? (
                    <LoadingBox>
                        <Loading />
                    </LoadingBox>
                ) : (
                    <React.Fragment>
                        {this.renderFilter()}
                        <Projects>{this.renderProjects()}</Projects>
                        <PaginationContent>
                            <Pagination
                                pageCount={paging.total_pages}
                                forcePage={currentPage - 1}
                                handlePageClick={({ selected }) =>
                                    this.handlePageChange(selected)
                                }
                            />
                        </PaginationContent>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }

    render() {
        const { projects, loading } = this.props;
        return (
            <Container>
                <Content>
                    {projects.length === 0 && !loading
                        ? this.renderNoProjects()
                        : this.renderWithProjects()}
                </Content>
                <img alt="decoration" src={grafismo} />
            </Container>
        );
    }

    componentDidMount = () => {
        document.addEventListener('scroll', this.handleScroll);
    };

    componentWillUnmount = () => {
        document.removeEventListener('scroll', this.handleScroll);
    };
}

interface Props extends RouteComponentProps, WithTranslation {
    projects: Project[];
    user: User;
    limit: number;
    loading: boolean;
    paging: Paging;
    setProject(project: Project): void;
    deleteProject(project: Project, callback: Function): void;
    getProjects(filter: GetFilter, callback?: Function): void;
    recoverProject(id: number, callback?: Function): void;
}

interface State {
    currentPage: number;
    selectFilter: ProjectsFilter;
    headerFixed: boolean;
}

export default ProjectsContainer;
