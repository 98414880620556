import React from 'react';
import { StyledInput, Container, InputIcon } from './index.styled';
import { useTranslation } from 'react-i18next';

export const Input = ({
    setFieldValue,
    setFieldTouched,
    tagPosition = 'right',
    tagPadding,
    icon,
    placeholder,
    ...props
}: IInputProps) => {
    const { t } = useTranslation('app');
    return (
        <Container>
            {icon && <InputIcon name={icon} />}
            <StyledInput
                data-tagposition={tagPosition}
                data-tagpadding={tagPadding}
                data-icon={icon}
                placeholder={placeholder ? t(placeholder) : ''}
                {...props}
            />
        </Container>
    );
};

export interface IInputProps {
    name?: string;
    placeholder?: string;
    readOnly?: boolean | undefined;
    disabled?: boolean | undefined;
    id?: string;
    tag?: string;
    value?: any;
    className?: string;
    tagPosition?: 'left' | 'right';
    tagPadding?: string;
    icon?: string;
    i18nKey?: string | any[];
    onChange?;
    onBlur?;
    onClick?;
    maxLength?;
    setFieldValue?(name: string, value): void;
    setFieldTouched?(name: string, value: boolean): void;
}
