import styled from 'styled-components';
import { Span } from '../../../../../../../../../../components/UI';

export const UserItem: any = styled.div`
    width: 100%;
`;

UserItem.Email = styled(Span)`
    font-size: 16px;
    font-weight: bold;
    color: #000;
    padding: 4px 0;
    width: 100%;
    display: block;
`;

UserItem.Name = styled(Span)`
    font-size: 14px;
    font-weight: normal;
    color: #ccc;
    padding: 4px 0;
    width: 100%;
    display: block;
`;

export const SearchContainer = styled.div`
    padding-bottom: 28px;
`;
