import styled from "styled-components";
import { Radio as SemanticRadio } from "semantic-ui-react";

export const InputRadio = styled(SemanticRadio)`
    margin-right: 8px;
`;
export const Container = styled.div`
    display: flex;
    align-items: center;
`;
