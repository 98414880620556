import React from 'react';
import { Empty } from '../../UI';
import { Footer as FooterStyled, ReservedRights, Logo } from './index.styled';
import { withRouter } from 'react-router-dom';
import logo from '../../../assets/images/footer_logo.png';
import { modal } from '../../UI/Modal';
import TermsOfUseModal from "../../common/TermsOfUse";

export const Footer = withRouter(({ location }) => {
    const showTermsOfUse = () => {
        modal({})(() => <TermsOfUseModal />);
    }

    if (location.pathname === '/simulation') {
        return <Empty />;
    }

    return (
        <FooterStyled>
            <Logo src={logo} />
            <ReservedRights onClick={showTermsOfUse} i18nKey="footer.reservedRights" />
        </FooterStyled>
    );
});
