import App from '../app/index.translation.json';
import { Translation } from '../models/translation.model';
import { mergeDeep } from '../helpers/mergeDeep';

const combineTranslations = (components: Translation[]) => {
    const combinedTranslations = {
        pt_br: { app: { ...App.pt_br.app } },
        en_us: { app: { ...App.en_us.app } },
        es_es: { app: { ...App.es_es.app } }
    };
    components.forEach(component => {
        combinedTranslations.pt_br.app = mergeDeep(
            combinedTranslations.pt_br.app,
            component.pt_br
        );
        combinedTranslations.en_us.app = mergeDeep(
            combinedTranslations.en_us.app,
            component.en_us
        );
        combinedTranslations.es_es.app = mergeDeep(
            combinedTranslations.es_es.app,
            component.es_es
        );
    });
    return combinedTranslations;
};

export default combineTranslations;
