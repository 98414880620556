export interface GetFilter<T = {}> {
    q?: string;
    sort?: GetFilterSort | T;
    page: number;
    limit: number;
}

export enum GetFilterSort {
    CREATED_AT = 'CREATED_AT',
    UPDATED_AT = 'UPDATED_AT'
}
