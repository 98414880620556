import React from 'react';
import { StyledLabel } from './index.styled';
import { withTranslationText } from '../../hoc/withTranslationText';

export const Label = withTranslationText(
    ({ renderText, children, ...props }: ILabelProps) => {
        return (
            <StyledLabel {...props}>
                {renderText ? renderText : children}
            </StyledLabel>
        );
    }
);

interface ILabelProps {
    id?: string;
    htmlFor?: string;
    disabled?: boolean;
    renderText?: string;
    children?;
}
