import { Reducer } from 'redux';
import { ConversionState, ConversionTypes } from '../../types/conversion.types';
import { CostEstimate, UnitMeasure } from '../../../models/conversion.model';

enum UNIT_TYPE {
    MASS = 'MASS',
    VOLUME = 'VOLUME'
}

const costEstimate: CostEstimate = {
    totalCost: 0,
    estimates: [],
    measurement: {
        concentration: UNIT_TYPE.MASS,
        cost: UNIT_TYPE.MASS
    }
};

const unitMeasure: UnitMeasure = {
    conversions: [],
    measurement: ''
};

const INITIAL_STATE: ConversionState = {
    costEstimate: costEstimate,
    unitMeasure: unitMeasure,
    unitType: null,
    loading: false,
    error: false,
    message: ''
};

const conversion: Reducer<ConversionState> = (
    state = INITIAL_STATE,
    action
) => {
    switch (action.type) {
        case ConversionTypes.SWITCH_COST_UNIT:
            return {
                ...state,
                unitMeasure: {
                    ...state.unitMeasure,
                    measurement: action.payload
                }
            };
        case ConversionTypes.UNIT_MEASURE_REQUEST:
            return { ...state, loading: true };
        case ConversionTypes.UNIT_MEASURE_SUCCESS:
            return {
                ...state,
                unitMeasure: action.payload,
                unitType: action.payload.measurement,
                loading: false,
                error: false
            };
        case ConversionTypes.UNIT_MEASURE_FAILURE:
            return {
                ...state,
                unitMeasure,
                loading: false,
                error: true
            };

        case ConversionTypes.COST_ESTIMATE_REQUEST:
            return { ...state, loading: true };
        case ConversionTypes.COST_ESTIMATE_SUCCESS:
            return {
                ...state,
                costEstimate: {
                    ...state.costEstimate,
                    totalCost: action.payload
                },
                loading: false,
                error: false
            };
        case ConversionTypes.COST_ESTIMATE_FAILURE:
            return {
                ...state,
                costEstimate,
                loading: false,
                error: true
            };
        case ConversionTypes.SWITCH_UNIT_TYPE:
            return {
                ...state,
                unitType: action.payload
            };
        default:
            return state;
    }
};

export default conversion;
