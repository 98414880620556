import React from 'react';
import Data from './Data';
import Active from './Active';
import Group from '../../../../../../../../../models/group.model';
import { ApplicationState } from '../../../../../../../../../redux/store';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { searchUser } from '../../../../../../../../../redux/actions/users.actions';
import { User } from '../../../../../../../../../models/user.model';

class UsersStep extends React.Component<Props, {}> {
    static Active = Active;
    static Data = Data;

    private handleSearchChange = (query: string) => {
        const { searchUser } = this.props;
        const search = {
            q: query,
            limit: 100,
            page: 1
        };
        searchUser(search);
    };

    render() {
        const { children, group, ...rest } = this.props;
        return (
            <div>
                {children({
                    onSearchChange: this.handleSearchChange,
                    values: group ? (group.users ? group.users : []) : [],
                    ...rest
                })}
            </div>
        );
    }
}

interface Props {
    group: Group | null;
    users: Partial<User>[];
    loading: boolean;
    children;
    onSave(data): void;
    searchUser(search, callback?);
}

const mapStateToProps = (state: ApplicationState) => ({
    users: state.users.items,
    loading: state.users.loading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            searchUser
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersStep);
