import styled from 'styled-components';
import { Span, Icon } from '../../../../components/UI';

export const Container = styled.section`
    width: 100%;
    max-width: 220px;
    background-color: #fff;
    padding: 40px 10px;
`;

export const User: any = styled.div`
    width: 100%;
    padding: 17px 0;
`;

User.Key = styled(Icon)`
    color: #f6a906;
    font-size: 22px;
    margin-right: 4px;
`;

User.Name = styled(Span)`
    color: #444444;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    display: block;
    width: 100%;
    padding: 3px 0;
`;

User.Role = styled(Span)`
    color: #a6a6a6;
    font-size: 14px;
    letter-spacing: 0.7px;
    line-height: 18px;
    display: block;
    width: 100%;
    padding: 3px 0;
`;

export const Category: any = styled.div`
    padding: 17px 0;
`;

Category.Title = styled(Span)`
    color: #444444;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.8px;
    line-height: 20px;
`;

Category.Items = styled.ul`
    padding: 0px 8px;
`;

Category.Item = styled.li`
    padding: 10px 0;
    cursor: pointer;

    &:hover i,
    &:hover span {
        color: #763689;
    }

    &.active i,
    &.active span {
        color: #763689;
    }
`;

Category.Item.Icon = styled(Icon)`
    margin-right: 8px;
    color: #444444;
    font-size: 18px;
`;

Category.Item.Name = styled(Span)`
    color: #444444;
    font-size: 16px;
    letter-spacing: 0.8px;
    line-height: 20px;
    display: inline-block;
`;
