import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import {
    getTermConditionsSuccess,
    getTermConditionsError
} from '../../actions/content.actions';

export default function* getTermConditions() {
    try {
        const { data } = yield call(api.get, 'content/term_conditions');
        if (data.object) {
            yield put(getTermConditionsSuccess(data.object));
        }
    } catch (err) {
        yield put(getTermConditionsError());
    }
}
