import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApplicationState } from '../../../../../redux/store';
import StepFormulationContainer from './container';
import { filterSolventsRequest } from '../../../../../redux/actions/solvents.actions';
import {
    costEstimateRequest,
    unitMeasureRequest
} from '../../../../../redux/actions/conversion.actions';
import {
    Solvent,
    FormulationSolvent
} from '../../../../../models/solvent.model';
import {
    CostEstimate,
    UnitMeasure
} from '../../../../../models/conversion.model';
import { SimulationContext } from '..';
import {
    setFormulation,
    simulationRequest,
    setSolventConcentration,
    setSolventCost,
    addSolventToFormulation,
    removeSolventToFormulation,
    switchUnitType,
    switchCostUnit
} from '../../../../../redux/actions/simulation.actions';
import SimulationConfig from '../../../../../models/simulationConfig.model';
import { searchRequest } from '../../../../../redux/actions/search.actions';
import Search from '../../../../../models/search.model';

const StepFormulation = (props: Props) => {
    const { wizardProps } = props;

    const handleNext = data => {
        const { setFormulation } = props;
        setFormulation(data);
        wizardProps.simulate();
    };

    const handleOnSearchChange = (item: Search, callback: Function) => {
        const { filterSolvents } = props;
        filterSolvents([item.id], callback);
    };

    return (
        <SimulationContext.Consumer>
            {context => (
                <StepFormulationContainer
                    initialValues={{
                        is_volume: props.config.is_volume,
                        is_value_per_liter: props.config.is_value_per_liter,
                        solvents: props.config.solvents
                    }}
                    stepNumber={3}
                    limit={20}
                    startSearch={2}
                    onNext={handleNext}
                    isComparation={wizardProps.isComparation}
                    isChanged={wizardProps.isChanged}
                    {...context}
                    {...props}
                    onSearchChange={handleOnSearchChange}
                />
            )}
        </SimulationContext.Consumer>
    );
};

interface Props {
    solvents: Solvent[];
    solventLoading: boolean;
    costEstimate: CostEstimate;
    unitMeasure: UnitMeasure;
    conversionLoading: boolean;
    conversionMessageError: string;
    wizardProps;
    config: Partial<SimulationConfig>;
    searchRequest(search, callback): void;
    switchUnit(type): void;
    switchCost(type): void;
    simulationRequest(config): void;
    setFormulation(data): void;
    filterSolvents(ids: number[], callback: Function): void;
    dispatchCostEstimate(data: CostEstimate): void;
    dispatchUnitMeasure(data: UnitMeasure): void;
    setConcentration(data: { value: number; id: number }): void;
    setCost(data: { value: number; id: number }): void;
    addSolvent(solvent: FormulationSolvent): void;
    removeSolvent(id: number): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    solvents: state.solvents.items,
    costEstimate: state.conversion.costEstimate,
    unitMeasure: state.conversion.unitMeasure,
    solventLoading: state.solvents.loading,
    conversionLoading: state.conversion.loading,
    conversionMessageError: state.conversion.message,
    config: state.simulation.config
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            filterSolvents: filterSolventsRequest,
            dispatchCostEstimate: costEstimateRequest,
            dispatchUnitMeasure: unitMeasureRequest,
            setFormulation: setFormulation,
            simulationRequest: simulationRequest,
            setConcentration: setSolventConcentration,
            setCost: setSolventCost,
            addSolvent: addSolventToFormulation,
            removeSolvent: removeSolventToFormulation,
            switchUnit: switchUnitType,
            switchCost: switchCostUnit,
            searchRequest
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StepFormulation);
