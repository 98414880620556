import styled from 'styled-components';
import { H3, Span, Grid, Button, Row } from '../../../components/UI';

export const StyledLogin = styled.section`
    overflow: auto;
    flex: 1;

    .overlay {
        background: #f7f7f7;
    }

    .container {
        z-index: 0;
    }

    @media (max-width: 768px) {
        .container {
            min-height: 600px;
            min-width: ${`${window.innerWidth * 2}px`};
        }
    }
`;

export const Opposite: any = styled(Grid)`
    width: 100%;
    max-width: 480px;
    text-align: left;
    height: auto;
    padding: 10px 25px;
`;

Opposite.Row = styled(Row)`
    padding: 7.5px 0;
`;

Opposite.Title = styled(H3)``;

Opposite.Span = styled(Span)``;

Opposite.Button = styled(Button)`
    &.ui.button {
        font-size: 16px;
        padding: 4px 24px;
        margin-right: 0;
    }
`;

Opposite.LeftImage = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    width: 50%;
`;

Opposite.RightImage = styled.img`
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 50%;
    transform: scaleX(-1);
`;
