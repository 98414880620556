import React from 'react';
import Firebase from '../../../configs/firebase';
import {
    completeSign,
    recoverUser,
    clearUser
} from '../../../redux/actions/user.actions';
import history from '../../../configs/history';

const withFirebase = Component => props => {
    class FirebaseListener extends React.PureComponent {
        render() {
            return <Component {...props} />;
        }

        componentDidMount() {
            completeSign();
            Firebase.auth.onAuthStateChanged(user => {
                this.forceUpdate();
                if (user) {
                    recoverUser(() => {
                        if (history.location.pathname === '/sign') {
                            history.push('/projects');
                        }
                    });
                } else {
                    clearUser();
                }
            });
        }
    }
    return <FirebaseListener {...props} />;
};

export default withFirebase;
