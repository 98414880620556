import React from 'react';
import { StyledBreadcrumb } from './index.styled';
import { Line } from '../Line';

export class Breadcrumb extends React.Component<any, any> {
    static Section = StyledBreadcrumb.Section;
    static Divider = StyledBreadcrumb.Divider;

    render() {
        return (
            <div>
                <StyledBreadcrumb {...this.props} />
                <Line />
            </div>
        );
    }
}
