import styled from 'styled-components';
import {
    H3,
    Span,
    IconButton,
    Icon,
    Row,
    Button
} from '../../../../components/UI';
import colors from '../../../../theme/colors';

export const Container = styled.div`
    position: relative;
    border: 0.5px solid #cccccc;
    background-color: #fff;
    border-radius: 4px;
    margin: 24px 0;
    box-shadow: 0 2px 4px 0 rgba(68, 68, 68, 0.1),
        0 0 6px 0 rgba(166, 166, 166, 0.1);
`;

export const StyledRow = styled(Row)`
    padding: 4px 0;
`;

export const Title = styled(H3)`
    height: auto;
    display: block;
    width: 100%;
    word-break: break-word;
`;

export const Inf = styled(Span)`
    display: inline-block;
    word-break: break-word;
`;

export const Flask = styled(Icon)`
    color: ${colors.main};
    margin-right: 4px;
    display: inline-block;
    margin-left: -4px;
`;

export const Arrow = styled(IconButton)`
    &.ui.button {
        position: absolute;
        top: 36px;
        right: 24px;
        z-index: 1;
    }
`;

export const Compressed = styled.div`
    padding: 20px;
    cursor: pointer;
`;

export const Expanded = styled.div`
    padding: 0 20px;
    margin-bottom: 24px;
`;

export const Buttons: any = styled.div`
    padding: 8px;
    border-top: 1px solid #ccc;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
`;

Buttons.Delete = styled(Button)`
    &.ui.button:hover,
    &.ui.button:active,
    &.ui.button:focus {
        color: #a90b50;
    }
`;

Buttons.Edit = styled(Button)`
    margin-left: auto !important;
    &.ui.button:hover,
    &.ui.button:active,
    &.ui.button:focus {
        color: ${colors.main};
    }

    @media (max-width: 768px) {
        margin-left: inherit !important;
    }
`;

Buttons.Open = styled(Button)``;
