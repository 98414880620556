import React from 'react';
import NumberFormat from 'react-number-format';
import { Input, IInputProps } from '..';

export const InputNumber = ({
    name,
    setFieldValue,
    setFieldTouched,
    onChange,
    ...rest
}: IInputNumberProps) => {
    return (
        <NumberFormat
            {...rest}
            onValueChange={values => {
                const { floatValue } = values;
                if (setFieldValue && setFieldTouched) {
                    setFieldValue(name!, floatValue);
                    setFieldTouched(name!, true);
                } else {
                    onChange(values);
                }
            }}
            customInput={Input}
        />
    );
};

InputNumber.defaultProps = {
    decimalSeparator: ','
};

export interface IInputNumberProps extends IInputProps {
    decimalSeparator?: string;
    decimalScale?: number;
    setFieldValue?(name: string, value): void;
    setFieldTouched?(name: string, value: boolean): void;
}
