import React from 'react';
import GroupModel from '../../../../../../../models/group.model';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import {
    takeGroup,
    deleteGroup,
    saveGroupData,
    saveGroupUsers,
    saveGroupSolvents,
    saveGroupPolymers
} from '../../../../../../../redux/actions/groups.actions';
import { ApplicationState } from '../../../../../../../redux/store';
import { connect } from 'react-redux';
import { Loading } from '../../../../../../../components/UI';
import { LoadingBox } from './index.styled';
import { withTranslation, WithTranslation } from 'react-i18next';
import GroupContainer from './container';

class Group extends React.Component<Props, {}> {
    render() {
        const { loading } = this.props;
        return loading ? (
            <LoadingBox>
                <Loading />
            </LoadingBox>
        ) : (
            <GroupContainer {...this.props} />
        );
    }

    componentWillMount() {
        const { match, takeGroup } = this.props;
        let groupId: number | null = null;
        if (match && match.params && match.params['id']) {
            groupId = match.params['id'];
        }
        takeGroup(groupId!);
    }
}

interface Props extends RouteComponentProps, WithTranslation {
    group: GroupModel | null;
    loading: boolean;
    saveGroupData(group: GroupModel, callback?: Function): void;
    saveGroupUsers(groupId: number, ids: number[], callback?: Function): void;
    saveGroupPolymers(
        groupId: number,
        ids: number[],
        callback?: Function
    ): void;
    saveGroupSolvents(
        groupId: number,
        ids: number[],
        callback?: Function
    ): void;
    takeGroup(id: number): void;
    deleteGroup(group: GroupModel, callback?: Function): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    group: state.groups.current,
    loading: state.groups.loading
});

const mapDispatchToProps = dispatcher =>
    bindActionCreators(
        {
            takeGroup,
            deleteGroup,
            saveGroupData,
            saveGroupUsers,
            saveGroupPolymers,
            saveGroupSolvents
        },
        dispatcher
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation('app')(withRouter(Group)));
