import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
    Table,
    IconButton,
    Empty,
    Field,
    InputNumber,
    Radio,
    ProgressBar,
    Span,
    Hint
} from '../../../../../../../components/UI';
import { FormulationSolvent } from '../../../../../../../models/solvent.model';
import {
    StyledTable,
    LimitExceeded,
    Limit,
    ParamsSpan,
    VolumeCell,
    MassCell,
    RadioContainer,
    ProgressSpan,
    SpanCostMessage,
    SpanCost,
    CostContainer,
    ButtonContainer,
    ConvertButton,
    StepLine,
    SolventNameSpan,
    TER,
    CostMessage,
    HeaderSpan
} from './index.styled';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    CostEstimate,
    UnitMeasure,
    ConversionUnitMeasure,
    Estimate
} from '../../../../../../../models/conversion.model';
import {
    StepButtons,
    IWizardContext
} from '../../../../../../../components/UI';
import { SimulationContext } from '../../..';
import { outTranslator } from '../../../../../../../helpers/util';
import SimulationConfig from '../../../../../../../models/simulationConfig.model';

enum valueTypes {
    COST = '-cost',
    CONCENTRATION = '-concentration'
}

const SolventGrid = ({
    t,
    conversionLoading,
    wizardSize,
    stepNumber,
    currentStep,
    stepProgress,
    initialValues,
    limit,
    handleRemoveSolvent,
    setConcentration,
    setCost,
    onNext,
    unitMeasure,
    costEstimate,
    dispatchUnitMeasure,
    dispatchCostEstimate,
    switchCost,
    isComparation,
    isChanged
}: Props) => {
    let hasAllConcentration = true;
    const calculateConcetration = () => {
        if (initialValues.solvents) {
            return initialValues.solvents.reduce((total, solvent) => {
                if (solvent.concentration && solvent.concentration > 0) {
                    total += +solvent.concentration.toFixed(2);
                } else {
                    hasAllConcentration = false;
                }
                return total;
            }, 0);
        }
        return 0;
    };
    const unitType = initialValues.is_volume ? 'VOLUME' : 'MASS';
    const costUnit = initialValues.is_value_per_liter ? 'VOLUME' : 'MASS';
    const concentrationTotal = +calculateConcetration().toFixed(2);

    const renderLimit = () => {
        if (initialValues.solvents!.length === 0 || !limit) {
            return <Empty />;
        }
        if (initialValues.solvents!.length === limit) {
            return (
                <LimitExceeded
                    id="SolventGrid_limit_exceeded"
                    i18nKey={[
                        'simulation.list.exceeded',
                        t => ({
                            name: t('simulation.stepFormulation.solvents')
                        })
                    ]}
                />
            );
        }
        return (
            <Limit
                id="SolventGrid_limit"
                i18nKey={[
                    'simulation.list.limit',
                    t => ({
                        limit: `<b>${limit -
                            initialValues.solvents!.length}</b>`,
                        name: t('simulation.stepFormulation.solvents')
                    })
                ]}
            />
        );
    };

    const createRow = (item: FormulationSolvent, index) => {
        return (
            <Table.Row key={index} textAlign="center">
                <Table.Cell textAlign="left">
                    <SolventNameSpan>{item.solvent.name}</SolventNameSpan>
                </Table.Cell>
                <Table.Cell>{item.solvent.chemical.delta_d}</Table.Cell>
                <Table.Cell>{item.solvent.chemical.delta_p}</Table.Cell>
                <Table.Cell>{item.solvent.chemical.delta_h}</Table.Cell>
                <Table.Cell>
                    {item.solvent.chemical.relative_evaporation_rate}
                </Table.Cell>
                <Table.Cell>
                    <Field
                        name={`${item.id}${valueTypes.CONCENTRATION}`}
                        component={InputNumber}
                        placeholder="0"
                        decimalScale={2}
                        isTouched={
                            !item.concentration || item.concentration < 0.01
                                ? true
                                : false
                        }
                        onBlur={e => {
                            const value = e.target.value.replace(',', '.');
                            setConcentration({
                                value: +value,
                                id: item.id
                            });
                        }}
                    />
                </Table.Cell>
                <Table.Cell>
                    <Field
                        name={`${item.id}${valueTypes.COST}`}
                        component={InputNumber}
                        placeholder="0"
                        decimalScale={2}
                        onBlur={e => {
                            const value = e.target.value.replace(',', '.');
                            setCost({
                                value: +value,
                                id: item.id
                            });
                        }}
                    />
                </Table.Cell>
                <Table.Cell>
                    <IconButton
                        tabIndex="-1"
                        iconName="trash-alt"
                        iconSize={18}
                        onClick={() => handleRemoveSolvent(item.id)}
                    />
                </Table.Cell>
            </Table.Row>
        );
    };

    const createFirstRow = () => {
        const renderVolumeOrMassCell = () => {
            switch (unitType) {
                case 'VOLUME':
                    return (
                        <VolumeCell>
                            <HeaderSpan i18nKey="simulation.stepFormulation.solventGrid.volumePercent" />
                        </VolumeCell>
                    );
                case 'MASS':
                    return (
                        <MassCell>
                            <HeaderSpan i18nKey="simulation.stepFormulation.solventGrid.massPercent" />
                        </MassCell>
                    );
                default:
                    return <Table.Cell />;
            }
        };
        return (
            <Table.Row textAlign="center">
                <Table.Cell />
                <Table.Cell>
                    <ParamsSpan cl="primary">dD</ParamsSpan>
                </Table.Cell>
                <Table.Cell>
                    <ParamsSpan cl="primary">dP</ParamsSpan>
                </Table.Cell>
                <Table.Cell>
                    <ParamsSpan cl="primary">dH</ParamsSpan>
                </Table.Cell>
                <Table.Cell />
                {renderVolumeOrMassCell()}
                <Table.Cell>
                    <RadioContainer>
                        <Radio
                            name="costUnit"
                            i18nKey="simulation.stepFormulation.solventGrid.WeightCoin"
                            value="MASS"
                            changeValue={value => {
                                switchCost(value);
                            }}
                        />
                        <Radio
                            name="costUnit"
                            i18nKey="simulation.stepFormulation.solventGrid.literCoin"
                            value="VOLUME"
                            changeValue={value => {
                                switchCost(value);
                            }}
                        />
                    </RadioContainer>
                </Table.Cell>
                <Table.Cell />
            </Table.Row>
        );
    };

    const renderPercentMessage = () => {
        if (concentrationTotal === 100.0) {
            return <ProgressSpan>100%</ProgressSpan>;
        }
        if (concentrationTotal < 100.0) {
            return (
                <React.Fragment>
                    <Span i18nKey="simulation.stepFormulation.message.increment" />
                    <ProgressSpan data-error>
                        {(100 - concentrationTotal).toFixed(2)}%
                    </ProgressSpan>
                </React.Fragment>
            );
        }
        return (
            <React.Fragment>
                <Span i18nKey="simulation.stepFormulation.message.decrement" />
                <ProgressSpan data-error>
                    {(concentrationTotal - 100).toFixed(2)}%
                </ProgressSpan>
            </React.Fragment>
        );
    };

    const getInitialValues = () => {
        const values = {};
        if (initialValues.solvents) {
            initialValues.solvents.forEach(item => {
                if (item.concentration) {
                    values[item.id + valueTypes.CONCENTRATION] =
                        item.concentration;
                }
                if (item.cost) {
                    values[item.id + valueTypes.COST] = item.cost;
                }
            });
        }

        return {
            costUnit,
            ...values
        };
    };

    const calculateCost = () => {
        let total = 0;
        if (initialValues.solvents) {
            initialValues.solvents.forEach(solvent => {
                if (solvent.cost && solvent.concentration) {
                    total += (solvent.cost * solvent.concentration) / 100;
                } else {
                    total = 0;
                    return;
                }
            });
        }
        return total;
    };

    const validationSchema = () => {
        const values = {};
        if (initialValues.solvents) {
            initialValues.solvents.forEach((item: FormulationSolvent) => {
                values[item.id + valueTypes.COST] = yup
                    .number()
                    .min(0.01)
                    .max(9999999.99);
                values[item.id + valueTypes.CONCENTRATION] = yup
                    .number()
                    .required()
                    .min(0.01)
                    .max(999.99);
            });
        }

        return yup.object().shape(values);
    };

    const conversionValues = () => {
        const conversions: ConversionUnitMeasure[] = [];
        if (initialValues.solvents) {
            initialValues.solvents.forEach((item: FormulationSolvent) => {
                conversions.push(
                    new ConversionUnitMeasure(item.id, item.concentration!)
                );
            });
            unitMeasure.conversions = conversions;
            unitMeasure.measurement = unitType;
            dispatchUnitMeasure(unitMeasure);
            if (calculateCost() > 0) {
                const estimates: Estimate[] = [];
                initialValues.solvents.forEach(
                    (solvent: FormulationSolvent) => {
                        const estimate = new Estimate(
                            solvent.id,
                            solvent.concentration!,
                            solvent.cost!
                        );
                        estimates.push(estimate);
                    }
                );
                costEstimate.estimates = estimates;
                costEstimate.measurement.cost = costUnit;
                costEstimate.measurement.concentration = unitType;
                dispatchCostEstimate(costEstimate);
            }
        }
    };

    const handleSubmit = values => {
        const data: FormulationSolvent[] = [];
        if (initialValues.solvents) {
            initialValues.solvents.forEach((solvent: FormulationSolvent) => {
                data.push(
                    new FormulationSolvent(
                        solvent.id,
                        solvent.solvent,
                        values[solvent.id + valueTypes.CONCENTRATION],
                        values[solvent.id + valueTypes.COST]
                    )
                );
            });
        }

        onNext({ data });
    };

    const createLastRow = () => {
        return (
            <Table.Row textAlign="center">
                <Table.Cell colSpan="5" />
                <Table.Cell>
                    <ProgressBar percent={concentrationTotal} />
                    {renderPercentMessage()}
                </Table.Cell>
                <Table.Cell>
                    <CostContainer>
                        <CostMessage>
                            <SpanCostMessage i18nKey="simulation.stepFormulation.solventGrid.formulationCost" />
                            <Hint i18nKey="simulation.stepFormulation.solventGrid.costHint" />
                        </CostMessage>
                        <SpanCost>
                            <SpanCost.Coin
                                i18nKey={
                                    costUnit === 'MASS'
                                        ? 'simulation.stepFormulation.solventGrid.WeightCoin'
                                        : 'simulation.stepFormulation.solventGrid.literCoin'
                                }
                            />
                            {calculateCost()
                                .toFixed(2)
                                .replace('.', ',')}
                        </SpanCost>
                    </CostContainer>
                </Table.Cell>
                <Table.Cell />
            </Table.Row>
        );
    };

    if (initialValues.solvents && initialValues.solvents.length === 0) {
        return <Empty />;
    }

    const values = getInitialValues();
    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={values}
            enableReinitialize
            isInitialValid
            onSubmit={handleSubmit}
            render={formikProps => {
                return (
                    <Form>
                        {renderLimit()}
                        <StyledTable celled structured>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell width={5}>
                                        {t(
                                            'simulation.stepFormulation.solventGrid.solvents'
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        textAlign="center"
                                        width={4}
                                        colSpan={3}
                                    >
                                        {t(
                                            'simulation.stepFormulation.solventGrid.solubilityParameters'
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        textAlign="center"
                                        width={1}
                                    >
                                        <TER>
                                            TER
                                            <Hint
                                                i18nKey="simulation.stepFormulation.solventGrid.TERHint"
                                                color="#ffffff"
                                            />
                                        </TER>
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        width={2}
                                        textAlign="center"
                                    >
                                        {t(
                                            'simulation.stepFormulation.solventGrid.concentration'
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell
                                        width={3}
                                        textAlign="center"
                                    >
                                        {t(
                                            'simulation.stepFormulation.solventGrid.cost'
                                        )}
                                    </Table.HeaderCell>
                                    <Table.HeaderCell width={1} />
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {createFirstRow()}
                                {initialValues.solvents &&
                                    initialValues.solvents.map((item, index) =>
                                        createRow(item, index)
                                    )}
                                {createLastRow()}
                            </Table.Body>
                        </StyledTable>

                        <StepLine id="StepButtons_line" />
                        <ButtonContainer>
                            <ConvertButton
                                id="Button_convert"
                                loading={conversionLoading}
                                i18nKey={
                                    unitType === 'MASS'
                                        ? `simulation.stepFormulation.button.convertVolume`
                                        : 'simulation.stepFormulation.button.convertMass'
                                }
                                cl="invertedPrimary"
                                onClick={
                                    concentrationTotal === 100 &&
                                    hasAllConcentration &&
                                    formikProps.isValid
                                        ? () => conversionValues()
                                        : () => {}
                                }
                                disabledWithTooltip={
                                    concentrationTotal === 100 &&
                                    hasAllConcentration &&
                                    formikProps.isValid
                                        ? false
                                        : true
                                }
                                tooltip={
                                    concentrationTotal !== 100
                                        ? outTranslator(
                                              'simulation.stepFormulation.solventGrid.conversionDisable'
                                          )
                                        : ''
                                }
                            />
                        </ButtonContainer>
                        <SimulationContext.Consumer>
                            {context => (
                                <StepButtons
                                    {...context}
                                    fromStep={stepNumber}
                                    disableNext={
                                        concentrationTotal !== 100 ||
                                        !formikProps.isValid ||
                                        !hasAllConcentration ||
                                        isComparation ||
                                        !isChanged
                                    }
                                    onNext={formikProps.handleSubmit}
                                    onSave={() => {}}
                                    onCancel={() => {}}
                                    currentStep={currentStep}
                                    stepProgress={stepProgress}
                                    wizardSize={wizardSize}
                                />
                            )}
                        </SimulationContext.Consumer>
                    </Form>
                );
            }}
        />
    );
};

export interface Props extends WithTranslation, IWizardContext {
    isComparation: boolean;
    isChanged: boolean;
    currentStep: number;
    stepNumber: number;
    initialValues: Partial<SimulationConfig>;
    limit: number;
    costEstimate: CostEstimate;
    unitMeasure: UnitMeasure;
    conversionLoading: boolean;
    conversionMessageError: string;
    onNext(data): void;
    handleRemoveSolvent(id: number): void;
    dispatchCostEstimate(data: CostEstimate): void;
    dispatchUnitMeasure(data: UnitMeasure): void;
    setConcentration(data: { value: number; id: number }): void;
    setCost(data: { value: number; id: number }): void;
    switchCost(type): void;
}

export default withTranslation('app')(SolventGrid);
