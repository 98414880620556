import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import _ from 'lodash';
import { dialog } from '../../../components/UI';
import {
    getProjectsSuccess,
    getProjectsError,
    saveProjectSuccess,
    saveProjectError,
    setProject,
    deleteProjectSuccess,
    deleteProjectError,
    recoverProjectError
} from '../../actions/projects.actions';

const translationPath = 'redux.sagas.projects.';

export function* getProjectsRequest(action) {
    const { filter, callback } = action.payload;
    try {
        const { status, data } = yield call(
            api.get,
            `/projects?page=${filter.page}&limit=${filter.limit}&sort=${filter.sort}`
        );
        if (status === 200 || status === 204) {
            yield put(getProjectsSuccess(data.paging, data.object));
            if (callback) {
                callback();
            }
        } else {
            yield put(getProjectsError());
        }
    } catch (ex) {
        yield put(getProjectsError());
    }
}

export function* saveProjectRequest(action) {
    const { project, callback } = action.payload;
    const loading = dialog({
        type: 'loading',
        i18nKey: {
            name: translationPath + 'saveProject.loading',
            vars: { name: project.name }
        },
        instantShow: false
    });
    loading.show();
    try {
        if (!project.simulations) {
            const { data, status } = yield call(
                api.get,
                `/projects/${project.id}`
            );
            if (status === 200 && data.object) {
                project.simulations = data.object.simulations;
            } else {
                throw new Error();
            }
        }
        const { data } = yield call(
            project.isNew ? api.post : api.put,
            `/projects${!project.isNew ? `/${project.id}` : ''}`,
            _.omit(project, ['created_at', 'updated_at', 'count_simulations'])
        );
        yield put(saveProjectSuccess(data.object));
        if (callback) {
            callback();
        }
    } catch (err) {
        yield put(saveProjectError());
    }
    loading.close();
}

export function* recoverProjectRequest(action) {
    const { id, callback } = action.payload;
    const loading = dialog({
        type: 'loading',
        i18nKey: translationPath + 'recoverProject.loading',
        instantShow: false
    });
    loading.show();
    try {
        const { data, status } = yield call(api.get, `/projects/${id}`);
        if (status === 200 && data.object) {
            yield put(setProject(data.object));
            if (callback) {
                callback(data.object);
            }
        } else {
            yield put(recoverProjectError());
        }
    } catch (e) {
        yield put(recoverProjectError());
    }
    loading.close();
}

export function* deleteProjectRequest(action) {
    const { project, callback } = action.payload;
    // const loading = dialog({
    //     type: 'loading',
    //     i18nKey: {
    //         name: translationPath + 'deleteProject.loading',
    //         vars: { name: project.name }
    //     },
    //     instantShow: false
    // });
    // loading.show();
    try {
        const { status } = yield call(api.delete, `/projects/${project.id}`);
        if (status === 200) {
            yield put(deleteProjectSuccess(project.name));
            if (callback) {
                callback();
            }
        } else {
            yield put(deleteProjectError(project.name));
        }
    } catch (ex) {
        yield put(deleteProjectError(project.name));
    }
    // loading.close();
}
