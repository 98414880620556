import React from 'react';
import { Icon } from '../index';

export const Trash = ({ id, handleOnClick }: ITrashProps) => (
    <Icon
        id={id ? `trash-${id}` : 'trash'}
        name="trash-alt"
        onClick={handleOnClick}
    />
);

export interface ITrashProps {
    id?: string;
    handleOnClick(fn);
}
