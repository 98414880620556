import React from 'react';
import { Breadcrumb } from '../../../../../../../../components/UI';
import { WithTranslation } from 'react-i18next';
import { Container, Data } from './index.styled';
import { Term } from '../../../../../../../../models/term.model';
import TermsForm from '../../../../../../../../components/forms/Terms';
import { RouteComponentProps, Link } from 'react-router-dom';
import { confirmCancel } from '../../../../../../../../components/UI/Dialog';
const MASTER_TERMS_PATH = '/master/terms'; // Define a constant for the duplicated path

const TermContainer = ({ t, history, term, saveTerms }: Props) => {
    const handleSubmit = data => {
        const updatedData = {
            ...term,
            ...data
        };
        saveTerms(updatedData, () => {
            history!.push(MASTER_TERMS_PATH);
        });
    };

    const handleCancel = (haveData: boolean) => {
        if (!haveData) {
            history!.push(MASTER_TERMS_PATH);
        } else {
            confirmCancel(() => {
                history!.push(MASTER_TERMS_PATH);
            });
        }
    };

    const renderBreadcrumb = () => {
        return (
            <Breadcrumb>
                <Breadcrumb.Section>
                    <Link to="/master/terms">
                        {t('master.terms.term.termsOfUse')}
                    </Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section>
                    {t(`master.terms.language.${term.language}`)}
                </Breadcrumb.Section>
            </Breadcrumb>
        );
    };

    return (
        <Container>
            {renderBreadcrumb()}
            <Data>
                <TermsForm
                    term={term}
                    onCancel={handleCancel}
                    onSubmit={handleSubmit}
                />
            </Data>
        </Container>
    );
};

interface Props extends WithTranslation, Partial<RouteComponentProps> {
    term: Term;
    saveTerms(term: Term, callback?: Function): void;
}

export default TermContainer;
