import React from 'react';
import { StyledSpan } from './index.styled';
import { withTranslationText } from '../../hoc/withTranslationText';
import { Icon } from '../Icon';
import { Empty } from '../Empty';

export const Span = withTranslationText(
    ({ renderText, children, icon, ...props }: ISpanProps) => {
        return (
            <StyledSpan {...props}>
                {icon ? <Icon name={icon} /> : <Empty />}
                {renderText ? renderText : children}
            </StyledSpan>
        );
    }
);

interface ISpanProps {
    id?: string;
    icon?: string;
    renderText?: string;
    cl?: string;
    children?;
}
