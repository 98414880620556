import React from 'react';
import {
    Wizard,
    IStep,
    createWizardContext,
    initWizardContext
} from '../../../../components/UI';
import _ from 'lodash';
import StepPolymer from './StepPolymer';
import StepCondition from './StepCondition';
import StepFormulation from './StepFormulation';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { simulationRequest } from '../../../../redux/actions/simulation.actions';
import SimulationResultWithConfigs from '../../../../models/simulationResultWithConfigs.model';
import SimulationConfig, {
    ConditionsConfig
} from '../../../../models/simulationConfig.model';

export const wizardSteps: IStep[] = [
    {
        i18nTitle: 'simulation.stepPolymer.stepInfo.title',
        component: StepPolymer
    },
    {
        i18nTitle: 'simulation.stepCondition.stepInfo.title',
        component: StepCondition
    },
    {
        i18nTitle: 'simulation.stepFormulation.stepInfo.title',
        component: StepFormulation
    }
];

export const SimulationContext = createWizardContext();

const Configuration = ({
    simulationRequest,
    simulation,
    config,
    isComparation
}: Props) => {
    const [currentSimulation, setCurrentSimulation] = React.useState(
        simulation
    );

    const contextValues = initWizardContext(
        config.polymers.length !== 0 && config.solvents.length !== 0
            ? wizardSteps.length
            : 1,
        wizardSteps
    );

    if (currentSimulation !== simulation) {
        if (!simulation) {
            contextValues.resetSteps();
        }
        setCurrentSimulation(simulation);
    }

    let isChanged = false;
    if (currentSimulation) {
        if (!_.isEqual(currentSimulation.parameters, config)) {
            isChanged = true;
        }
    } else {
        const empty = new SimulationConfig();
        empty.is_value_per_liter = false;
        empty.is_volume = null;
        empty.polymers = [];
        empty.solvents = [];
        empty.conditions = new ConditionsConfig();
        if (
            !_.isEqual(
                { ...config, ['__proto__']: Object.prototype },
                { ...empty, ['__proto__']: Object.prototype }
            )
        ) {
            isChanged = true;
        }
    }

    return (
        <Wizard
            steps={wizardSteps}
            context={SimulationContext}
            value={contextValues}
            componentProps={{
                simulate: simulationRequest,
                isComparation,
                isChanged
            }}
        />
    );
};

interface Props {
    id: string;
    simulation?: SimulationResultWithConfigs;
    config: SimulationConfig;
    isComparation: boolean;
    simulationRequest(): void;
}

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            simulationRequest
        },
        dispatch
    );

export default connect(
    null,
    mapDispatchToProps
)(Configuration);
