import styled from 'styled-components';
import {
    Card,
    Label as UILabel,
    Span
} from '../../../../../../../components/UI';

export const Polymers = styled.section`
    padding-bottom: 28px;
`;

export const Label = styled(UILabel)`
    height: 19px;
    color: #444444;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    display: inline-block;
`;

export const Limit = styled(Span)`
    color: #444444;
    font-size: 14px;
    line-height: 23px;
    float: right;
`;

export const LimitExceeded = styled(Span)`
    color: #a70e1a;
    font-size: 14px;
    line-height: 23px;
    float: right;
`;

export const PolymersOrdenedList = styled.ol`
    width: calc(100% + 10px);
    padding: 0;
    padding-top: 10px;
    margin-left: -5px !important;
`;
PolymersOrdenedList.displayName = 'ol';

export const PolymerListItem = styled.li`
    width: 50%;
    box-sizing: border-box;
    display: inline-block;
    padding: 5px;

    @media (max-width: 767px) {
        width: 100%;
    }
`;
PolymerListItem.displayName = 'li';

export const PolymerName = styled.span`
    color: #763689;
    font-size: 16px;
    line-height: 27px;
    margin-right: 20px;
`;

export const PolymerConcentration = styled.span`
    color: #a6a6a6;
    font-size: 16px;
    line-height: 27px;
`;

export const PolymerConcentrationPercentage = styled.span`
    color: #444444;
    font-size: 16px;
    line-height: 20px;
    margin-left: 10px;
`;

export const PolymerInf = styled.div`
    display: grid;
    padding: 15px 0;
    flex: 1;
`;

export const PolymerCard = styled(Card)`
    position: relative;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    min-height: 90px;
    float: left;

    i {
        color: #444444;
        font-size: 20px;
        cursor: pointer;
    }

    i:hover {
        color: #a90b50;
    }
`;
