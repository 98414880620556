import * as THREE from 'three';
import helveticaRegular from '../assets/fonts/helvetiker_bold.typeface.json';

export const makeTextSprite = (message, parameters = {}) => {
    const fontface = parameters['fontface'] || 'Helvetica';
    const fontsize = parameters['fontsize'] || 50;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    context.font = fontsize + 'px ' + fontface;

    // text color
    context.fillStyle = 'rgba(0, 0, 0, 1.0)';
    context.fillText(message, 0, fontsize);

    // canvas contents will be used for a texture
    const texture = new THREE.Texture(canvas);
    texture.minFilter = THREE.LinearFilter;
    texture.needsUpdate = true;

    const spriteMaterial = new THREE.SpriteMaterial({
        map: texture
    });
    const sprite = new THREE.Sprite(spriteMaterial);
    sprite.scale.set(100, 50, 1.0);
    return sprite;
};

export const createEllipsoidGeometry = function(
    width,
    height,
    depth,
    widthSegments,
    heightSegments,
    phiStart?,
    phiLength?,
    thetaStart?,
    thetaLength?
) {
    const geometry = new THREE.SphereGeometry(
        width,
        widthSegments,
        heightSegments,
        phiStart,
        phiLength,
        thetaStart,
        thetaLength
    );
    geometry.applyMatrix(
        new THREE.Matrix4().makeScale(1.0, height / width, depth / width)
    );
    return geometry;
};

export const renderPlot = (scene, object) => {
    const find = scene.children.find(item => item.name === object.name);
    if (!find) {
        scene.add(object);
    }
};

export const singlePush = (array, object) => {
    const find = array.find(item => item.name === object.name);
    if (!find) {
        array.push(object);
    }
};

export const buildGraphHelper = (
    gridSize,
    scale,
    initialX,
    initialY,
    initialZ
) => {
    const scene = new Array<any>();
    const buildHelperXYX = (direction: 'x' | 'y' | 'z', helper, position) => {
        helper.position.copy({ x: 0, y: 0, z: 0, ...position });
        helper.material['opacity'] = 0.25;
        helper.material['transparent'] = true;
        helper.receiveShadow = true;
        helper.name = `base@helper_${direction}`;
        return helper;
    };
    const buildX = () => {
        const helper = new THREE.GridHelper(gridSize * scale, gridSize);
        scene.push(buildHelperXYX('x', helper, { y: initialY() }));
    };
    const buildY = () => {
        const helper = new THREE.GridHelper(gridSize * scale, gridSize).rotateZ(
            Math.PI / 2
        );
        scene.push(
            buildHelperXYX('y', helper, {
                x: initialX(),
                y: initialX() * -1
            })
        );
    };
    const buildZ = () => {
        const helper = new THREE.GridHelper(gridSize * scale, gridSize).rotateX(
            Math.PI / 2
        );
        scene.push(
            buildHelperXYX('z', helper, {
                z: initialZ(),
                y: initialZ() * -1
            })
        );
    };
    buildX();
    buildY();
    buildZ();
    return scene;
};

export const buildLine = (
    name,
    color,
    size = { x: 0, y: 0, z: 0 },
    position = { x: 0, y: 0, z: 0 },
    rotation?
) => {
    const lineGeometry = new THREE.BoxGeometry(size.x, size.y, size.z);
    const lineMaterial = new THREE.MeshBasicMaterial({ color });
    const lineMesh = new THREE.Mesh(lineGeometry, lineMaterial);
    lineMesh.name = name;
    lineMesh.position.x = position.x;
    lineMesh.position.y = position.y;
    lineMesh.position.z = position.z;
    if (rotation) {
        lineMesh.rotation.y = rotation;
    }
    return lineMesh;
};

export const buildAxis = (
    controls,
    camera,
    gridSize,
    scale,
    initialX,
    initialY,
    initialZ
) => {
    const scene = new Array<any>();
    const buildLabel = (name, position) => {
        const font = new THREE.FontLoader().parse(helveticaRegular);
        const textGeo = new THREE.TextBufferGeometry(name, {
            font: font,
            size: 20,
            height: 1,
            curveSegments: 1
        });
        const textMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
        const textMesh = new THREE.Mesh(textGeo, textMaterial);
        textMesh.name = `base@axis_label_${name}`;
        textMesh.position.copy(position);
        controls.addEventListener('change', _ =>
            textMesh.rotation.copy(camera.rotation)
        );
        scene.push(textMesh);
    };
    const buildAxisLabel = direction => {
        for (let i = 0; i <= gridSize; i++) {
            if (i % 5 === 0 && i !== 0) {
                const position = {
                    dP: initialX(),
                    dH: initialY(),
                    dD: initialZ()
                };
                position[direction] += i * scale;
                scene.push(
                    buildLine(
                        `base@axis_divisors_${direction}_${i}`,
                        0x000000,
                        { x: 6, y: 6, z: 6 },
                        { x: position.dP, y: position.dH, z: position.dD }
                    )
                );

                buildLabel(
                    i.toString(),
                    new THREE.Vector3(
                        direction === 'dD' ? position.dP - 50 : position.dP,
                        position.dH,
                        direction === 'dP' || 'dH'
                            ? position.dD - 50
                            : position.dD
                    )
                );
            }
            if (i === gridSize) {
                const position = {
                    dP: initialX(),
                    dH: initialY(),
                    dD: initialZ()
                };
                position[direction] += gridSize * scale + 50;
                buildLabel(
                    direction.toString(),
                    new THREE.Vector3(position.dP, position.dH, position.dD)
                );
            }
        }
    };
    const buildAxisLine = (name, size, position, rotate?) => {
        buildAxisLabel(name);
        scene.push(
            buildLine(
                `base@axis_line_${name}`,
                0xa6a6a6,
                {
                    x: 5,
                    y: 0,
                    z: 5,
                    ...size
                },
                {
                    x: initialX(),
                    y: initialY(),
                    z: initialZ(),
                    ...position
                },
                rotate
            )
        );
    };
    const buildX = () => {
        buildAxisLine(
            'dP',
            { x: gridSize * scale },
            { x: initialX() + (gridSize * scale) / 2 }
        );
    };
    const buildY = () => {
        buildAxisLine(
            'dH',
            { y: gridSize * scale },
            { y: initialY() + (gridSize * scale) / 2 }
        );
    };
    const buildZ = () => {
        buildAxisLine(
            'dD',
            { x: gridSize * scale },
            { z: initialZ() + (gridSize * scale) / 2 },
            Math.PI / 2
        );
    };
    buildX();
    buildY();
    buildZ();
    return scene;
};
