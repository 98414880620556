import React, { PureComponent } from 'react';
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Area
} from 'recharts';
import CustomTooltip from '../Tooltip';
import { Container } from './index.styled';
import SimulationResultWithConfigs from '../../../models/simulationResultWithConfigs.model';
import { toFixedTrunc, normalizePolymersValuesForComparation } from '../../../helpers/util';

export class LineChart extends PureComponent<Props> {
    positions = [ 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90];

    get normalizedValuesForComparation() {
        const { simulations } = this.props;
        return normalizePolymersValuesForComparation(this.positions, simulations);
    }

    private createDataValues() {
        let max = 0;
        let min = 99999;
        const values: any[] = [];
        this.normalizedValuesForComparation.forEach(polymers => {
            polymers.forEach(value => {
                value.axes.forEach((axe, index) => {
                    if (axe.y >= max) {
                        max = axe.y;
                    }
                    if (axe.y < min) {
                        min = axe.y;
                    }
                    const y = toFixedTrunc(+max + 0.1, 2);
                    if (!values[index]) {
                        values.push({
                            x: axe.x,
                            y,
                            [`${value.name}///${value.simulation.name}///${value.simulation.date}`]: axe.y
                        });
                    } else {
                        values[index][
                            `${value.name}///${value.simulation.name}///${value.simulation.date}`
                        ] = axe.y;
                    }
                });
            });
        });
        return { values, min, max };
    }

    private renderLines() {
        const { simulations } = this.props;
        const lines: any[] = [];
        simulations.forEach(simulation => {
            simulation.result.graphs.polymer_normalized_distance.forEach(
                (polymer, index) => {
                    lines.push(
                        <Line
                            key={`${polymer.id}///${simulation.name}`}
                            dataKey={`${polymer.name}///${simulation.name}///${simulation.date}`}
                            type="monotone"
                            dot={() => {}}
                            strokeDasharray={
                                simulation.selected === 1 ? null : '5 5'
                            }
                            stroke={
                                simulation.selected === 1
                                    ? this.props.colors.current[index]
                                    : this.props.colors.proposal[index]
                            }
                        />
                    );
                }
            );
        });
        return lines;
    }

    render() {
        let { values, min, max } = this.createDataValues();
        min = +(min - 0.1).toFixed(2);
        max = +(max + 0.1).toFixed(2);

        return (
            <Container>
                <ResponsiveContainer>
                    <ComposedChart
                        data={values}
                        syncId="anyId"
                        margin={{
                            top: 0,
                            right: 0,
                            left: 20,
                            bottom: 0
                        }}
                    >
                        <CartesianGrid />
                        <Tooltip
                            wrapperStyle={{ zIndex: 8 }}
                            content={<CustomTooltip />}
                        />
                        <Area
                            type="monotone"
                            dataKey={() => min}
                            fill="#fff"
                            stroke="tranparent"
                            strokeWidth={2}
                            stackId="1"
                            activeDot={() => {}}
                        />
                        <Area
                            type="monotone"
                            dataKey={() => {
                                if (min > 1) {
                                    return 0;
                                } else {
                                    return 1 - min;
                                }
                            }}
                            fill="#c1dcd1"
                            stroke="#0A7648"
                            strokeWidth={2}
                            stackId="1"
                            activeDot={() => {}}
                        />
                        <Area
                            activeDot={() => {}}
                            type="monotone"
                            dataKey={() => 9}
                            fill="#e9c2c6"
                            stroke="#e9c2c6"
                            stackId="1"
                        />
                        {this.renderLines()}
                        <XAxis
                            dataKey="x"
                            type="number"
                            ticks={this.positions}
                            domain={[0, 90]}
                            allowDecimals={false}
                        />
                        <YAxis dataKey="y" domain={[min, max]} />
                    </ComposedChart>
                </ResponsiveContainer>
            </Container>
        );
    }
}

interface Props {
    simulations: SimulationResultWithConfigs[];
    colors: {
        current: string[];
        proposal: string[];
    };
}
