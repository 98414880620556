import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { PolymerState } from './types/polymers.types';
import { PaintsState } from './types/paints.types';
import { SolventsState } from './types/solvents.types';
import { ConversionState } from './types/conversion.types';
import reducers from './reducers';
import sagas from './sagas';
import { SimulationState } from './types/simulation.types';
import { UserState } from './types/user.types';
import { ContentState } from './types/content.types';
import { SearchState } from './types/search.types';
import { UsersState } from './types/users.types';
import { GroupsState } from './types/groups.types';
import { TermsState } from './types/terms.types';
import { ProjectsState } from './types/projects.types';
import { PropertiesState } from './types/properties.types';

export interface ApplicationState {
    polymers: PolymerState;
    paints: PaintsState;
    solvents: SolventsState;
    conversion: ConversionState;
    simulation: SimulationState;
    content: ContentState;
    user: UserState;
    search: SearchState;
    users: UsersState;
    groups: GroupsState;
    terms: TermsState;
    projects: ProjectsState;
    properties: PropertiesState;
}

const middlewares: any[] = [];

const sagaMiddleware = createSagaMiddleware();

middlewares.push(sagaMiddleware);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ 
    && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) 
    || compose;
const store: Store<ApplicationState> = createStore(
    reducers,
    {},
    composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(sagas);

export default store;
