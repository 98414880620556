import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

export const StyledReactTooltip = styled(ReactTooltip)`
    &.__react_component_tooltip {
        font-weight: normal;
        z-index: 9;
        font-size: 14px;
        line-height: 26px;
        color: #444444;
        text-align: center;
        min-height: 45px;
        min-width: 186px;
        max-width: 280px;
        border: 0.5px solid #dddddd;
        border-radius: 2px;
        background-color: #ffffff;
        box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.2),
            0 0 4px 2px rgba(204, 204, 204, 0.15);

        &.show {
            opacity: 1;
        }

        &.type-light {
            &.place-top::before {
                bottom: -6px;
                border-top-color: #dddddd;
                border-top-style: solid;
                border-top-width: 6px;
            }
            &.place-top::after {
                bottom: -5px;
                border-top-color: #ffffff;
            }
            &.place-bottom::before {
                top: -6px;
                border-top-color: #dddddd;
                border-top-style: solid;
                border-top-width: 6px;
            }
            &.place-bottom::after {
                top: -5px;
                border-bottom-color: #ffffff;
            }
            &.place-left::before {
                right: -6px;
                border-right-color: #dddddd;
                border-right-style: solid;
                border-right-width: 6px;
            }
            &.place-left::after {
                right: -5px;
                border-left-color: #ffffff;
            }
            &.place-right::before {
                left: -6px;
                border-left-color: #dddddd;
                border-left-style: solid;
                border-left-width: 6px;
            }
            &.place-right::after {
                right: -5px;
                border-right-color: #ffffff;
            }
        }
    }
`;
