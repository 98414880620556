import styled from 'styled-components';

export const headerHeight = 101;

const Header = styled.header`
    height: ${headerHeight}px;
    width: 100%;
    position: relative;
    z-index: 11;
`;

Header.displayName = 'header';

export { Header };
