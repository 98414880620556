import { action } from 'typesafe-actions';
import { GroupsTypes } from '../types/groups.types';
import Group, { GroupRequest } from '../../models/group.model';
import { dialog } from '../../components/UI';

export const searchGroups = (search, callback) =>
    action(GroupsTypes.SEARCH_GROUPS_REQUEST, { search, callback });

export const searchGroupsSuccess = data =>
    action(GroupsTypes.SEARCH_GROUPS_SUCCESS, data);

export const searchGroupsError = () =>
    action(GroupsTypes.SEARCH_GROUPS_FAILURE);

export const deleteGroup = (group: Group, callback?: Function) =>
    action(GroupsTypes.DELETE_GROUP_REQUEST, { group, callback });

export const deleteGroupSuccess = (group: Group) => {
    dialog({
        type: 'success',
        i18nKey: {
            name: 'redux.actions.groups.delete.success',
            vars: { name: group.name }
        }
    });
    return action(GroupsTypes.DELETE_GROUP_SUCCESS);
};

export const deleteGroupError = (group: Group) => {
    dialog({
        type: 'error',
        i18nKey: {
            name: 'redux.actions.groups.delete.error',
            vars: { name: group.name }
        }
    });
    return action(GroupsTypes.DELETE_GROUP_FAILURE);
};

export const takeGroup = (id: number) =>
    action(GroupsTypes.TAKE_GROUP_REQUEST, id);

export const takeGroupSuccess = payload =>
    action(GroupsTypes.TAKE_GROUP_SUCCESS, payload);

export const takeGroupError = () => {
    dialog({
        type: 'error',
        i18nKey: 'redux.actions.groups.takeError'
    });
    return action(GroupsTypes.TAKE_GROUP_FAILURE);
};

export const saveGroupData = (group: GroupRequest, callback?: Function) => {
    return action(GroupsTypes.SAVE_GROUP_DATA, { group, callback });
};

export const saveGroupUsers = (
    id: number,
    users: number[],
    callback?: Function
) => {
    return action(GroupsTypes.SAVE_GROUP_USERS, { id, ids: users, callback });
};

export const saveGroupPolymers = (
    id: number,
    polymers: number[],
    callback?: Function
) => {
    return action(GroupsTypes.SAVE_GROUP_POLYMERS, {
        id,
        ids: polymers,
        callback
    });
};

export const saveGroupSolvents = (
    id: number,
    solvents: number[],
    callback?: Function
) => {
    return action(GroupsTypes.SAVE_GROUP_SOLVENTS, {
        id,
        ids: solvents,
        callback
    });
};

export const saveGroupSuccess = (isCreate?: boolean) => {
    dialog({
        type: 'success',
        i18nKey: isCreate
            ? 'redux.actions.groups.create.success'
            : 'redux.actions.groups.save.success'
    });
    return action(GroupsTypes.SAVE_GROUP_SUCCESS);
};

export const saveGroupError = () => {
    dialog({
        type: 'error',
        i18nKey: 'redux.actions.groups.save.error'
    });
    return action(GroupsTypes.SAVE_GROUP_FAILURE);
};
