import Search from '../../models/search.model';

/**
 * Action types
 */
export enum SearchTypes {
    SEARCH_REQUEST = 'search:SEARCH_REQUEST',
    SEARCH_SUCCESS = 'search:SEARCH_SUCCESS',
    SEARCH_FAILURE = 'search:SEARCH_FAILURE'
}

/**
 * State types
 */
export interface SearchState {
    readonly items: Search[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly message: string;
}
