import styled from 'styled-components';
import {
    Grid,
    Span,
    Column,
    Icon,
    Row,
    Input,
    Button,
    Checkbox
} from '../../../../../components/UI';
import colors from '../../../../../theme/colors';

export const StyledCard: any = styled.div`
    width: 100%;
    border: 0.5px solid #cccccc;
    border-radius: 4px;
    overflow: hidden;
    display: inline-block;
    margin: 2.5px;
    position: relative;
    background-color: #fff;

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(68, 68, 68, 0.25),
            0 0 12px 0 rgba(166, 166, 166, 0.5);
    }
`;

StyledCard.Header = styled(Grid)`
    width: 100%;
    height: 64px;
    background-color: #763689;
    padding: 0px 10px;
`;

StyledCard.Header.Row = styled(Row)`
    height: 100%;
`;

StyledCard.Header.Column = styled(Column)`
    height: 100%;
`;

StyledCard.HeaderInputName = styled(Input)`
    width: 100%;

    &.ui.input > input {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        font-weight: 600;
        border: none;
        padding: 0;
    }

    &.ui.input > input:hover,
    &.ui.input > input:active,
    &.ui.input > input:focus,
    &.ui.input > input:focus-within {
        border: none !important;
        background-color: transparent;
        border-radius: 0 !important;
        border-bottom: 1px solid #fff !important;
    }
`;

StyledCard.HeaderDateTime = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
`;

StyledCard.HeaderDate = styled(Span)`
    font-size: 16px;
    color: #ffffff;
    font-weight: 600;
`;

StyledCard.HeaderTime = styled(Span)`
    font-size: 10px;
    color: #ffffff;
    font-weight: 600;
`;

StyledCard.HeaderFlask = styled(Icon)`
    font-size: 25px;
    color: #ffffff;
    margin-right: 10px;
`;

StyledCard.Body = styled.div`
    padding: 15px;
    min-height: 180px;
`;

StyledCard.BodySpan = styled(Span)`
    font-size: 16px;
    line-height: 20px;
    padding-right: 7px;
`;

StyledCard.BodySpan.DPH = styled.div`
    display: inline-block;
`;

StyledCard.UnitMass = styled(StyledCard.BodySpan)`
    color: #e09a05;
`;

StyledCard.UnitVolume = styled(StyledCard.BodySpan)`
    color: #c40d5d;
`;

StyledCard.BodyRow = styled(Row)`
    padding: 10px 0;
`;

StyledCard.PolymersRow = styled(Row)`
    padding: 5px 0;
`;

StyledCard.PolymersRow.Row = styled(Row)`
    padding: 0;
`;

StyledCard.Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: ${props => (props['data-checked'] ? '0px' : '70px')};
    background-color: rgba(68, 68, 68, 0.9);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
`;

StyledCard.Overlay.ComparativeCheck = styled(Checkbox)`
    position: absolute;
    left: 15px;
    top: 15px;

    .ui.checkbox {
        width: 30px;
        height: 30px;

        input:focus ~ label:before {
            border-color: ${props =>
                props['checked'] ? '#763689' : '#fff'} !important;
        }
    }

    label {
        &::before {
            width: 30px !important;
            height: 30px !important;
            border-radius: 100% !important;
            ${props => {
                if (props['value']) {
                    return `
                        background: #0DA565 !important;
                        border: 0px !important;
                    `;
                } else {
                    return `
                        background: transparent !important;
                        border: 2px solid #fff !important;
                    `;
                }
            }}
        }
        &::after {
            font-size: 26px !important;
            line-height: 30px;
            height: 30px !important;
            width: 30px !important;
        }
    }
`;

StyledCard.Overlay.ResultButton = styled(Button)`
    &.ui.button {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 4px;
        font-size: 17px;
        font-weight: 600;
        padding: 5px 30px;
        box-shadow: inset 0px 0px 0px 1px #fff;

        &:hover {
            background-color: transparent;
            box-shadow: inset 0px 0px 0px 2px #fff;
        }
    }
`;

StyledCard.Footer = styled.div`
    padding: 15px;
    border-top: 0.5px solid #cccccc;
    height: 70px;
    text-align: right;
`;

StyledCard.FooterBtn = styled(Span)`
    color: #a6a6a6;
    font-size: 16px;
    font-weight: 600;
    line-height: 40px;
    padding: 0px 15px;

    &:hover {
        cursor: pointer;
        color: #a90b50;
    }
`;

StyledCard.LockedOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background-color: rgba(118, 54, 137, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
`;

StyledCard.LockedOverlay.Padlock = styled(Icon)`
    font-size: 48px;
    color: #ffffff;
    opacity: 0.5;
`;

StyledCard.FooterExpandBtn = styled(StyledCard.FooterBtn)`
    color: ${colors.buttons.invertedPrimary.color};

    &:hover {
        color: ${colors.buttons.invertedPrimary.hover.color};
    }
`;

StyledCard.SpanRed = styled(Span)`
    color: #a70e1a;
    margin-right: 7.5px;
`;
StyledCard.SpanGreen = styled(Span)`
    color: #0b8e57;
    margin-right: 7.5px;
`;
