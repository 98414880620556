import styled from 'styled-components';
import { Span, Column } from '../../../../../../components/UI';
import { PieChart } from 'recharts';

export const SizedBox = styled.div`
    display: inline-flex;
    width: ${props => (props['data-comparation'] ? '50%' : '100%')};

    @media (max-width: 1280px) {
        width: 100%;
    }

    @media (max-width: 480px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`;

export const Graph = styled.div`
    height: 250px;
    position: relative;
`;

export const GraphLegend = styled.div`
    position: relative;
    width: 100%;
`;

const SpanInsideGraph = styled(Span)`
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    width: 150px;
    text-align: center;
    height: 20px;
    color: #a6a6a6;
    font-size: 14px;
    font-weight: 600;
`;

export const GraphColumn = styled(Column)`
    min-width: 250px;
    display: block;
`;

export const FormulationSpan = styled(SpanInsideGraph)`
    top: 30px;
    bottom: 0;
`;

export const NameSpan = styled(SpanInsideGraph)`
    top: -10px;
    bottom: 0;
    width: 200px;
    color: #444444;
    font-size: 14px;
    font-weight: 600;
`;

export const Chart = styled(PieChart)``;
