import styled from 'styled-components';
import { H2, Span } from '../../../components/UI';

export const Container = styled.div`
    width: 100%;
    padding: 25px 0;
`;

export const ImageBox = styled.div`
    max-height: 400px;
    width: 100%;
    border-radius: 4px;
    background-color: #fbf9fb;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
`;

export const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

export const Title = styled(H2)`
    line-height: 36px;
    width: 100%;
    margin-bottom: 10px;
`;

export const Description = styled(Span)`
    color: #444444;
    font-size: 18px;
    line-height: 27px;
    width: 100%;
    display: block;
    margin-bottom: 15px;
`;
