import styled from 'styled-components';
import colors from '../../../theme/colors';

export const StyledH3 = styled.h3`
    height: 30px;
    color: #444444;
    font-size: 18px;
    font-weight: 600;
    line-height: 23px;

    ${(props: any) => {
        const { cl = 'default' } = props;
        return `
            color: ${colors.label[cl].color};
        `;
    }}
`;
