import styled from 'styled-components';
import { Breadcrumb as SemanticBreadcrumb } from 'semantic-ui-react';

export const StyledBreadcrumb: any = styled(SemanticBreadcrumb)`
    .section {
        color: #a6a6a6 !important;
    }

    &.ui.breadcrumb a {
        color: #a6a6a6 !important;
        text-decoration: none;
    }
`;
