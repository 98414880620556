import styled from 'styled-components';
import { H2, H3, Span, IconButton } from '../../../../../../components/UI';

export const Container = styled.section`
    padding: 48px;
`;

export const Title = styled(H2)`
    padding-bottom: 7px;
`;

export const TermsList: any = styled.ul`
    margin-top: 30px;
`;

TermsList.Item = styled.li`
    position: relative;
    min-height: 96px;
    border-radius: 4px;
    border: 0.5px solid #ddd;
    box-shadow: 0 2px 4px 0 rgba(68, 68, 68, 0.1),
        0 0 6px 0 rgba(166, 166, 166, 0.1);
    padding: 24px;
    margin: 24px 0;

    &:hover {
        box-shadow: 0 2px 4px 0 rgba(68, 68, 68, 0.25),
            0 0 12px 0 rgba(166, 166, 166, 0.5);
    }
`;

TermsList.Item.EditBtn = styled(IconButton)`
    position: absolute;
    top: 24px;
    right: 24px;
`;

TermsList.Item.Name = styled(H3)``;

TermsList.Item.LastUpdate = styled(Span)`
    color: #a6a6a6;
`;
