export class Solvent {
    id: number;
    name: string;
    chemical: Chemical;

    constructor(id: number, name: string, chemical: Chemical) {
        this.id = id;
        this.name = name;
        this.chemical = chemical;
    }
}

export class Chemical {
    delta_d: number;
    delta_p: number;
    delta_h: number;
    relative_evaporation_rate: number;

    constructor(
        delta_d: number,
        delta_p: number,
        delta_h: number,
        relative_evaporation_rate: number
    ) {
        this.delta_d = delta_d;
        this.delta_p = delta_p;
        this.delta_h = delta_h;
        this.relative_evaporation_rate = relative_evaporation_rate;
    }
}

export class FormulationSolvent {
    id: number;
    concentration?: number;
    cost?: number;
    solvent: Solvent;

    constructor(
        id: number,
        solvent: Solvent,
        concentration?: number,
        cost?: number
    ) {
        this.id = id;
        this.concentration = concentration;
        this.cost = cost;
        this.solvent = solvent;
    }
}
