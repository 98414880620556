import React from 'react';
import { Line } from '../../../../../../components/UI';
import { Container, Title, TermsList } from './index.styled';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Term } from '../../../../../../models/term.model';

class TermsContainer extends React.Component<Props, {}> {
    private renderItems() {
        const { terms } = this.props;
        return terms.map(term => {
            const updatedWithZone = moment(`${term.updated_at}Z`);
            return (
                <TermsList.Item key={term.language}>
                    <Link to={`/master/terms/term/${term.language}`}>
                        <TermsList.Item.EditBtn
                            iconName="edit-alt"
                            iconSize={19}
                        />
                    </Link>
                    <TermsList.Item.Name
                        i18nKey={`master.terms.language.${term.language}`}
                    />
                    <TermsList.Item.LastUpdate
                        i18nKey={[
                            'master.terms.lastUpdate',
                            {
                                date: moment(updatedWithZone).format(
                                    'DD/MM/YYYY'
                                ),
                                time: `${moment(updatedWithZone).format(
                                    'HH'
                                )}h${moment(updatedWithZone).format('mm')}m`,
                                email: term.last_modified_by
                            }
                        ]}
                    />
                </TermsList.Item>
            );
        });
    }

    render() {
        return (
            <Container>
                <Title i18nKey="master.terms.title" />
                <Line />
                <TermsList>{this.renderItems()}</TermsList>
            </Container>
        );
    }
}

interface Props {
    terms: Term[];
}

export default TermsContainer;
