import React from 'react';
import ReactDOM from 'react-dom';
import {
    Background,
    Dialog,
    SuccessIcon,
    CloseBtn,
    WarningIcon,
    DeleteIcon,
    Footer,
    Header,
    Body,
    ErrorIcon,
    LoadingIcon,
    FlaskLoading
} from './index.styled';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import { outTranslator } from '../../../helpers/util';
import { Empty } from '..';
import flaskLoadingAnimation from '../../../assets/images/flask-loading/flask-loading.gif';

export const dialog = ({
    type,
    closeTime = 15000,
    instantShow = true,
    i18nKey,
    message,
    header,
    body,
    buttons,
    onClose,
    flaskLoading
}: DialogParams) => {
    const root = document.getElementById('root')!;
    const modal = document.createElement('div');
    let isRemoved = false;

    if (!root) {
        return {
            show: () => {},
            close: () => {}
        };
    }

    const removeModal = () => {
        try {
            isRemoved = true;
            root.removeChild(modal);
        } catch (ex) {}
    };

    const handleClose = () => {
        if (!isRemoved) {
            removeModal();
            if (onClose) {
                onClose();
            }
        }
    };

    const destructI18nKey = i18nKey => {
        if (typeof i18nKey === 'string') {
            return outTranslator(i18nKey);
        }
        return outTranslator(i18nKey.name, i18nKey.vars);
    };

    const renderCloseBtn = () => {
        if (type !== 'loading') {
            return <CloseBtn name="times" onClick={handleClose} />;
        }
        return <Empty />;
    };

    const renderFlaskLoading = () => {
        return <FlaskLoading alt="loading" src={flaskLoadingAnimation} />;
    };

    const renderIcon = () => {
        switch (type) {
            case 'success':
                return <SuccessIcon name="check-circle" />;
            case 'warning':
                return <WarningIcon name="exclamation-circle" />;
            case 'error':
                return <ErrorIcon name="times-circle" />;
            case 'delete':
                return <DeleteIcon name="exclamation-circle" />;
            case 'loading':
                return flaskLoading ? renderFlaskLoading() : <LoadingIcon />;
            default:
                return <SuccessIcon name="check-circle" />;
        }
    };

    const renderHeader = () => {
        if (message || !header) {
            return <Empty />;
        }
        return <Header renderText={destructI18nKey(header!.i18nKey)} />;
    };

    const renderBody = () => {
        return (
            <Body
                renderText={
                    message
                        ? message
                        : destructI18nKey(i18nKey ? i18nKey : body!.i18nKey)
                }
            />
        );
    };

    const renderFooter = () => {
        if (buttons) {
            const { confirm, cancel } = buttons;
            return (
                <Footer>
                    {cancel ? (
                        <Footer.Button
                            cl="invertedPrimary"
                            renderText={destructI18nKey(cancel.i18nKey)}
                            onClick={() => {
                                handleClose();
                                if (cancel.event) {
                                    cancel.event();
                                }
                            }}
                        />
                    ) : (
                        <Empty />
                    )}
                    <Footer.Button
                        cl="primary"
                        renderText={destructI18nKey(confirm.i18nKey)}
                        onClick={() => {
                            handleClose();
                            confirm.event();
                        }}
                    />
                </Footer>
            );
        }
        return <Empty />;
    };

    ReactDOM.render(
        <Background
            data-type={type}
            onClick={type !== 'loading' ? handleClose : () => {}}
        >
            <I18nextProvider i18n={i18next}>
                <Dialog
                    onClick={e => {
                        e.stopPropagation();
                    }}
                >
                    {renderCloseBtn()}
                    {renderIcon()}
                    {renderHeader()}
                    {renderBody()}
                    {renderFooter()}
                </Dialog>
            </I18nextProvider>
        </Background>,
        modal
    );

    if (instantShow && root) {
        root.appendChild(modal);
    }

    if (closeTime && type !== 'loading' && type !== 'delete') {
        setTimeout(handleClose, closeTime);
    }

    return {
        show: () => root.appendChild(modal),
        close: () => removeModal()
    };
};

interface DialogParams {
    flaskLoading?: boolean;
    message?: string;
    closeTime?: number;
    instantShow?: boolean;
    i18nKey?: string | { name: string; vars: {} };
    header?: { i18nKey: string | { name: string; vars: {} } };
    body?: { i18nKey: string | { name: string; vars: {} } };
    type?: 'success' | 'warning' | 'error' | 'loading' | 'delete';
    buttons?: {
        confirm: {
            i18nKey: string | { name: string; vars: {} };
            event: Function;
        };
        cancel?: {
            i18nKey: string | { name: string; vars: {} };
            event?: Function;
        };
    };
    onClose?(): void;
}

export const confirmCancel = onCancel => {
    dialog({
        type: 'warning',
        header: { i18nKey: 'dialogs.cancel.title' },
        body: { i18nKey: 'dialogs.cancel.body' },
        buttons: {
            confirm: {
                i18nKey: 'dialogs.cancel.confirmBtn',
                event: onCancel
            },
            cancel: {
                i18nKey: 'dialogs.cancel.cancelBtn'
            }
        }
    });
};

export const confirmDelete = (name, onDelete, onCancel?) => {
    dialog({
        type: 'delete',
        header: {
            i18nKey: {
                name: 'dialogs.delete.title',
                vars: { name }
            }
        },
        body: {
            i18nKey: {
                name: 'dialogs.delete.body',
                vars: { name }
            }
        },
        buttons: {
            confirm: {
                i18nKey: 'dialogs.delete.confirmBtn',
                event: onDelete
            },
            cancel: {
                i18nKey: 'dialogs.delete.cancelBtn',
                event: onCancel
            }
        }
    });
};
