import { action } from 'typesafe-actions';
import { SolventsTypes } from '../types/solvents.types';

export const filterSolventsRequest = (ids, callback) =>
    action(SolventsTypes.FILTER_REQUEST, { ids, callback });

export const filterSolventsSuccess = data =>
    action(SolventsTypes.FILTER_SUCCESS, data);

export const filterSolventsError = () => action(SolventsTypes.FILTER_FAILURE);
