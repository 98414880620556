import React from 'react';
import { StyledReactQuill } from './index.styled';

export const Editor = ({
    name,
    error,
    setFieldValue,
    setFieldTouched,
    onChange,
    className,
    ...rest
}: Props) => {
    const modules = {
        toolbar: [
            [{ font: [] }, { size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ color: [] }, { background: [] }],
            [
                { align: [] },
                { list: 'ordered' },
                { list: 'bullet' },
                { indent: '-1' },
                { indent: '+1' }
            ],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            matchVisual: false
        }
    };

    const formats = [
        'font',
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'list',
        'bullet',
        'indent',
        'link',
        'image',
        'video',
        'color',
        'background',
        'align'
    ];

    return (
        <StyledReactQuill
            {...rest}
            className={className}
            data-error={error}
            theme="snow"
            modules={modules}
            formats={formats}
            onBlur={() => {
                if (setFieldTouched) {
                    setFieldTouched(name!, true);
                }
            }}
            onChange={data => {
                if (setFieldValue && setFieldTouched) {
                    setFieldValue(name!, data);
                    setFieldTouched(name!, true);
                }
                if (onChange) {
                    onChange(data);
                }
            }}
        />
    );
};

interface Props {
    name?: string;
    error?: boolean;
    className?: string;
    onChange?(data: string): void;
    setFieldValue?(name: string, value): void;
    setFieldTouched?(name: string, value: boolean): void;
}
