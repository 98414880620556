import React from 'react';
import Dropzone from 'react-dropzone';
import { Line, dialog, Loading } from '../../../../../../components/UI';
import {
    Title,
    Container,
    Content,
    Drop,
    Data,
    Download,
    OutputUpload
} from './index.styled';
import { modal } from '../../../../../../components/UI/Modal';

class UploadContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            file: null
        };
    }

    private addFile = file => {
        this.setState({ file: file[0] });
    };

    private removeFile = () => {
        this.setState({ file: null });
    };

    private handleDropRejected = () => {
        dialog({
            type: 'error',
            i18nKey: 'master.properties.upload.dropzone.rejected'
        });
    };

    private outputUploadProperties = data => {
        const handleClose = () => this.setState({file: null});
        modal({onClose: handleClose})(({ close }) => (
            <OutputUpload>
                <OutputUpload.Title i18nKey="master.properties.upload.output.title" />
                {!data.messages.errors && !data.messages.warnings && (
                    <OutputUpload.NoChanges i18nKey="master.properties.upload.output.noChanges" />
                )}
                {data.messages.errors && (
                    <OutputUpload.Type>
                        <OutputUpload.Type.Header>
                        <OutputUpload.Type.Header.Errors name="times" />
                        <OutputUpload.Type.Header.Title i18nKey="master.properties.upload.output.errors" />
                        </OutputUpload.Type.Header>
                        <OutputUpload.Type.List>
                            {data.messages.errors.map((error, index) => (
                                <OutputUpload.Type.ListItem key={index}>
                                    <b>E.{index + 1}</b> - {error}
                                </OutputUpload.Type.ListItem>
                            ))}
                        </OutputUpload.Type.List>
                    </OutputUpload.Type>
                )}
                {data.messages.warnings && (
                    <OutputUpload.Type>
                        <OutputUpload.Type.Header>
                            <OutputUpload.Type.Header.Warnings name="info-circle" />
                            <OutputUpload.Type.Header.Title i18nKey="master.properties.upload.output.warnings" />
                        </OutputUpload.Type.Header>
                        <OutputUpload.Type.List>
                            {data.messages.warnings.map((warning, index) => (
                                <OutputUpload.Type.ListItem key={index}>
                                    <b>W.{index + 1}</b> - {warning}
                                </OutputUpload.Type.ListItem>
                            ))}
                        </OutputUpload.Type.List>
                    </OutputUpload.Type>
                )}
                <OutputUpload.OK
                    cl="primary"
                    i18nKey="close"
                    onClick={close}
                />
            </OutputUpload>
        ));
    };

    private handleUploadProperties = () => {
        const { uploadProperties } = this.props;
        const { file } = this.state;
        uploadProperties(file!, this.outputUploadProperties);
    };

    private renderDragMessage = (isDragAccept, isDragReject) => {
        if (isDragAccept) {
            return (
                <Drop.Span i18nKey="master.properties.upload.dropzone.accepted" />
            );
        }

        if (isDragReject) {
            return (
                <Drop.Span i18nKey="master.properties.upload.dropzone.rejected" />
            );
        }

        return (
            <Drop.Span i18nKey="master.properties.upload.dropzone.default" />
        );
    };

    private renderDropzone() {
        const { uploading } = this.props;
        const { file } = this.state;
        if (!file) {
            return (
                <Dropzone
                    accept={[
                        '.xlsx',
                        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                    ]}
                    onDrop={this.addFile}
                    onDropRejected={this.handleDropRejected}
                    disabled={uploading}
                >
                    {({
                        getRootProps,
                        getInputProps,
                        isDragAccept,
                        isDragReject
                    }) => (
                        <Drop
                            {...getRootProps()}
                            isDragAccept={isDragAccept}
                            isDragReject={isDragReject}
                            uploading={uploading}
                        >
                            <input {...getInputProps()} />
                            <Drop.Icon name="file-upload" />
                            {this.renderDragMessage(isDragAccept, isDragReject)}
                        </Drop>
                    )}
                </Dropzone>
            );
        } else {
            return (
                <Data>
                    {uploading ? (
                        <Loading />
                    ) : (
                        <React.Fragment>
                            <Data.File>
                                <Data.Name renderText={file.name} />
                                <Data.Size
                                    renderText={`${Math.round(
                                        file.size / 1000
                                    )} KB`}
                                />
                            </Data.File>
                            <Data.Buttons>
                                <Data.Buttons.Cancel
                                    cl="invertedPrimary"
                                    i18nKey="cancel"
                                    onClick={this.removeFile}
                                />
                                <Data.Buttons.Upload
                                    cl="primary"
                                    i18nKey="update"
                                    onClick={this.handleUploadProperties}
                                />
                            </Data.Buttons>
                        </React.Fragment>
                    )}
                </Data>
            );
        }
    }

    render() {
        const { downloading, downloadProperties } = this.props;
        return (
            <Container>
                <Title i18nKey="master.properties.upload.title" />
                <Line />
                <Content>
                    {this.renderDropzone()}
                    <Download>
                        <Download.ButtonContainer>
                            <Download.Button
                                loading={downloading}
                                cl="primary"
                                renderText="Download"
                                onClick={() => downloadProperties()}
                            />
                        </Download.ButtonContainer>
                        
                        <Download.Info>
                            <Download.Info.Title i18nKey="master.properties.upload.download.title" />

                            <Download.Info.Span i18nKey="master.properties.upload.download.step-1" />
                            <Download.Info.Span i18nKey="master.properties.upload.download.step-2" />
                            <Download.Info.Span i18nKey="master.properties.upload.download.step-3" />
                            <Download.Info.Span i18nKey="master.properties.upload.download.step-4" />
                        </Download.Info>
                        
                    </Download>
                </Content>
            </Container>
        );
    }
}

interface Props {
    uploading: boolean;
    downloading: boolean;
    uploadProperties(file: File, callback?: Function): void;
    downloadProperties(): void;
}

interface State {
    file: File | null;
}

export default UploadContainer;
