import React from 'react';
import {
    Container,
    SolventLabel,
    SolventSpan,
    SolventContainer,
    SolventLine,
    HeaderContainer,
    EvaporationContainer,
    REDContainer,
    LabelRED,
    ValueRED,
    InlineLegend,
    BodyContainer
} from './index.styled';
import { toFixedTrunc } from '../../../helpers/util';
const CustomTooltip = ({ active, payload, label }: Props) => {
    const organizeBySimulation = () => {
        const organized = {};
        payload.slice(3, payload.length).forEach((value: DataItem) => {
            const splittedName = value.name.split('///');
            const simulationRef = `${splittedName[1]}///${splittedName[2]}`;
            organized[simulationRef] = organized[simulationRef]
                ? [...organized[simulationRef], value]
                : [value];
        });
        return organized;
    };

    const renderSimulationSolvents = () => {
        const organized = organizeBySimulation();
        return Object.keys(organized).map((key, index) => {
            const simulationName = key.split('///')[0];
            return (
                <InlineLegend key={index}>
                    <SolventSpan>{simulationName}</SolventSpan>
                    {organized[key].map((item: DataItem) => {
                        const { name, stroke, payload } = item;
                        const red = toFixedTrunc(payload[name], 2);
                        const polymerName = name.split('///')[0];
                        return (
                            <div key={stroke}>
                                <SolventContainer>
                                    <SolventLine color={stroke} />
                                    <SolventLabel>{polymerName}</SolventLabel>
                                </SolventContainer>
                                <REDContainer>
                                    <LabelRED
                                        i18nKey={'evaporationGraph.red'}
                                    />
                                    <ValueRED data-valid={+red <= 1}>
                                        {red}
                                    </ValueRED>
                                </REDContainer>
                            </div>
                        );
                    })}
                </InlineLegend>
            );
        });
    };

    if (active) {
        return (
            <Container>
                <HeaderContainer>
                    <EvaporationContainer>
                        <SolventLabel>{Math.round(label) + '%'}</SolventLabel>
                        <SolventSpan i18nKey={'evaporationGraph.evaporated'} />
                    </EvaporationContainer>
                </HeaderContainer>
                <BodyContainer>{renderSimulationSolvents()}</BodyContainer>
            </Container>
        );
    }

    return null;
};

CustomTooltip.defaultProps = {
    active: false,
    payload: [],
    label: 0
};

interface Props {
    active?: boolean;
    payload: DataItem[];
    label: number;
}

interface DataItem {
    name: string;
    value: string;
    stroke: string;
    payload: any;
}
export default CustomTooltip;
