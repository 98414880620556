import React from 'react';
import Mask from 'react-input-mask';
import { IInputProps, Input } from '..';

export const InputMask = (props: IInputMaskProps) => (
    <Mask {...props}>
        {inputProps => {
            delete inputProps.setFieldValue;
            delete inputProps.setFieldTouched;
            return <Input {...inputProps} disabled={props.disabled} />;
        }}
    </Mask>
);

InputMask.defaultProps = {
    maskChar: ''
};

export interface IInputMaskProps extends IInputProps {
    mask?: string;
    maskChar?: string;
}
