import React from 'react';
import styled, { css } from 'styled-components';
import { Select, Button, Span } from '../../../../UI';
import colors from '../../../../../theme/colors';
import { StyledProps } from '../../../../../models/styledProps.model';
import { headerHeight } from '../../index.styled';
import { Link } from 'react-router-dom';

export const height = headerHeight;

const WrapperSelect = props => <Select {...props} />;

const containerFixed = css`
    position: fixed;
    background-color: ${colors.main};
    height: 80px;
    box-shadow: 0 4px 8px 0 rgba(117, 117, 117, 0.5);
`;

export const Container = styled.div<StyledProps>`
    height: ${height}px;
    width: 100%;
    background-color: #ffffff;
    padding: 0 120px;
    z-index: 11;
    transition: all 500ms linear;
    ${({ styledProps }) =>
        (styledProps.location !== '/' ||
            (styledProps.location === '/' && styledProps.user)) &&
        'border-bottom: 1px solid rgba(117, 117, 117, 0.4);'}
    ${({ styledProps }) =>
        styledProps.location === '/' &&
        !styledProps.user &&
        styledProps.fixed &&
        containerFixed}

    .navigation-fixed {
        ul {
            li {
                a {
                    color: #fff;

                    &:hover {
                        color: #ccc;
                    }
                }
            }
        }
    }

    .btn-signin-fixed {
        &.ui.button {
            box-shadow: inset 0px 0px 0px 1px #fff;
            color: #fff;

            &:hover {
                box-shadow: inset 0px 0px 0px 1px #ccc;
                color: #ccc;
            }
        }
    }

    @media (max-width: 992px) {
        padding: 0 15px;
    }
`;

export const Logo = styled.div`
    box-sizing: border-box;

    img {
        width: 78px;
        padding-top: 5px;
    }
`;

export const Navigation: any = styled.nav`
    outline: none;
`;

Navigation.ul = styled.ul`
    margin-bottom: 0;
    padding: 0px 24px;
`;

Navigation.li = styled.li`
    display: inline-block;
    padding: 0px 24px;

    .active span,
    .active {
        color: #74499c;
    }
`;

Navigation.Link = styled(Span)`
    color: #757575;
    font-size: 18px;
    letter-spacing: 1px;
    line-height: 25px;

    &:hover {
        color: #74499c;
        cursor: pointer;
    }
`;

export const Language: any = styled.div`
    width: auto;
    margin-left: 50px;
`;

Language.Select = styled(WrapperSelect)`
    display: flex !important;
    align-items: center;
    border: none !important;
    box-shadow: none !important;
    width: auto !important;
    padding: 0 !important;
    margin: 0 !important;
    background: transparent !important;

    .item {
        display: flex !important;
        align-items: center;

        img {
            height: 20px;
            padding-right: 10px;
        }

        span {
            font-size: 14px;
            font-weight: bold;
        }
    }

    &.ui.selection.active.dropdown .menu {
        width: 116px;
        margin-left: -45px;
        margin-top: -7px !important;
    }

    &.ui.dropdown > .dropdown.icon {
        display: none !important;
    }

    &.ui.dropdown > .text {
        display: none;
    }

    &:before {
        width: 24px;
        height: 24px;
        content: ${({ options, value }) =>
            `url(${options.find(option => option.value === value).image})`};
    }

    @media (max-width: 992px) {
        &.ui.selection.active.dropdown .menu {
            margin-left: -78px;
        }
    }
`;

export const User = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
        font-size: 16px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Authenticated: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    & > div * {
        display: block;
        text-align: right;
    }
`;

Authenticated.Greetings = styled(Span)`
    color: #a6a6a6;
    font-size: 16px;
    line-height: 20px;
    /* padding-top: 20px; */

    & > span {
        color: #444444;
        display: inline-block;
        margin: 0;
        margin-left: 2.5px;
        font-weight: 600;
    }
`;

Authenticated.NeedHelp = styled.a`
    & > span {
        font-size: 12px !important;
        color: #009EE0;
        cursor: pointer;
    }
`;

Authenticated.Exit = styled(Span)`
    color: ${colors.buttons.invertedPrimary.color};
    display: flex;
    align-items: center;
    cursor: pointer;

    i {
        margin-left: 16px;

        &:before {
            color: ${colors.buttons.invertedPrimary.color};
            font-size: 20px;
            margin-right: 4px;
        }
    }

    &:hover {
        color: ${colors.buttons.invertedPrimary.hover.color};

        i:before {
            color: ${colors.buttons.invertedPrimary.hover.color};
        }
    }
`;

export const Unauthenticated: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
`;

Unauthenticated.SignInButtonLink = styled(Link)`
    margin-right: 32px;
`;

Unauthenticated.SignInButton = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 0.3px;
        padding: 6px 24px;
        margin: 0;
    }
`;

Unauthenticated.SignUpButton = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 0.3px;
        padding: 6px 24px;
        margin: 0;
    }
`;

const mobileMenuFixed = css`
    .bm-burger-bars {
        background: #fff !important;
    }
    .bm-burger-button {
        top: ${80 / 2 - 10}px !important;
    }
`;

export const MobileMenu = styled.div<StyledProps>`
    ${({ styledProps }) =>
        styledProps.fixed && styledProps.pathname === '/' && mobileMenuFixed}

    /* Position and sizing of burger button */
    .bm-burger-button {
        position: absolute;
        width: 27px;
        height: 20px;
        left: 20px;
        top: ${height / 2 - 10}px;
        transition: all 1s;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
        background: ${colors.buttons.default.bg};
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
        background: ${colors.buttons.default.hover.bg};
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
        height: 24px;
        width: 24px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
        background: #373a47;
    }

    /*
    Sidebar wrapper styles
    Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
    */
    .bm-menu-wrap {
        top: 0;
        left: 0;
        position: fixed;
        height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
        background: #fff;
        padding: 2.5em 1.5em 0;
        font-size: 1.15em;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
        fill: #373a47;
    }

    /* Wrapper for item list */
    .bm-item-list {
        color: #b8b7ad;
        padding: 0.8em;
        padding: 0;
        outline: none;

        & ul {
            padding: 0;

            & li {
                display: block;
                padding: 15px 0;
                width: 100%;
                border-bottom: 1px solid rgba(0, 0, 0, 0.05);

                & a,
                & a span {
                    width: 100%;
                    display: block;
                    height: 100%;
                }
            }
        }
    }

    /* Individual item */
    .bm-item {
        display: inline-block;
    }

    /* Styling of overlay */
    .bm-overlay {
        background: rgba(0, 0, 0, 0.3);
        left: 0;
        top: 0;
    }
`;

export const Contact = styled.div`
    display: flex;
    align-items: center;
    justify-content: right;
    text-align:right
    width: 100%;

    span {
        font-size: 12px;
    }
`;

export const ContactContent: any = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-left: 50px;
`;

ContactContent.Message = styled(Span)`
    color: #a6a6a6;
    font-size: 12px;
    line-height: 20px;
    /* padding-top: 20px; */

    & > span {
        color: #444444;
        display: inline-block;
        margin: 0;
        margin-left: 2.5px;
        font-weight: 600;
    }
`;

ContactContent.Email = styled.a`
    & > span {
        font-size: 12px !important;
        color: #009EE0;
        
}
`;
