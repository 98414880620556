import { action } from 'typesafe-actions';
import { PolymersTypes } from '../types/polymers.types';

export const filterPolymersRequest = (ids, callback) =>
    action(PolymersTypes.FILTER_REQUEST, { ids, callback });

export const filterPolymersSuccess = data =>
    action(PolymersTypes.FILTER_SUCCESS, data);

export const filterPolymersError = () => action(PolymersTypes.FILTER_FAILURE);
