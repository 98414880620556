import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { searchSuccess, searchError } from '../../actions/search.actions';
import {
    searchGroupsSuccess,
    searchGroupsError
} from '../../actions/groups.actions';
import {
    searchUserSuccess,
    searchUserError
} from '../../actions/users.actions';

export function* search(action) {
    const { search, callback } = action.payload;
    const { data } = yield call(
        api.get,
        `/search?q=${search.q}&type=${search.type}&restricted=${Boolean(
            search.restricted
        )}`
    );
    try {
        if (data.object.length > 0) {
            yield put(searchSuccess({ items: data.object, message: '' }));
            if (callback) {
                callback(data.object.map(obj => obj.id));
            }
        } else {
            yield put(
                searchSuccess({
                    items: [],
                    message: data.messages.warnings[0]
                })
            );
            if (callback) {
                callback(data.object.map(obj => obj.id));
            }
        }
    } catch (ex) {
        yield put(searchError());
    }
}

export function* searchGroups(action) {
    const { search, callback } = action.payload;
    const { data } = yield call(
        api.get,
        `/search/data/groups?q=${search.q}&page=${search.page}&limit=${search.limit}`
    );
    try {
        if (data.object.length > 0) {
            yield put(
                searchGroupsSuccess({
                    items: data.object,
                    paging: data.paging,
                    message: ''
                })
            );
            if (callback) {
                callback(data.object);
            }
        } else {
            yield put(
                searchGroupsSuccess({
                    items: [],
                    paging: data.paging,
                    message: data.messages.warnings[0]
                })
            );
            if (callback) {
                callback(data.object);
            }
        }
    } catch (ex) {
        yield put(searchGroupsError());
    }
}

export function* searchUsers(action) {
    const { search, callback } = action.payload;
    const { data } = yield call(
        api.get,
        `/search/users?q=${search.q}&page=${search.page}&limit=${search.limit}`
    );
    try {
        if (data.object.length > 0) {
            yield put(
                searchUserSuccess({
                    items: data.object,
                    paging: data.paging,
                    message: ''
                })
            );
            if (callback) {
                callback(data.object);
            }
        } else {
            yield put(
                searchUserSuccess({
                    items: [],
                    paging: data.paging,
                    message: data.messages.warnings[0]
                })
            );
            if (callback) {
                callback(data.object);
            }
        }
    } catch (ex) {
        yield put(searchUserError());
    }
}
