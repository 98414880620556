import { Polymer } from '../../models/polymer.model';

/**
 * Action types
 */
export enum PolymersTypes {
    FILTER_REQUEST = 'polymer:FILTER_REQUEST',
    FILTER_SUCCESS = 'polymer:FILTER_SUCCESS',
    FILTER_FAILURE = 'polymer:FILTER_FAILURE'
}

/**
 * State types
 */
export interface PolymerState {
    readonly items: Polymer[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly message: string;
}
