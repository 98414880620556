import React from 'react';
import { Container, Plus } from './index.styled';

const EmptyStep = ({ disabled }) => (
    <Container data-disabled={disabled}>
        <Plus name="plus" />
    </Container>
);

export default EmptyStep;
