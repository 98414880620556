import { Paint } from "../../models/paint.model";

/**
 * Action types
 */
export enum PaintsTypes {
    GET_REQUEST = "paints:GET_REQUEST",
    GET_SUCCESS = "paints:GET_SUCCESS",
    GET_FAILURE = "paints:GET_FAILURE"
}

/**
 * State types
 */
export interface PaintsState {
    readonly items: Paint[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly message: string;
}
