import styled from 'styled-components';
import {
    Icon,
    Span,
    Grid,
    Row,
    Column,
    Select
} from '../../../../../components/UI';
import colors from '../../../../../theme/colors';

export const Container = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid rgba(166, 166, 166, 0.5);
    z-index: ${props => (!props['data-expanded'] ? 10 : 13)};
    transition: height 1s;
    height: ${props => (!props['data-expanded'] ? '72px' : '90%')};
`;

export const BackgroundOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 12;
    background-color: rgba(68, 68, 68, 0.5);
`;

export const Header: any = styled(Grid)`
    height: 72px;
    width: 100%;
    padding: 0px 120px;
    border-bottom: 1px solid rgba(166, 166, 166, 0.5);
    display: table;
    cursor: pointer;

    @media (max-width: 767px) {
        padding: 10px 10px;
    }
`;

Header.Row = styled(Row)`
    width: 100%;
    display: table-cell;
    vertical-align: middle;
    min-width: 300px;
`;

Header.Column = styled(Column)`
    display: flex;
    align-items: center;
    height: 100%;
`;

Header.RightColumn = styled(Header.Column)`
    justify-content: flex-end;
    height: 100%;
`;

Header.Flask = styled(Icon)`
    font-size: 32px;
    color: #763689;
`;

Header.Title = styled(Span)`
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    margin-left: 8px;
`;

Header.Arrow = styled(Icon)`
    color: #656565;
    font-size: 24px;
`;

Header.NewSimulation = styled(Span)`
    color: ${colors.buttons.invertedPrimary.color};
    font-size: 18px;
    font-weight: 600;
    line-height: 18px;

    &:hover {
        cursor: pointer;
        color: ${colors.buttons.invertedPrimary.hover.color};
    }
`;

export const Body: any = styled(Grid)`
    height: ${props =>
        props['data-checkedcards'].length === 0
            ? 'calc(100% - 72px)'
            : 'calc(100% - 159px)'};
    width: 100%;
    padding: 20px 120px;
    padding-top: 0;
    position: relative;

    @media (max-width: 767px) {
        padding: 20px 10px;
        padding-top: 0;
    }
`;

Body.FilterAndPagination = styled.div`
    display: table;
    width: 100%;
    padding: 0 15px;
`;

Body.FilterAndPagination.Row = styled(Row)`
    vertical-align: middle;
    height: 80px;
    width: 100%;
    margin-bottom: 10px;
`;

Body.FilterAndPagination.FilterColumn = styled(Column)``;

Body.FilterAndPagination.PaginationColumn = styled(Column)`
    @media (max-width: 767px) {
        justify-content: center !important;
        height: 45px !important;
    }
`;

Body.ExpandAll = styled(Span)`
    color: ${colors.buttons.invertedPrimary.color};
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;

    &:hover {
        cursor: pointer;
        color: ${colors.buttons.invertedPrimary.hover.color};
    }
`;

Body.CardsRow = styled(Row)`
    overflow-y: auto;
    height: calc(100% - 130px);
    width: 100%;
`;

Body.Cards = styled(Column)`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const SelectContainer = styled.div`
    display: flex;
    align-items: center;
    margin-right: 30px;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: flex-start;
    }
`;

export const SelectSpan = styled(Span)`
    text-align: right;
    color: #444444;
    font-family: 'Source Sans Pro';
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-right: 8px;
`;

export const SelectFiler = styled(Select)`
    width: 180px !important;
`;
