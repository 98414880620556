import { ContentTypes } from '../types/content.types';
import { action } from 'typesafe-actions';

export const getTermConditions = () =>
    action(ContentTypes.GET_TERMS_CONDITIONS_REQUEST);

export const getTermConditionsSuccess = email =>
    action(ContentTypes.GET_TERMS_CONDITIONS_SUCCESS, email);

export const getTermConditionsError = () =>
    action(ContentTypes.GET_TERMS_CONDITIONS_FAILURE);
