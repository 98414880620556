import styled from 'styled-components';

export const Container = styled.div`
    .next a:after {
        font-family: 'unicons';
        content: '\\e82b';
        font-size: 20px;
    }

    .previous a:after {
        font-family: 'unicons';
        content: '\\e82a';
        font-size: 20px;
    }

    .break {
        cursor: pointer;
        margin: 0 5px;

        a {
            outline: none;
            color: #656565;
        }
    }

    .pagination {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .next-previous {
        font-weight: bold;
        font-size: 24px;
        padding: 0px 10px;

        a {
            outline: none;
            color: #656565;
            cursor: pointer;
        }
    }

    .page {
        display: inline-block;
        width: 24px;
        padding: 0px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #444;
        cursor: pointer;

        a {
            color: #444;
            outline: none;

            &:hover {
                color: #444;
            }
        }
    }

    .active {
        height: 24px;
        width: 24px;
        border-radius: 50px;
        background-color: #763689;
        margin: 0 5px;

        a {
            color: #fff;

            &:hover {
                color: #fff;
            }
        }
    }
`;
