import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { dialog } from '../../../components/UI';
import {
    deleteUserError,
    deleteUserSuccess,
    takeUserSuccess,
    takeUserError,
    saveUserSuccess,
    saveUserError
} from '../../actions/users.actions';

export function* deleteUserRequest(action) {
    const { user, callback } = action.payload;

    const loading = dialog({
        type: 'loading',
        i18nKey: {
            name: 'dialogs.delete.loading',
            vars: { name: user.display_name ? user.display_name : user.email }
        },
        instantShow: false
    });

    loading.show();

    try {
        const { status } = yield call(api.delete, `/user/${user.id}`);
        if (status === 200) {
            yield put(deleteUserSuccess(user));
            if (callback) {
                callback(user);
            }
        } else {
            yield put(deleteUserError(user));
        }
    } catch (ex) {
        yield put(deleteUserError(user));
    }

    loading.close();
}

export function* takeUserRequest(action) {
    const id = action.payload;

    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });

    loading.show();

    try {
        const { status, data } = yield call(api.get, `/user/${id}`);
        if (status === 200 && data.object) {
            yield put(
                takeUserSuccess({ user: data.object, message: data.messages })
            );
        } else {
            yield put(takeUserError());
        }
    } catch (ex) {
        yield put(takeUserError());
    }

    loading.close();
}

export function* saveUserRequest(action) {
    const { user, callback } = action.payload;
    const { id, ...restUser } = user;

    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });

    loading.show();
    try {
        const verifyUserExists = yield api.post('user/exists', {
            email: restUser.email,
            phone_number: restUser.phone_number
        });

        const takeUser = yield call(api.get, `/user/${id}`);

        if (
            verifyUserExists.data.object &&
            !(
                takeUser.data.object.display_name !== restUser.display_name ||
                takeUser.data.object.email !== restUser.email ||
                takeUser.data.object.phone_number !== restUser.phone_number
            )
        ) {
            loading.close();
            yield put(saveUserError());
            return;
        }

        const url = `/user/${id}`;
        const { status, data } = yield call(api.put, url, {
            ...restUser
        });

        if (status === 201 || status === 200) {
            yield put(saveUserSuccess());
            if (callback) {
                callback(data.object);
            }
        } else {
            yield put(saveUserError());
        }
    } catch (ex) {
        yield put(saveUserError());
    }

    loading.close();
}
