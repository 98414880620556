import React from 'react';
import EmailRecoveryContainer from './container';
import { ApplicationState } from '../../../../../../redux/store';
import { bindActionCreators } from 'redux';
import {
    recoveryEmail,
    recoveryEmailClear
} from '../../../../../../redux/actions/user.actions';
import { connect } from 'react-redux';

const EmailRecovery = props => <EmailRecoveryContainer {...props} />;

const mapStateToProps = (state: ApplicationState) => ({
    recoveredEmail: state.user.recoveredEmail,
    loading: state.user.loading
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            recoveryEmail,
            recoveryEmailClear
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EmailRecovery);
