import { Reducer } from 'redux';
import { SearchState, SearchTypes } from '../../types/search.types';

const INITIAL_STATE: SearchState = {
    items: [],
    loading: false,
    error: false,
    message: ''
};

const reducer: Reducer<SearchState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SearchTypes.SEARCH_REQUEST:
            return { ...state, loading: true };
        case SearchTypes.SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                error: false,
                items: action.payload.items,
                message: action.payload.message
            };
        case SearchTypes.SEARCH_FAILURE:
            return {
                ...state,
                loading: false,
                error: true,
                items: [],
                message: ''
            };
        default:
            return state;
    }
};

export default reducer;
