import React from 'react';
import { Container } from './index.styled';
import Group from '../../../../../../../../models/group.model';
import GroupStep from './GroupStep';
import UsersStep from './UsersStep';
import PolymersStep from './PolymersStep';
import SolventsStep from './SolventsStep';
import {
    confirmDelete,
    confirmCancel
} from '../../../../../../../../components/UI';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Breadcrumb } from '../../../../../../../../components/UI';
import { WithTranslation } from 'react-i18next';
import MasterStep from '../../../../../../../../components/common/MasterStep';

class GroupContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            active: 0,
            group: null
        };
    }

    private handleEdit = (stepNumber: number) => {
        this.setState({ active: stepNumber });
    };

    private setActive = (stepNumber: number) => {
        this.setState({ active: stepNumber });
    };

    private handleCancel = () => {
        confirmCancel(() => {
            this.setActive(0);
        });
    };

    private handleDelete = () => {
        const { deleteGroup, history } = this.props;
        const { group } = this.state;
        if (group) {
            confirmDelete(group.name, () => {
                deleteGroup(group, () => history.push('/master/groups'));
            });
        } else {
            history.push('/master/groups');
        }
    };

    private groupActiveOnSave = data => {
        const { saveGroupData } = this.props;
        const { group } = this.state;
        const request = group
            ? {
                  id: group.id,
                  ...data
              }
            : data;
        saveGroupData(request, ({ id }) => {
            this.setState({ active: 0, group: { ...group, id, ...data } });
        });
    };

    private usersActiveOnSave = data => {
        const { saveGroupUsers } = this.props;
        const { group } = this.state;
        saveGroupUsers(group!.id, data.map(data => data.id), () => {
            this.setState({ active: 0, group: { ...group!, users: data } });
        });
    };

    private polymersActiveOnSave = data => {
        const { saveGroupPolymers } = this.props;
        const { group } = this.state;
        saveGroupPolymers(group!.id, data.map(data => data.id), () => {
            this.setState({ active: 0, group: { ...group!, polymers: data } });
        });
    };

    private solventsActiveOnSave = data => {
        const { saveGroupSolvents } = this.props;
        const { group } = this.state;
        saveGroupSolvents(group!.id, data.map(data => data.id), () => {
            this.setState({ active: 0, group: { ...group!, solvents: data } });
        });
    };

    private renderBreadcrumb() {
        const { t } = this.props;
        const { group } = this.state;
        return (
            <Breadcrumb>
                <Breadcrumb.Section>
                    <Link to="/master/groups">
                        {t('master.groups.group.breadcrumb.groups')}
                    </Link>
                </Breadcrumb.Section>
                <Breadcrumb.Divider icon="right angle" />
                <Breadcrumb.Section>
                    {group
                        ? group.name
                        : t('master.groups.group.breadcrumb.newGroup')}
                </Breadcrumb.Section>
            </Breadcrumb>
        );
    }

    render() {
        const { active, group } = this.state;
        return (
            <Container>
                {this.renderBreadcrumb()}
                <MasterStep
                    name="master.groups.group.group"
                    stepNumber={1}
                    active={active}
                    haveData={Boolean(group)}
                    componentProps={{ group }}
                    component={GroupStep}
                    onDeleteClick={this.handleDelete}
                    onEmptyClick={this.setActive}
                    onSave={this.groupActiveOnSave}
                    onCancel={this.handleCancel}
                    onEdit={this.handleEdit}
                />
                <MasterStep
                    name="master.groups.group.users"
                    stepNumber={2}
                    active={active}
                    disabled={!group}
                    haveData={Boolean(
                        group && group.users && group.users.length > 0
                    )}
                    isDataList
                    componentProps={{ group }}
                    component={UsersStep}
                    onEmptyClick={this.setActive}
                    onSave={this.usersActiveOnSave}
                    onCancel={this.handleCancel}
                    onEdit={this.handleEdit}
                />
                <MasterStep
                    name="master.groups.group.polymers"
                    stepNumber={3}
                    active={active}
                    disabled={!group}
                    haveData={Boolean(
                        group && group.polymers && group.polymers.length > 0
                    )}
                    isDataList
                    componentProps={{ group }}
                    component={PolymersStep}
                    onEmptyClick={this.setActive}
                    onSave={this.polymersActiveOnSave}
                    onCancel={this.handleCancel}
                    onEdit={this.handleEdit}
                />
                <MasterStep
                    name="master.groups.group.solvents"
                    stepNumber={4}
                    active={active}
                    disabled={!group}
                    haveData={Boolean(
                        group && group.solvents && group.solvents.length > 0
                    )}
                    isDataList
                    componentProps={{ group }}
                    component={SolventsStep}
                    onEmptyClick={this.setActive}
                    onSave={this.solventsActiveOnSave}
                    onCancel={this.handleCancel}
                    onEdit={this.handleEdit}
                />
            </Container>
        );
    }

    componentWillMount() {
        const { group } = this.props;
        this.setState({
            group,
            active: group ? 0 : 1
        });
    }
}

interface Props extends RouteComponentProps, WithTranslation {
    group: Group | null;
    saveGroupData(group: Group, callback?: Function): void;
    saveGroupUsers(groupId: number, ids: number[], callback?: Function): void;
    saveGroupPolymers(
        groupId: number,
        ids: number[],
        callback?: Function
    ): void;
    saveGroupSolvents(
        groupId: number,
        ids: number[],
        callback?: Function
    ): void;
    deleteGroup(group: Group, callback?: Function): void;
}

interface State {
    active: number;
    group: Group | null;
}

export default GroupContainer;
