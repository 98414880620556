import { call, put } from 'redux-saga/effects';
import api from '../../../services/api';
import { dialog } from '../../../components/UI';
import {
    saveTermsSuccess,
    saveTermsError,
    getTermsSuccess,
    getTermsError
} from '../../actions/terms.actions';

export function* saveTermsRequest(action) {
    const { term, callback } = action.payload;

    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });

    loading.show();

    try {
        const { status, data } = yield call(api.put, 'term_conditions', term);

        if (status === 201 || status === 200) {
            yield put(saveTermsSuccess());
            if (callback) {
                callback(data.object);
            }
        } else {
            yield put(saveTermsError());
        }
    } catch (ex) {
        yield put(saveTermsError());
    }

    loading.close();
}

export default function* getTermsRequest() {
    const loading = dialog({
        type: 'loading',
        i18nKey: 'wait',
        instantShow: false
    });

    loading.show();

    try {
        const { data } = yield call(api.get, 'term_conditions');
        if (data.object) {
            yield put(getTermsSuccess(data.object));
        }
    } catch (err) {
        yield put(getTermsError());
    }

    loading.close();
}
