import styled from 'styled-components';
import { TextArea } from 'semantic-ui-react';

export const StyledTextArea = styled(TextArea)`
    border: 1px solid #a6a6a6;
    border-radius: 4px;
    background-color: #ffffff;
    width: 100%;
    padding: 0.67857143em 1em;
    color: #444;

    &:hover,
    &:active,
    &:focus,
    &:focus-within {
        ${props => (!props.error ? 'border-color: #444444 !important;' : '')}
        border-radius: 4px !important;
        outline: 0px !important;
    }

    ${props => {
        if (props['error']) {
            return `
                border-color: #a70e1a;
                color: inherit;
            `;
        }
        return '';
    }}
`;
