import React, { Component } from 'react';
import {
    ConcentrationUnitContainer,
    ConcentrationUnitText,
    ConcentrationUnitType,
    Mass,
    Volume,
    ConcentrationUnitTypeText,
    CustomIcon,
    SolventContainer
} from './index.styled';
import { Empty } from '../../../../../../components/UI';
import {
    Solvent,
    FormulationSolvent
} from '../../../../../../models/solvent.model';
import { WithTranslation, withTranslation } from 'react-i18next';
import SolventGrid from './SolventGrid';
import {
    CostEstimate,
    UnitMeasure
} from '../../../../../../models/conversion.model';
import SimulationConfig from '../../../../../../models/simulationConfig.model';
import { dialog } from '../../../../../../components/UI/Dialog';
import { IWizardContext } from '../../../../../../components/UI';
import Search from '../../../../../../components/UI/DocumentSearch';
import { htmlT } from '../../../../../../helpers/util';
import SearchModel from '../../../../../../models/search.model';

class StepFormulationContainer extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            solventList: []
        };
    }

    private handleRemoveSolvent = id => {
        const { solventList } = this.state;
        const { removeSolvent } = this.props;
        removeSolvent(id);
        this.setState({
            solventList: solventList.filter(solvent => solvent.id !== id)
        });
    };

    private handleOnChange = item => {
        const { addSolvent } = this.props;
        const { solventList } = this.state;
        const formulationSolvent = new FormulationSolvent(item.id, item);
        addSolvent(formulationSolvent);
        this.setState({
            solventList: [...solventList, formulationSolvent]
        });
    };

    private handleSwitchUnit = (type: string) => {
        const { initialValues, switchUnit } = this.props;

        const unitType =
            initialValues.is_volume === null
                ? null
                : initialValues.is_volume === true
                ? 'VOLUME'
                : 'MASS';

        if (unitType !== null) {
            dialog({
                        type: 'warning',
                        header: {
                            i18nKey: 'simulation.stepFormulation.dialog.title'
                        },
                        body: {
                            i18nKey: 'simulation.stepFormulation.dialog.body'
                        },
                        buttons: {
                            confirm: {
                                i18nKey:
                                    'simulation.stepFormulation.dialog.confirmBtn',
                                event: () => this.props.switchUnit(type)
                            }
                        }
                    })
        } else {
            switchUnit(type);
        }
    };

    private renderSelectSolvent = () => {
        const { t, startSearch, onSearchChange, limit } = this.props;
        const { solventList } = this.state;
        const i18nNameRefer = t(
            'simulation.stepFormulation.solventSelection.nameRefer'
        );
        const placeHolder = t('search.simulation.placeHolder', {
            i18nNameRefer
        });
        const noResultMessage = searchQuery =>
            htmlT(t, 'search.simulation.notFound', {
                name: `<b>${searchQuery}</b>`,
                i18nNameRefer
            });
        return (
            <Search
                id="StepPolymer_solventSelect"
                type="SOLVENT"
                startSearch={startSearch}
                stateValues={solventList.map(solvent => solvent.id)}
                i18nKey="simulation.stepFormulation.solventSelection.selectLabel"
                placeHolder={placeHolder}
                limit={limit}
                noResultsMessage={noResultMessage}
                onChange={item => {
                    onSearchChange(item, solvent => {
                        this.handleOnChange(solvent);
                    });
                }}
            />
        );
    };

    render() {
        const {
            stepNumber,
            currentStep,
            stepProgress,
            limit,
            dispatchCostEstimate,
            dispatchUnitMeasure,
            unitMeasure,
            costEstimate,
            conversionLoading,
            conversionMessageError,
            onNext,
            setConcentration,
            initialValues,
            setCost,
            switchCost,
            isComparation,
            isChanged
        } = this.props;

        if (currentStep !== stepNumber && stepProgress < stepNumber) {
            return <Empty />;
        }
        const unitType =
            initialValues.is_volume === null
                ? ''
                : initialValues.is_volume === true
                ? 'VOLUME'
                : 'MASS';
        return (
            <React.Fragment>
                <ConcentrationUnitContainer>
                    <ConcentrationUnitText i18nKey="simulation.stepFormulation.stepInfo.concentrationUnit.title" />
                    <CustomIcon
                        name="info-circle"
                        size={19}
                        i18nKey="simulation.stepFormulation.stepInfo.concentrationUnit.tooltip"
                    />
                    <ConcentrationUnitType>
                        <Mass
                            id="concentrationUnitTypeMass"
                            data-unit={unitType}
                            onClick={() => this.handleSwitchUnit('MASS')}
                        >
                            <ConcentrationUnitTypeText i18nKey="simulation.stepFormulation.stepInfo.concentrationUnit.mass" />
                        </Mass>

                        <Volume
                            id="concentrationUnitTypeVolume"
                            data-unit={unitType}
                            onClick={() => this.handleSwitchUnit('VOLUME')}
                        >
                            <ConcentrationUnitTypeText i18nKey="simulation.stepFormulation.stepInfo.concentrationUnit.volume" />
                        </Volume>
                    </ConcentrationUnitType>
                </ConcentrationUnitContainer>
                {unitType && (
                    <SolventContainer>
                        {this.renderSelectSolvent()}
                        <SolventGrid
                            {...this.props}
                            stepNumber={stepNumber}
                            limit={limit}
                            initialValues={{
                                is_volume: initialValues.is_volume,
                                is_value_per_liter:
                                    initialValues.is_value_per_liter,
                                solvents: initialValues.solvents
                            }}
                            switchCost={switchCost}
                            handleRemoveSolvent={this.handleRemoveSolvent}
                            dispatchCostEstimate={dispatchCostEstimate}
                            dispatchUnitMeasure={dispatchUnitMeasure}
                            costEstimate={costEstimate}
                            unitMeasure={unitMeasure}
                            conversionLoading={conversionLoading}
                            conversionMessageError={conversionMessageError}
                            onNext={onNext}
                            setConcentration={setConcentration}
                            setCost={setCost}
                            isComparation={isComparation}
                            isChanged={isChanged}
                        />
                    </SolventContainer>
                )}
            </React.Fragment>
        );
    }

    componentWillMount() {
        const { initialValues } = this.props;
        this.setState({
            solventList: initialValues.solvents!
        });
    }
}

interface Props extends IWizardContext, WithTranslation {
    isComparation: boolean;
    isChanged: boolean;
    stepNumber: number;
    limit: number;
    startSearch: number;
    solvents: Solvent[];
    solventLoading: boolean;
    costEstimate: CostEstimate;
    unitMeasure: UnitMeasure;
    conversionLoading: boolean;
    conversionMessageError: string;
    initialValues: Partial<SimulationConfig>;
    onSearchChange(item: SearchModel, callback: Function);
    onNext(data): void;
    switchUnit(type): void;
    switchCost(type): void;
    dispatchCostEstimate(data: CostEstimate): void;
    dispatchUnitMeasure(data: UnitMeasure): void;
    setConcentration(data: { value: number; id: number }): void;
    setCost(data: { value: number; id: number }): void;
    addSolvent(solvent: FormulationSolvent): void;
    removeSolvent(id: number): void;
}

interface State {
    solventList: FormulationSolvent[];
}

export default withTranslation('app')(StepFormulationContainer);
