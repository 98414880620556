import React from 'react';
import { ButtonContainer, CustomButtom } from './index.styles';
import { generateReport } from './utils';
import { outTranslator, showToastr } from '../../../helpers/util';

class ReportButtom extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        };
    }

    private downloadPdf = () => {
        this.setState({ loading: true }, () =>
            showToastr('info', outTranslator('simulation.export.await_export'))
        );
        generateReport(this.props.projectName, () => {
            this.setState({ loading: false }, () =>
                showToastr(
                    'success',
                    outTranslator('simulation.export.success_export')
                )
            );
        });
    };

    render() {
        const { loading } = this.state;
        return (
            <ButtonContainer>
                <CustomButtom
                    id="Button_export"
                    loading={loading}
                    i18nKey={'simulation.export.buttom'}
                    cl="invertedPrimary"
                    onClick={this.downloadPdf}
                />
            </ButtonContainer>
        );
    }
}

export interface Props {
    projectName: string;
}

export default ReportButtom;
