const disabledColor = 'rgba(204, 204, 204, 0.5)';

const colors = {
    main: '#763689',
    label: {
        default: {
            color: '#444444'
        },
        primary: {
            color: '#763689'
        },
        secondary: {
            color: '#A6A6A6'
        }
    },
    buttons: {
        default: {
            bg: '#763689',
            color: '#FFFFFF',
            border: '#763689',
            hover: {
                bg: '#5B2A6A',
                color: '#FFFFFF',
                border: '#5B2A6A'
            }
        },
        invertedDefault: {
            bg: 'transparent',
            color: '#763689',
            border: '#763689',
            hover: {
                bg: 'transparent',
                color: '#5B2A6A',
                border: '#5B2A6A'
            }
        },
        primary: {
            bg: '#009EE0',
            color: '#FFFFFF',
            border: '#009EE0',
            hover: {
                bg: '#005E85',
                border: '#005E85',
                color: '#FFFFFF'
            }
        },
        invertedPrimary: {
            bg: 'transparent',
            color: '#009EE0',
            border: '#009EE0',
            hover: {
                bg: 'transparent',
                color: '#005E85',
                border: '#005E85'
            }
        },
        span: {
            bg: 'transparent',
            color: '#a6a6a6',
            border: 'none',
            hover: {
                bg: 'transparent',
                color: '#005E85',
                border: 'none'
            }
        },
        disabled: {
            bg: disabledColor,
            color: '#aaaaaa',
            border: disabledColor,
            hover: {
                bg: disabledColor,
                color: '#aaaaaa',
                border: disabledColor
            }
        }
    }
};

export default colors;
