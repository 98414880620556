import axios from 'axios';
import i18next from 'i18next';
import Firebase from '../configs/firebase';
import store from '../redux/store';
import { signOut } from '../redux/actions/user.actions';
import { dialog } from '../components/UI';
import { sleep } from '../helpers/util';
import idleToken from "./idleToken";

const api = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

api.interceptors.request.use(
    async config => {
        let token = '';
        if (Firebase && Firebase.auth && Firebase.auth.currentUser) {
            await idleToken();
            token = await Firebase.auth.currentUser.getIdToken();
        }
        return {
            ...config,
            headers: {
                'Accept-Language': i18next.language? i18next.language.replace('_', '-') : 'pt-br',
                'Authorization': token,
                'Access-Control-Allow-Origin': '*'
            }
         };
    },
    error => Promise.reject(error)
);

api.interceptors.response.use(
    success => {
        return success;
    },
    async error => {
        const request = error.config;
        if (!request) {
            return error;
        }
        if (error.request.status === 401) {
            const exit = () => {
                store.dispatch(signOut());
                window.location.href = `${process.env.REACT_APP_CURRENT_URL}/sign`;
            };
            dialog({
                type: 'error',
                i18nKey: 'tokenExpired',
                onClose: exit
            });
            await sleep(999999);
            return error;
        }
        if (error.request.status === 404) {
            if (request.method === 'post' || request.method === 'put') {
                return axios[request.method](
                    request.url.replace(
                        'https://simulation-dot-solvay-solsys-lite-dev.appspot.com',
                        'http://localhost:8000'
                    ),
                    request.data
                ).then(data => {
                    return data;
                });
            }
            if (request.method === 'get' || request.method === 'delete') {
                return axios[request.method](
                    request.url.replace(
                        'https://simulation-dot-solvay-solsys-lite-dev.appspot.com',
                        'http://localhost:8000'
                    )
                ).then(data => {
                    return data;
                });
            }
        }
        return error;
    }
);

export default api;
