import React from 'react';
import { Table as SemanticTable } from 'semantic-ui-react';
import {
    Container,
    StyledTable,
    StyledTableHeader,
    StyledTableHeaderCell,
    StyledTableCell,
    StyledTableCellCustom,
    StyledTableHeaderCellInverted
} from './index.styled';

export class Table extends React.Component<any, {}> {
    public static Header = StyledTableHeader;
    public static HeaderCell = StyledTableHeaderCell;
    public static HeaderCellInverted = StyledTableHeaderCellInverted;
    public static Row = SemanticTable.Row;
    public static Body = SemanticTable.Body;
    public static Cell = StyledTableCell;
    public static CellCustom = StyledTableCellCustom;

    render() {
        const { className, ...rest } = this.props;
        return (
            <Container className={className}>
                <StyledTable {...rest} />
            </Container>
        );
    }
}
