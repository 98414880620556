import { Term } from '../../models/term.model';
import { dialog } from '../../components/UI';
import { action } from 'typesafe-actions';
import { TermsTypes } from '../types/terms.types';

export const getTerms = () => action(TermsTypes.GET_TERMS_REQUEST);

export const getTermsSuccess = (terms: Term[]) =>
    action(TermsTypes.GET_TERMS_SUCCESS, terms);

export const getTermsError = () => action(TermsTypes.GET_TERMS_FAILURE);

export const saveTerms = (term: Term, callback?: Function) =>
    action(TermsTypes.SAVE_TERMS_REQUEST, { term, callback });

export const saveTermsSuccess = () => {
    dialog({
        type: 'success',
        i18nKey: 'redux.actions.terms.save.success'
    });
    return action(TermsTypes.SAVE_TERMS_SUCCESS);
};

export const saveTermsError = () => {
    dialog({
        type: 'error',
        i18nKey: 'redux.actions.terms.save.error'
    });
    return action(TermsTypes.SAVE_TERMS_FAILURE);
};
