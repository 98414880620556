import styled from 'styled-components';
import * as THREE from 'three';
import { Span, Row } from '../../../../../../components/UI';

export const Container = styled.div`
    position: absolute;
    min-width: 220px;
    border: 0.5px solid #cccccc;
    border-radius: 4px;
    padding: 10px 15px;
    background-color: #ffffff;
    box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.2),
        0 0 4px 2px rgba(204, 204, 204, 0.15);
    ${({ x, y }: THREE.Vector3) => {
        return `
            left: ${x}px;
            top: ${y}px;
        `;
    }}
`;

export const MixtureIcon = styled.img`
    height: 9px;
    margin-right: 7.5px;
`;

export const Name = styled(Span)`
    color: #444444;
    font-size: 14px;
    font-weight: 600;
    display: inline-block;
`;

export const AxisDescription = styled(Span)`
    color: #a6a6a6;
    font-size: 14px;
    width: 20px;
    display: inline-block;
`;

export const Description = styled(Span)`
    color: #a6a6a6;
    font-size: 14px;
    margin-right: 5px;
    display: inline-block;
`;

export const StyledRow = styled(Row)`
    margin: 7.5px 0 !important;
`;

export const GreenSpan = styled(Span)`
    color: #0b8e57;
    font-size: 14px;
    margin-right: 5px;
    display: inline-block;
`;

export const RedSpan = styled(Span)`
    color: #a70e1a;
    font-size: 14px;
    margin-right: 5px;
    display: inline-block;
`;

export const SimularionName = styled(Span)`
    font-size: 12px;
    line-height: 18px;
    color: #a6a6a6;
    margin-bottom: 8px;
    display: block;
`;
