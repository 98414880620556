import React from 'react';
import { Container } from './index.styled';
import ReactPaginate from 'react-paginate';

export const Pagination = ({
    pageCount,
    handlePageClick,
    ...rest
}: PaginationProps) => {
    return (
        <Container>
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                breakLabel={'...'}
                pageClassName={'page'}
                pageCount={pageCount}
                marginPagesDisplayed={0}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                nextClassName={'next-previous next'}
                previousClassName={'next-previous previous'}
                activeClassName={'active'}
                {...rest}
            />
        </Container>
    );
};

interface PaginationProps {
    pageCount: number;
    forcePage?: number;
    handlePageClick: Function;
}
