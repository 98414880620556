import React from 'react';
import { StyledHint } from './index.styled';

export const Hint = (props: HintProps) => <StyledHint {...props} />;

export interface HintProps {
    name: string;
    i18nKey: string;
    size: number;
    color: string;
}

Hint.defaultProps = {
    name: 'info-circle',
    size: 19,
    color: '#656565'
};
