import React from 'react';
import { Pie, Cell } from 'recharts';
import {
    GraphLegend,
    FormulationSpan,
    Graph,
    Chart,
    NameSpan,
    SizedBox
} from './index.styled';
import Legends from './Legends';

class CompositionGraph extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            selected: null
        };
    }

    private isSelected = id => {
        const { selected } = this.state;
        if (!selected) {
            return 1;
        }
        if (selected === id) {
            return 1;
        } else {
            return 0.3;
        }
    };

    private onSelected = id => {
        this.setState({ selected: id });
    };

    render() {
        const { data, name, pallet, isComparation } = this.props;
        const { selected } = this.state;
        return (
            <SizedBox data-comparation={isComparation}>
                <Graph>
                    <NameSpan renderText={name} />
                    <FormulationSpan i18nKey="compositionGraph.formulation" />
                    <Chart width={250} height={250}>
                        <Pie
                            data={data}
                            innerRadius={80}
                            outerRadius={120}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            {data.map((entry, index) => (
                                <Cell
                                    key={`cell-${index}`}
                                    fill={pallet[index]}
                                    onMouseLeave={() => this.onSelected(null)}
                                    fillOpacity={this.isSelected(entry.id)}
                                    onMouseEnter={() =>
                                        this.onSelected(entry.id)
                                    }
                                />
                            ))}
                        </Pie>
                    </Chart>
                </Graph>
                <GraphLegend data-comparation={isComparation}>
                    <Legends
                        pallet={pallet}
                        solvents={data}
                        selected={selected}
                        onSelected={this.onSelected}
                    />
                </GraphLegend>
            </SizedBox>
        );
    }
}

interface Props {
    name: string;
    data: any[];
    isComparation: boolean;
    pallet;
}

export default CompositionGraph;
