import styled from 'styled-components';
import { Span } from '../../UI/Span';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 0.5px solid #cccccc;
    padding: 8px 16px;
    z-index: 100;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: -1px -1px 0 0 rgba(68, 68, 68, 0.2),
        1px 1px 4px 2px rgba(204, 204, 204, 0.15);
`;

const CustomSpan = styled(Span)`
    font-family: 'Source Sans Pro';
    font-size: 14px;
    line-height: 18px;
`;

export const SimulationName = styled(CustomSpan)`
    color: #a6a6a6;
    font-size: 12px;
    line-height: 15px;
`;

export const SolventLabel = styled(CustomSpan)`
    color: #444444;
`;

export const SolventSpan = styled(CustomSpan)`
    color: #a6a6a6;
`;

export const SolventLine = styled.div`
    box-sizing: border-box;
    margin-right: 4px;
    margin-top: 8px;
    height: 2px;
    width: 10px;
    background-color: ${props => props.color};
`;

export const SolventContainer = styled.div`
    display: flex;
    align-items: flex-start;
    margin: 4px 0;
`;

export const HeaderContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const EvaporationContainer = styled.div`
    margin-bottom: 16px;
`;

export const LabelRED = styled(SolventSpan)`
    margin-left: 14px;
`;

export const ValueRED = styled(CustomSpan)`
    color: ${props => (props['data-valid'] ? '#0b8e57' : '#a70e1a')};
    margin-left: 4px;
`;

export const REDContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const BodyContainer = styled.ul`
    width: auto;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const InlineLegend = styled.li`
    display: inline-block;
    width: 200px;
    border-right: 1px solid #ccc;
    padding-right: 15px;

    &:last-child {
        border-right: none;
        padding-right: 0px;
        padding-left: 15px;
    }
`;
