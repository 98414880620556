import styled from 'styled-components';
import { H1, Button } from '../../../UI';

export const Modal: any = styled.div`
    padding: 24px;
`;

Modal.Title = styled(H1)`
    height: 45px;
    color: #000000;
    font-size: 36px;
    font-weight: bold;
    line-height: 45px;
    display: unset;
`;

Modal.Buttons = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

Modal.Buttons.Button = styled(Button)`
    &.ui.button {
        padding: 4px 40px;
        font-size: 16px;
        font-weight: normal;
        margin-right: 0;
        margin-left: 15px;
    }
`;

Modal.Buttons.SaveButton = styled(Modal.Buttons.Button)`
    &.ui.button {
        font-weight: bold;
    }
`;
