import React from 'react';
import * as THREE from 'three';
import ThreeMeshLine from 'three.meshline';
import ThreeOrbitControls from 'three-orbit-controls-fx';
import {
    Graph,
    Container,
    ButtonsBar,
    ButtonsContainer,
    ComparationBar
} from './index.styled';
import _ from 'lodash';
import {
    createEllipsoidGeometry,
    renderPlot,
    buildGraphHelper,
    buildAxis,
    buildLine,
    singlePush
} from '../../../../../../helpers/three';
import { Solvent } from '../../../../../../models/solvent.model';
import { Empty } from '../../../../../../components/UI';
import {
    getMousePos,
    convertHexColorToNumber,
    outTranslator
} from '../../../../../../helpers/util';
import Definitions from '../Definitions';
import pallet from '../../index.pallet.json';
import { Polymer } from '../../../../../../models/polymer.model';
import { SolubilityObjectType } from '../../../../../../models/solubilityObjectType.enum';
import SimulationResultWithConfigs from '../../../../../../models/simulationResultWithConfigs.model';

class SolubilityGraph3D extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            animate: false,
            selected: null,
            scale: 20,
            gridSize: 40,
            opacities: [],
            initialX: () => -((this.state.gridSize * this.state.scale) / 2),
            initialZ: () => -((this.state.gridSize * this.state.scale) / 2),
            initialY: () => 0
        };
    }

    private raycaster = new THREE.Raycaster();
    private orbitControls = new ThreeOrbitControls(THREE);
    private scene: THREE.Scene;
    private renderer: THREE.WebGLRenderer;
    private camera: THREE.PerspectiveCamera;
    private controls: ThreeOrbitControls;
    private meshLines: any[] = [];

    private get canvas() {
        return document.getElementById('solubility_graph')!;
    }

    private expand = () => {
        this.setState({ expanded: true });
    };

    private compress = () => {
        this.setState({ expanded: false });
    };

    private toggleVisibleSimulation = simulation => {
        this.scene.children.forEach(children => {
            if (children.name && children.name.includes(simulation.date)) {
                children.visible = !children.visible;
            }
        });
    };

    private recoverOpacityEffect = selected => {
        const { opacities } = this.state;
        try {
            this.scene.children.forEach(children => {
                if (children['material'] && selected.object) {
                    children['material'].opacity = opacities.find(
                        opacity => opacity.id === children.id
                    ).opacity;
                }
            });
        } catch (ex) {}
    };

    private removeObjectLines() {
        this.scene.children = this.scene.children.filter(
            object => !object.name.includes('base@object_line_position_')
        );
    }

    private clearSelected = () => {
        const { selected } = this.state;
        if (selected) {
            this.removeObjectLines();
            this.recoverOpacityEffect(selected);
            this.setState({
                selected: null
            });
        }
    };

    private setOpacityEffect = selected => {
        const opacities = new Array<any>();
        this.scene.children.forEach(children => {
            if (children['material'] && selected) {
                opacities.push({
                    id: children.id,
                    opacity: children['material'].opacity
                });
                const objectName = children.name.split('@')[0];
                if (
                    objectName !== selected.object.name &&
                    objectName !== 'base'
                ) {
                    children['material'].transparent = true;
                    children['material'].opacity = 0.05;
                }
            }
        });
        this.setState({
            opacities
        });
    };

    private createObjectLinePosition(selected) {
        if (
            selected &&
            selected.object &&
            selected.object.userData &&
            selected.object.userData.position
        ) {
            const { scale, initialX, initialY, initialZ } = this.state;
            const { dD, dP, dH } = selected.object.userData.position;
            const color = 0xa6a6a6;
            const size = { x: 1, y: 1, z: 1 };
            const position = {
                x: initialX() + dP * scale,
                y: initialY() + dH * scale,
                z: initialZ() + dD * scale
            };
            const lineD = buildLine(
                'base@object_line_position_d',
                color,
                { ...size, x: dP * scale },
                {
                    ...position,
                    x: initialX() + (dP * scale) / 2
                }
            );
            const lineP = buildLine(
                'base@object_line_position_p',
                color,
                { ...size, z: dD * scale },
                {
                    ...position,
                    z: initialZ() + (dD * scale) / 2
                }
            );
            const lineH = buildLine(
                'base@object_line_position_h',
                color,
                { ...size, y: dH * scale },
                {
                    ...position,
                    y: initialY() + (dH * scale) / 2
                }
            );
            this.scene.add(lineD, lineP, lineH);
        }
    }

    private _setDefinitions = event => {
        const mouse = new THREE.Vector2();
        const bounds = this.canvas.getBoundingClientRect();
        mouse.x =
            ((event.clientX - bounds.left) / this.canvas.clientWidth) * 2 - 1;
        mouse.y =
            -((event.clientY - bounds.top) / this.canvas.clientHeight) * 2 + 1;
        this.raycaster.setFromCamera(mouse, this.camera);
        const intersects = this.raycaster.intersectObjects(
            [...this.scene.children, ...this.meshLines],
            false
        );
        const selected = intersects.find(
            intersect => !_.isEqual(intersect.object.userData, {})
        );
        this.clearSelected();
        this.createObjectLinePosition(selected);
        this.setOpacityEffect(selected);
        this.setState({
            selected: { ...selected, mouse: getMousePos(bounds, event) }
        });
    };
    public get setDefinitions() {
        return this._setDefinitions;
    }
    public set setDefinitions(value) {
        this._setDefinitions = value;
    }

    private stopRotate = () => {
        this.controls.autoRotate = false;
    };

    private addListeners() {
        this.canvas.addEventListener('click', this.setDefinitions);
        this.canvas.addEventListener('click', this.stopRotate);
        this.controls.addEventListener('change', this.clearSelected);
    }

    private removeListeners() {
        this.canvas.removeEventListener('click', this.setDefinitions);
        this.canvas.removeEventListener('click', this.stopRotate);
        this.controls.dispose();
    }

    private buildRender() {
        const renderer = new THREE.WebGLRenderer({
            antialias: true,
            preserveDrawingBuffer: true
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(this.canvas.clientWidth, this.canvas.clientHeight);
        renderer.shadowMap.enabled = true;
        this.canvas.appendChild(renderer.domElement);
        this.renderer = renderer;
    }

    private buildScene() {
        const scene = new THREE.Scene();
        scene.background = new THREE.Color(0xfbfbfb);
        scene.add(new THREE.AmbientLight(0x222244));
        this.scene = scene;
    }

    private buildLight() {
        const light = new THREE.DirectionalLight();
        light.position.set(0.5, 1, 0.5);
        light.castShadow = true;
        light.shadow.camera.zoom = 30;
        this.scene.add(light);
    }

    private buildCamera() {
        const camera = new THREE.PerspectiveCamera(
            45,
            this.canvas.clientWidth / this.canvas.clientHeight,
            1,
            5000
        );
        camera.position.set(1100, 1000, 1200);
        camera.setViewOffset(
            this.canvas.clientWidth,
            this.canvas.clientHeight,
            0,
            -100,
            this.canvas.clientWidth,
            this.canvas.clientHeight
        );
        this.scene.add(camera);
        this.camera = camera;
    }

    private buildControls() {
        const { scale } = this.state;
        const controls = new this.orbitControls(
            this.camera,
            this.renderer.domElement
        );
        controls.autoRotate = true;
        controls.minDistance = 0;
        controls.maxDistance = 100 * scale;
        this.controls = controls;
    }

    private buildSolvent(
        id: number,
        name: string,
        color: string,
        position = { dP: 0, dH: 0, dD: 0 },
        ter,
        simulation
    ) {
        const { initialX, initialY, initialZ, scale } = this.state;
        const solventGeometry = new THREE.SphereBufferGeometry(8, 25, 25);
        const solventMaterial = new THREE.MeshPhongMaterial({
            color: convertHexColorToNumber(color)
        });
        const solventMesh = new THREE.Mesh(solventGeometry, solventMaterial);
        solventMesh.userData = {
            type: SolubilityObjectType.Solvent,
            id,
            simulationName: simulation.name,
            name,
            ter,
            color,
            position,
            mixture: simulation.result.graphs.mixing
        };
        solventMesh.name = `${simulation.date}_solvent_${id}`;
        solventMesh.position.x = initialX() + position.dP * scale;
        solventMesh.position.y = initialY() + position.dH * scale;
        solventMesh.position.z = initialZ() + position.dD * scale;
        solventMesh.castShadow = true;
        solventMesh.receiveShadow = true;
        renderPlot(this.scene, solventMesh);
    }

    private buildPolymer(
        id: number,
        name: string,
        color: string,
        position = { dP: 0, dH: 0, dD: 0 },
        size = { rP: 10, rH: 10, rD: 10 },
        simulation
    ) {
        const { scale, initialX, initialY, initialZ } = this.state;
        const buildInnerSphere = () => {
            // create inner sphere of polymer in 3d graph
            const sphereGeometry = new THREE.SphereGeometry(5, 25, 25);
            const sphereMaterial = new THREE.MeshBasicMaterial({
                color: convertHexColorToNumber(color)
            });
            const sphereMesh = new THREE.Mesh(sphereGeometry, sphereMaterial);

            // create inner sphere line of polymer
            const lineMesh = buildLine(null, convertHexColorToNumber(color), {
                x: 50,
                y: 1,
                z: 1
            });

            const innerSphereGeometry = new THREE.Geometry();
            innerSphereGeometry.mergeMesh(sphereMesh);
            innerSphereGeometry.mergeMesh(lineMesh);
            const innerSphereMaterial = new THREE.MeshPhongMaterial({
                color: convertHexColorToNumber(color)
            });
            const innerSphereMesh = new THREE.Mesh(
                innerSphereGeometry,
                innerSphereMaterial
            );
            innerSphereMesh.name = `${simulation.date}_polymer_${id}`;
            innerSphereMesh.userData = {
                type: SolubilityObjectType.Polymer,
                simulationName: simulation.name,
                id,
                name,
                color,
                position,
                size,
                mixture: simulation.result.graphs.mixing
            };
            innerSphereMesh.position.x = initialX() + position.dP * scale;
            innerSphereMesh.position.y = initialY() + position.dH * scale;
            innerSphereMesh.position.z = initialZ() + position.dD * scale;

            return innerSphereMesh;
        };

        const buildOuterSphere = position => {
            // create outer sphere of polymer in 3d graph
            const outerSphereGeometry = createEllipsoidGeometry(
                size.rP * scale,
                size.rH * scale,
                size.rD * scale,
                50,
                50
            );
            const outerSphereMaterial = new THREE.MeshPhongMaterial({
                color,
                emissive: color,
                transparent: true,
                opacity: 0.008,
                side: THREE.DoubleSide,
                depthWrite: false
            });
            const outerSphereMesh = new THREE.Mesh(
                outerSphereGeometry,
                outerSphereMaterial
            );
            outerSphereMesh.name = `${simulation.date}_polymer_${id}@outer`;
            outerSphereMesh.position.copy(position);
            return outerSphereMesh;
        };

        const buildOuterWiredSphere = position => {
            // create outer wireframe sphere of polymer in 3d graph
            const outerWireSphereGeometry = createEllipsoidGeometry(
                size.rP * scale,
                size.rH * scale,
                size.rD * scale,
                30,
                30
            );
            const outerWireSphereMaterial = new THREE.MeshPhongMaterial({
                color,
                emissive: color,
                wireframe: true,
                transparent: true,
                opacity: 0.15
            });
            const outerWireSphereMesh = new THREE.Mesh(
                outerWireSphereGeometry,
                outerWireSphereMaterial
            );
            outerWireSphereMesh.name = `${simulation.date}_polymer_${id}@outer2`;
            outerWireSphereMesh.position.copy(position);
            return outerWireSphereMesh;
        };

        // spheres in scene
        const innerSphere = buildInnerSphere();
        renderPlot(this.scene, innerSphere);
        renderPlot(this.scene, buildOuterSphere(innerSphere.position));
        renderPlot(this.scene, buildOuterWiredSphere(innerSphere.position));
    }

    private buildMixturePath(vertices: THREE.Vector3[], simulation) {
        const { initialX, initialY, initialZ, scale } = this.state;
        const buildMixturePoint = index => {
            const mixtureSphereGeometry =
                index === 0
                    ? new THREE.TorusGeometry(3, 1, 20, 100)
                    : new THREE.SphereGeometry(3, 20, 20);
            const mixtureSphereMaterial = new THREE.MeshBasicMaterial({
                color: index === 0 ? 0xab62c1 : 0x36193e
            });
            const mixtureSphereMesh = new THREE.Mesh(
                mixtureSphereGeometry,
                mixtureSphereMaterial
            );
            mixtureSphereMesh.position.x =
                initialX() + vertices[index].x * scale;
            mixtureSphereMesh.position.y =
                initialY() + vertices[index].y * scale;
            mixtureSphereMesh.position.z =
                initialZ() + vertices[index].z * scale;

            return mixtureSphereMesh;
        };
        const buildMixtureLine = () => {
            return vertices
                .map(
                    vertice =>
                        new THREE.Vector3(
                            initialX() + vertice.x * scale,
                            initialY() + vertice.y * scale,
                            initialZ() + vertice.z * scale
                        )
                )
                .splice(15, vertices.length - 1);
        };
        const buildMixture = (
            initial: THREE.Mesh,
            final: THREE.Mesh,
            line: THREE.Vector3[]
        ) => {
            const extractVertices = (mesh: THREE.Mesh) =>
                (mesh.geometry as THREE.Geometry).vertices.map(vertice => {
                    vertice.x = mesh.position.x + vertice.x;
                    vertice.y = mesh.position.y + vertice.y;
                    vertice.z = mesh.position.z + vertice.z;
                    return vertice;
                });

            const mixtureGeometry = new THREE.Geometry();
            mixtureGeometry.vertices = [
                ...mixtureGeometry.vertices,
                ...extractVertices(initial),
                ...line,
                ...extractVertices(final)
            ];

            const mixtureMaterial = new ThreeMeshLine.MeshLineMaterial({
                color: 0x763689,
                lineWidth: 2,
                side: THREE.DoubleSide
            });

            const meshLine = new ThreeMeshLine.MeshLine();
            meshLine.setGeometry(mixtureGeometry);

            const mixtureMesh = new THREE.Mesh(
                meshLine.geometry,
                mixtureMaterial
            );

            mixtureMesh.name = `${simulation.date}_mixture`;
            meshLine.name = `${simulation.date}_mixture`;
            meshLine.userData = {
                simulationName: simulation.name,
                type: SolubilityObjectType.Mixture,
                position: {
                    dP: vertices[0].x,
                    dH: vertices[0].y,
                    dD: vertices[0].z
                },
                mixture: simulation.result.graphs.mixing
            };

            singlePush(this.meshLines, meshLine);
            renderPlot(this.scene, mixtureMesh);
        };
        buildMixture(
            buildMixturePoint(0),
            buildMixturePoint(vertices.length - 1),
            buildMixtureLine()
        );
    }

    private plot() {
        const { simulations } = this.props;
        if (this.scene) {
            simulations.forEach((simulation, index) => {
                const {
                    solvents: formulationSolvent,
                    polymers
                } = simulation.parameters;
                const mixture = simulation.result.graphs.mixing;
                const solvents = formulationSolvent.map(
                    formulationSolvent => formulationSolvent.solvent
                );
                const isCurrent = index === 0;
                // criar solvents
                solvents
                    .slice(0, 19)
                    .forEach(
                        (
                            {
                                id,
                                name,
                                chemical: {
                                    delta_d,
                                    delta_h,
                                    delta_p,
                                    relative_evaporation_rate
                                }
                            }: Solvent,
                            index
                        ) => {
                            this.buildSolvent(
                                id,
                                name,
                                pallet.solvent[
                                    isCurrent ? 'current' : 'proposal'
                                ][index],
                                {
                                    dP: delta_p,
                                    dH: delta_h,
                                    dD: delta_d
                                },
                                relative_evaporation_rate,
                                simulation
                            );
                        }
                    );

                // criar os polimeros
                polymers.forEach(({ id, name, dimensions }: Polymer, index) => {
                    if (dimensions) {
                        const { axes, radius } = dimensions;
                        this.buildPolymer(
                            id,
                            name,
                            pallet.polymer[isCurrent ? 'current' : 'proposal'][
                                index
                            ],
                            {
                                dP: axes.delta_p,
                                dH: axes.delta_h,
                                dD: axes.delta_d
                            },
                            {
                                rP: radius.delta_p,
                                rH: radius.delta_h,
                                rD: radius.delta_d
                            },
                            simulation
                        );
                    }
                });

                // caminho da mistura
                this.createMixturePath(mixture, simulation);

            });
        }
    }
    private createMixturePath(mixture, simulation) {
        if (!mixture) {return;}
    
        const points = mixture.paths.map(path => new THREE.Vector3(path.delta_p, path.delta_h, path.delta_d));
        this.buildMixturePath(points, simulation);
    }
    private renderDefinitions() {
        const { selected } = this.state;
        if (!selected || !selected.object) {
            return <Empty />;
        }
        return <Definitions mouse={selected.mouse} object={selected.object} />;
    }

    private animate = () => {
        const { animate } = this.state;
        this.controls.update(this.camera);
        this.renderer.render(this.scene, this.camera);
        if (animate) {
            requestAnimationFrame(this.animate.bind(this));
        }
    };

    private startAnimate = () => {
        this.setState({ animate: true }, this.animate);
    };

    private stopAnimate = () => {
        this.setState({ animate: false });
    };

    render() {
        const { simulations } = this.props;
        const { expanded } = this.state;
        return (
            <Container
                id="solubility_graph_section"
                data-expanded={expanded}
                onMouseEnter={this.startAnimate}
                onMouseLeave={this.stopAnimate}
            >
                {this.renderDefinitions()}
                <Graph
                    pose={expanded ? 'fullscreen' : 'idle'}
                    id="solubility_graph"
                />
                <ButtonsBar data-html2canvas-ignore>
                    <ButtonsContainer>
                        {!expanded ? (
                            <ButtonsBar.Button
                                iconName="expand-arrows-alt"
                                iconSize={20}
                                onClick={this.expand}
                            />
                        ) : (
                            <ButtonsBar.Button
                                iconName="compress-arrows"
                                iconSize={20}
                                onClick={this.compress}
                            />
                        )}
                        <ButtonsBar.TopButton
                            iconName="plus"
                            iconSize={20}
                            onClick={() => {
                                this.controls.dollyOut(
                                    this.controls.getZoomScale()
                                );
                            }}
                        />
                        <ButtonsBar.BottomButton
                            iconName="minus"
                            iconSize={20}
                            onClick={() => {
                                this.controls.dollyIn(
                                    this.controls.getZoomScale()
                                );
                            }}
                        />
                        <ButtonsBar.Button
                            iconName="crosshair"
                            iconSize={20}
                            onClick={() => this.controls.reset()}
                        />
                    </ButtonsContainer>
                    <ButtonsBar.Icon
                        name="expand-arrows-alt"
                        iconSize={26}
                        renderText={outTranslator('solubilityGraph.mouseHint')}
                    />
                </ButtonsBar>
                {simulations.length > 1 && (
                    <ComparationBar data-html2canvas-ignore>
                        {simulations.map((simulation, index) => {
                            return (
                                <ComparationBar.Checkbox
                                    defaultChecked
                                    key={index}
                                    i18nKey={simulation.name}
                                    onClick={() =>
                                        this.toggleVisibleSimulation(simulation)
                                    }
                                />
                            );
                        })}
                    </ComparationBar>
                )}
            </Container>
        );
    }

    private buildGraph() {
        const { gridSize, scale, initialX, initialY, initialZ } = this.state;
        this.buildScene();
        this.buildLight();
        this.buildRender();
        this.buildCamera();
        this.buildControls();
        this.scene.add(
            ...buildGraphHelper(gridSize, scale, initialX, initialY, initialZ)
        );
        this.scene.add(
            ...buildAxis(
                this.controls,
                this.camera,
                gridSize,
                scale,
                initialX,
                initialY,
                initialZ
            )
        );

        this.plot();
        this.addListeners();
        this.forceUpdate(() => this.animate());
    }

    componentDidMount() {
        this.buildGraph();
    }

    componentWillUnmount() {
        this.removeListeners();
    }
}

interface Props {
    simulations: SimulationResultWithConfigs[];
}

export default SolubilityGraph3D;
