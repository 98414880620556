import styled from 'styled-components';
import { Span } from '../../../../../../../components/UI';

export const Compact = styled.section`
    padding: 15px 15px;
    width: 100%;
    border: 0.5px solid #dddddd;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.2),
        0 0 4px 2px rgba(204, 204, 204, 0.15);
    margin-bottom: 40px;
`;

export const SpanDescription = styled(Span)`
    padding-right: 16px;
    min-width: 101px;

    @media (max-width: 992px) {
        min-width: 101px;
        display: inline-block;
    }
`;
