import React, { Component } from 'react';
import { Empty } from '../../../../../../components/UI';
import { IConditionsOfApplicationFormSubmit } from '../../../../../../components/forms/ConditionsOfApplication';
import { Paint } from '../../../../../../models/paint.model';
import Compacted from './Compacted';
import Expanded from './Expanded';
import { IWizardContext } from '../../../../../../components/UI';

class StepConditionContainer extends Component<
    IStepConditionContainerProps,
    IStepConditionContainerState
> {
    constructor(props) {
        super(props);
        this.state = {
            data: null
        };
    }

    private handleSubmit = newData => {
        const { onNext, nextStep, previousStep } = this.props;
        const { data } = this.state;
        const actualData = data;
        this.setState({ data: newData }, () => {
            !actualData
                ? nextStep(() => onNext(newData))
                : previousStep(() => onNext(newData));
        });
    };

    private handleCancel = () => {
        const { data } = this.state;
        const { previousStep } = this.props;
        this.setState({ data }, () => previousStep());
    };

    private getPaintName() {
        const { paints } = this.props;
        const { data } = this.state;
        return paints.find((paint: Paint) => paint.id === data!.paint_type)!
            .name;
    }

    render() {
        const { data } = this.state;
        const {
            loading,
            paints,
            stepNumber,
            currentStep,
            stepProgress,
            previousStep
        } = this.props;

        if (
            currentStep !== stepNumber &&
            stepProgress > stepNumber &&
            data &&
            paints.length > 0
        ) {
            return (
                <section id="StepConditionOfApplication">
                    <Compacted
                        name={this.getPaintName()}
                        moisture={data!.moisture!}
                        temperature={data!.temperature!}
                        custom={data!.custom!}
                    />
                </section>
            );
        }

        if (currentStep !== stepNumber) {
            return (
                <section id="StepConditionOfApplication">
                    <Empty />
                </section>
            );
        }

        return (
            <section id="StepConditionOfApplication">
                <Expanded
                    loading={loading}
                    paints={paints}
                    stepNumber={stepNumber}
                    data={data!}
                    onCancel={this.handleCancel}
                    previousStep={previousStep}
                    currentStep={currentStep}
                    stepProgress={stepProgress}
                    onSubmit={this.handleSubmit}
                />
            </section>
        );
    }

    componentWillMount() {
        const { initialValues } = this.props;
        this.setState({ data: initialValues ? initialValues : null });
    }
}

export interface IStepConditionContainerProps extends IWizardContext {
    initialValues?;
    stepNumber: number;
    loading: boolean;
    paints: Paint[];
    onNext(data): void;
}

interface IStepConditionContainerState {
    data: Partial<IConditionsOfApplicationFormSubmit> | null;
}

export default StepConditionContainer;
