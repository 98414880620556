import React from 'react';
import { StyledIcon } from './index.styled';
import { Tooltip } from '../Tooltip';
import { withTranslationText } from '../../hoc/withTranslationText';

export const Icon = withTranslationText(
    ({ name, renderText, className, ...rest }: IIconProps) => {
        const tooltipId = `tooltip-${Math.random()
            .toString(36)
            .substring(2, 15)}`;
        const isMobile = window.innerWidth <= 767;
        return (
            <React.Fragment>
                <StyledIcon
                    {...rest}
                    className={`uil uil-${name} ${className}`}
                    data-for={tooltipId}
                    data-tip={renderText}
                    data-event={isMobile ? 'focus' : ''}
                    data-event-off={isMobile ? 'blur' : ''}
                />
                {renderText && (
                    <Tooltip id={tooltipId} effect="solid" type="light" />
                )}
            </React.Fragment>
        );
    }
);

interface IIconProps {
    name: string;
    size?: number;
    renderText: string;
    mirror?: boolean;
    className?: string;
    onClick?: { (event): void };
}
