import React from 'react';
import { StyledIconButton } from './index.styled';
import { Icon } from '../Icon';

export const IconButton = ({
    iconName,
    iconSize,
    mirror,
    tip,
    children,
    ...rest
}: IIconButtonProps) => {
    return (
        <StyledIconButton {...rest}>
            <Icon
                name={iconName}
                size={iconSize}
                mirror={mirror}
                i18nKey={tip}
            />
            {children}
        </StyledIconButton>
    );
};

IconButton.defaultProps = {
    type: 'button'
};

interface IIconButtonProps {
    tabIndex?: string;
    iconName: string;
    tip?: string;
    iconSize?: number;
    mirror?: boolean;
    id?: string;
    onClick();
    children?;
}
