import styled, { css } from 'styled-components';
import { Span } from '../../../../../../components/UI/Span';
import { Icon } from '../../../../../../components/UI/Icon';

const desactivedToogle = css`
    background-color: #fff;
    border: 1px solid #a6a6a6;

    span {
        color: #444444;
    }
`;

export const SolventContainer = styled.div`
    height: 100%;
    min-height: 300px;
`;

export const ConcentrationUnitContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 32px;
`;

export const ConcentrationUnitText = styled(Span)`
    padding-right: 3px;
`;

export const ConcentrationUnitType = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    width: 180px;
    margin-left: 16px;
    white-space: nowrap;
`;

export const Mass = styled.div`
    padding: 7px 15px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border: 1px solid #f6a906;
    border-right: none !important;
    background-color: #f6a906;
    cursor: pointer;
    ${props => (props['data-unit'] !== 'MASS' ? desactivedToogle : '')}
`;

export const Volume = styled.div`
    padding: 7px 15px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    border: 1px solid #d70e66;
    background-color: #d70e66;
    cursor: pointer;
    ${props => (props['data-unit'] !== 'VOLUME' ? desactivedToogle : '')}
`;
export const ConcentrationUnitTypeText = styled(Span)`
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
`;

export const CustomIcon = styled(Icon)`
    &:hover::before {
        color: #763689 !important;
    }
`;
