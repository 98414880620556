import styled from 'styled-components';
import colors from '../../../theme/colors';

export const StyledSpan = styled.span`
    font-size: 16px;
    line-height: 20px;

    ${(props: any) => {
        const { cl = 'default' } = props;
        return `
            color: ${colors.label[cl].color};
        `;
    }}
`;
