import React from 'react';
import { withTranslationText } from '../../hoc/withTranslationText';
import { StyledButton } from './index.styled';
import { Tooltip } from '../Tooltip';

export const Button = withTranslationText(
    class extends React.Component<IButtonProps, {}> {
        static defaultProps = {
            type: 'button'
        };

        render() {
            const {
                tooltip,
                renderText,
                children,
                disabledWithTooltip,
                disabled,
                loading,
                onClick,
                ...rest
            } = this.props;
            const tooltipId = `tooltip-${Math.random()
                .toString(36)
                .substring(2, 15)}`;
            const isMobile = window.innerWidth <= 767;
            return (
                <StyledButton
                    {...rest}
                    onClick={!disabledWithTooltip ? onClick : () => {}}
                    disabled={disabled || loading}
                    loading={loading}
                    data-for={tooltipId}
                    data-tip={tooltip}
                    data-event={isMobile ? 'focus' : ''}
                    data-event-off={isMobile ? 'blur' : ''}
                    data-disabled-with-tooltip={disabledWithTooltip}
                >
                    {renderText ? renderText : children}
                    {tooltip && (
                        <Tooltip id={tooltipId} effect="solid" type="light" />
                    )}
                </StyledButton>
            );
        }
    }
);

interface IButtonProps {
    id?: string;
    tabIndex?: string;
    type: string;
    renderText?: string;
    cl?: string;
    disabledWithTooltip?;
    disabled?;
    children?;
    tooltip?: string;
    loading?: boolean;
    onClick?();
}
