import styled from 'styled-components';
import { Span } from '../../../../../components/UI';

export const Doughnut = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 100px;
    margin-right: 7.5px;
    display: inline-block;
    ${props =>
        `border: 2px solid ${props['data-color']};
    height: ${props['data-size'] ? `${props['data-size']}px` : '10px'};
    width: ${props['data-size'] ? `${props['data-size']}px` : '10px'};`}
`;

export const Circle = styled.div`
    border-radius: 100px;
    margin-right: 7.5px;
    display: inline-block;
    ${props =>
        `background-color: ${props['data-color']};
        height: ${props['data-size'] ? `${props['data-size']}px` : '10px'};
        width: ${props['data-size'] ? `${props['data-size']}px` : '10px'};`}
`;

export const MixtureIcon = styled.img`
    height: 8px;
    margin-right: 7.5px;
`;

export const SimularionName = styled(Span)`
    font-size: 12px;
    line-height: 18px;
    color: #a6a6a6;
    margin-bottom: 8px;
    display: block;
`;

export const Legend: any = styled.div`
    margin: 10px 0px;
    width: 100%;
    margin-bottom: 24px;
`;

Legend.Span = styled(Span)`
    font-size: 14px;
    line-height: 18px;
    margin: 4px 0;
    margin-right: 24px;
    display: inline-block;
`;
