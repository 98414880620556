import React from 'react';
import { StyledGoTop, ArrowUp } from './index.styled';

const GoTopContainer = ({ scrollToTop, scrolledY }: GoTopContainerProps) => {
    return (
        <StyledGoTop data-scrolled={scrolledY} onClick={scrollToTop}>
            <ArrowUp name="arrow-up" />
        </StyledGoTop>
    );
};

interface GoTopContainerProps {
    scrollToTop(): void;
    scrolledY: number;
}

export default GoTopContainer;
