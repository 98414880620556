import { Reducer } from 'redux';
import { PropertiesState, PropertiesTypes } from '../../types/properties.types';

const INITIAL_STATE: PropertiesState = {
    uploading: false,
    downloading: false,
    error: false,
    message: ''
};

const users: Reducer<PropertiesState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PropertiesTypes.UPLOAD_REQUEST:
            return {
                ...state,
                uploading: true
            };
        case PropertiesTypes.UPLOAD_SUCCESS:
            return {
                ...state,
                uploading: false,
                error: false,
                message: action.payload
            };
        case PropertiesTypes.UPLOAD_FAILURE:
            return {
                ...state,
                uploading: false,
                error: true,
                message: action.payload
            };
        case PropertiesTypes.DOWNLOAD_REQUEST:
            return {
                ...state,
                downloading: true
            };
        case PropertiesTypes.DOWNLOAD_SUCCESS:
            return {
                ...state,
                downloading: false,
                error: false,
                message: action.payload
            };
        case PropertiesTypes.DOWNLOAD_FAILURE:
            return {
                ...state,
                downloading: false,
                error: true,
                message: action.payload
            };
        default:
            return state;
    }
};

export default users;
