import styled from 'styled-components';
import { Column, Span, H3 } from '../../../../../../../../../components/UI';

export const StyledColumn = styled(Column)`
    padding: 6px 0;
`;

export const PropSpan = styled(Span)`
    margin-right: 8px;
    display: inline-block;
`;

export const ResSpan = styled(Span)`
    word-break: break-all;
`;

export const GroupTitle = styled(H3)`
    font-size: 16px;
`;
