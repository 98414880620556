import styled from 'styled-components';
import { H2, Row, Span } from '../../../../../components/UI';

export const Container = styled.div`
    width: 100%;
    max-width: 486px;
    padding: 10px;
`;

export const Title = styled(H2)``;

export const StyledRow = styled(Row)`
    padding: 10px 0;
`;

export const NotReceived = styled(Span)`
    font-size: 14px;
    line-height: 18px;
    color: #a6a6a6;

    &:hover {
        color: #763689;
        cursor: pointer;
    }
`;

export const RequestResentment = styled(Span)`
    font-size: 14px;
    line-height: 18px;
    color: #a6a6a6;
`;

export const CountdownSpan = styled(Span)`
    font-size: 14px;
    line-height: 18px;
    color: #763689;
`;
