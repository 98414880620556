import styled from 'styled-components';
import { Span } from '../../UI';

export const footerHeight = 101;

export const Footer = styled.footer`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    min-height: ${footerHeight}px;
    width: 100%;
    background-color: #ffffff;
    border-top: 1px solid #cccccc;
    padding: 0 120px;

    @media (max-width: 992px) {
        padding: 0 40px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
    }
`;

export const Logo = styled.img`
    height: 100px;
`;

export const ReservedRights = styled(Span)`
    font-size: 14px;
    line-height: 18px;
    margin-left: auto;
    cursor: pointer;

    @media (max-width: 768px) {
        margin: auto;
    }
`;
