import { Paging } from '../../models/paging.model';
import { User } from '../../models/user.model';

/**
 * Action types
 */
export enum UsersTypes {
    SEARCH_USER_REQUEST = 'user:SEARCH_USER_REQUEST',
    SEARCH_USER_SUCCESS = 'user:SEARCH_USER_SUCCESS',
    SEARCH_USER_FAILURE = 'user:SEARCH_USER_FAILURE',
    DELETE_USER_REQUEST = 'user:DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS = 'user:DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE = 'user:DELETE_USER_FAILURE',
    TAKE_USER_REQUEST = 'user:TAKE_USER_REQUEST',
    TAKE_USER_SUCCESS = 'user:TAKE_USER_SUCCESS',
    TAKE_USER_FAILURE = 'user:TAKE_USER_FAILURE',
    SAVE_USER_REQUEST = 'user:SAVE_USER_REQUEST',
    SAVE_USER_SUCCESS = 'user:SAVE_USER_SUCCESS',
    SAVE_USER_FAILURE = 'user:SAVE_USER_FAILURE'
}

/**
 * State types
 */
export interface UsersState {
    readonly current: User | null;
    readonly items: {
        id: number;
        display_name: string;
        email: string;
        groups_count: number;
    }[];
    readonly loading: boolean;
    readonly error: boolean;
    readonly message: string;
    readonly paging: Paging;
}
