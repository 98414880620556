import styled from 'styled-components';
import { Span } from '../Span';

export const SpanError = styled(Span)`
    color: #a70e1a;
    font-size: 14px;
    line-height: 18px;
    margin-top: 5px;
    margin-left: 2.5px;
`;
SpanError.displayName = 'span';
