import { Reducer } from 'redux';
import { UserTypes, UserState } from '../../types/user.types';

const INITIAL_STATE: UserState = {
    recoveredEmail: '',
    currentUser: null,
    loading: false
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UserTypes.RECOVERY_REQUEST:
            return { ...state, loading: true };
        case UserTypes.RECOVERY_SUCCESS:
            return {
                ...state,
                loading: false,
                recoveredEmail: action.payload
            };
        case UserTypes.RECOVERY_FAILURE:
            return {
                ...state,
                loading: false
            };
        case UserTypes.RECOVERY_CLEAR:
            return {
                ...state,
                recoveredEmail: ''
            };
        case UserTypes.USER_REQUEST:
            return {
                ...state,
                loading: true
            };
        case UserTypes.USER_SUCCESS:
            return {
                ...state,
                loading: false,
                currentUser: action.payload
            };
        case UserTypes.USER_CLEAR:
            return {
                ...state,
                loading: false,
                currentUser: null
            };
        default:
            return state;
    }
};

export default reducer;
