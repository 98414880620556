import styled from 'styled-components';
import { Grid, H2 } from '../../../components/UI';
import { height as TopbarHeight } from '../../../components/layout/Header/Topbar/container/index.styled';

export const StyledSimulation = styled.section`
    padding: 40px 120px 100px 120px;
    width: 100%;
    flex: 1;
    overflow: hidden;

    @media (max-width: 992px) {
        padding: 40px 0 100px 0;
    }
`;

export const SimulationGrid = styled(Grid)`
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: ${TopbarHeight}px;
`;

export const SimulationSection = styled.section`
    margin-bottom: 56px;
`;

export const Title = styled(H2)`
    height: 49px;
    border-bottom: 1px solid #cccccc;

    @media (max-width: 992px) {
        padding-left: 15px;
    }
`;
