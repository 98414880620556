import { PropertiesTypes } from '../types/properties.types';
import { action } from 'typesafe-actions';
import { dialog } from '../../components/UI';

export const uploadProperties = (file: File, callback?: Function) =>
    action(PropertiesTypes.UPLOAD_REQUEST, { file, callback });

export const uploadPropertiesSuccess = () =>
    action(PropertiesTypes.UPLOAD_SUCCESS);

export const uploadPropertiesError = () => {
    dialog({
        type: 'error',
        i18nKey: 'redux.actions.properties.upload.error'
    });
    return action(PropertiesTypes.UPLOAD_FAILURE);
};

export const downloadProperties = (callback?: Function) => {
    return action(PropertiesTypes.DOWNLOAD_REQUEST, { callback });
};

export const downloadSuccess = () => action(PropertiesTypes.DOWNLOAD_SUCCESS);

export const downloadError = () => {
    dialog({
        type: 'error',
        i18nKey: 'redux.actions.properties.download.error'
    });
    return action(PropertiesTypes.DOWNLOAD_FAILURE);
};
