import styled from 'styled-components';
import { Table } from 'semantic-ui-react';

export const Container = styled.section`
    border-radius: 4px;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #ccc;
    display: block;
    width: 100%;
    box-shadow: 0px 0px 0 0 rgba(68, 68, 68, 0.2),
        1px 1px 4px 2px rgba(204, 204, 204, 0.15);
`;

export const StyledTable = styled(Table)`
    &.ui.table {
        border: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
    }

    @media only screen and (max-width: 767px) {
        &.ui.table:not(.unstackable) thead tr {
            padding: 0 !important;
        }

        &.ui.table thead tr > th {
            text-align: center;
        }

        &.ui.table tr > td {
            text-align: center;
        }
    }
`;

export const StyledTableHeader = styled(Table.Header)`
    background-color: #763689;
`;

export const StyledTableHeaderCell = styled(Table.HeaderCell)`
    background-color: transparent !important;
    color: #fff !important;
    padding: 10px 15px !important;
    font-weight: normal !important;
    font-size: 16px;
    border-left: none !important;
    border-radius: 0 !important;

    &:last-child {
        border-right: none !important;
    }
`;

export const StyledTableCell = styled(Table.Cell)`
    padding: 8px !important;
`;

export const StyledTableCellCustom = styled(StyledTableCell)`
    padding: 0 !important;
`;

export const StyledTableHeaderCellInverted = styled(StyledTableHeaderCell)`
    background-color: #ccc !important;
    color: #444444 !important;
    border: none !important;
    font-weight: 600;
`;
