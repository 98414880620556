import React from 'react';
import { ApplicationState } from '../../../redux/store';
import { connect } from 'react-redux';
import { objectByString } from '../../../helpers/util';

const SwitchComponent = ({
    reduxPath,
    condition
}: Params) => Component1 => Component2 => {
    const Switch = ({ condition, ...props }) => {
        return condition ? (
            <Component1 {...props} />
        ) : (
            <Component2 {...props} />
        );
    };
    if (reduxPath) {
        const mapStateToProps = (state: ApplicationState) => ({
            condition: objectByString(state, reduxPath)
        });
        return connect(mapStateToProps)(Switch);
    } else {
        return props => <Switch condition={condition} {...props} />;
    }
};

interface Params {
    reduxPath?: string;
    condition?: boolean;
}

export default SwitchComponent;
