import { UnitMeasure, CostEstimate } from "../../models/conversion.model";

/**
 * Action types
 */
export enum ConversionTypes {
    UNIT_MEASURE_REQUEST = "conversion:UNIT_MEASURE_REQUEST",
    UNIT_MEASURE_SUCCESS = "conversion:UNIT_MEASURE_SUCCESS",
    UNIT_MEASURE_FAILURE = "conversion:UNIT_MEASURE_FAILURE",
    COST_ESTIMATE_REQUEST = "conversion:COST_ESTIMATE_REQUEST",
    COST_ESTIMATE_SUCCESS = "conversion:COST_ESTIMATE_SUCCESS",
    COST_ESTIMATE_FAILURE = "conversion:COST_ESTIMATE_FAILURE",
    SWITCH_COST_UNIT = "conversion:SWITCH_COST_UNIT",
    SWITCH_UNIT_TYPE = "conversion:SWITCH_UNIT_TYPE"
}

/**
 * State types
 */
export interface ConversionState {
    readonly unitMeasure: UnitMeasure;
    readonly costEstimate: CostEstimate;
    readonly unitType: "MASS" | "VOLUME" | null;
    readonly loading: boolean;
    readonly error: boolean;
    readonly message: string;
}
