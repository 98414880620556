import styled from 'styled-components';
import { Icon } from '..';

export const Background = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: auto;
`;

export const Modal = styled.div`
    position: relative;
    min-height: 250px;
    width: 100%;
    max-width: 800px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 0 16px 0 rgba(117, 117, 117, 0.5);
    margin: auto;
`;

export const CloseBtn = styled(Icon)`
    font-size: 21px;
    position: absolute;
    top: 10px;
    right: 10px;

    &:hover {
        color: #444444;
        cursor: pointer;
    }
`;
