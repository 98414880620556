import styled from 'styled-components';
import { H2, IconButton } from '../../..';

export const StyledStep = styled.section`
    border-bottom: 1px solid #cccccc;
    padding-top: 28px;
    position: relative;

    &::after {
        content: ' ';
        display: block;
        height: 0;
        clear: both;
    }
`;

export const StepInfo = styled.section`
    line-height: normal;
    height: 26px;
    margin-bottom: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const StepNumber = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background-color: #763689;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
    padding-bottom: 3px;
`;

export const StepTitle = styled(H2)`
    line-height: 45px;
`;

export const StepEditBtn = styled(IconButton)`
    position: absolute;
    right: 0;
    top: 23px;
`;
