import { Button as SemanticButton } from 'semantic-ui-react';
import styled from 'styled-components';

export const StyledIconButton = styled(SemanticButton)`
    &.ui.button {
        height: 20px;
        background-color: transparent;
        height: 34px;
        width: 34px;
        box-shadow: none;
        padding: 0;
        margin: 0;

        &.ui.button > .icon:not(.button) {
            width: 12px;
            height: 14px;
            margin-left: 5px;
        }

        &:active,
        &:focus,
        &:hover {
            background-color: transparent;
            i {
                color: #763689 !important;
            }
        }
    }
`;
