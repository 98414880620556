import React from 'react';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import { Grid, Field, Input, Row } from '../../UI';
import { StyledColumn } from './index.styled';
import { withTranslation, WithTranslation } from 'react-i18next';
import { User } from '../../../models/user.model';

class UserProfileForm extends React.Component<Props, any> {
    constructor(props) {
        super(props);
        const { user } = this.props;
        this.state = {
            initialValues: {
                display_name: user.display_name,
                email: user.email,
                phone_number: user.phone_number
            }
        };
    }

    private get validationSchema() {
        return yup.object().shape({
            display_name: yup
                .string()
                .max(50)
                .required(),
            email: yup
                .string()
                .email()
                .max(50)
                .required(),
            phone_number: yup
                .string()
                .min(6)
                .max(15)
                .required()
        });
    }

    render() {
        const { initialValues } = this.state;
        const { children, t, onSubmit } = this.props;
        return (
            <Formik
                validationSchema={this.validationSchema}
                onSubmit={onSubmit}
                initialValues={initialValues}
            >
                {({ isValid, handleSubmit }) => {
                    return (
                        <Form>
                            <Grid>
                                <Row>
                                    <StyledColumn
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                    >
                                        <Field
                                            id="user_displayName"
                                            name="display_name"
                                            i18nKey="forms.userProfile.displayName"
                                            placeholder={t(
                                                'forms.userProfile.displayNamePlaceholder'
                                            )}
                                            component={Input}
                                        />
                                    </StyledColumn>
                                </Row>
                                <Row>
                                    <StyledColumn xs={12} sm={8} md={8} lg={8}>
                                        <Field
                                            id="user_email"
                                            name="email"
                                            i18nKey="forms.userProfile.email"
                                            placeholder={t(
                                                'forms.userProfile.emailPlaceholder'
                                            )}
                                            component={Input}
                                        />
                                    </StyledColumn>
                                    <StyledColumn xs={12} sm={4} md={4} lg={4}>
                                        <Field
                                            id="user_phoneNumber"
                                            name="phone_number"
                                            i18nKey="forms.userProfile.phoneNumber"
                                            placeholder={t(
                                                'forms.userProfile.phoneNumberPlaceholder'
                                            )}
                                            component={Input}
                                        />
                                    </StyledColumn>
                                </Row>
                                {children({
                                    onSave: handleSubmit,
                                    disableSave: !isValid
                                })}
                            </Grid>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}

interface Props extends WithTranslation {
    user: User;
    onSubmit(data): void;
    children?;
}

export default withTranslation('app')(UserProfileForm);
