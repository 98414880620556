import React from 'react';
import { User } from '../../../../../../models/user.model';
import {
    Container,
    Title,
    Subtitle,
    SearchContainer,
    DataList,
    DataListResources,
    Data
} from './index.styled';
import {
    Line,
    Grid,
    Row,
    Column,
    Input,
    Pagination,
    IconButton,
    Span,
    confirmDelete
} from '../../../../../../components/UI';
import { RouteComponentProps } from 'react-router-dom';
import { removeObjectReference } from '../../../../../../helpers/util';

class UsersContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            onHover: null,
            currentPage: 1,
            selecteds: []
        };
    }

    private handleMouseEnter = id => {
        this.setState({ onHover: id });
    };

    private handleMouseLeave = () => {
        this.setState({ onHover: null });
    };

    private addSelected = (id: number) => {
        const { selecteds } = this.state;
        this.setState({ selecteds: [...selecteds, id] });
    };

    private removeSelected = (id: number) => {
        const { selecteds } = this.state;
        const index = selecteds.findIndex(item => item === id);
        selecteds.splice(index, 1);
        this.setState({ selecteds });
    };

    private deleteUser = user => {
        const { deleteUser, limit, searchUser, users } = this.props;
        const { value, currentPage } = this.state;
        return new Promise(resolve => {
            confirmDelete(
                user.email,
                () => {
                    deleteUser(user, () => {
                        const { selecteds } = this.state;
                        let page = currentPage;
                        const userIndex = selecteds.findIndex(
                            id => id === user.id
                        );
                        selecteds.splice(userIndex, 1);
                        if (users.length === 1 && currentPage > 1) {
                            page = currentPage - 1;
                        }
                        this.setState({ selecteds }, () => {
                            const search = {
                                q: value,
                                limit,
                                page
                            };
                            searchUser(search);
                            resolve();
                        });
                    });
                },
                resolve
            );
        });
    };

    private deleteAllSelecteds = async () => {
        const { selecteds } = this.state;
        const { users } = this.props;
        for await (const id of removeObjectReference(selecteds)) {
            const user = users.find(user => user.id === id);
            if (user) {
                await this.deleteUser(user);
            }
        }
    };

    private handleSearchChange = e => {
        const { searchUser, limit } = this.props;
        const value = e.target.value;
        const search = {
            q: value,
            limit,
            page: 1
        };
        this.setState({ value }, () => searchUser(search));
    };

    private handlePageChange = page => {
        const { limit, searchUser } = this.props;
        const { value } = this.state;
        const search = {
            q: value,
            limit,
            page: page + 1
        };
        this.setState(
            {
                currentPage: page + 1
            },
            () => searchUser(search)
        );
    };

    private selectAll = () => {
        const { users } = this.props;
        this.setState({
            selecteds: users.map(user => user.id!)
        });
    };

    private unselectAll = () => {
        this.setState({
            selecteds: []
        });
    };

    private openUser = id => {
        const { history } = this.props;
        history.push(`/master/users/user/${id}`);
    };

    private createRow(user: Partial<User>) {
        const { onHover, selecteds } = this.state;
        const { id, display_name, email, groups_count } = user;
        return (
            <DataList.Row
                key={id}
                onClick={() => this.openUser(id)}
                onMouseEnter={() => this.handleMouseEnter(id)}
                onMouseLeave={this.handleMouseLeave}
            >
                <DataList.Cell textAlign="center">
                    <DataList.Checkbox
                        onClick={e => e.stopPropagation()}
                        value={
                            selecteds.find(item => item === id) ? true : false
                        }
                        onChange={(_, { checked }) =>
                            checked
                                ? this.addSelected(id!)
                                : this.removeSelected(id!)
                        }
                    />
                </DataList.Cell>
                <DataList.Cell>{display_name}</DataList.Cell>
                <DataList.Cell>{email}</DataList.Cell>
                <DataList.Cell textAlign="center">
                    {onHover !== id ? (
                        groups_count
                    ) : (
                        <DataList.DeleteBtn
                            onClick={e => {
                                e.stopPropagation();
                                this.deleteUser(user);
                            }}
                            iconName="trash-alt"
                            iconSize={18}
                        />
                    )}
                </DataList.Cell>
            </DataList.Row>
        );
    }

    private renderUsers() {
        const { users } = this.props;
        return users.map(user => this.createRow(user));
    }

    render() {
        const { loading, users, paging } = this.props;
        const { selecteds, currentPage } = this.state;
        return (
            <Container>
                <Grid>
                    <Row>
                        <Column xs={12} sm={6} md={6} lg={6}>
                            <Title i18nKey="master.users.title" />
                        </Column>
                        <Column
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            flex={{ align: 'center', justify: 'end' }}
                        ></Column>
                    </Row>
                </Grid>
                <Line />
                <SearchContainer>
                    <Input
                        icon="search"
                        placeholder="master.users.search.placeholder"
                        onChange={this.handleSearchChange}
                    />
                </SearchContainer>
                <Subtitle i18nKey="master.users.subtitle" />
                <Line />
                <DataListResources>
                    <Grid>
                        <Row>
                            <Column
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                flex={{ align: 'center', justify: 'start' }}
                            >
                                <DataListResources.Checkbox
                                    indeterminate={
                                        selecteds.length > 0 &&
                                        selecteds.length < users.length
                                    }
                                    value={
                                        selecteds.length === users.length &&
                                        users.length > 0
                                    }
                                    onClick={() => {
                                        selecteds.length !== users.length &&
                                        users.length > 0
                                            ? this.selectAll()
                                            : this.unselectAll();
                                    }}
                                />
                                {selecteds.length > 0 && (
                                    <IconButton
                                        onClick={this.deleteAllSelecteds}
                                        iconName="trash-alt"
                                        iconSize={18}
                                    />
                                )}
                            </Column>
                            <Column
                                xs={12}
                                sm={6}
                                md={6}
                                lg={6}
                                flex={{ align: 'center', justify: 'end' }}
                            >
                                <Pagination
                                    forcePage={currentPage - 1}
                                    pageCount={paging.total_pages}
                                    handlePageClick={({ selected }) =>
                                        this.handlePageChange(selected)
                                    }
                                />
                            </Column>
                        </Row>
                    </Grid>
                </DataListResources>
                <Data>
                    <DataList>
                        <DataList.Header>
                            <DataList.HeaderRow>
                                <DataList.HeaderCell
                                    width={1}
                                    textAlign="center"
                                />
                                <DataList.HeaderCell width={5}>
                                    <Span i18nKey="master.users.table.header.userName" />
                                </DataList.HeaderCell>
                                <DataList.HeaderCell width={5}>
                                    <Span i18nKey="master.users.table.header.userEmail" />
                                </DataList.HeaderCell>
                                <DataList.HeaderCell
                                    width={1}
                                    textAlign="center"
                                >
                                    <DataList.UsersIcon name="users-alt" />
                                </DataList.HeaderCell>
                            </DataList.HeaderRow>
                        </DataList.Header>
                        <DataList.Body>{this.renderUsers()}</DataList.Body>
                    </DataList>
                    {loading && (
                        <Data.LoadingOverlay>
                            <Data.Loading />
                        </Data.LoadingOverlay>
                    )}
                </Data>
            </Container>
        );
    }
}

interface Props extends RouteComponentProps {
    users: Partial<User>[];
    loading: boolean;
    limit: number;
    paging: {
        total_items: number;
        total_pages: number;
    };
    searchUser(filter, callback?): void;
    deleteUser(user: Partial<User>, callback?: Function): void;
}

interface State {
    value: string;
    onHover: number | null;
    currentPage: number;
    selecteds: number[];
}

export default UsersContainer;
