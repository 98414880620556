import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TermsContainer from './container';
import { ApplicationState } from '../../../../../redux/store';
import { Term } from '../../../../../models/term.model';
import { getTerms } from '../../../../../redux/actions/terms.actions';

class Terms extends React.Component<Props, {}> {
    render() {
        return <TermsContainer {...this.props} />;
    }

    componentWillMount() {
        const { getTerms } = this.props;
        getTerms();
    }
}

interface Props {
    terms: Term[];
    getTerms(): void;
}

const mapStateToProps = (state: ApplicationState) => ({
    terms: state.terms.items
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getTerms
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Terms);
