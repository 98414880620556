import React from 'react';
import _ from 'lodash';
import PolymersList from '../PolymersList';
import { Polymer } from '../../../../../../../models/polymer.model';
import { StepButtons } from '../../../../../../../components/UI/Wizard/StepButtons';
import { IWizardContext } from '../../../../../../../components/UI/Wizard';
import Search from '../../../../../../../components/UI/DocumentSearch';
import SearchModel from '../../../../../../../models/search.model';
import { htmlT } from '../../../../../../../helpers/util';

class Expanded extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            polymerSelected: null,
            polymerSearchQuery: '',
            polymersList: []
        };
    }

    private removePolymer = (polymer: Polymer) => {
        const { polymersList } = this.state;
        this.setState({
            polymersList: polymersList.filter(item => item.id !== polymer.id)
        });
    };

    private renderStepSelect = () => {
        const { polymersList } = this.state;
        const { onSearchChange, t, limit, startSearch } = this.props;
        const i18nNameRefer = t('simulation.stepPolymer.resin');
        const placeHolder = t('search.simulation.placeHolder', {
            i18nNameRefer
        });
        const noResultMessage = searchQuery =>
            htmlT(t, 'search.simulation.notFound', {
                name: `<b>${searchQuery}</b>`,
                i18nNameRefer
            });
        return (
            <Search
                id="StepPolymer_polymerSelect"
                type="POLYMER"
                startSearch={startSearch}
                stateValues={polymersList.map(polymer => polymer.id)}
                i18nKey="simulation.stepPolymer.polymerSelection.selectLabel"
                placeHolder={placeHolder}
                limit={limit}
                onChange={item => {
                    onSearchChange(item, polymer => {
                        this.setState({
                            polymersList: [...polymersList, polymer]
                        });
                    });
                }}
                noResultsMessage={noResultMessage}
            />
        );
    };

    private handleNext = () => {
        const { onNext, savePolymersList } = this.props;
        const { polymersList } = this.state;
        savePolymersList(polymersList);
        onNext(polymersList);
    };

    render() {
        const {
            limit,
            stepNumber,
            nextStep,
            previousStep,
            currentStep,
            stepProgress,
            wizardSize,
            polymersList: polymersListProps
        } = this.props;
        const { polymersList } = this.state;
        return (
            <React.Fragment>
                {this.renderStepSelect()}
                <PolymersList
                    polymers={polymersList}
                    limit={limit}
                    removePolymer={this.removePolymer}
                />
                <StepButtons
                    fromStep={stepNumber}
                    disableNext={
                        polymersList.length === 0 ||
                        _.isEqual(polymersList, polymersListProps)
                    }
                    onNext={() => nextStep!(this.handleNext)}
                    onSave={() => previousStep!(this.handleNext)}
                    onCancel={() => previousStep!()}
                    currentStep={currentStep}
                    stepProgress={stepProgress}
                    wizardSize={wizardSize}
                />
            </React.Fragment>
        );
    }

    componentWillMount() {
        const { polymersList } = this.props;
        this.setState({
            polymersList
        });
    }
}

export interface Props extends Partial<IWizardContext> {
    polymers: Polymer[];
    polymersList: Polymer[];
    limit: number;
    loading: boolean;
    startSearch: number;
    stepNumber: number;
    savePolymersList(polymers: Polymer[]);
    onNext(polymers: Polymer[]);
    onSearchChange(item: SearchModel, callback: Function);
    onChange?(polymers: Polymer[]): void;
    t: Function;
}

export interface State {
    polymersList: Polymer[];
    polymerSelected: Polymer | null;
    polymerSearchQuery: string;
}

export default Expanded;
