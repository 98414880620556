import React, { ComponentClass, FunctionComponent } from 'react';
import { Container } from './index.styled';
import {
    Error,
    Label,
    Input,
    IInputProps,
    IInputMaskProps,
    IInputNumberProps
} from '..';
import { Field as FormikField } from 'formik';

export const Field = ({
    name,
    validate,
    disabled,
    i18nKey,
    placeholder,
    isTouched,
    component: Component,
    componentLabel,
    ...rest
}: IFieldProps) => {
    return (
        <FormikField name={name} validate={validate}>
            {({ field, form }) => {
                const { setFieldValue, setFieldTouched } = form;
                const hasError: string | undefined =
                    form && form.errors[field!.name]
                        ? form.errors[field!.name]
                        : undefined;
                const hasTouched = form
                    ? Boolean(form.touched[field!.name])
                    : false;
                if (componentLabel && i18nKey) {
                    rest['i18nKey'] = i18nKey;
                }
                return (
                    <Container>
                        {i18nKey && !componentLabel && (
                            <Label disabled={disabled} i18nKey={i18nKey} />
                        )}
                        <Component
                            {...field}
                            {...rest}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            disabled={disabled}
                            error={(hasError && hasTouched) || isTouched}
                            placeholder={placeholder}
                            {...rest}
                        />
                        <Error message={hasError} touched={hasTouched} />
                    </Container>
                );
            }}
        </FormikField>
    );
};

Field.defaultProps = {
    component: Input,
    placeholder: '',
    componentLabel: false
};

export interface IFieldProps
    extends IInputProps,
        IInputMaskProps,
        IInputNumberProps {
    name: string;
    component: ComponentClass<any, any> | FunctionComponent<any>;
    componentLabel: boolean;
    validate?: Function;
    placeholder?: string;
    isTouched?: boolean;
}
