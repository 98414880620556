import styled from 'styled-components';
import { H3, Button, IconButton } from '../../UI';

export const Container = styled.section`
    padding: 20px 0;
`;

export const Data: any = styled.div`
    position: relative;
    border: 0.5px solid #cccccc;
    border-radius: 4px;
    box-shadow: 0px 0px 0 0 rgba(68, 68, 68, 0.2),
        1px 1px 4px 2px rgba(204, 204, 204, 0.15);
    padding: 24px;
    padding-bottom: 12px;
    background-color: #fff;
`;

Data.EditBtn = styled(IconButton)`
    position: absolute;
    top: 24px;
    right: 68px;
`;

Data.DeleteBtn = styled(IconButton)`
    position: absolute;
    top: 24px;
    right: 24px;
`;

export const DataList: any = styled.div`
    position: relative;
`;

DataList.EditBtn = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
`;

DataList.Content = styled.div`
    padding-top: 14px;
`;

export const Title = styled(H3)`
    height: auto;
`;

export const Content = styled.div`
    padding: 14px 0;
`;

export const Buttons: any = styled.div`
    width: 100%;
    text-align: right;
    padding-top: 30px;
`;

Buttons.Save = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: bold;
        margin: 4px 0;
        margin-left: 32px;
    }
`;

Buttons.Cancel = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: bold;
        margin: 4px 0;
    }
`;
