import React from 'react';
import Data from './Data';

class JuridicalStep extends React.Component<Props, {}> {
    static Data = Data;

    render() {
        const { children, ...rest } = this.props;
        return <div>{children(rest)}</div>;
    }
}

interface Props {
    user;
    children;
}

export default JuridicalStep;
