import { combineReducers } from 'redux';
import polymers from './polymers';
import paints from './paints';
import solvents from './solvents';
import conversion from './conversion';
import simulation from './simulation';
import user from './user';
import content from './content';
import search from './search';
import groups from './groups';
import users from './users';
import terms from './terms';
import projects from './projects';
import properties from './properties';

export default combineReducers({
    polymers,
    paints,
    solvents,
    conversion,
    simulation,
    user,
    content,
    search,
    groups,
    users,
    terms,
    projects,
    properties
});
