import styled from 'styled-components';
import { Label } from '../../../../../components/UI';

export const Container = styled.section`
    margin: 24px 0;
`;

export const CardLabel = styled(Label)`
    color: #a6a6a6;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 20px;
    margin-left: 2px;
`;
