import styled from 'styled-components';
import { IconButton, Icon, Checkbox } from '../../../../../../components/UI';
import posed from 'react-pose';
import { tween } from 'popmotion';

export const Container = styled.section`
    display: flex;
    align-items: center;
    justify-items: center;

    ${props => {
        if (!props['data-expanded']) {
            return `
                position: relative;
            `;
        } else {
            return `
                position: fixed;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 20;
            `;
        }
    }}
`;

const PosedGraph = posed.div({
    fullscreen: {
        width: '100vw',
        height: '100vh',
        transition: tween
    },
    idle: {
        width: '100%',
        height: 500,
        transition: tween
    }
});

export const Graph = styled(PosedGraph)`
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: hidden;

    canvas {
        width: 100% !important;
        height: 100% !important;
    }
`;

export const ButtonsBar: any = styled.div`
    position: absolute;
    right: 15px;
    top: 15px;
    height: calc(100% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
`;

export const ButtonsContainer: any = styled.div`
    display: flex;
    flex-direction: column;
`;

ButtonsBar.Icon = styled(Icon)`
    transform: rotate(45deg);
    padding-bottom: 14px;
`;

ButtonsBar.Button = styled(IconButton)`
    &.ui.button {
        height: 40px;
        width: 40px;
        border: 0.5px solid #dddddd;
        border-radius: 4px;
        background-color: #ffffff !important;
        margin: 10px 0;
        box-shadow: 0 0 0 0 rgba(68, 68, 68, 0.2),
            0 0 4px 2px rgba(204, 204, 204, 0.15);
    }

    &i {
        color: #656565;
    }
`;

ButtonsBar.TopButton = styled(ButtonsBar.Button)`
    &.ui.button {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0;
    }
`;

ButtonsBar.BottomButton = styled(ButtonsBar.Button)`
    &.ui.button {
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;
    }
`;

export const ComparationBar: any = styled.div`
    position: absolute;
    left: 15px;
    top: 15px;
    display: flex;
    flex-direction: column;
`;

ComparationBar.Checkbox = styled(Checkbox)`
    & > label {
        font-size: 14px;
        line-height: 24px;
        padding-left: 5px;
        display: inline-block;
    }
`;
