export class UnitMeasure {
    measurement: string | null;
    reference_quantity?: number;
    conversions: ConversionUnitMeasure[];

    constructor(
        measurement: string,
        conversions: ConversionUnitMeasure[],
        reference_quantity: number
    ) {
        this.measurement = measurement;
        this.reference_quantity = reference_quantity;
        this.conversions = conversions;
    }
}

export class CostEstimate {
    totalCost: number;
    measurement: Measurement;
    estimates: Estimate[];

    constructor(
        measurement: Measurement,
        estimates: Estimate[],
        totalCost: number
    ) {
        this.measurement = measurement;
        this.estimates = estimates;
        this.totalCost = totalCost;
    }
}

export class ConversionUnitMeasure {
    id: number;
    concentration: number;

    constructor(id: number, concentration: number) {
        this.id = id;
        this.concentration = concentration;
    }
}

export class Measurement {
    cost: string;
    concentration: string;

    constructor(cost: string, concentration: string) {
        this.cost = cost;
        this.concentration = concentration;
    }
}

export class Estimate {
    id: number;
    concentration: number;
    cost: number;

    constructor(id: number, concentration: number, cost: number) {
        this.id = id;
        this.concentration = concentration;
        this.cost = cost;
    }
}
