import styled from 'styled-components';
import {
    Grid,
    Label,
    Button,
    Span,
    IconButton
} from '../../../../../../../components/UI';

export const Section = styled.section`
    z-index: 1;
    position: relative;
`;

export const StyledGrid = styled(Grid)`
    height: 87px;
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #ccc;
    display: flex;
    align-items: center;
    overflow-x: auto;

    & > .row {
        min-width: 1200px;
    }

    .row {
        width: 100%;
        display: flex;
        align-items: center;
    }
`;

export const SelectedLabel = styled(Label)`
    color: #a6a6a6;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 20px;
    font-weight: normal;
    text-align: right;
`;

export const SelectedBox: any = styled.div`
    height: 40px;
    width: 100%;
    border-radius: 4px;
    background-color: rgba(118, 54, 137, 0.08);
    display: flex;
    align-items: center;
    padding: 0px 16px;
`;

SelectedBox.Name = styled(Span)`
    color: #763689;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.3px;
    line-height: 20px;
    display: inline-block;
    width: 100%;
`;

SelectedBox.Padlock = styled(IconButton)`
    &.ui.button i {
        color: #656565;
        font-size: 20px;
    }
`;

export const UnselectedBox: any = styled.div`
    height: 40px;
    width: 100%;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 16px;
    border: 1px solid #a6a6a6;
    border-style: dashed;
`;

UnselectedBox.Span = styled(Span)`
    color: #a6a6a6;
    font-size: 14px;
    letter-spacing: 0.26px;
    line-height: 18px;
`;

export const CompareButton = styled(Button)`
    &.ui.button {
        font-size: 16px;
        font-weight: bold;
    }
`;
