import React from 'react';
import { NavLink } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import { DropdownItemProps } from 'semantic-ui-react';
import i18next from 'i18next';
import { slide as Menu } from 'react-burger-menu';
import eua from '../../../../../assets/images/flags/eua.svg';
import br from '../../../../../assets/images/flags/brazil.svg';
import es from '../../../../../assets/images/flags/spain.svg';
import logo from '../../../../../assets/images/logo.png';
import logoWhite from '../../../../../assets/images/logo_white.png';
import { Column, Grid, Row, Empty } from '../../../../UI';
import { User as UserModel } from '../../../../../models/user.model';
import { Span } from "../../../../UI/Span";
import {
    Language,
    Logo,
    Navigation,
    Container,
    Unauthenticated,
    User,
    Authenticated,
    height,
    MobileMenu,
    Contact,
    ContactContent
} from './index.styled';
import { dialog } from '../../../../UI';
import { Project } from '../../../../../models/project.model';
//@ts-ignore
import manualPDF from "../../../../../assets/manual.pdf";

class TopbarContainer extends React.Component<
    TopbarContainerProps,
    TopbarContainerState
    > {
    constructor(props) {
        super(props);
        this.state = {
            fixed: false,
            showMobileMenu: false,
            language: i18next.language
        };
    }

    private handleScroll = e => {
        const { fixed } = this.state;
        const path = e.path || (e.composedPath && e.composedPath());
        if (!path) {
            return;
        }
        if (path[1].scrollY > height) {
            this.setState({ fixed: true });
        } else if (fixed) {
            this.setState({ fixed: false });
        }
    };

    private isMenuOpen = ({ isOpen }) => {
        this.setState({ showMobileMenu: isOpen });
    };

    private closeMobileMenu = () =>
        this.setState({
            showMobileMenu: false
        });

    private isActive = ({ pathname, search }, location, params?) =>
        Array.isArray(location)
            ? location.find(url => url === pathname)
            : !search ? pathname.includes(location) : pathname.includes(location) && search === params;

    private handleAuthenticatedExit = (callback) => {
        const { project, isChanged, saveProject } = this.props;
        this.closeMobileMenu();
        if (isChanged && project) {
            dialog({
                type: 'delete',
                header: {
                    i18nKey: 'simulation.topbar.exit.title'
                },
                body: {
                    i18nKey: 'simulation.topbar.exit.message'
                },
                buttons: {
                    confirm: {
                        i18nKey: 'simulation.topbar.exit.buttons.exit',
                        event: callback
                    },
                    cancel: {
                        i18nKey: 'simulation.topbar.exit.buttons.saveChanges',
                        event: () => {
                            saveProject(project, callback);
                        }
                    }
                }
            });
        } else {
            callback();
        }
    };

    private renderLogo = () => {
        const { location } = this.props;
        const { fixed } = this.state;
        return (
            <Logo id="Topbar_logo">
                <Link to="/">
                    <img
                        src={
                            location.pathname === '/' && fixed
                                ? logoWhite
                                : logo
                        }
                        alt="logo"
                    />
                </Link>
            </Logo>
        );
    };

    private renderNavigation = () => {
        const { user, location, history } = this.props;
        const { fixed } = this.state;

        if (location.pathname === '/' && !user) {
            return <Empty />;
        }

        return (
            <Navigation
                id="Topbar_navigation"
                className={fixed && 'navigation-fixed'}
            >
                <Navigation.ul>
                    {user && (
                        <React.Fragment>
                            {user.privileges && (
                                <Navigation.li>
                                    <Navigation.Link
                                        renderText="Master"
                                        onClick={() => this.handleAuthenticatedExit(() => history.push('/master'))}
                                        className={
                                            this.isActive(location, '/master') ? 'active' : ''
                                        }
                                    />
                                </Navigation.li>
                            )}
                            <Navigation.li>
                                    <Navigation.Link
                                        i18nKey="topbar.navigation.projects"
                                        onClick={() => this.handleAuthenticatedExit(() => history.push('/projects'))}
                                        className={
                                            this.isActive(location, [
                                            '/simulation',
                                            '/projects'
                                            ]) ? 'active' : ''
                                        }
                                    />
                            </Navigation.li>
                        </React.Fragment>
                    )}
                </Navigation.ul>
            </Navigation>
        );
    };

    private renderMobileMenu() {
        const { user, location, signOut, history } = this.props;
        const { showMobileMenu, fixed } = this.state;

        return (
            <MobileMenu styledProps={{ fixed, pathname: location.pathname }}>
                <Menu
                    isOpen={showMobileMenu}
                    onStateChange={this.isMenuOpen}
                    disableAutoFocus
                >
                    <Navigation>
                        <Navigation.ul>
                            {user && (
                                <React.Fragment>
                                    { user.privileges && (
                                        <Navigation.li>
                                            <Navigation.Link
                                                renderText="Master"
                                                onClick={() => this.handleAuthenticatedExit(() => history.push('/master'))}
                                                className={
                                                    this.isActive(location, '/master') ? 'active' : ''
                                                }
                                            />
                                        </Navigation.li>
                                    )}
                                    <Navigation.li>
                                        <Navigation.Link
                                            i18nKey="topbar.navigation.projects"
                                            onClick={() => this.handleAuthenticatedExit(() => history.push('/projects'))}
                                            className={
                                                this.isActive(location, [
                                                '/simulation',
                                                '/projects'
                                                ]) ? 'active' : ''
                                            }
                                        />
                                    </Navigation.li>
                                </React.Fragment>
                            )}
                            {!user && (
                                <React.Fragment>
                                    <Navigation.li>
                                        <NavLink
                                            to="/sign?signIn"
                                            activeClassName="active"
                                            onClick={this.closeMobileMenu}
                                            isActive={(_, match) =>
                                                this.isActive(match, '/sign', '?signIn')
                                            }
                                        >
                                            <Navigation.Link i18nKey="topbar.user.signIn" />
                                        </NavLink>
                                    </Navigation.li>
                                    <Navigation.li>
                                        <NavLink
                                            to="/sign?signUp"
                                            activeClassName="active"
                                            onClick={this.closeMobileMenu}
                                            isActive={(_, match) =>
                                                this.isActive(match, '/sign', '?signUp')
                                            }
                                        >
                                            <Navigation.Link i18nKey="topbar.user.signUp" />
                                        </NavLink>
                                    </Navigation.li>
                                </React.Fragment>
                            )}
                            {user && (
                                <Navigation.li>
                                    <Navigation.Link
                                        i18nKey="exit"
                                        onClick={() => this.handleAuthenticatedExit(signOut)}
                                    />
                                </Navigation.li>
                            )}
                        </Navigation.ul>
                    </Navigation>
                </Menu>
            </MobileMenu>
        );
    }

    private renderLanguageSelector = () => {
        const { language } = this.state;
        const { onChangeLanguage } = this.props;
        const options: DropdownItemProps[] = [
            {
                image: br,
                key: 'pt_br',
                text: 'PT-BR',
                value: 'pt_br'
            },
            {
                image: eua,
                key: 'en_us',
                text: 'EN-US',
                value: 'en_us'
            },
            {
                image: es,
                key: 'es_es',
                text: 'ES-ES',
                value: 'es_es'
            }
        ];
        return (
            <Language>
                <Language.Select
                    id="Topbar_language"
                    fluid
                    value={language}
                    options={options}
                    onChange={(_, { value }) => {
                        onChangeLanguage(value);
                        this.setState({ language: value });
                    }}
                />
            </Language>
        );
    };

    private renderUser = () => {
        const { fixed } = this.state;
        const { user, signOut } = this.props;
        return (
            <User id="Topbar_user">
                {!user ? (
                    <Unauthenticated id="Topbar_user_unauthenticated">
                        <Unauthenticated.SignInButtonLink to="/sign?signIn">
                            <Unauthenticated.SignInButton
                                id="Topbar_user_sign_in"
                                i18nKey="topbar.user.signIn"
                                cl="invertedPrimary"
                                className={fixed && 'btn-signin-fixed'}
                            />
                        </Unauthenticated.SignInButtonLink>
                        <Link to="/sign?signUp">
                            <Unauthenticated.SignUpButton
                                id="Topbar_user_sign_up"
                                i18nKey="topbar.user.signUp"
                                cl="primary"
                            />
                        </Link>
                    </Unauthenticated>
                ) : (
                    <Authenticated id="Topbar_user_authenticated">
                        <div>
                            <Authenticated.Greetings
                                id="Topbar_user_authenticated_greetings"
                                i18nKey={[
                                    'topbar.user.greetings',
                                    {
                                        name: user.display_name
                                            ? user.display_name.split(' ')[0]
                                            : 'User'
                                    }
                                ]}
                            />
                            <Authenticated.NeedHelp
                                target="_blank"
                                href={manualPDF}
                            >
                                <Span i18nKey="topbar.user.needHelp" />
                            </Authenticated.NeedHelp>
                        </div>
                        <Authenticated.Exit
                            icon="exit"
                            i18nKey="topbar.user.exit"
                            onClick={() => this.handleAuthenticatedExit(signOut)}
                        />
                    </Authenticated>
                    )}
            </User>
        );
    };

    private renderContact = () => {

        return(
            <Contact>
                <ContactContent id="Topbar_contact">
                    <div>
                    <ContactContent.Message
                    id='Topbar_contact_message'
                    i18nKey="topbar.navigation.contact">
                    </ContactContent.Message>
                    <ContactContent.Email
                    id='Topbar_contact_email'
                    >
                        <span>solsys.support@solvay.com</span>
                    </ContactContent.Email>
                    </div>                        
                </ContactContent>
            </Contact>
        )
    }

    render() {
        const { location, user } = this.props;
        const { fixed } = this.state;
        return (
            <Container
                id="Topbar"
                styledProps={{
                    location: location.pathname,
                    fixed,
                    user
                }}
            >
                <Grid>
                    <Row flex="start">
                        <Column flex="start" xs={2} sm={0} md={0} lg={0}>
                            {this.renderMobileMenu()}
                        </Column>
                        <Column flex="start" xs={4} sm={1} md={1} lg={1}>
                            {this.renderLogo()}
                        </Column>
                        <Column flex="start" xs={0} sm={5} md={5} lg={5}>
                            {this.renderNavigation()}
                        </Column>
                        <Column flex="end" xs={6} sm={6} md={6} lg={6}>
                            {this.renderUser()}
                            {this.renderLanguageSelector()}
                            {this.renderContact()}
                        </Column>
                    </Row>
                </Grid>
            </Container>
        );
    }

    componentDidMount() {
        document.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScroll);
    }
}

export interface TopbarContainerProps extends RouteComponentProps {
    user?: UserModel;
    isChanged: boolean;
    project: Project | null;
    signOut(): void;
    saveProject(data, callback): void;
    onChangeLanguage(value: string): void;
}

interface TopbarContainerState {
    fixed: boolean;
    language: string;
    showMobileMenu: boolean;
}

export default TopbarContainer;
